import { Box } from '@mui/material';

import { useGetBrandColor } from 'src/hooks/hooks';

export const BrandIcon = ({ index }: { index: number }) => {
  const color = useGetBrandColor(index);

  return (
    <Box
      bgcolor={color}
      borderRadius='3px'
      display='block'
      height='20px'
      maxWidth='15px'
      minWidth='15px'
    />
  );
};
