import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { CsatSubmissionTranscriptComponent } from './types';
import { ResponseMessage } from 'src/pages/solve-config/styledComponents';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const CsatSubmissionStep = ({
  transcriptComponent,
}: {
  transcriptComponent: CsatSubmissionTranscriptComponent;
}) => {
  const { theme_color: themeColor } = useSelector(selectWidgetConfiguration);

  return (
    <ResponseMessage backgroundColor={themeColor}>
      <Box display='flex' flexDirection='column' gap='4px'>
        <CsatField name='CSAT score' value={transcriptComponent.csat_score} />

        <CsatField
          name='Reasons for rating'
          value={
            transcriptComponent.csat_feedback &&
            transcriptComponent.csat_feedback.length > 0
              ? transcriptComponent.csat_feedback.join(',')
              : undefined
          }
        />

        <CsatField
          name='Additional Feedback'
          value={transcriptComponent.additional_feedback}
        />

        <CsatField
          name='Resolution Confirmation'
          value={transcriptComponent.csat_resolve}
        />
      </Box>
    </ResponseMessage>
  );
};

const CsatField = ({
  name,
  value,
}: {
  name: string;
  value?: string | boolean;
}) => {
  const fontColor = useSelector(selectLegibleTextColor);

  return (
    <Box display='flex' flexDirection='column'>
      <Typography color={fontColor} variant='font14Medium'>
        {name}:
      </Typography>
      <Typography color={fontColor} variant='font14Medium'>
        {value || 'n/a'}
      </Typography>
    </Box>
  );
};
