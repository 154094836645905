import { UserRoles } from 'src/utils/enums';
import { Permission, Routes } from 'src/utils/enums';

export const ROUTE_PREDICTIONS = {
  path: Routes.PREDICTIONS,
  permissionKey: Permission.PREDICTIONS,
};

export const ROUTE_PREDICTION_SETTINGS = {
  path: Routes.PREDICTIONS_SETTINGS,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_PREDICTION_SETTINGS_DETAIL = {
  path: Routes.PREDICTIONS_SETTINGS_DETAIL,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_SEARCH = {
  path: Routes.SEARCH,
  permissionKey: Permission.SEARCH,
};

export const ROUTE_SOLVE = {
  path: Routes.SOLVE,
  permissionKey: Permission.SOLVE,
};

export const ROUTE_SOLVE_ANALYTICS_V2 = {
  path: Routes.SOLVE_ANALYTICS_V2,
  permissionKey: Permission.SOLVE_ANALYTICS_V2,
};

export const ROUTE_ANSWERS = {
  path: Routes.ANSWERS,
  permissionKey: Permission.ANSWERS,
};

export const ROUTE_MACRO_CONTROLS = {
  path: Routes.MACRO_CONTROLS,
  permissionKey: Permission.MACRO_CONTROLS,
};

export const ROUTE_SOLVE_LITE = {
  path: Routes.SOLVE_LITE,
  permissionKey: Permission.SOLVE_LITE,
};

export const ROUTE_SOLVE_PREVIEW = {
  path: Routes.SOLVE_PREVIEW,
  permissionKey: Permission.SOLVE_PREVIEW,
};

export const ROUTE_WORKFLOW_BUILDER = {
  path: Routes.WORKFLOW_BUILDER,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_IR_REPORT = {
  path: Routes.IR_REPORT,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_WORKFLOW_BUILDER_EDIT = {
  path: Routes.WORKFLOW_BUILDER_EDIT,
  permissionKey: Permission.FLAMETHROWER_WORKFLOW_BUILDER_EDIT,
};

export const ROUTE_WORKFLOW_BUILDER_EDIT_EMAIL_PREVIEW = {
  path: Routes.WORKFLOW_BUILDER_EDIT_EMAIL_PREVIEW,
  permissionKey: Permission.FLAMETHROWER_WORKFLOW_BUILDER_EDIT,
};

export const ROUTE_ACCOUNT_SETTINGS = {
  path: Routes.ACCOUNT_SETTINGS,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_SOLVE_CONFIGURATION = {
  path: Routes.SOLVE_CONFIGURATION,
  permissionKey: Permission.WORKFLOW_BUILDER,
};

export const ROUTE_AUTONOMOUS_AGENT_PREVIEW = {
  path: Routes.AUTONOMOUS_AGENT_PREVIEW,
  permissionKey: Permission.WORKFLOW_BUILDER,
};

export const ROUTE_WORKFLOW_BUILDER_PREVIEW = {
  path: Routes.WORKFLOW_BUILDER_PREVIEW,
  permissionKey: Permission.WORKFLOW_BUILDER,
};

export const ROUTE_INTEGRATIONS = {
  path: Routes.INTEGRATIONS,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_TEAM = {
  path: Routes.TEAM,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_SSO_SETTINGS = {
  path: Routes.SSO_SETTINGS,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_ACTION_BUILDER = {
  path: Routes.ACTION_BUILDER,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_ACTION_BUILDER_EDIT_V2 = {
  path: Routes.ACTION_BUILDER_EDIT_V2,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_CUSTOM_ANALYTICS = {
  path: Routes.CUSTOM_ANALYTICS,
  permissionKey: Permission.CUSTOM_ANALYTICS,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_LOOKER_ANALYTICS = {
  path: Routes.LOOKER_ANALYTICS,
  permissionKey: Permission.LOOKER_ANALYTICS,
};

export const ROUTE_ASSIST_AUTOMATIONS = {
  path: Routes.ASSIST_AUTOMATIONS,
  permissionKey: Permission.ASSIST_AUTOMATIONS,
};

export const ROUTE_ASSIST_NOTES = {
  path: Routes.ASSIST_NOTES,
  permissionKey: Permission.ASSIST,
};

export const ROUTE_ASSIST_AUTOMATIONS_CREATE = {
  path: Routes.ASSIST_AUTOMATIONS_CREATE,
  permissionKey: Permission.ASSIST_AUTOMATIONS,
};

export const ROUTE_DISCOVER_DASHBOARD = {
  path: Routes.DISCOVER_DASHBOARD,
  permissionKey: Permission.DISCOVER,
};

export const ROUTE_DISCOVER_TOPICS = {
  path: Routes.DISCOVER_TOPICS,
  permissionKey: Permission.DISCOVER,
};

export const ROUTE_DISCOVER_AUTOMATION = {
  path: Routes.DISCOVER_AUTOMATION,
  permissionKey: Permission.DISCOVER,
  search: '?tab=Recommendation',
};

export const ROUTE_TOPIC_DETAIL = {
  path: Routes.DISCOVER_TOPIC_DETAIL,
  permissionKey: Permission.DISCOVER,
};

export const ROUTE_PREDICT_INTENTS = {
  path: Routes.PREDICT_INTENTS,
  permissionKey: Permission.PREDICT_INTENTS,
};

export const ROUTE_PREDICT_INTENTS_TEST_DETAIL = {
  path: Routes.PREDICT_INTENTS_TEST_DETAIL,
  permissionKey: Permission.PREDICT_INTENTS,
};

export const ROUTE_SECURITY = {
  featureFlags: ['security_page'],
  path: Routes.SECURITY,
  roles: new Set([UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_SOLVE_INSIGHTS_MAIN = {
  path: Routes.SOLVE_INSIGHTS_MAIN,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_SOLVE_INSIGHTS_TOPIC_DETAIL_PARENT = {
  path: Routes.SOLVE_INSIGHTS_TOPIC_DETAIL_PARENT,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_SOLVE_INSIGHTS_TOPIC_DETAIL = {
  path: Routes.SOLVE_INSIGHTS_TOPIC_DETAIL,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_SOLVE_INSIGHTS_ARTICLE_DETAIL = {
  path: Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL,
  permissionKey: Permission.FLAMETHROWER,
};

export const ROUTE_LOG = {
  ignorePermissionForSuperAdmin: true,
  path: Routes.LOG,
  permissionKey: Permission.LOG,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN]),
};

export const ROUTE_TRIAGE_MODELS = {
  path: Routes.TRIAGE_MODELS,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN]),
};

export const ROUTE_TRIAGE_VERSION_CONTROL = {
  path: Routes.TRIAGE_VERSION_CONTROL,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN]),
};

export const ROUTE_TRIAGE_SIMULATIONS = {
  path: Routes.TRIAGE_SIMULATION_RUNS,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN]),
};

export const ROUTE_TRIAGE_SIMULATIONS_DETAIL = {
  path: Routes.TRIAGE_SIMULATION_RUNS_DETAIL,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN]),
};

export const ROUTE_TRIAGE_INSIGHTS = {
  ignorePermissionForSuperAdmin: true,
  path: Routes.TRIAGE_INSIGHTS,
  permissionKey: Permission.TRIAGE_INSIGHTS_WIP,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN]),
};

export const ROUTE_PLAN_PAGE = {
  path: Routes.PLAN,
  roles: new Set([UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN]),
};

export const ROUTE_SOLVE_INSIGHTS_WORKFLOW_DETAIL = {
  path: Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL,
  permissionKey: Permission.FLAMETHROWER,
};
