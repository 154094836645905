import '../chart.scss';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

import { ExtendedLineTooltipFormatterContextObject } from '../types';
import {
  getAllYAxises,
  handleYAxisOptions,
  renderChartTooltip,
} from './lineGraphUtils';
import { Scope } from 'src/components/dashboard-pages/solve-insights/types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  LineGraphDataType,
  LineGraphLabelType,
  OverviewOvertimeGraphDataType,
} from 'src/services/insights/types';

// Numbers will have commas
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

interface LineGraphProps {
  colors?: string[];
  containerProps?: { [key: string]: { [key: string]: string } };
  data: LineGraphDataType | OverviewOvertimeGraphDataType;
  disableTooltip?: boolean;
  handleToggleVisibility?: (label: LineGraphLabelType) => void;
  legendItemClickDisabled?: boolean;
  mergeDeflectionAxis?: boolean;
  mergeOverviewOvertimeAxes?: boolean;
  multipleYAxis?: boolean;
  scope?: Scope;
}

const LineGraph = ({
  colors,
  containerProps,
  data,
  disableTooltip = false,
  handleToggleVisibility,
  legendItemClickDisabled = false,
  mergeDeflectionAxis = false,
  mergeOverviewOvertimeAxes = false,
  multipleYAxis = false,
  scope,
}: LineGraphProps) => {
  const theme = useTheme();
  const { palette } = theme;

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const trackYAxises = getAllYAxises({
    data,
  });
  // Using unknown, due to data transformation if it has multiple axis causes type error
  const constructBarGraphOptions = (
    transformedData: Highcharts.SeriesOptionsType[],
  ) => {
    const yAxisOptions = handleYAxisOptions({
      data,
      mergeDeflectionAxis,
      mergeOverviewOvertimeAxes,
      theme,
    });

    return {
      chart: {
        type: 'spline',
      },
      colors: scope === 'parent_detail' ? undefined : colors,
      credits: {
        enabled: false,
      },
      legend: {
        floating: false,
        symbolWidth: scope === 'parent_detail' ? 40 : undefined,
        verticalAlign: 'top',
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          marker: {
            enabled: true,
            radius: 3,
            symbol: 'circle',
          },
        },
      },
      series: transformedData,
      title: {
        text: undefined,
      },
      tooltip: {
        backgroundColor: palette.colors.white,
        borderColor: palette.colors.slate[200],
        borderWidth: 0,
        enabled: !disableTooltip,
        formatter: function (this: ExtendedLineTooltipFormatterContextObject) {
          return renderChartTooltip(palette, this, colors);
        },
        hideDelay: 0,
        outside: true,
        padding: 0,
        shadow: false,
        shared: false,
        split: false,
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
        },
        useHTML: true,
      },
      xAxis: {
        categories: data?.x_axis,
        labels: {
          style: {
            color: palette.colors.grey[600],
            fontFamily: theme.typography.fontFamily,
            fontSize: '11px',
          },
        },
        lineWidth: 0,
      },
      yAxis: yAxisOptions,
    };
  };

  const handleDataTransformation = () => {
    const xAxisExtended = data.x_axis_extended ?? [];
    const result: Highcharts.SeriesSplineOptions[] = data.values.map(
      (value, idx) => {
        if (xAxisExtended.length) {
          const hasDataInArray =
            value.value.filter(item => item !== null).length > 0;

          /**
           * Merges certain Y axes if `mergeOverviewOvertimeAxes === true`
           */
          const getYAxisIndex = () => {
            if (!mergeOverviewOvertimeAxes) {
              return trackYAxises.get(value.label);
            }

            if (
              value.label === 'Chats' ||
              value.label === 'Deflections' ||
              value.label === 'Non-deflections'
            ) {
              return trackYAxises.get('Chats');
            }

            if (
              value.label === 'Deflected rate' ||
              value.label === 'Quick feedback' ||
              value.label === 'Relevance' ||
              value.label === 'User engagement'
            ) {
              return trackYAxises.get('Deflected rate');
            }

            return trackYAxises.get(value.label);
          };

          return {
            color: value.color,
            dashStyle: value.dashStyle ? value.dashStyle : 'Solid',
            data: hasDataInArray
              ? value.value.map((itemValue, valueIdx) => {
                  return {
                    tooltip_values:
                      'tooltip_values' in value && value.tooltip_values
                        ? value.tooltip_values
                        : undefined,
                    type: value.type ?? 'number',
                    y: itemValue,
                    yAxisExtended: xAxisExtended[valueIdx],
                  };
                })
              : [],
            events: {
              legendItemClick: function (e) {
                if (legendItemClickDisabled) {
                  e.preventDefault();
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const visibility = (this as any)?.visible
                    ? 'hidden'
                    : 'visible';
                  handleToggleVisibility?.(value.label);
                  emitTrackingEventCallback(
                    'insight-line-graph-legend-change',
                    {
                      name: value.label,
                      scope,
                      tab: 'article',
                      value: visibility,
                    },
                  );
                }
              },
            },
            linkedTo: value.linkedTo,
            name: value.label,
            // topic parent detail shows legend based on unique y Axis
            showInLegend: value.showInLegend ?? scope !== 'parent_detail',
            type: 'spline',
            visible: value.visible,
            yAxis:
              typeof value.yAxis === 'number' ? value.yAxis : getYAxisIndex(),
          };
        } else {
          const isEmptyArray =
            value.value.filter(item => item === null).length > 0;
          return {
            data: isEmptyArray ? [] : value.value,
            events: {
              legendItemClick: function (e) {
                if (legendItemClickDisabled) {
                  e.preventDefault();
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const visibility = (this as any)?.visible
                    ? 'hidden'
                    : 'visible';
                  emitTrackingEventCallback(
                    'insight-line-graph-legend-change',
                    {
                      name: value.label,
                      scope,
                      tab: 'article',
                      value: visibility,
                    },
                  );
                }
              },
            },
            name: value.label,
            type: 'spline',
            yAxis: multipleYAxis ? idx : 0,
          };
        }
      },
    );
    // Workaround to get unique legend to be used
    if (scope === 'parent_detail') {
      trackYAxises.forEach((value, key) => {
        result.unshift({
          color: 'black',
          dashStyle: value ? 'LongDash' : 'Solid',
          data: [],
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            },
          },
          name: key,
          showInLegend: true,
          type: 'spline',
          yAxis: value,
        });
      });
    }
    return result;
  };

  const transformData = handleDataTransformation();
  const options = constructBarGraphOptions(transformData);

  return (
    <HighchartsReact
      containerProps={containerProps}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default LineGraph;
