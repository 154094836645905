import { formatDateForTicketCard } from '../dashboard-pages/discover-topic-detail-page/helpers';
import TicketStatusTableValue from '../dashboard-pages/triage-model-detail-page/tickets-tab/TicketStatusTableValue';
import { TicketMetricData } from './types';
import capitalize from 'lodash/capitalize';
import { getHoursFromSeconds } from 'src/utils/discover/helpers';

const createHoursText = (value: number | null) => {
  if (value === null) {
    return null;
  }
  const hrs = getHoursFromSeconds(value);
  if (hrs === '1.0') {
    return '1.0 hr';
  }
  return `${hrs} hrs`;
};

export const createData = (metrics: TicketMetricData) => {
  return [
    {
      label: 'Date created',
      value: formatDateForTicketCard(new Date(metrics.date_created).getTime()),
    },
    {
      label: 'Channel',
      value: capitalize(metrics.channel || ''),
    },
    {
      label: 'Ticket status',
      Value: metrics.status ? (
        <TicketStatusTableValue status={metrics.status} />
      ) : null,
    },
    {
      label: 'Resolved on first contact',
      value:
        metrics.first_contact_resolution === true
          ? 'Yes'
          : metrics.first_contact_resolution === false
          ? 'No'
          : null,
    },
    {
      label: 'First resolution time',
      value: createHoursText(metrics.first_resolution_time_secs),
    },
    {
      label: 'Full resolution time',
      value: createHoursText(metrics.full_resolution_time_secs),
    },
    {
      label: 'Agent replies',
      value: metrics.num_replies?.toString() || null,
    },
    {
      label: 'Average reply time',
      value: createHoursText(metrics.user_wait_time_secs),
    },
  ];
};
