import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

interface FilterLabelContainerProps {
  children: React.ReactNode;
  label: string;
}

const FilterLabelContainer = ({
  children,
  label,
}: FilterLabelContainerProps) => {
  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography variant='font14Bold'>{label}</Typography>
      {children}
    </Box>
  );
};

export default FilterLabelContainer;
