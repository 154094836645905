import { useMemo } from 'react';

import { metricFilterOptions } from 'src/constants/discover';
import {
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicMetric,
} from 'src/reducers/discoverReducer/types';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';

export const useGetAvailableMetricFilterOptions = (
  metricFilter: DiscoverTopicAggregatedMetricType = 'volume',
  metricData?: (DiscoverTopicMetric | null)[],
): {
  isSentimentAvailable: boolean;
  options: { label: string; value: DiscoverTopicAggregatedMetricType }[];
} => {
  const { data: metadata, isLoading: isMetadataLoading } =
    useGetMetadataQuery();
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };

  const availableMetricFilterOptions = useMemo(() => {
    const availableMetricsTypes = new Set(
      availableMetrics.map(({ type }) => type),
    );

    const isSentimentAvailable =
      metricData?.some(
        metric =>
          metric && metric.name === 'sentiment' && metric.value !== null,
      ) ?? false;

    const filteredOptions = metricFilterOptions.filter(item => {
      // Exclude sentiment if it’s not available
      if (item.value === 'sentiment' && !isSentimentAvailable) {
        return false;
      }
      return availableMetricsTypes.has(item.value);
    });

    return { filteredOptions, isSentimentAvailable };
  }, [availableMetrics, metricData]);

  const initialMetricFilterOptions = metricFilterOptions.filter(
    option => option.value === metricFilter,
  );

  const options =
    isMetadataLoading ||
    availableMetricFilterOptions.filteredOptions.length === 0
      ? initialMetricFilterOptions
      : availableMetricFilterOptions.filteredOptions;

  return {
    isSentimentAvailable: availableMetricFilterOptions.isSentimentAvailable,
    options,
  };
};
