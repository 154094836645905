import { useSelector } from 'react-redux';

import { selectIsUserSuperAdmin } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export default function useIsFilterUxUpdateEnabled() {
  const isSuperAdmin = useSelector(selectIsUserSuperAdmin);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  return featureFlags.includes('filter_ux_update') && isSuperAdmin;
}
