import Box from '@mui/material/Box';

import { DISCOVER_MODAL_TEXT, DISCOVER_TEXT } from '../constants';
import AutomationMetric from './AutomationMetric';
import SummaryContainer from './SummaryContainer';
import { DiscoverTopicActionableValue } from 'src/reducers/discoverReducer/types';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';
import {
  getCostReductionCopy,
  getDiscoverBannerValues,
} from 'src/utils/discover/helpers';

interface BannerStatsProps {
  chatValues?: DiscoverTopicActionableValue | null;
  ticketValues?: DiscoverTopicActionableValue | null;
}
const BannerStats = ({ chatValues, ticketValues }: BannerStatsProps) => {
  const { data: metadata } = useGetMetadataQuery();
  const { tooltip: costReductionTooltip } = getCostReductionCopy(
    metadata?.ticket_cost_info,
  );
  if (!ticketValues || !chatValues) {
    return null;
  }
  const { volumeValue: chatVolumeValue } = getDiscoverBannerValues(chatValues);
  const { costValue, volumeValue: ticketVolumeValue } =
    getDiscoverBannerValues(ticketValues);

  return (
    <SummaryContainer
      appCues={{
        componentType: 'information',
        featureName: 'automationmodal',
        pageName: 'solve',
        subType: 'savings',
      }}
      summary={DISCOVER_TEXT.automationChatTopicSummary}
    >
      <Box display='flex' flexDirection='column' gap='11px'>
        {chatVolumeValue && (
          <AutomationMetric
            title={DISCOVER_MODAL_TEXT.drawerChatTitle}
            tooltip={DISCOVER_MODAL_TEXT.chatTooltip}
            value={chatVolumeValue}
          />
        )}
        {ticketVolumeValue && (
          <AutomationMetric
            title={DISCOVER_MODAL_TEXT.drawerVolumeTitle}
            tooltip={DISCOVER_MODAL_TEXT.chatTooltip}
            value={ticketVolumeValue}
          />
        )}
        {costValue && (
          <AutomationMetric
            title={DISCOVER_MODAL_TEXT.drawerCostTitle}
            tooltip={costReductionTooltip}
            value={costValue}
          />
        )}
      </Box>
    </SummaryContainer>
  );
};

export default BannerStats;
