import React from 'react';
import { useTheme } from '@mui/material';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { dateRangeToTimestamp } from '../helpers';
import { InsightPeriod, Scope } from '../types';
import { renderLineGraphUtils } from 'src/components/solve-insights-charts/line-graph/lineGraphUtils';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import { useGetArticleDetailGraphQuery } from 'src/services/insights';

interface ArticlesLineChartProps {
  articleId: string;
  channels: string[];
  dateRange: DateRange;
  periodicalFilter: InsightPeriod;
  scope: Scope;
}

const ArticlesLineChart: React.FC<
  React.PropsWithChildren<ArticlesLineChartProps>
> = ({ articleId, channels, dateRange, periodicalFilter, scope }) => {
  const { palette } = useTheme();
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  const {
    data: articleDetailData,
    isFetching,
    isLoading,
  } = useGetArticleDetailGraphQuery({
    article_id: articleId,
    channels: buildQueryFromMultiSelected(channels)?.channels,
    end: backendReadyTimestamps.end_timestamp,
    period: periodicalFilter,
    start: backendReadyTimestamps.start_timestamp,
  });
  if (!articleDetailData || isLoading || isFetching) {
    return <Skeleton height='275px' width='100%' />;
  }
  return renderLineGraphUtils(palette, articleDetailData, scope);
};

export default ArticlesLineChart;
