import moment from 'moment';

import { TicketTableRequest, TriageFilterTicketRequest } from './types';
import intersection from 'lodash/intersection';
import {
  FILTER_CORRECT_PREDICTION,
  FILTER_HELP_DESK,
  FILTER_KEY_FINALIZED_LABEL,
  FILTER_KEY_PREDICTED_LABEL,
  FILTER_REASON_KEY,
  FILTER_STATUS,
  FILTER_STATUS_KEY,
  reasonForNoPredictionOptions,
} from 'src/components/dashboard-pages/triage-model-detail-page/tickets-tab/constants';
import { PatchTriageModelRequest } from 'src/reducers/triageSettingsReducer/types';

export const createStartDate = (value: number) => {
  const date = new Date(value);
  return moment(date.setHours(0, 0, 0, 0)).unix();
};

export const createEndDate = (value: number) => {
  const date = new Date(value);
  return moment(date.setHours(23, 59, 59, 999)).unix();
};

export const createTimeStampObject = (startDate: number, endDate: number) => ({
  end: createEndDate(endDate),
  start: createStartDate(startDate),
});

const createTimestampParam = (
  key: string,
  value: number,
  dateType: 'start' | 'end',
) => {
  if (!value || Number.isNaN(value)) {
    return '';
  }
  const date = new Date(value);
  const dateValue =
    dateType === 'start'
      ? moment(date.setHours(0, 0, 0, 0)).unix()
      : moment(date.setHours(23, 59, 59, 999)).unix();

  return `${key}=${dateValue}`;
};

export const createTimeStamps = (
  startDate: number,
  endDate: number,
  options?: { endKey: string; startKey: string },
) => {
  const dates = [
    createTimestampParam(
      options?.startKey || 'start_timestamp',
      startDate,
      'start',
    ),
    createTimestampParam(options?.endKey || 'end_timestamp', endDate, 'end'),
  ].filter(item => item !== '');
  if (!dates.length) {
    return '';
  }
  return `?${dates.join('&')}`;
};

export const createSearchQuery = (
  keywordSearch?: string,
  ticketId?: string,
) => {
  if (keywordSearch) {
    return `&keyword_search=${keywordSearch}`;
  }
  if (ticketId) {
    return `&ticket_id_search=${ticketId}`;
  }
  return '';
};

const removeFilterKey = (filterKey: string, items: string[]) => {
  return items.map(item => item.replace(filterKey, ''));
};

const createFilterValue =
  (filters: string[]) =>
  ({
    filterKey,
    filterValues,
  }: {
    filterKey: string;
    filterValues?: Record<string, string>;
  }) => {
    const valuesToGet = Object.values(filterValues || {});
    const data = intersection(filters, valuesToGet);

    return data.length ? removeFilterKey(filterKey, data) : null;
  };

const toSeconds = (dateTime: number) => {
  return Math.floor(dateTime / 1000);
};

const getBooleanFilters = (
  filters: string[],
  key: { NO: string; YES: string },
) => {
  const yes = filters.find(item => item === key.YES);
  const no = filters.find(item => item === key.NO);

  return [yes ? true : null, no ? false : null].filter(item => item !== null);
};

const defaultNull = (data: string | string[] | (boolean | null)[]) => {
  if (data.length === 0) {
    return null;
  }
  return data;
};

export const createFilterBody = ({
  endDate,
  filters,
  keywordSearch,
  modelId,
  page,
  startDate,
  ticketId,
}: TicketTableRequest) => {
  const generateFilters = createFilterValue(filters);
  const ticket_status = generateFilters({
    filterKey: FILTER_STATUS_KEY,
    filterValues: FILTER_STATUS,
  });

  const finalized_label = removeFilterKey(
    FILTER_KEY_FINALIZED_LABEL,
    filters.filter(item => item.includes(FILTER_KEY_FINALIZED_LABEL)),
  );

  const predicted_label = removeFilterKey(
    FILTER_KEY_PREDICTED_LABEL,
    filters.filter(item => item.includes(FILTER_KEY_PREDICTED_LABEL)),
  );
  const reasons = reasonForNoPredictionOptions.map(item => item.value);
  const reason_no_prediction = removeFilterKey(
    FILTER_REASON_KEY,
    intersection(filters, reasons),
  );
  const written_to_helpdesk = getBooleanFilters(filters, FILTER_HELP_DESK);
  const is_prediction_accurate = getBooleanFilters(
    filters,
    FILTER_CORRECT_PREDICTION,
  );

  return {
    end: toSeconds(endDate),
    finalized_label: defaultNull(finalized_label),
    is_prediction_accurate: defaultNull(is_prediction_accurate),
    keyword_search: keywordSearch,
    label: defaultNull(predicted_label),
    model_id: modelId,
    page,
    reason_no_prediction: defaultNull(reason_no_prediction),
    start: toSeconds(startDate),
    ticket_id_search: defaultNull(ticketId || ''),
    ticket_status,
    written_to_helpdesk: defaultNull(written_to_helpdesk),
  };
};

export const updateValueMappingFromLabels = (
  model: PatchTriageModelRequest['body'],
) => {
  if (!model.labels || !model.model) {
    return model;
  }
  const newVm = model.labels.reduce((prev, current) => {
    return {
      ...prev,
      [current.title]: current.output_field_value,
    };
  }, {});
  const modelCopy = JSON.parse(JSON.stringify(model));
  modelCopy.model.model_output_formatter.value_mapping = newVm;
  return modelCopy;
};

export const removeNullFields = <T>(obj: T): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj || {}).filter(([_, value]) => value !== null),
  ) as Partial<T>;

export const createSerializedFilterForTickets = (
  filters: TriageFilterTicketRequest,
) => {
  const arrayFields = [
    'ticket_status',
    'label',
    'finalized_label',
    'reason_no_prediction',
    'is_prediction_accurate',
    'written_to_helpdesk',
  ];

  const serializedFilters = Object.entries(filters).reduce(
    (acc, [key, value]) => {
      if (arrayFields.includes(key)) {
        return acc.concat(`${key}=${value?.join(',') || ''}`);
      }
      return acc.concat(`${key}=${value}`);
    },
    '',
  );

  return serializedFilters;
};
