import { useSelector } from 'react-redux';

import { Typography } from '@forethought-technologies/forethought-elements';
import { TextTranscriptComponent } from './types';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import {
  AgentMessage,
  ResponseMessage,
} from 'src/pages/solve-config/styledComponents';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const TextStep = ({
  transcriptComponent,
}: {
  transcriptComponent: TextTranscriptComponent;
}) => {
  const { theme_color: themeColor } = useSelector(selectWidgetConfiguration);
  const fontColor = useSelector(selectLegibleTextColor);

  const component_type = transcriptComponent.component_type;
  if (component_type === 'output') {
    return (
      <AgentMessage>
        <Typography variant='font14Medium'>
          <HtmlComponent
            content={transcriptComponent.message}
            linkifyHtmlOptions={solveLinkifyHtmlOptions}
          />
        </Typography>
      </AgentMessage>
    );
  }
  return (
    <ResponseMessage backgroundColor={themeColor}>
      <Typography color={fontColor} variant='font14Medium'>
        {transcriptComponent.message}
      </Typography>
    </ResponseMessage>
  );
};
