import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { Prediction } from 'src/services/triage/types';

interface TriageDetailInsightLabelGroupProps {
  labelType: 'ground_truth' | 'prediction';
  predictions: Prediction[];
}

const TriageDetailInsightLabelGroup = ({
  labelType,
  predictions,
}: TriageDetailInsightLabelGroupProps) => {
  const { palette } = useTheme();
  const targetField =
    labelType === 'ground_truth' ? 'end_field_value' : 'predicted_label';
  const items = predictions.filter(item => item[targetField] !== null);

  if (items.length === 0) {
    return <Typography color={palette.colors.grey[500]}>{NA}</Typography>;
  }

  return items.map((item, index) => {
    const label = item[targetField];
    const text = `${label} (${item.model_name})`;

    if (item.llm_model_id) {
      return (
        <Link key={index} to={`/triage-settings/models/${item.llm_model_id}`}>
          <Typography color={palette.colors.purple[500]} variant='font12'>
            {text}
          </Typography>
        </Link>
      );
    }

    return (
      <Typography key={index} variant='font12'>
        {text}
      </Typography>
    );
  });
};

export default TriageDetailInsightLabelGroup;
