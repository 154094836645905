import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { filterAndSortContextVariables, getAllContextVariables } from './utils';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import { selectTicketFieldMappingsState } from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

export const useGetContextVariables = ({
  filterFn,
  shouldIncludeDynamicListContextVariables = true,
  shouldIncludeSystemContextVariables = false,
  shouldIncludeTemplateContextVariables = false,
  shouldIncludeTriageContextVariables = false,
  shouldIncludeWorkflowTagContextVariable = true,
  shouldOnlyReturnSupportedFilterableContextVariables = false,
  systemContextVariableIdsToInclude,
}: {
  filterFn?: (cv: ContextVariable) => boolean;
  shouldIncludeDynamicListContextVariables?: boolean;
  shouldIncludeSystemContextVariables?: boolean;
  shouldIncludeTemplateContextVariables?: boolean;
  shouldIncludeTriageContextVariables?: boolean;
  shouldIncludeWorkflowTagContextVariable?: boolean;
  shouldOnlyReturnSupportedFilterableContextVariables?: boolean;
  systemContextVariableIdsToInclude?: string[];
} = {}) => {
  const ticketFieldMappingsState = useSelector(selectTicketFieldMappingsState);
  const { data, isLoading } = useGetContextVariablesQuery();
  const {
    context_variables: contextVariablesData = [],
    template_context_variables: templateContextVariables = [],
    usable_system_context_variables: usableSystemContextVariables = [],
  } = data ?? {};
  const { ticketFieldMappings = [] } = ticketFieldMappingsState;

  return useMemo(() => {
    const contextVariables = getAllContextVariables({
      contextVariablesData,
      shouldIncludeSystemContextVariables,
      shouldIncludeTemplateContextVariables,
      shouldIncludeTriageContextVariables,
      systemContextVariableIdsToInclude,
      templateContextVariables,
      ticketFieldMappings,
      usableSystemContextVariables,
    });

    return {
      contextVariables: filterAndSortContextVariables(
        contextVariables,
        shouldIncludeWorkflowTagContextVariable,
        shouldIncludeDynamicListContextVariables,
        shouldOnlyReturnSupportedFilterableContextVariables,
        filterFn,
      ),
      isLoading,
    };
  }, [
    contextVariablesData,
    shouldIncludeSystemContextVariables,
    shouldIncludeTemplateContextVariables,
    shouldIncludeTriageContextVariables,
    systemContextVariableIdsToInclude,
    templateContextVariables,
    ticketFieldMappings,
    usableSystemContextVariables,
    shouldIncludeWorkflowTagContextVariable,
    shouldIncludeDynamicListContextVariables,
    filterFn,
    isLoading,
    shouldOnlyReturnSupportedFilterableContextVariables,
  ]);
};
