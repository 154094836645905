import { Brand } from 'src/types/brandTypes';

export const getIsBrandPublishedTooltipText = (brand: Brand) => {
  if (brand.published_version_exists && brand.published_version_is_active) {
    return 'Published and active';
  }

  if (!brand.published_version_exists) {
    return 'Draft';
  }

  if (!brand.published_version_is_active) {
    return 'Published and inactive';
  }

  return '';
};
