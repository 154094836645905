import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { DEFAULT_CHART_STYLE, SANKEY_DIAGRAM_COLORS } from './constants';
import { createData, createNodes } from './helpers';
import { SankeyDiagramData } from './types';
import Sankey from 'highcharts/modules/sankey';
import merge from 'lodash/fp/merge';

Sankey(Highcharts);

export interface SankeyDiagramProps {
  chartStyle?: { [key: string]: string };
  data: SankeyDiagramData[];
}

const SankeyDiagram = ({ chartStyle, data }: SankeyDiagramProps) => {
  const options = {
    credits: {
      enabled: false,
    },
    series: [
      {
        colors: SANKEY_DIAGRAM_COLORS,
        data: createData(data),
        dataLabels: {
          enabled: false,
        },
        keys: ['from', 'to', 'weight', 'color'],
        nodeAlignment: 'top',
        nodes: createNodes(data),
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
        type: 'sankey',
      },
    ],
    title: {
      text: '',
    },
    tooltip: {
      headerFormat: null,
      nodeFormat: '{point.name}: {point.sum:,.0f} ',
      pointFormat:
        '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight:,.0f}',
    },
  };

  return (
    <HighchartsReact
      containerProps={{ style: merge(chartStyle, DEFAULT_CHART_STYLE) }}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default SankeyDiagram;
