import { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus } from '@tabler/icons-react';

import { Button } from '@forethought-technologies/forethought-elements';
import { ArticleSourceComponent } from '../customization-panel/granular-article-suggestion-v2-settings-panel/ArticleSourceComponent';
import { Separator } from '../ForethoughtCanvasRight';
import {
  useAddAutoflowToolMutation,
  useUpdateAutoflowToolConfigurationFieldsMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  ArticleSource,
  AutoflowTool,
  ToolDefinitionResponse,
} from 'src/types/workflowBuilderAPITypes';

interface EditToolDrawerProps {
  intentWorkflowId: string;
  onClose: () => void;
  tool:
    | (AutoflowTool & { toolDefinition?: ToolDefinitionResponse | undefined })
    | undefined;
}

export const EditToolDrawer = ({
  intentWorkflowId,
  onClose,
  tool,
}: EditToolDrawerProps) => {
  const [mutate, { isLoading: isUpdatingFields }] =
    useUpdateAutoflowToolConfigurationFieldsMutation();
  const [addAutoflowTool, { isLoading: isAddingTool }] =
    useAddAutoflowToolMutation();

  const [articleSources, setArticleSources] = useState<ArticleSource[]>(
    tool?.toolDefinition?.tool_fields?.article_sources
      ? tool?.toolDefinition?.tool_fields.article_sources
      : [{ doc_type: null, filter_queries: [] }],
  );

  const isFailingValidation = useMemo(() => {
    return articleSources.some(
      articleSource =>
        !articleSource.doc_type ||
        articleSource.filter_queries.some(
          query =>
            !query.field ||
            (typeof query.value?.value !== 'boolean' && !query.value?.value),
        ),
    );
  }, [articleSources]);

  const isLoading = isUpdatingFields || isAddingTool;

  const handleUpdateArticleSource = (
    articleSourceIndex: number,
    updatedArticleSource: ArticleSource,
  ) => {
    const copy = [...articleSources];
    copy[articleSourceIndex] = updatedArticleSource;

    setArticleSources(copy);
  };

  const handleDeleteArticleSource = (articleSourceIndex: number) => {
    setArticleSources(
      articleSources.filter((_, index) => index !== articleSourceIndex),
    );
  };

  const handleAddArticleSource = () => {
    setArticleSources([
      ...articleSources,
      { doc_type: null, filter_queries: [] },
    ]);
  };

  return (
    <Box
      alignItems='space-between'
      bgcolor={theme => theme.palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      p={3}
      width='650px'
    >
      <Box>
        <Typography variant='font20'>{tool?.tool_name}</Typography>
      </Box>
      <Typography
        color={theme => theme.palette.colors.grey[700]}
        variant='font14Medium'
      >
        Enabling ‘Search Help Center Articles’ action generates responses from
        knowledge base content using Autoflow policy and conversational
        technology to paraphrase and formulate effective, relevant answers.
        Customize article sources and filters to ensure a personalized
        experience.
      </Typography>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        gap={3}
        overflow='scroll'
      >
        <Separator />
        {articleSources.map((articleSource, articleSourceIndex) => {
          return (
            <Box
              display='flex'
              flexDirection='column'
              gap={2}
              key={articleSourceIndex}
            >
              <ArticleSourceComponent
                areErrorsVisible
                articleSource={articleSource}
                articleSourceIndex={articleSourceIndex}
                availableArticleSources={
                  tool?.configuration_fields.available_article_sources ?? []
                }
                handleDeleteArticleSource={handleDeleteArticleSource}
                handleUpdateArticleSource={handleUpdateArticleSource}
                isDeleteDisabled={articleSources.length === 1}
              />
              <Separator />
            </Box>
          );
        })}
        <Button
          onClick={handleAddArticleSource}
          startIcon={<IconPlus />}
          variant='ghost'
        >
          Add article source
        </Button>
      </Box>
      <Box display='flex' gap={2}>
        <Button onClick={onClose} size='large' variant='secondary'>
          Cancel
        </Button>
        <Button
          disabled={isLoading || isFailingValidation}
          fullWidth
          isLoading={isLoading}
          onClick={async () => {
            try {
              if (!tool?.toolDefinition) {
                const toolData = {
                  body: {
                    tool_id: tool?.tool_id ?? '',
                    tool_type: tool?.tool_type ?? 'system_built_in',
                  },
                  intentWorkflowId,
                };
                await addAutoflowTool(toolData).unwrap();
              }
              await mutate({
                body: {
                  configuration_fields: { article_sources: articleSources },
                  tool_id: tool?.tool_id || '',
                },
                intentWorkflowId,
              }).unwrap();
              onClose();
            } catch (error) {
              console.error(error);
            }
          }}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
