import { FILTER_OPTIONS, FILTER_PREFIX } from './constants';
import { NA } from 'src/constants/solve';
import { LineGraphDataType } from 'src/services/insights/types';
import { ModelLabelMapping } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

const createOptionItem = (value: string, label?: string) => ({
  label: label || value,
  value: value,
});

const createLabelOptions = (
  target: 'prediction_values' | 'end_field_values',
  modelLabels: ModelLabelMapping[],
  modelNames: string[],
) =>
  modelLabels?.reduce<{ label: string; value: string }[]>(
    (acc, modelLabel) =>
      acc.concat(
        modelLabel[target]
          .filter(() => {
            if (!modelNames.length) {
              return true;
            }
            return modelNames.includes(modelLabel.model_name);
          })
          .map(targetValue =>
            createOptionItem(
              `${target}_${targetValue}`,
              `${targetValue}(${modelLabel.model_name})`,
            ),
          ),
      ),
    [],
  );

export const createOptions = (
  modelLabels: ModelLabelMapping[],
  value: string[],
) => {
  const modelNames = getFiltersByPrefix(value, FILTER_PREFIX.model) || [];
  const modelOptionValues = modelLabels?.map(value =>
    createOptionItem(`model_${value.model_name}`, value.model_name),
  );
  const predictedLabelOptionValues = createLabelOptions(
    'prediction_values',
    modelLabels,
    modelNames,
  );
  const endFieldOptionValues = createLabelOptions(
    'end_field_values',
    modelLabels,
    modelNames,
  );

  const modelOptions = [
    {
      label: 'Models',
      options: modelOptionValues || [],
      value: 'models',
    },
    {
      label: 'Predicted labels',
      options: predictedLabelOptionValues || [],
      value: 'predicted_labels',
    },
    {
      label: 'Ground truth labels',
      options: endFieldOptionValues || [],
      value: 'ground_truth_labels',
    },
  ];

  const [ticketStatus, ...rest] = FILTER_OPTIONS;

  return [ticketStatus, ...modelOptions, ...rest];
};

const getFiltersByPrefix = (
  filters: string[],
  prefix: (typeof FILTER_PREFIX)[keyof typeof FILTER_PREFIX],
) => {
  const data = filters
    .filter(filter => filter.startsWith(prefix))
    .map(filter => filter.slice(prefix.length));
  if (!data.length) {
    return null;
  }
  return data;
};

const toBooleanFilter = (filters: string[] | null) =>
  filters?.map(filter => (filter === 'yes' ? true : false)) || null;

export const createApiFilters = ({
  dateRange,
  filters,
  searchText,
  searchType,
}: {
  dateRange: DateRange;
  filters: string[];
  searchText: string;
  searchType: string;
}) => {
  const written_to_helpdesk = toBooleanFilter(
    getFiltersByPrefix(filters, FILTER_PREFIX.writtenToHelpdesk),
  );
  const is_prediction_accurate = toBooleanFilter(
    getFiltersByPrefix(filters, FILTER_PREFIX.correctPrediction),
  );

  return {
    end: dateRange.to?.valueOf() || 0,
    finalized_label: getFiltersByPrefix(filters, FILTER_PREFIX.endFieldValues),
    is_prediction_accurate,
    keyword_search: searchType === 'keyword' ? searchText : null,
    label: getFiltersByPrefix(filters, FILTER_PREFIX.predictionValues),
    model_names: getFiltersByPrefix(filters, FILTER_PREFIX.model) || [],
    reason_no_prediction: getFiltersByPrefix(
      filters,
      FILTER_PREFIX.reasonForIncorrectPrediction,
    ),
    start: dateRange.from?.valueOf() || 0,
    ticket_id_search: searchType === 'ticket_id' ? searchText : null,
    ticket_status: getFiltersByPrefix(filters, FILTER_PREFIX.ticketStatus),
    written_to_helpdesk,
  };
};

export const createTicketStatusValue = (value?: number | null) => {
  if (value === null || value === undefined) {
    return NA;
  }
  const percentage = Math.floor(value * 100);
  return `${percentage}%`;
};

export const createLineChartData = (data?: LineGraphDataType) => {
  return data?.values.map(item => {
    if ((item.label as string) === 'Accuracy') {
      return {
        ...item,
        yAxis: 1,
      };
    }
    return {
      ...item,
      yAxis: 0,
    };
  });
};
