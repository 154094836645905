import { useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatMetricValue } from './helpers';
import { OverviewMetricValueType } from './types';
import { NA } from 'src/constants/solve';

export type MetricValue = number | null | undefined;

export interface OverviewMetricValueProps {
  loading?: boolean;
  metricType?: OverviewMetricValueType;
  prefix?: string;
  suffix?: string;
  value: MetricValue;
  Value?: React.ReactNode | React.FC<{ value: MetricValue }>;
  valueFormatter?: (value: number) => string;
}

const OverviewMetricValue = ({
  loading,
  metricType,
  prefix,
  suffix,
  value,
  Value,
  valueFormatter,
}: OverviewMetricValueProps) => {
  const { palette } = useTheme();

  if (Value && typeof Value === 'function') {
    return <Value value={value} />;
  }

  if (Value) {
    return Value;
  }

  if (loading) {
    return <Skeleton />;
  }

  if (value === null || value === undefined) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font24'>
        {NA}
      </Typography>
    );
  }

  const formattedValue = formatMetricValue(value, metricType, valueFormatter);
  const formattedValueWithPrefixAndSuffix = `${prefix || ''}${formattedValue}${
    suffix || ''
  }`;

  return (
    <Typography variant='font24'>
      {formattedValueWithPrefixAndSuffix}
    </Typography>
  );
};

export default OverviewMetricValue;
