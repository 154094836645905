import { useMemo } from 'react';
import Box from '@mui/material/Box';
import { IconFilterPlus } from '@tabler/icons-react';

import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import { createOptions } from './helpers';
import { useGetInsightModelMappingQuery } from 'src/services/triage/triageApi';

interface TicketFilterProps {
  onChange: (value: string[]) => void;
  value: string[];
}

const TicketFilter = ({ onChange, value }: TicketFilterProps) => {
  const { data, isLoading } = useGetInsightModelMappingQuery();
  const options = useMemo(
    () => createOptions(data?.model_labels || [], value),
    [data?.model_labels, value],
  );

  return (
    <Box
      sx={isLoading ? { opacity: '0.75', pointerEvents: 'none' } : undefined}
    >
      <MultiSelectFilter
        icon={<IconFilterPlus />}
        isMenuSearchable
        onChange={onChange}
        options={options}
        placeholder='Filter by'
        value={value}
      />
    </Box>
  );
};

export default TicketFilter;
