import { Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TriageDetailInsightLabelGroup from './TicketDetailInsightLabelGroup';
import { Prediction } from 'src/services/triage/types';

interface TicketDetailInsightLabelsProps {
  predictions: Prediction[];
}

const TicketDetailInsightLabels = ({
  predictions,
}: TicketDetailInsightLabelsProps) => {
  const { palette } = useTheme();

  return (
    <Box
      display='flex'
      flexDirection='column'
      rowGap={2}
      sx={{
        a: {
          textDecoration: 'none',
          wordBreak: 'break-word',
        },
      }}
    >
      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography color={palette.colors.grey[600]} variant='font12'>
          Triage prediction label and model:
        </Typography>
        <TriageDetailInsightLabelGroup
          labelType='prediction'
          predictions={predictions}
        />
      </Box>
      <Divider />
      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography color={palette.colors.grey[600]} variant='font12'>
          Ground truth label:
        </Typography>

        <TriageDetailInsightLabelGroup
          labelType='ground_truth'
          predictions={predictions}
        />
      </Box>
    </Box>
  );
};

export default TicketDetailInsightLabels;
