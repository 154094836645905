import { Roles } from 'src/components/app/types';

export enum Permission {
  ANSWERS = 'answers_analytics',
  ASSIST = 'assist',
  ASSIST_AUTOMATIONS = 'assist_automations',
  CUSTOM_ANALYTICS = 'custom_analytics',
  DISCOVER = 'discover',
  FLAMETHROWER = 'flamethrower',
  FLAMETHROWER_WORKFLOW_BUILDER_EDIT = 'flamethrower_workflow_builder_edit',
  LOG = 'log',
  LOOKER_ANALYTICS = 'looker_analytics',
  MACRO_CONTROLS = 'macro_controls_admin',
  PREDICT_INTENTS = 'predict_intents',
  PREDICTIONS = 'predictions_analytics',
  SEARCH = 'search_analytics',
  SOLVE = 'solve_analytics',
  SOLVE_ANALYTICS_V2 = 'solve_analytics_v2',
  SOLVE_API = 'solve_api',
  SOLVE_INSIGHTS = 'solve_insights',
  SOLVE_INSIGHTS_WIP = 'solve_insights_wip',
  SOLVE_LITE = 'solve_lite',
  SOLVE_PREVIEW = 'solve_preview',
  TRIAGE_INSIGHTS = 'triage_insights',
  TRIAGE_INSIGHTS_WIP = 'triage_insights_wip',
  TRIAGE_SELF_SERVE = 'triage_self_serve',
  TRIAGE_SELF_SERVE_WIP = 'triage_self_serve_wip',
  WORKFLOW_BUILDER = 'workflow_builder',
  WORKFLOW_BUILDER_PREVIEW = 'workflow_builder_preview',
}

export enum Environment {
  LOCAL = 'local',
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export enum TablesToSort {
  ACTION_BUILDER = 'actions',
  ANSWERS = 'answers_table',
  ANSWERS_V1 = 'answers_v1_table',
  MACRO_CONTROLS = 'macro_controls_admin_table',
  PREDICTIONS = 'prediction_table',
  SEARCH = 'search_table',
  SOLVE = 'solve_table',
}

export enum Apps {
  INSIGHTS = 'insights',
  SEARCH = 'search',
}

export enum ModuleName {
  ANALYTICS = 'dashboard-analytics',
  DASHBOARD = 'dashboard',
  EMAIL_BUILDER = 'email-builder',
  FLAMETHROWER = 'flamethrower',
  SOLVE_CONFIG = 'solve-config',
  SOLVE_LITE = 'solve-lite',
}

export enum Tabs {
  ACCOUNT_SETTINGS = 'Account',
  ACTION_BUILDER = 'Action Builder',
  ACTION_BUILDER_EDIT = 'Action Builder Edit',
  ANSWERS = 'Assist',
  ASSIST_ANALYTICS = 'Assist Analytics',
  ASSIST_AUTOMATIONS = 'Assist Automations',
  ASSIST_NOTES = 'Notes',
  CONVERSATIONS = 'Conversations',
  CUSTOM_ANALYTICS = 'Custom Analytics',
  DISCOVER = 'Discover',
  DISCOVER_AUTOMATION = 'Automation',
  DISCOVER_DASHBOARD = 'Dashboard',
  DISCOVER_TOPICS = 'All Topics',
  INTEGRATIONS = 'Integrations',
  LOG = 'Log',
  LOOKER_ANALYTICS = 'Analytics',
  MACRO_CONTROLS = 'Macro Controls',
  PLAN = 'Plan',
  PREDICT_INTENTS = 'Intent Predictions',
  PREDICTIONS = 'Triage',
  PREDICTIONS_DASHBOARD = 'Dashboard',
  PREDICTIONS_SETTINGS = 'Models',
  SEARCH = 'Assist Search',
  SECURITY = 'Security',
  SETTINGS = 'Settings',
  SIMULATION_RUNS = 'Simulations',
  SOLVE = 'Solve',
  SOLVE_ANALYTICS_V2 = 'Solve Analytics',
  SOLVE_API_CONFIGURATION = 'API Configuration',
  SOLVE_CONFIGURATION = 'Configuration',
  SOLVE_DASHBOARD = 'Solve Dashboard',
  SOLVE_EMAIL_CONFIGURATION = 'Email Configuration',
  SOLVE_INSIGHT = 'Widget Insights',
  SOLVE_LITE = 'Solve Lite',
  SOLVE_PREVIEW = 'Solve Preview',
  SSO_SETTINGS = 'SSO',
  TEAM = 'Team',
  TRIAGE_INSIGHTS = 'Insights',
  WORKFLOW_BUILDER = 'Workflow Builder',
  WORKFLOW_BUILDER_EDIT = 'Workflow Builder Edit',
}

export const DISCOVER_ROUTE_PREFIX = '/discover' as const;
export const SOLVE_INSIGHT_ROUTE_PREFIX = '/solve-insights' as const;

export enum Routes {
  ACCOUNT_SETTINGS = '/account-settings',
  ACTION_BUILDER = '/action-builder',
  ACTION_BUILDER_EDIT = '/action-builder-edit',
  ACTION_BUILDER_EDIT_V2 = '/solve/action-builder/:actionId?',
  ANSWERS = '/assist',
  ASSIST_AUTOMATIONS = '/assist-automations',
  ASSIST_AUTOMATIONS_CREATE = '/assist-automations-create',
  ASSIST_LOGIN = '/assist-login',
  ASSIST_NOTES = '/assist/notes',
  AUTONOMOUS_AGENT_PREVIEW = '/solve/preview/:intentId?',
  CREATE_PASSWORD = '/create-password',
  CUSTOM_ANALYTICS = '/analytics/custom-dashboards',
  DISCOVER_AUTOMATION = '/discover/automation',
  DISCOVER_DASHBOARD = '/discover/dashboard',
  DISCOVER_TOPIC_DETAIL = '/discover/topic/:topicId',
  DISCOVER_TOPICS = '/discover/topics',
  FORGOT_PASSWORD = '/forgot-password',
  HOMEPAGE = '/',
  INTEGRATIONS = '/integrations',
  IR_REPORT = '/widget-configuration/ir-report',
  LOG = '/log',
  LOGOUT = '/logout',
  LOOKER_ANALYTICS = '/analytics/:dashboardId',
  MACRO_CONTROLS = '/macro-controls',
  MAGIC_LINK = '/magic-link',
  ORG_NOT_FOUND = '/org-not-found',
  PLAN = '/plan',
  PREDICT_INTENTS = '/predict-intents',
  PREDICT_INTENTS_TEST_DETAIL = '/predict-intents/tests/:intentTestId',
  PREDICTIONS = '/triage',
  PREDICTIONS_SETTINGS = '/triage-settings',
  PREDICTIONS_SETTINGS_DETAIL = '/triage-settings/:name',
  SEARCH = '/assist-search',
  SECURITY = '/security',
  SIGN_UP = '/signup',
  SINGLE_SIGN_ON = '/single-sign-on',
  SOLVE = '/solve-analytics',
  SOLVE_ANALYTICS_V2 = '/solve-analytics-v2',
  SOLVE_API_CONFIGURATION = '/solve/api-configuration',
  SOLVE_CONFIGURATION = '/solve-configuration',
  SOLVE_EMAIL_CONFIGURATION = '/solve/email-configuration',
  SOLVE_INSIGHTS_ARTICLE_DETAIL = '/solve-insights/articles/:articleId',
  SOLVE_INSIGHTS_MAIN = '/solve-insights',
  SOLVE_INSIGHTS_TOPIC_DETAIL = '/solve-insights/topic/:topicId',
  SOLVE_INSIGHTS_TOPIC_DETAIL_PARENT = '/solve-insights/topic/parent/:topicId',
  SOLVE_INSIGHTS_WORKFLOW_DETAIL = '/solve-insights/workflow/:workflowId',
  SOLVE_LITE = '/solve-lite',
  SOLVE_PREVIEW = '/solve-preview',
  SSO_SETTINGS = '/sso-settings',
  TEAM = '/team',
  TRIAGE_INSIGHTS = '/triage-insights',
  TRIAGE_MODELS = '/triage-settings/models/:modelId',
  TRIAGE_SIMULATION_RUNS = '/triage-simulations',
  TRIAGE_SIMULATION_RUNS_DETAIL = '/triage-simulations/:modelId',
  TRIAGE_VERSION_CONTROL = '/triage-settings/version-control/:modelId',
  WIDGET_CONFIGURATION = '/widget-configuration',
  WORKFLOW_BUILDER = '/workflow-builder',
  WORKFLOW_BUILDER_EDIT = '/workflow-builder-edit',
  WORKFLOW_BUILDER_EDIT_EMAIL_PREVIEW = '/workflow-builder-edit/email-preview',
  WORKFLOW_BUILDER_PREVIEW = '/workflow-builder-preview/:channel',
}

export enum AnswersReports {
  ACTIVE_AGENTS = 'active_agents',
  ENGAGED_TICKETS = 'engaged_tickets',
}

export enum ChartClassNames {
  ACTIVE_AGENTS = '.active-agents-line-chart',
  ENGAGED_TICKETS = '.engaged-tickets-line-chart',
}

export enum InitialsIconColors {
  BLUE = 'blue',
  COAL = 'coal',
  DARK_BLUE = 'dark-blue',
  DEEP_GRAY = 'deep-gray',
  GREEN = 'green',
  ICE_CREAM = 'ice-cream',
  MAGENTA = 'magenta',
  ORANGE = 'orange',
  PURPLE = 'purple',
  RED = 'red',
  SALMON = 'salmon',
  TEAL = 'teal',
  UNKNOWN_AGENT = 'unknown-agent',
  VIOLET = 'violet',
  YELLOW = 'yellow',
}

export enum ViewModes {
  CHART = 'Chart',
  TABLE = 'Table',
}

export enum TimeFrameFilters {
  DAILY = 'Daily',
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
}

export enum ErrorBookmarkToast {
  ICONCLASS = 'ToastNotification-errorIcon',
  TOASTCLASS = 'ToastNotification-bookmarkError',
  TOASTMESSAGE = "You've reached the maximum amount of starred reports.",
}

export enum linkCopiedToast {
  ICONCLASS = 'ToastNotification-copyLinkIcon',
  TOASTCLASS = 'ToastNotification-linkCopied',
  TOASTMESSAGE = 'URL copied to clipboard.',
}

export enum MacroUpdateErrorToast {
  ICONCLASS = 'iconError',
  TOASTCLASS = 'macroErrorToast',
  TOASTMESSAGE = 'This macro was recently updated. The latest changes are now displayed.',
}

export enum MacroSucessToast {
  ICONCLASS = 'iconSuccess',
  TOASTCLASS = 'macroSuccessToast',
  TOASTMESSAGE = 'Your macro has been successfully activated.',
}

export enum InvalidEmailToast {
  ICONCLASS = 'invalidEmailIcon',
  TOASTCLASS = 'invalidEmailToast',
  TOASTMESSAGE = 'Please enter a valid email',
}

export enum DuplicateEmailToast {
  ICONCLASS = 'invalidEmailIcon',
  TOASTCLASS = 'invalidEmailToast',
  TOASTMESSAGE = 'This email has already been added',
}

export enum TypesOfToast {
  BOOKMARK_TOAST = 'bookmark_toast',
  DUPLICATE_EMAIL = 'duplicate email',
  INVALID_EMAIL = 'invalid email',
  LINK_COPIED = 'link copied',
  MACRO_DEACTIVATION_SUCCESS = 'macro deactivation success',
  MACRO_UPDATE_ERROR = 'macro update error',
  MACRO_UPDATE_SUCCESS = 'macro update success',
}

export enum URL {
  PATH = '/assist',
  URL_REPORT_KEY = '#report_id=',
  URL_UNSAVED_REPORT_KEY = '#unsaved_report=',
}

export const redirectMap: any = {
  '/answers-analytics': Routes.ANSWERS,
  '/predictions': Routes.PREDICTIONS,
  '/search-analytics': Routes.SEARCH,
};

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_AGENT = 'ROLE_AGENT',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_USER = 'ROLE_USER',
}

export enum noDataViewProps {
  ANALYTICS_TIME_RANGE = 'There’s no data for this time range',
  DATE_ICON_CLASS = 'dateIcon',
  MACRO_CONTROLS = 'There is no data to dislpay',
  NO_DATA_ICON_CLASS = 'noDataIcon',
}

export enum MacroDeactivationSucessToast {
  ICONCLASS = 'iconSuccess',
  TOASTCLASS = 'macroSuccessToast',
  TOASTMESSAGE = 'Your macro has been successfully deactivated.',
}

/**
 * NOTE: SUPER_ADMIN ONLY
 * Organizaion selected by the user that's stored in localStorage
 * */
export function getSelectedOrganizationForSuperAdmin() {
  try {
    const orgAsString = localStorage.getItem('org');
    if (orgAsString) {
      return JSON.parse(orgAsString);
    }
  } catch {}

  return null;
}

/** User role that's stored in localStorage */
export const getUserRole = () => localStorage.getItem('userRole') as Roles;

export enum SolveChannels {
  API = 'api',
  EMAIL = 'email',
  WIDGET = 'widget',
}

export enum ExpressionTypes {
  BOOLEAN_AGGREGATOR = 'boolean_aggregator',
  FILTER = 'filter',
}

export enum StepTypes {
  ARTICLE = 'article',
  ARTICLE_SUGGESTION = 'article_suggestion',
  ATTACHMENT_ANALYSIS_UPLOAD = 'attachment_analysis_upload',
  ATTACHMENT_UPLOAD = 'attachment_upload',
  BUTTONS = 'buttons',
  CONDITION = 'condition',
  CSAT_TRIGGER_POINT = 'csat_trigger_point',
  DYNAMIC_CARD = 'dynamic_card',
  EMBED = 'embed',
  END_INTERACTIVE_EMAIL_CHAT = 'end_interactive_email_chat',
  FLAMETHROWER_API_CALL = 'flamethrower_api_call',
  FORM = 'form',
  FRESHCHAT_CHAT_HANDOFF = 'freshchat_chat_handoff',
  GO_TO_HANDOFF = 'go_to_handoff',
  GO_TO_INTENT = 'go_to_intent',
  GORGIAS_CHAT_HANDOFF = 'gorgias_chat_handoff',
  HYPERLINK_REDIRECT = 'hyperlink_redirect',
  IMAGE = 'image',
  KUSTOMER_CHAT_HANDOFF = 'kustomer_chat_handoff',
  KUSTOMER_CONVERSATION_CREATION = 'kustomer_conversation_creation',
  LIVE_CHAT_CHAT_HANDOFF = 'live_chat_chat_handoff',
  PARSE_JWT = 'parse_jwt',
  RESTART_CONVERSATION = 'restart_conversation',
  SALESFORCE_CASE_CREATION = 'salesforce_case_creation',
  SALESFORCE_CHAT_HANDOFF = 'salesforce_chat_handoff',
  SALESFORCE_MESSAGING_HANDOFF = 'salesforce_messaging_handoff',
  SET_CONTEXT_VARIABLE = 'set_context_variable',
  SHOPIFY_TRACKING = 'shopify_tracking',
  SNAPENGAGE_CHAT_HANDOFF = 'snapengage_chat_handoff',
  SNOWFLAKE_DATA_QUERY = 'snowflake_data_query',
  SUNCO_LIVE_CHAT = 'sunco_live_chat',
  TEXT_MESSAGE = 'text_message',
  TRIGGER_EVENT = 'trigger_event',
  VARIABLE_MAPPING = 'variable_mapping',
  ZENDESK_ATTACHMENT_UPLOAD = 'zendesk_attachment_upload',
  ZENDESK_CHAT_HANDOFF = 'zendesk_chat_handoff',
  ZENDESK_MESSAGING_HANDOFF = 'zendesk_messaging_handoff',
  ZENDESK_TICKET_CREATION = 'zendesk_ticket_creation',
}

export enum QuasiStepTypes {
  DYNAMIC_ARTICLE_SUGGESTION = 'dynamic_article_suggestion',
  FEEDBACK = 'feedback',
}

export enum ConfigErrorPrompt {
  LABEL = 'ERRORS',
  PROMPT = 'Configuration Error',
}

export enum DeleteWorkflowPrompt {
  DESCRIPTION = 'Deleting this workflow will lose any data associated with it.',
  LABEL = 'WARNING',
  LEFT_BUTTON_TEXT = 'Cancel',
  PROMPT = 'Delete this workflow?',
  RIGHT_BUTTON_TEXT = 'Delete',
}

export enum ContextVariableTypes {
  CHECKBOX = 'Checkbox',
  DATE = 'Date',
  DYNAMIC_LIST = 'Dynamic list',
  EMAIL = 'Email',
  FLAT_LIST = 'File list',
  LIST = 'List',
  LONG_TEXT = 'Long text',
  MULTI_SELECT_LIST = 'Multi-select List',
  MULTIOPTIONS = 'Multi-options',
  NUMBER = 'Number',
  PHONE = 'Phone',
  SHORT_TEXT = 'Short text',
}

export enum ContextVariableTypeKeys {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DYNAMIC_LIST = 'DYNAMIC_LIST',
  EMAIL = 'EMAIL',
  LIST = 'LIST',
  LONG_TEXT = 'LONG_TEXT',
  MULTI_SELECT_LIST = 'MULTI_SELECT_LIST',
  MULTIOPTIONS = 'MULTIOPTIONS',
  NUMBER = 'NUMBER',
  PHONE = 'PHONE',
  SHORT_TEXT = 'SHORT_TEXT',
}

export enum ActionBuilderActionTypes {
  API = 'api',
  ARTICLE_SUGGESTION = 'article_suggestion',
  ARTICLE_SUGGESTION_GRANULAR = 'article_suggestion_granular',
  BRAND_EMBASSY_HANDOFF = 'brand_embassy_handoff',
  CATCH_ALL_HANDOFF = 'catch_all_handoff',
  CONTEXT_VARIABLE_MAPPING = 'context_variable_mapping',
  // a FE only type to differentiate between the draggable normal vs. dynamic article suggestion
  DYNAMIC_ARTICLE_SUGGESTION = 'dynamic_article_suggestion',
  DYNAMIC_CARD = 'dynamic_card',
  END_INTERACTIVE_EMAIL_CHAT = 'end_interactive_email_chat',
  FORETHOUGHT_LIVE_CHAT_HANDOFF = 'forethought_live_chat_handoff',
  FRESHCHAT_CHAT_HANDOFF = 'freshchat_chat_handoff',
  FRESHDESK_TICKET_AND_CONTACT_CREATION = 'freshdesk_ticket_and_contact_creation',
  FRESHDESK_TICKET_CREATION = 'freshdesk_ticket_creation',
  GORGIAS_CHAT_HANDOFF = 'gorgias_chat_handoff',
  GRANULAR_ARTICLE_SUGGESTION_V2 = 'granular_article_suggestion_action_v2',
  HYPERLINK_REDIRECT = 'hyperlink_redirect',
  INTERCOM_HANDOFF = 'intercom_handoff',
  JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST = 'jira_service_management_create_request',
  KUSTOMER_CHAT_HANDOFF = 'kustomer_chat_handoff',
  KUSTOMER_CONVERSATION_CREATION = 'kustomer_conversation_creation',
  KUSTOMER_HANDOFF = 'kustomer_handoff',
  LIVE_CHAT_CHAT_HANDOFF = 'live_chat_chat_handoff',
  PARSE_JWT = 'parse_jwt',
  SALESFORCE_CASE_CREATION = 'salesforce_case_creation',
  SALESFORCE_CHAT_HANDOFF = 'salesforce_chat_handoff',
  SALESFORCE_HANDOFF = 'salesforce_handoff',
  SALESFORCE_MESSAGING_HANDOFF = 'salesforce_messaging_handoff',
  SET_CONTEXT_VARIABLE = 'set_context_variable',
  SHOPIFY_ORDER_STATUS_TEMPLATE = 'shopify_order_status_template',
  SNAPENGAGE_CHAT_HANDOFF = 'snapengage_chat_handoff',
  SNOWFLAKE_DATA_QUERY_TEMPLATE = 'snowflake_data_query_template',
  SUNCO_LIVE_CHAT = 'sunco_live_chat',
  TEMPLATE_API = 'template_api',
  TRIGGER_EVENT = 'trigger_event',
  ZENDESK_ATTACHMENT_UPLOAD = 'zendesk_attachment_upload',
  ZENDESK_CHAT_HANDOFF = 'zendesk_chat_handoff',
  ZENDESK_CHAT_HANDOFF_V2 = 'zendesk_chat_handoff_v2',
  ZENDESK_HANDOFF = 'zendesk_handoff',
  ZENDESK_MESSAGING_HANDOFF = 'zendesk_messaging_handoff',
  ZENDESK_TICKET_CREATION = 'zendesk_ticket_creation',
}

export enum FlamethrowerTrackingApplications {
  ACTION_BUILDER = 'action-builder',
  WORKFLOW_BUILDER = 'workflow-builder',
  WORKFLOW_BUILDER_AUTOFLOW_CANVAS = 'workflow-builder-autoflow-canvas',
  WORKFLOW_BUILDER_CANVAS = 'workflow-builder-canvas',
}

export enum FlamethrowerTrackingEventTypes {
  ACTION_CREATED = 'action-created',
  ACTION_DELETED = 'action-deleted',
  ACTION_UPDATED = 'action-updated',
  CONFIG_PROACTIVE_DISABLED = 'config-proactive-disabled',
  CONFIG_PROACTIVE_ENABLED = 'config-proactive-enabled',
  COPY_WORKFLOW_STEPS = 'copy-workflow-steps',
  DOMAIN_ADD_BUTTON = 'domain_add_button',
  DOMAIN_REMOVE_BUTTON = 'domain_remove_button',
  INTENT_ROUTING_ADDED = 'intent-routing-added',
  INTENT_TOPIC_CLICKED = 'intent-topic-clicked',
  LIVE_WORKFLOW_VERSION_SELECTED = 'live-workflow-version-selected',
  NEW_WORKFLOW_DRAFT_VERSION_SELECTED = 'new-workflow-draft-version-selected',
  PASTE_WORKFLOW_STEPS = 'paste-workflow-steps',
  RESTART_CONVERSATION_STEP_ADDED = 'restart-conversation-step-added',
  STARTER_PACK_INTENT_ADDED = 'starter-pack-intent-added',
  STARTER_PACK_INTENT_REMOVED = 'starter-pack-intent-removed',
  STARTER_PACK_INTENT_VIEWED = 'starter-pack-intent-viewed',
  STARTER_PACK_PHRASE_ADDED = 'starter-pack-phrase-added',
  STEP_LEVEL_ARTICLE_SUGGESTION_STEP_OPTION_SELECTED = 'step-level-article-suggestion-step-option-selected',
  STEP_LEVEL_BUTTON_STEP_OPTION_SELECTED = 'step-level-button-step-option-selection',
  STEP_LEVEL_METRICS_BUTTON_CLICKED = 'step-level-metrics-button-clicked',
  WORKFLOW_ACTION_ADDED = 'workflow-action-added',
  WORKFLOW_ACTION_DELETED = 'workflow-action-deleted',
  WORKFLOW_ACTION_EDITED = 'workflow-action-edited',
  WORKFLOW_ACTIVATED = 'workflow-activated',
  WORKFLOW_ADDED_ARTICLE = 'workflow-added-article',
  WORKFLOW_ADDED_ARTICLE_SUGGESTION = 'workflow-added-article-suggestion',
  WORKFLOW_ADDED_BUTTON_STEP = 'workflow-added-button-step',
  WORKFLOW_ADDED_CONDITIONS_STEP = 'workflow-added-conditions-step',
  WORKFLOW_ADDED_EMBED_STEP = 'workflow-added-embed-step',
  WORKFLOW_ADDED_FORM_STEP = 'workflow-added-form-step',
  WORKFLOW_ADDED_IMAGE = 'workflow-added-image',
  WORKFLOW_ADDED_TEXT_STEP = 'workflow-added-text-step',
  WORKFLOW_ADDED_TEXT_STEP_OVER_50_WORDS = 'workflow-added-text-step-over-50-words',
  WORKFLOW_ADDED_TEXT_STEP_WITH_LINK = 'workflow-added-text-step-with-link',
  WORKFLOW_CREATED = 'workflow-created',
  WORKFLOW_DEACTIVATED = 'workflow-deactivated',
  WORKFLOW_DELETED = 'workflow-deleted',
  WORKFLOW_EDITING_BEGAN = 'workflow-editing-began',
  WORKFLOW_GENERIC_HANDOFF_ACTION_ADDED = 'workflow-generic-handoff-action-added',
  WORKFLOW_JIRA_HANDOFF_ACTION_ADDED = 'workflow-jira-handoff-action-added',
  WORKFLOW_KUSTOMER_CHAT_HANDOFF_ACTION_ADDED = 'workflow-kustomer-chat-handoff-action-added',
  WORKFLOW_KUSTOMER_CONVERSATION_CREATION_ACTION_ADDED = 'workflow-kustomer-conversation-creation-action-added',
  WORKFLOW_SALESFORCE_CASE_CREATION_ACTION_ADDED = 'workflow-salesforce-case-creation-action-added',
  WORKFLOW_SALESFORCE_CHAT_HANDOFF_ACTION_ADDED = 'workflow-salesforce-chat-handoff-action-added',
  WORKFLOW_SALESFORCE_HANDOFF_ACTION_ADDED = 'workflow-salesforce-handoff-action-added',
  WORKFLOW_STEP_AND_BELOW_DELETED = 'workflow-step-and-below-deleted',
  WORKFLOW_STEP_DELETED = 'workflow-step-deleted',
  WORKFLOW_STEP_EDITED = 'workflow-step-edited',
  WORKFLOW_SUGGESTED_INTENT_ADDED = 'workflow-suggested-intent-added',
  WORKFLOW_VERSION_CONTROL_PANEL_OPENED = 'workflow-version-control-panel-opened',
  WORKFLOW_ZENDESK_CHAT_HANDOFF_ACTION_ADDED = 'workflow-zendesk-chat-handoff-action-added',
  WORKFLOW_ZENDESK_HANDOFF_ACTION_ADDED = 'workflow-zendesk-handoff-action-added',
  WORKFLOW_ZENDESK_TICKET_CREATION_ACTION_ADDED = 'workflow-zendesk-ticket-creation-action-added',
}

export enum SolveConfigurationTypes {
  HANDOFF = 'handoff',
  KNOWLEDGE = 'knowledge',
  WIDGET = 'widget',
}

export enum SolveConfigTrackingEventTypes {
  ADD_CUSTOM_URL_FOR_KNOWLEDGE_BASE = 'add-custom-url-for-knowledge-base',
  CONFIGURATION_PUBLISHED = 'configuration-published',
  CONFIGURATION_UPDATE = 'configuration-update',
  CONNECT_KNOWLEDGE_BASE = 'connect-knowledge-base',
  NAVIGATE_TO_INTEGRATIONS_PAGE = 'navigate-to-integrations-page',
  TAB_NAVIGATION = 'tab-navigation',
  WIDGET_ACTIVATED = 'widget-activated',
  WIDGET_DEACTIVATED = 'widget-deactivated',
  WIDGET_PREVIEW = 'widget-preview',
}

export enum DragNDrops {
  FT_ACTION = 'ft-action',
  FT_ARTICLE = 'ft-article',
  FT_INTENT = 'ft-intent',
  FT_RESTART = 'ft-restart',
}

export enum RevampedDragNDrops {
  REVAMPED_FT_ACTION = 'revamped-ft-action',
  REVAMPED_FT_ARTICLE = 'revamped-ft-article',
  REVAMPED_FT_INTENT = 'revamped-ft-intent',
  REVAMPED_FT_NON_SQUEEZE_ACTION = 'revamped-ft-non-squeeze-action',
  REVAMPED_FT_RESTART = 'revamped-ft-restart',
}

export enum WorkflowMetricsTimePeriods {
  ALL_TIME = 'all_time',
  SEVEN_DAYS = 'seven_days',
  THIRTY_DAYS = 'thirty_days',
}

export enum ElasticSearchDataType {
  BOOLEAN = 'boolean',
  DOUBLE = 'double',
  FLOAT = 'float',
  INTEGER = 'integer',
  IP = 'ip',
  KEYWORD = 'keyword',
  LONG = 'long',
  NULL = 'null',
  TEXT = 'text',
  UNSIGNED_LONG = 'unsigned_long',
  VERSION = 'version',
}

export enum CommonIntentWorkflowType {
  FALLBACK = 'Fallback',
  GENERAL_HANDOFF = 'general-handoff',
  HANDOFF = 'Handoff',
  KNOWLEDGE_ARTICLE = 'Knowledge_Article',
}

export enum TranslationsTableMode {
  DRAFT = 'draft',
  LIVE = 'live',
}

export enum APITemplateTypes {
  HUBSPOT_CREATE_CONTACT_TEMPLATE = 'hubspot_create_contact_template',
}

export enum ExportableTableType {
  INSIGHT_ARTICLES_TABLE = 'insight_articles_table',
  INSIGHT_CHATS_TABLE = 'insight_chats_table',
  INSIGHT_EMAIL_ARTICLES_TABLE = 'insight_email_articles_table',
  INSIGHT_TOPICS_TABLE = 'insight_topics_table',
  INSIGHT_WORKFLOWS_TABLE = 'insight_workflows_table',
}
