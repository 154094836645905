import TriageInsightsModelsTab from './models-tab';
import TriageInsightsTicketsTab from './tickets-tab';
import { DashboardTab } from 'src/components/dashboard-tabs/DashboardTabs';

export const TABS: DashboardTab[] = [
  {
    Component: <TriageInsightsModelsTab />,
    label: 'Models',
    trackingEvent: {
      extra: {
        tab: 'models',
      },
      name: 'triage-clicked-on-insights-models-tab',
    },
  },
  {
    Component: <TriageInsightsTicketsTab />,
    label: 'Tickets',
    trackingEvent: {
      extra: {
        tab: 'tickets',
      },
      name: 'triage-clicked-on-insights-tickets-tab',
    },
  },
];
