import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Alert,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import { ChartType } from '../chart-type-button/ChartTypeButton';
import DashboardChart, { DashboardChartProps } from './DashboardChart';
import DashboardChartHeader, {
  DashboardChartHeaderProps,
} from './DashboardChartHeader';
import { generateChartData } from './helpers';

type DashboardChartSectionProps = Omit<
  DashboardChartHeaderProps,
  'chartType' | 'setChartType' | 'title'
> & {
  data: DashboardChartProps['data'] | undefined;
  enabledLegends: string[];
  errorMessage?: string;
  height?: string;
  isFetching?: boolean;
  isLoading?: boolean;
  onChartTypeChange?: (value: ChartType) => void;
  onToggleVisibility?: DashboardChartProps['onToggleVisibility'];
  selectedChartType?: ChartType;
  title?: string;
  yAxis?: Highcharts.YAxisOptions[];
};

const DashboardChartSection = ({
  data,
  dateRange,
  enabledLegends,
  errorMessage,
  height = '450px',
  isFetching,
  isLoading,
  onChartTypeChange,
  onToggleVisibility,
  periodicalFilter,
  selectedChartType,
  setPeriodicalFilter,
  title = 'Time series chart',
  yAxis,
}: DashboardChartSectionProps) => {
  const [legends, setLegends] = useState<string[]>(enabledLegends);
  const [chartType, setChartType] = useState<ChartType>(
    selectedChartType || 'spline',
  );
  const { palette } = useTheme();
  const chartData = useMemo(
    () => generateChartData({ data, enabledLegends: legends }),
    [data, legends],
  );

  useEffect(() => {
    if (selectedChartType) {
      setChartType(selectedChartType);
    }
  }, [selectedChartType]);

  useEffect(() => {
    setLegends(enabledLegends);
  }, [enabledLegends]);

  const handleChartTypeChange = (value: ChartType) => {
    if (onChartTypeChange) {
      onChartTypeChange(value);
    } else {
      setChartType(value);
    }
  };

  const handleToggleVisibility = (legend: string) => {
    const isEnabled = legends.includes(legend);
    setLegends(
      isEnabled
        ? legends.filter(item => item !== legend)
        : [...legends, legend],
    );
  };
  const handleLegendChange = (legend: string) => {
    if (onToggleVisibility) {
      onToggleVisibility(legend);
    } else {
      handleToggleVisibility(legend);
    }
  };

  return (
    <Box
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius={1}
      display='flex'
      flexDirection='column'
      height={height}
      p={3}
      rowGap={1}
      sx={{
        opacity: isFetching ? '0.5' : undefined,
        pointerEvents: isFetching ? 'none' : undefined,
      }}
    >
      {isFetching && <LinearProgress />}
      {errorMessage && <Alert title={errorMessage} variant='danger' />}
      <DashboardChartHeader
        chartType={chartType}
        dateRange={dateRange}
        periodicalFilter={periodicalFilter}
        setChartType={handleChartTypeChange}
        setPeriodicalFilter={setPeriodicalFilter}
        title={title}
      />
      {isLoading && <Skeleton height={height} />}
      {!isLoading && (
        <DashboardChart
          chartType={chartType}
          data={chartData}
          onToggleVisibility={handleLegendChange}
          yAxis={yAxis}
        />
      )}
    </Box>
  );
};

export default DashboardChartSection;
