import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import GenAiIcon from './GenAiIcon';
import { IdleCanvasComponentProps } from 'src/components/email-builder/types';
import { selectEmailBuilderState } from 'src/slices/email-builder/emailBuilderSlice';

const IntentEmailAutopilotComponent = ({}: IdleCanvasComponentProps) => {
  const theme = useTheme();
  const [, setSearchParams] = useSearchParams();
  const { intentEmailConfiguration } = useSelector(selectEmailBuilderState);

  if (!intentEmailConfiguration) {
    return <></>;
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='16px'
      margin='0 auto'
      padding='16px'
      width='100%'
    >
      <div>
        <Box alignItems='center' display='flex' gap='8px' marginBottom='8px'>
          <GenAiIcon />
          <Typography variant='font18Bold'>Autopilot email policy</Typography>
        </Box>
        <Typography color={theme.palette.colors.grey[800]} variant='font14'>
          Create an Autopilot email policy with Autoflow to manage automated
          responses according to your guidelines. Include a greeting and any
          necessary standard message, while allowing Autopilot to handle the
          rest based on your defined policy.
        </Typography>
      </div>
      <Box display='flex' flexDirection='column' gap='8px'>
        <div>
          <Typography variant='font11'>
            Autopilot policy for {intentEmailConfiguration.name}:
          </Typography>
        </div>
        <div>
          <Button
            fullWidth
            onClick={() => {
              setSearchParams(params => {
                params.set('autopilot', 'true');
                return params;
              });
            }}
            size='large'
            variant='secondary'
          >
            Create Autopilot policy with Autoflow
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default IntentEmailAutopilotComponent;
