import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { INITIAL_EXPRESSION } from './constants';
import ContextVariableExpressionBuilder from './ContextVariableExpressionBuilder';
import {
  addDefaultField,
  filterSupportedContextVariables,
  getQueryExpressionFieldType,
  updateArrayItem,
} from './helper';
import { ContextVariableFilterItemValue } from './types';
import { FieldOption } from 'src/components/expression-builder/ExpressionBuilder';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import { DateRange } from 'src/types/types';

interface TicketFilterBuilderProps {
  error?: string;
  filters: ContextVariableFilterItemValue[];
  onChange: (value: ContextVariableFilterItemValue[]) => void;
  uiDateRange: DateRange;
}

const ContextVariableFilterBuilder = ({
  error,
  filters,
  onChange,
  uiDateRange,
}: TicketFilterBuilderProps) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
    shouldOnlyReturnSupportedFilterableContextVariables: true,
  });

  const fieldOptions: FieldOption[] = useMemo(
    () => filterSupportedContextVariables(contextVariables),
    [contextVariables],
  );

  const handleChange = useCallback(
    (field: Partial<ConditionExpressions>, index: number) => {
      const updatedFilters = updateArrayItem(filters, index, field);
      onChange(updatedFilters);
    },
    [filters, onChange],
  );

  const handleBooleanOperatorChange = useCallback(
    (booleanOperator: string) => {
      const items = filters.map(item => {
        return {
          ...item,
          boolean_operator: booleanOperator,
        };
      });
      onChange(items);
    },
    [filters, onChange],
  );

  const handleAdd = useCallback(() => {
    const target = filters[filters.length - 1];
    const booleanOperator = target?.boolean_operator || 'and';
    const expression = addDefaultField(
      fieldOptions,
      INITIAL_EXPRESSION.expression,
    );
    const items = filters.concat({
      boolean_operator: booleanOperator,
      expression,
    });
    onChange(items);
  }, [fieldOptions, filters, onChange]);

  const handleDelete = useCallback(
    (index: number) => {
      const items = filters.filter((_, i) => i !== index);
      onChange(items);
    },
    [filters, onChange],
  );

  return (
    <Box display='flex' flexDirection='column' rowGap={2}>
      <Typography variant='font14Bold'>Context variables</Typography>
      <Button
        onClick={handleAdd}
        startIcon={<IconPlus fontSize='small' />}
        variant='secondary'
      >
        Context variable filter
      </Button>
      {Boolean(error) && (
        <Typography color='error' variant='font12'>
          {error}
        </Typography>
      )}
      {filters.map((item, index) => {
        return (
          <Box display='flex' flexDirection='column' key={index} rowGap={1}>
            <ContextVariableExpressionBuilder
              booleanOperator={index === 0 ? undefined : item.boolean_operator}
              expression={addDefaultField(fieldOptions, item.expression)}
              field={item.expression.field}
              fieldOptions={fieldOptions}
              fieldType={getQueryExpressionFieldType(
                item.expression.field,
                fieldOptions,
              )}
              index={index}
              isBooleanOperatorDisabled={false || index > 1}
              isDeleteButtonDisabled={false}
              onDeleteButtonClick={() => handleDelete(index)}
              onSelectBooleanOperator={booleanOperator =>
                handleBooleanOperatorChange(booleanOperator)
              }
              onSelectedField={field =>
                handleChange({ field, values: [] }, index)
              }
              onSelectOperator={operator =>
                handleChange(
                  {
                    negate: operator.negate,
                    operator: operator.value,
                    values: [],
                  },
                  index,
                )
              }
              onValueChange={value => {
                const values = Array.isArray(value) ? value : [value];
                handleChange({ values }, index);
              }}
              uiDateRange={uiDateRange}
            />
          </Box>
        );
      })}
    </Box>
  );
};
export default ContextVariableFilterBuilder;
