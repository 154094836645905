import {
  INTENT_OPTION_TYPES,
  PLACEHOLDER_INTENTS,
  SOLVE_API_CONFIG_TABS,
  SOLVE_WIDGET_CONFIG_TABS,
} from './constants';
import {
  confirmAssetUploadComplete,
  createAssetUploadLinkAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
import { TopIntent } from 'src/slices/solve-config/types';
import {
  CreateAssetLinkResponse,
  IntentData,
} from 'src/types/workflowBuilderAPITypes';

export const getSolveWidgetConfigTabs = (params: {
  isBannerImageEnabled: boolean;
  isCsatConfigRevampEnabled: boolean;
  isFallbackWorkflowEnabled: boolean;
  isHandoffRevampEnabled: boolean;
  isMultibrandEnabled: boolean;
  isPrivacyConsentEnabled: boolean;
  isQuickFeedbackEnabled: boolean;
  isSolveKnowledgeConfigRevamp: boolean;
  isSolveLiteEnabled: boolean;
  isToneOfVoiceEnabled: boolean;
}) => {
  const {
    isBannerImageEnabled,
    isCsatConfigRevampEnabled,
    isFallbackWorkflowEnabled,
    isMultibrandEnabled,
    isPrivacyConsentEnabled,
    isQuickFeedbackEnabled,
    isSolveKnowledgeConfigRevamp,
    isSolveLiteEnabled,
    isToneOfVoiceEnabled,
  } = params;
  return SOLVE_WIDGET_CONFIG_TABS.filter(tab => {
    if (isSolveKnowledgeConfigRevamp && tab.label === 'Knowledge') {
      return true;
    }
    if (tab.label === 'More') {
      if (isSolveLiteEnabled) {
        return false;
      }
    }
    if (!isSolveLiteEnabled && tab.label === 'Knowledge') {
      return false;
    }
    if (tab.label === 'CSAT') {
      return isCsatConfigRevampEnabled;
    }
    if (tab.label === 'Privacy') {
      return isPrivacyConsentEnabled;
    }
    if (tab.label === 'Banner') {
      return isBannerImageEnabled;
    }
    if (tab.label === 'Fallback') {
      return isFallbackWorkflowEnabled && !isSolveLiteEnabled;
    }

    if (tab.label === 'Tone') {
      return isToneOfVoiceEnabled;
    }
    if (tab.label === 'Brand' || tab.label === 'Instruction') {
      return isMultibrandEnabled;
    }

    return true;
  }).map(tab => {
    if (tab.label === 'CSAT' && isQuickFeedbackEnabled) {
      return {
        ...tab,
        title: '',
      };
    }

    return tab;
  });
};

export const getSolveApiConfigTabs = (params: {
  isSolveKnowledgeConfigRevamp: boolean;
}) => {
  // eslint-disable-next-line unused-imports/no-unused-vars -- TODO: uncomment in future PR
  const { isSolveKnowledgeConfigRevamp } = params;
  // eslint-disable-next-line unused-imports/no-unused-vars -- TODO: uncomment in future PR
  return SOLVE_API_CONFIG_TABS.filter(tab => {
    // if (isSolveKnowledgeConfigRevamp && tab.label === 'Knowledge') {
    //   return true;
    // }
    // if (tab.label === 'Knowledge') {
    //   return false;
    // }
    return true;
  });
};

const getIntentNamesFromTopIntent = (
  topNIntents: TopIntent[] | null,
  manuallySelectedTopIntents: string[],
  numTopIntents: number,
  isSaving: boolean,
) => {
  // Manual scenario
  if (topNIntents && manuallySelectedTopIntents.length > 0) {
    return topNIntents
      .map(intent => {
        if (manuallySelectedTopIntents.includes(intent.intent_id)) {
          return intent.intent_name;
        }
      })
      .filter(Boolean) as string[];
  }
  // Auto scenario
  if (numTopIntents > 0 && topNIntents && topNIntents.length > 0) {
    return topNIntents
      .slice(0, numTopIntents)
      .map((intent: TopIntent) => intent.intent_name);
  }
  // Loading scenario if empty
  if (isSaving) {
    return [];
  }

  return PLACEHOLDER_INTENTS;
};

const getIntentNames = (paramObject: {
  intents: IntentData[] | null;
  isSaving: boolean;
  manuallySelectedTopIntents: string[];
  numTopIntents: number;
}) => {
  const { intents, isSaving, manuallySelectedTopIntents, numTopIntents } =
    paramObject;
  const isManualScenario = intents && manuallySelectedTopIntents.length > 0;
  const isAutoScenario = numTopIntents > 0 && intents && intents.length > 0;

  if (isManualScenario) {
    return manuallySelectedTopIntents
      .map(
        manuallySelectedTopIntent =>
          (
            intents.find(
              ({ intent_definition_id }) =>
                intent_definition_id === manuallySelectedTopIntent,
            ) as IntentData
          )?.intent_name,
      )
      .filter(Boolean);
  }
  if (isAutoScenario) {
    return intents.slice(0, numTopIntents).map(intent => intent.intent_name);
  }
  if (isSaving) {
    return [];
  }

  return PLACEHOLDER_INTENTS;
};

export const handleIntentNames = (paramObject: {
  intents: IntentData[] | null;
  intentType: INTENT_OPTION_TYPES;
  isSaving: boolean;
  manuallySelectedTopIntents: string[];
  numTopIntents: number;
  topNIntents: TopIntent[] | null;
}) => {
  const {
    intents,
    intentType,
    isSaving,
    manuallySelectedTopIntents,
    numTopIntents,
    topNIntents,
  } = paramObject;
  if (intentType === 'auto') {
    return getIntentNamesFromTopIntent(
      topNIntents,
      manuallySelectedTopIntents,
      numTopIntents,
      isSaving,
    );
  }
  if (intentType === 'manual') {
    return getIntentNames({
      intents,
      isSaving,
      manuallySelectedTopIntents,
      numTopIntents,
    });
  }
  return [];
};

// TODO: move other usages of similar code to this function
export const uploadImagetoS3 = async ({
  description,
  file,
}: {
  description: string;
  file: File;
}): Promise<CreateAssetLinkResponse> => {
  const fileExtension = file.name.split('.').pop();
  // generate s3 link
  const assetUpload = await createAssetUploadLinkAPI({
    content_type: file.type,
    description,
    file_extension: fileExtension ?? '',
  });
  // upload file to generated s3 url
  await fetch(assetUpload.url, {
    body: file,
    headers: {
      'Content-Type': file.type,
    },
    method: 'PUT',
  });
  // confirm upload complete
  await confirmAssetUploadComplete(assetUpload.asset_id);
  return assetUpload;
};
