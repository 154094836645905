import { OverviewMetricValueType } from './types';
import {
  getHoursFromSeconds,
  numberFormatter,
} from 'src/utils/discover/helpers';
import {
  formatNToPercentage,
  formatNToPercentageDecimal,
} from 'src/utils/formatToPercentage';

export const formatMetricValue = (
  value: number,
  metricType?: OverviewMetricValueType,
  valueFormatter?: (value: number) => string,
) => {
  if (valueFormatter) {
    return valueFormatter(value);
  }

  if (metricType === 'percentage') {
    return formatNToPercentage(value);
  }

  if (metricType === 'percentage_decimal') {
    return formatNToPercentageDecimal(value);
  }

  if (metricType === 'duration') {
    const duration = getHoursFromSeconds(value);
    return `${duration} hrs`;
  }

  if (metricType === 'compact_number') {
    return numberFormatter(value);
  }

  const numberValue = value.toLocaleString();

  if (metricType === 'cost') {
    return `$${numberValue}`;
  }

  return numberValue;
};
