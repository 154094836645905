import { useCallback } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { HELPDESKS_USING_RAW_TEMPLATE } from './constants';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import { selectIntentEmailConfigurations } from 'src/slices/email-builder/emailBuilderSlice';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

export const useGoToConfiguration = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (configurationId: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('configurationId', configurationId);

      navigate({
        search: searchParams.toString(),
      });
    },
    [navigate, location.search],
  );
};

const useRemoveConfigIdQueryParam = () => {
  const navigate = useNavigate();

  return useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('configurationId');
    navigate({
      search: searchParams.toString(),
    });
  }, [navigate]);
};

export const useUpdateStateAfterDeletingEmailConfiguration = () => {
  const goToConfiguration = useGoToConfiguration();
  const removeConfigIdParam = useRemoveConfigIdQueryParam();
  const intentEmailConfigurations = useSelector(
    selectIntentEmailConfigurations,
  );

  return useCallback(
    (configId: string) => {
      const updatedEmailConfigurations = intentEmailConfigurations.filter(
        configuration => configuration.configuration_id !== configId,
      );
      const updatedEmailConfigurationsLength =
        updatedEmailConfigurations.length;

      if (!!updatedEmailConfigurationsLength) {
        goToConfiguration(
          updatedEmailConfigurations[updatedEmailConfigurationsLength - 1]
            .configuration_id,
        );
      } else {
        removeConfigIdParam();
      }
    },
    [goToConfiguration, intentEmailConfigurations, removeConfigIdParam],
  );
};

export const useGetContextVariablesForTextEditor = () => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  return useMemo(() => {
    const flattened = flattenDynamicContextVariables(contextVariables);
    return flattened.map(cv => ({
      ...cv,
      isDefined: true,
    }));
  }, [contextVariables]);
};

export const useGetIsUsingRawTemplate = () => {
  const { data: helpdeskData } = useGetHelpdeskQuery();

  return useMemo(
    () => HELPDESKS_USING_RAW_TEMPLATE.includes(helpdeskData?.helpdesk || ''),
    [helpdeskData?.helpdesk],
  );
};
