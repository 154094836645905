import { dashboardApi } from '../dashboard-api';
import { LineGraphDataType, LineGraphValueData } from '../insights/types';
import {
  createFilterBody,
  createTimeStampObject,
  createTimeStamps,
  updateValueMappingFromLabels,
} from './helpers';
import {
  DuplicateVersionRequest,
  ModelBaseUpdate,
  ModelLabelsRequest,
  ModelLabelsResponse,
  PublishVersionRequest,
  TicketTableRequest,
  TicketTableResponse,
  TriageCreateAutoGenModelRequest,
  TriageCreateAutoGenModelResponse,
  TriageFilterTicketRequest,
  TriageInsightChartResponse,
  TriageInsightModelLabelMappingResponse,
  TriageInsightOverallPredictionMetricResponse,
  TriageInsightOverallTicketMetricResponse,
  TriageInsightsMetricChartRequest,
  TriageInsightsModelOverviewRequest,
  TriageInsightsModelOverviewResponse,
  TriageInsightTicketDetailResponse,
  TriagePredictionRun,
  TriagePredictionRunResponse,
  TriagePredictionTestResultResponse,
  TriageTicketOverviewRequest,
  TriageTicketOverviewResponse,
  TriageUpdateModelInfoRequest,
} from './types';
import _omit from 'lodash/fp/omit';
import {
  GetModelsAndVersionsResponse,
  HelpdeskOptionsResponse,
  HelpdeskResponseV2,
  PatchTriageModelRequest,
  SimulateTicketResponse,
  TestTriageModelRequest,
  TestTriageModelResponse,
  TriageModelDetail,
  TriageSimulateRequest,
  TriageTicketCustomFieldResponse,
  TriageTicketFieldsResponse,
  VersionedTriageModel,
  VersionedTriageModelRequest,
} from 'src/reducers/triageSettingsReducer/types';

const triageV1 = '/dashboard/triage/v1';

const triageApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    createAutoGenModel: builder.mutation<
      TriageCreateAutoGenModelResponse,
      TriageCreateAutoGenModelRequest
    >({
      invalidatesTags: [{ type: 'TriageModelDetail' }],
      query: body => ({
        body,
        method: 'POST',
        url: `${triageV1}/autogenerate-model`,
      }),
    }),
    createModel: builder.mutation<
      VersionedTriageModel,
      VersionedTriageModelRequest
    >({
      invalidatesTags: [{ type: 'TriageModelDetail' }],
      query: ({ model_description, model_name }) => ({
        body: { model_description, model_name },
        method: 'POST',
        url: `${triageV1}/models`,
      }),
    }),
    deleteModel: builder.mutation<VersionedTriageModel, string>({
      invalidatesTags: (_, __, arg) => [
        { type: 'TriageModelDetail' },
        { id: arg, type: 'TriageModelDetail' },
      ],
      query: modelId => ({
        method: 'DELETE',
        url: `${triageV1}/models/${modelId}`,
      }),
    }),
    deletePredictionTest: builder.mutation<TriagePredictionRunResponse, string>(
      {
        invalidatesTags: [{ type: 'Simulation' }],
        query: predictionTestId => ({
          method: 'DELETE',
          url: `${triageV1}/prediction-test/${predictionTestId}`,
        }),
      },
    ),
    deleteVersionModel: builder.mutation<VersionedTriageModel, ModelBaseUpdate>(
      {
        invalidatesTags: (_, __, arg) => [
          { id: arg.modelId, type: 'TriageModelDetail' },
        ],
        query: ({ modelId, versionId }) => ({
          method: 'DELETE',
          url: `${triageV1}/models/${modelId}/versions/${versionId}`,
        }),
      },
    ),
    duplicateVersion: builder.mutation<
      VersionedTriageModel,
      DuplicateVersionRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, versionId, versionName }) => ({
        body: { version_name: versionName },
        method: 'POST',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    getHelpdesk: builder.query<HelpdeskResponseV2, void>({
      query: () => ({
        method: 'GET',
        url: 'ssel/triage/helpdesk-integrations',
      }),
    }),
    getHelpdeskOptions: builder.query<HelpdeskOptionsResponse, void>({
      query: () => ({
        method: 'GET',
        url: `${triageV1}/helpdesk-options`,
      }),
    }),
    getInsightModelMapping: builder.query<
      TriageInsightModelLabelMappingResponse,
      void
    >({
      query: () => ({
        method: 'GET',
        url: `${triageV1}/triage-insight/model-label-mapping`,
      }),
    }),
    getModelById: builder.query<
      VersionedTriageModel,
      { modelId: string; showAll?: boolean }
    >({
      providesTags: (_, __, arg) => [
        { id: arg.modelId, showAll: arg.showAll, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, showAll = false }) => ({
        method: 'GET',
        params: { show_all: showAll },
        url: `${triageV1}/models/${modelId}`,
      }),
    }),
    getModelInsightsChart: builder.query<
      TriageInsightChartResponse,
      TriageInsightsMetricChartRequest
    >({
      query: ({ endDate, interval, modelName, startDate }) => ({
        body: {
          ...createTimeStampObject(startDate, endDate),
          interval,
          model_name: modelName,
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/model-time-series-chart`,
      }),
    }),
    getModelInsightsMetrics: builder.query<
      TriageInsightsModelOverviewResponse,
      TriageInsightsModelOverviewRequest
    >({
      query: ({ endDate, modelName, startDate }) => ({
        body: {
          ...createTimeStampObject(startDate, endDate),
          model_name: modelName,
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/label-table`,
      }),
    }),
    getModelInsightsTicketCount: builder.query<
      LineGraphValueData,
      TriageInsightsMetricChartRequest
    >({
      query: ({ endDate, interval, modelName, startDate }) => ({
        body: {
          ...createTimeStampObject(startDate, endDate),
          interval,
          model_name: modelName,
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/model-time-series-chart-total-tickets`,
      }),
    }),
    getModelLabels: builder.query<ModelLabelsResponse, ModelLabelsRequest>({
      providesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ endDate, modelId, startDate }) => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}/label-table${createTimeStamps(
          startDate,
          endDate,
        )}`,
      }),
    }),
    getModelsAndVersions: builder.query<GetModelsAndVersionsResponse[], void>({
      query: () => ({
        method: 'GET',
        url: `${triageV1}/models-and-versions`,
      }),
    }),
    getModelVersion: builder.query<VersionedTriageModel, ModelBaseUpdate>({
      providesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, versionId }) => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    getModelVersions: builder.query<VersionedTriageModel[], string>({
      providesTags: (_, __, arg) => [{ id: arg, type: 'TriageModelDetail' }],
      query: modelId => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}/versions`,
      }),
    }),
    getPredictionRuns: builder.query<TriagePredictionRunResponse, void>({
      providesTags: ['Simulation'],
      query: () => ({
        url: `${triageV1}/prediction-tests`,
      }),
    }),
    getPredictionTestResults: builder.query<
      TriagePredictionTestResultResponse,
      string
    >({
      query: predictionTestId => ({
        url: `${triageV1}/prediction-test-result/${predictionTestId}`,
      }),
    }),
    getSelfServeTriageModels: builder.query<Array<TriageModelDetail>, void>({
      providesTags: [{ type: 'TriageModelDetail' }],
      query: () => ({
        method: 'GET',
        url: `${triageV1}/models`,
      }),
    }),
    getTicketFields: builder.query<TriageTicketFieldsResponse, string>({
      query: dataSource => ({
        method: 'GET',
        url: `ssel/triage/models/data_source/${dataSource}/ticket_fields`,
      }),
    }),
    getTicketsOverviewMetrics: builder.query<
      TriageTicketOverviewResponse,
      TriageTicketOverviewRequest
    >({
      query: params => {
        return {
          body: createFilterBody(params),
          method: 'POST',
          url: `${triageV1}/ticket-tab/ticket-overview-metrics`,
        };
      },
    }),
    getTicketsTable: builder.query<TicketTableResponse, TicketTableRequest>({
      forceRefetch: ({ currentArg, previousArg }) => {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.page > 1) {
          currentCacheData.tickets = currentCacheData.tickets.concat(
            responseData.tickets,
          );
          currentCacheData.next_page = responseData.next_page;
        } else {
          currentCacheData.tickets = responseData.tickets;
          currentCacheData.next_page = responseData.next_page;
        }
      },
      query: params => {
        return {
          body: createFilterBody(params),
          method: 'POST',
          url: `${triageV1}/ticket-tab/ticket-table`,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const {
          endDate,
          filters = [],
          keywordSearch = '',
          modelId,
          page,
          startDate,
          ticketId = '',
        } = queryArgs;

        const qParams = new URLSearchParams({
          endDate: endDate.toString(),
          keywordSearch,
          modelId,
          page: page.toString(),
          startDate: startDate.toString(),
          ticketId,
        });

        filters.forEach((filter, index) => {
          qParams.append(`filter_${index}`, filter);
        });

        return `${endpointName}?${qParams.toString()}`;
      },
    }),
    getTriageInsightOverallPredictionMetrics: builder.query<
      TriageInsightOverallPredictionMetricResponse,
      TriageFilterTicketRequest
    >({
      query: filters => ({
        body: {
          ...filters,
          ...createTimeStampObject(filters.start, filters.end),
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/overall-prediction-metrics`,
      }),
    }),
    getTriageInsightOverallTicketMetrics: builder.query<
      TriageInsightOverallTicketMetricResponse,
      TriageFilterTicketRequest
    >({
      query: filters => ({
        body: {
          ...filters,
          ...createTimeStampObject(filters.start, filters.end),
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/overall-ticket-metrics`,
      }),
    }),
    getTriageInsightTable: builder.query<
      TicketTableResponse,
      TriageFilterTicketRequest
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.page && otherArgs.arg.page > 1) {
          currentCacheData.tickets = currentCacheData.tickets.concat(
            responseData.tickets,
          );
          currentCacheData.next_page = responseData.next_page;
        } else {
          currentCacheData.tickets = responseData.tickets;
          currentCacheData.next_page = responseData.next_page;
        }
      },
      query: filters => ({
        body: {
          ...filters,
          ...createTimeStampObject(filters.start, filters.end),
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/tickets-table`,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return _omit(['page'], queryArgs);
      },
    }),
    getTriageInsightTicketDetail: builder.query<
      TriageInsightTicketDetailResponse,
      string
    >({
      query: ticketId => ({
        url: `${triageV1}/triage-insight/ticket_detail/${ticketId}`,
      }),
    }),
    getTriageInsightTicketsTimeSeriesChart: builder.query<
      LineGraphDataType | undefined,
      TriageFilterTicketRequest
    >({
      query: filters => ({
        body: {
          ...filters,
          ...createTimeStampObject(filters.start, filters.end),
        },
        method: 'POST',
        url: `${triageV1}/triage-insight/tickets-time-series-chart`,
      }),
      transformResponse: (
        returnValue: LineGraphDataType & { status: number },
      ) => {
        if (returnValue.status === 500) {
          return undefined;
        }
        return returnValue;
      },
    }),
    getTriageModel: builder.query<TriageModelDetail, string>({
      query: modelName => ({
        method: 'GET',
        url: `ssel/triage/models/${modelName}`,
      }),
    }),
    getTriageModels: builder.query<Array<TriageModelDetail>, void>({
      providesTags: [{ type: 'TriageModelDetail' }],
      query: () => ({
        method: 'GET',
        url: 'ssel/triage/models',
      }),
    }),
    getTriageTicketCustomFields: builder.query<
      TriageTicketCustomFieldResponse,
      void
    >({
      query: () => ({
        method: 'GET',
        url: `${triageV1}/ticket-custom-field`,
      }),
    }),

    patchModelLabelsAndPhrases: builder.mutation<
      VersionedTriageModel,
      PatchTriageModelRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
        { type: 'TriageModelDetail' },
      ],
      query: ({ body, modelId, versionId }) => ({
        body: updateValueMappingFromLabels(body),
        method: 'PATCH',
        url: `${triageV1}/labels-phrases/${modelId}/versions/${versionId}`,
      }),
    }),

    patchPredictionModel: builder.mutation<
      TriagePredictionRunResponse,
      { name: string; predictionTestId: string }
    >({
      invalidatesTags: (_, __, arg) => [
        { type: 'Simulation' },
        { id: arg.predictionTestId, type: 'Simulation' },
      ],
      query: ({ name, predictionTestId }) => ({
        body: { name },
        method: 'PATCH',
        url: `${triageV1}/prediction-test/${predictionTestId}`,
      }),
    }),

    patchSelfServeTriageModel: builder.mutation<
      VersionedTriageModel,
      PatchTriageModelRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
        { type: 'TriageModelDetail' },
      ],
      query: ({ body, modelId, versionId }) => ({
        body: updateValueMappingFromLabels(body),
        method: 'PATCH',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    patchSelfServeTriageModelConfig: builder.mutation<
      VersionedTriageModel,
      PatchTriageModelRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
        { type: 'TriageModelDetail' },
      ],
      query: ({ body, modelId, versionId }) => ({
        body: updateValueMappingFromLabels(body),
        method: 'PATCH',
        url: `${triageV1}/config/${modelId}/versions/${versionId}`,
      }),
    }),
    patchSelfServeTriageModelForAllVersions: builder.mutation<
      VersionedTriageModel,
      Partial<PatchTriageModelRequest>
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
        { type: 'TriageModelDetail' },
      ],
      query: ({ body, modelId }) => ({
        body: body,
        method: 'PATCH',
        url: `${triageV1}/models/${modelId}`,
      }),
    }),
    publishVersion: builder.mutation<
      VersionedTriageModel,
      PublishVersionRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { type: 'TriageModelDetail' },
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, publish, versionId }) => ({
        body: { publish },
        method: 'PUT',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    simulateTriageTickets: builder.mutation<
      SimulateTicketResponse,
      TriageSimulateRequest
    >({
      async onCacheEntryAdded(_, api) {
        await api.cacheDataLoaded;
        const responseData = api.getCacheEntry().data;
        if (responseData) {
          api.dispatch(
            triageApi.util.updateQueryData(
              'getPredictionRuns',
              undefined,
              data => {
                const transformData: TriagePredictionRun = {
                  coverage: null,
                  created_at: responseData.created_at,
                  created_by: responseData.created_by,
                  end_datetime: responseData.end_datetime,
                  model_id: responseData.model_id,
                  model_name: responseData.model_name,
                  name: responseData.name,
                  org_id: responseData.org_id,
                  prediction_test_id: responseData.prediction_test_id,
                  start_datetime: responseData.start_datetime,
                  status: responseData.status as TriagePredictionRun['status'],
                  ticket_ids: responseData.ticket_ids,
                  version_id: responseData.version_id,
                  version_name: responseData.version_name,
                  volume: null,
                };
                data.prediction_tests.unshift(transformData);
              },
            ),
          );
        }
      },
      query: ({
        end_datetime,
        model_id,
        name,
        start_datetime,
        ticket_ids,
        version_id,
      }) => ({
        body: {
          end_datetime,
          model_id,
          name,
          start_datetime,
          ticket_ids,
          version_id,
        },
        method: 'POST',
        url: `${triageV1}/models/simulate`,
      }),
    }),
    testSelfServeModel: builder.mutation<
      TestTriageModelResponse,
      TestTriageModelRequest
    >({
      query: ({ body, modelId, versionId }) => ({
        body: body,
        method: 'POST',
        url: `${triageV1}/models/${modelId}/versions/${versionId}/test/v2`,
      }),
    }),
    updateModelInfo: builder.mutation<
      VersionedTriageModel,
      TriageUpdateModelInfoRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
        { type: 'TriageModelDetail' },
      ],
      query: ({ description, modelId, name, versionId, versionName }) => ({
        body: {
          model_description: description,
          model_name: name,
          version_name: versionName,
        },
        method: 'PATCH',
        url: `${triageV1}/info/${modelId}/versions/${versionId}`,
      }),
    }),
  }),
});

export const {
  useCreateAutoGenModelMutation,
  useCreateModelMutation,
  useDeleteModelMutation,
  useDeletePredictionTestMutation,
  useDeleteVersionModelMutation,
  useDuplicateVersionMutation,
  useGetHelpdeskOptionsQuery,
  useGetInsightModelMappingQuery,
  useGetModelByIdQuery,
  useGetModelInsightsChartQuery,
  useGetModelInsightsMetricsQuery,
  useGetModelInsightsTicketCountQuery,
  useGetModelLabelsQuery,
  useGetModelsAndVersionsQuery,
  useGetModelVersionQuery,
  useGetModelVersionsQuery,
  useGetPredictionRunsQuery,
  useGetPredictionTestResultsQuery,
  useGetSelfServeTriageModelsQuery,
  useGetTicketFieldsQuery,
  useGetTicketsOverviewMetricsQuery,
  useGetTicketsTableQuery,
  useGetTriageInsightOverallPredictionMetricsQuery,
  useGetTriageInsightOverallTicketMetricsQuery,
  useGetTriageInsightTableQuery,
  useGetTriageInsightTicketDetailQuery,
  useGetTriageInsightTicketsTimeSeriesChartQuery,
  useGetTriageModelQuery,
  useGetTriageModelsQuery,
  useGetTriageTicketCustomFieldsQuery,
  usePatchModelLabelsAndPhrasesMutation,
  usePatchPredictionModelMutation,
  usePatchSelfServeTriageModelConfigMutation,
  usePatchSelfServeTriageModelForAllVersionsMutation,
  usePatchSelfServeTriageModelMutation,
  usePublishVersionMutation,
  useSimulateTriageTicketsMutation,
  useTestSelfServeModelMutation,
  useUpdateModelInfoMutation,
} = triageApi;
export { triageApi };
