import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { styled } from '@mui/material';

import { EmailForm } from './EmailForm';
import { EmailTemplateError } from './EmailTemplateError';
import { PreviewEmailTemplate } from './EmailTemplatePreview';
import { InteractiveEmailWidgetPreview } from './interactiveEmailWidgetPreview';
import { useGetEmailFormData } from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-journey-map/test-journey-modal/hooks';
import { selectEmailWorkflowState } from 'src/slices/email-workflow/emailWorkflowSlice';
import {
  initializeEmailPreview,
  initializePreviewLogs,
  selectEmailPreviewStage,
} from 'src/slices/workflow-preview/workflowPreviewSlice';

export const EmailPreviewSection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const stage = useSelector(selectEmailPreviewStage);

  const params = new URLSearchParams(location.search);
  const previewFromWorkflowId = params.get('workflowId');
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);

  const { enabledLanguages, testContextVariables, testFields } =
    useGetEmailFormData(
      previewFromWorkflowId || emailWorkflow?.email_workflow_id || '',
    );

  const getComponentToRender = () => {
    if (stage === 'email-form') {
      return (
        <EmailForm
          enabledLanguages={enabledLanguages}
          testContextVariables={testContextVariables}
          testFields={testFields}
        />
      );
    } else if (stage === 'email-template') {
      return <PreviewEmailTemplate />;
    } else if (stage === 'email-template-error') {
      return <EmailTemplateError />;
    } else {
      return <InteractiveEmailWidgetPreview />;
    }
  };

  useEffect(() => {
    dispatch(initializeEmailPreview());
    dispatch(initializePreviewLogs());
  }, [dispatch]);

  return <LeftSection>{getComponentToRender()}</LeftSection>;
};

const LeftSection = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  flex: 1;
  background: #dfe3ed;
`;
