import { styled } from '@mui/material';

export const CircleIcon = styled('div')<{ bgcolor: string }>`
  background-color: ${props => props.bgcolor};
  border-radius: 16px;
  color: white;
  height: 32px;
  padding: 6px;
`;

export const ToolCard = styled('div')<{
  isActive: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  padding: 10px 16px 10px 8px;
  background-color: ${props => {
    if (props.isActive) {
      return props.theme.palette.colors.slate[100];
    }
    if (props.isDisabled) {
      return props.theme.palette.colors.slate[100];
    }
    return props.theme.palette.colors.white;
  }};
  border: ${props =>
    props.isActive
      ? `1px solid ${props.theme.palette.colors.purple[500]}`
      : `1px solid ${props.theme.palette.colors.slate[200]}`};
  &:hover {
    background-color: ${props =>
      !props.isDisabled && props.theme.palette.colors.slate[100]};
  }
`;
