import Box from '@mui/material/Box';

import {
  FilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ChartTypeButton, {
  ChartType,
} from '../chart-type-button/ChartTypeButton';
import { DateRange } from '../dashboard-pages/discover-dashboard-page/types';
import { initialInsightParams } from '../dashboard-pages/solve-insights/constants';
import { InsightPeriod } from '../dashboard-pages/solve-insights/types';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { periodicalFilterOptions } from 'src/constants/discover';
import {
  dateRangeToTimeFilter,
  isTimePeriodValid,
} from 'src/utils/discover/helpers';

export type DashboardChartHeaderProps = {
  chartType: ChartType;
  dateRange: DateRange;
  periodicalFilter: InsightPeriod;
  setChartType: (value: ChartType) => void;
  setPeriodicalFilter: (period: InsightPeriod) => void;
  title: string;
};

const DashboardChartHeader = ({
  chartType,
  dateRange,
  periodicalFilter,
  setChartType,
  setPeriodicalFilter,
  title,
}: DashboardChartHeaderProps) => {
  const timeFilter = dateRangeToTimeFilter(dateRange);

  return (
    <Box alignItems='center' display='flex' justifyContent='space-between'>
      <Typography variant='font16Bold'>{title}</Typography>

      <Box columnGap={1} display='flex'>
        <ChartTypeButton onChange={setChartType} value={chartType} />
        <FilterButton
          aria-label='time period filter'
          disabledTooltipMessage='Time period not valid for selected time range'
          initialValue={initialInsightParams.period}
          onChange={value => {
            setPeriodicalFilter(value as InsightPeriod);
          }}
          options={periodicalFilterOptions.map(option => {
            const isDisabled = !isTimePeriodValid(option.value, timeFilter);
            return { ...option, disabled: isDisabled };
          })}
          startAdornment={<FilterButtonIcon dropdownType='time-period' />}
          value={periodicalFilter}
        />
      </Box>
    </Box>
  );
};

export default DashboardChartHeader;
