import { Box, styled, useTheme } from '@mui/material';
import { IconExternalLink, IconTag } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from '../constants';
import ConversationTranscript from '../ConversationTranscript';
import { RevampConversationTranscript } from '../ConversationTranscript/RevampConversationTranscript';
import { formatCSAT, formatTimestamp } from '../helpers';
import useGetCSATColor from '../hooks/useGetCSATColor';
import useIsCSATEnabled from '../hooks/useIsCSATEnabled';
import useIsDeflectionInsightsEnabled from '../hooks/useIsDeflectionInsightsEnabled';
import { ArticlesSection } from './ArticlesSection';
import { ChatEventsSection } from './ChatEventsSection';
import { DeflectionInsightsData } from './DeflectionInsightData';
import { InlineData } from './InlineData';
import { formatContextVariables, formatTimeSpent } from './utils';
import OverflowDropdown from 'src/components/dashboard-pages/solve-insights/common/overflow-dropdown/OverflowDropdown';
import { Conversation } from 'src/services/apiInterfaces';

export const RevampDrawerBody = ({
  selectedConversation,
}: {
  selectedConversation: Conversation;
}) => {
  const {
    additional_feedback: csatAdditionalFeedback,
    channel: conversationChannel,
    chat_events,
    context_variables,
    conversation_id,
    csat_feedback,
    csat_resolve,
    csat_score,
    deflected,
    last_executed_intent_user_query,
    quick_feedback_responses,
    start_datetime,
    tagged_zendesk_ticket_source_id,
    tagged_zendesk_ticket_source_link,
    time_spent_in_widget,
    transcript,
    transcript_components,
    user_interactions,
    user_queries,
    workflow_tag,
  } = selectedConversation;

  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();
  const isCSATEnabled = useIsCSATEnabled();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  const csatFeedback = csat_feedback?.join(', ');

  const getCsatResolve = () => {
    if (typeof csat_resolve == 'boolean') {
      return csat_resolve ? 'Yes' : 'No';
    }
    return 'n/a';
  };

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Chat Details</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='30px' py='24px'>
        {/* Info section */}
        <Box display='flex' flexDirection='column' gap='12px'>
          <InlineData label='Chat ID' value={conversation_id} />
          <InlineData label='Deflected' value={deflected ? 'True' : 'False'} />
        </Box>
        {/* Live chat id section */}
        {tagged_zendesk_ticket_source_id &&
          tagged_zendesk_ticket_source_link && (
            <Box display='flex' flexDirection='column' gap='2px'>
              <Typography variant='font11'>
                Live Chat ID (ticket created to speak with a live agent)
              </Typography>
              <Typography
                color={palette.colors.grey[600]}
                variant='font12Medium'
              >
                <a
                  href={tagged_zendesk_ticket_source_link}
                  rel='noreferrer'
                  target='_blank'
                >
                  <Box alignItems='center' display='flex' gap='2px'>
                    {tagged_zendesk_ticket_source_id}
                    <IconExternalLink size={14} />
                  </Box>
                </a>
              </Typography>
            </Box>
          )}
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Summary</Typography>
          <SummaryBox>
            <Typography variant='font12'>Processing</Typography>
          </SummaryBox>
        </Box>
        {/* Chat section */}
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Chat</Typography>
          <Card>
            <CardSection>
              <InlineData
                label='Time created'
                value={formatTimestamp(start_datetime + 'Z')}
              />
              <InlineData
                label='Time spent'
                value={formatTimeSpent(time_spent_in_widget)}
              />
              <InlineData label='Channel' value={conversationChannel} />
              {workflow_tag && (
                <InlineData
                  label='Workflow Tag'
                  value={
                    <Box
                      sx={{
                        alignItems: 'center',
                        bgcolor: palette.colors.slate[100],
                        borderRadius: '4px',
                        color: palette.colors.grey[800],
                        display: 'flex',
                        px: 1,
                        py: 0.5,
                      }}
                    >
                      <IconTag color={palette.colors.blue[500]} size={16} />
                      <Typography
                        color={palette.colors.black}
                        noWrap
                        variant='font12'
                      >
                        {workflow_tag}
                      </Typography>
                    </Box>
                  }
                />
              )}
              <InlineData
                label='Deflected'
                value={deflected ? 'True' : 'False'}
              />
              <InlineData
                label='# of user interactions'
                value={user_interactions}
              />
              {isCSATEnabled && (
                <InlineData
                  color={getCSATColor(csat_score)}
                  label='CSAT'
                  value={formatCSAT(csat_score)}
                />
              )}
              {context_variables && (
                <InlineData
                  label='Context Variables'
                  value={formatContextVariables(context_variables)}
                />
              )}
            </CardSection>

            <Divider />

            <CardSection>
              <Typography color={palette.colors.grey[600]} variant='font12'>
                User queries
              </Typography>
              {user_queries ? (
                <OverflowDropdown
                  data={user_queries}
                  menuTitle='User query'
                  scope='workflow'
                  tab='chat'
                  variant='user_queries'
                />
              ) : (
                <i>
                  <Typography color={palette.colors.blue[600]} variant='font14'>
                    &quot;{last_executed_intent_user_query}&quot;
                  </Typography>
                </i>
              )}
            </CardSection>

            {chat_events && chat_events.length > 1 && (
              <>
                <Divider />
                <ChatEventsSection chatEvents={chat_events} />
              </>
            )}

            {isCSATEnabled && (
              <>
                <Divider />
                <CardSection>
                  <InlineData
                    color={getCSATColor(csat_score)}
                    label='CSAT'
                    value={formatCSAT(csat_score)}
                  />
                  <InlineData
                    label='Reasons for rating'
                    value={
                      <i>
                        <Typography
                          color={
                            !!csatFeedback
                              ? palette.colors.blue[600]
                              : palette.colors.grey[600]
                          }
                          variant='font12'
                        >
                          {csatFeedback || NA}
                        </Typography>
                      </i>
                    }
                  />
                  <InlineData
                    label='Additional Feedback'
                    value={
                      <i>
                        <Typography
                          color={
                            !!csatAdditionalFeedback
                              ? palette.colors.blue[600]
                              : palette.colors.grey[600]
                          }
                          variant='font12'
                        >
                          {csatAdditionalFeedback || NA}
                        </Typography>
                      </i>
                    }
                  />
                  <InlineData
                    label='Resolution Confirmation'
                    value={getCsatResolve()}
                  />
                </CardSection>
              </>
            )}

            <ArticlesSection
              quickFeedbackResponses={quick_feedback_responses}
            />
            <CardSection>
              {isDeflectionInsightsEnabled && (
                <DeflectionInsightsData
                  selectedConversation={selectedConversation}
                />
              )}
            </CardSection>
          </Card>
        </Box>
        <Box display='flex' flexDirection='column' gap='6px'>
          {transcript_components ? (
            <RevampConversationTranscript
              transcriptComponents={transcript_components}
            />
          ) : (
            <ConversationTranscript transcript={transcript} variant='drawer' />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Card = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SummaryBox = styled('div')`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[100]};
  background: linear-gradient(
    270deg,
    rgba(189, 0, 255, 0.05) 0%,
    rgba(56, 102, 245, 0.05) 100%
  );
`;
