import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { TicketMetrics, TriageTicket } from 'src/services/triage/types';

const TicketStatusTableValue = ({
  status,
}: {
  status: TriageTicket['status'] | TicketMetrics['status'];
}) => {
  const { palette } = useTheme();

  const valueMap = {
    closed: {
      background: palette.colors.slate[100],
      color: palette.colors.grey[700],
      text: 'Closed',
    },
    in_progress: {
      background: palette.colors.slate[100],
      color: palette.colors.grey[700],
      text: 'In progess',
    },
    open: {
      background: palette.colors.red[100],
      color: palette.colors.red[500],
      text: 'Open',
    },
    resolved: {
      background: palette.colors.green[100],
      color: palette.colors.green[600],
      text: 'Resolved',
    },
    unknown: {
      background: undefined,
      color: palette.colors.black,
      text: NA,
    },
  };

  const item = valueMap[status];

  return (
    <Box
      bgcolor={item.background}
      borderRadius={1.5}
      display='flex'
      px={1}
      py={0.5}
    >
      <Typography color={item.color} variant='font11'>
        {item.text}
      </Typography>
    </Box>
  );
};

export default TicketStatusTableValue;
