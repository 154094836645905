import { useCallback, useMemo } from 'react';

import {
  useAddContextVariableMutation,
  useAddToolDefinitionMutation,
  useRemoveContextVariableMutation,
  useRemoveToolDefinitionMutation,
  useUpdateAutopilotPolicyDescriptionMutation,
} from './emailAutopilotApi';
import { AutopilotWorkflowModel, ToolType } from './types';
import debounce from 'lodash/fp/debounce';
import { NONE_RTE_INPUT_DEBOUNCE_DELAY } from 'src/pages/solve-config/constants';

export const useContextVariableToggle = (
  autopilotWorkflow: AutopilotWorkflowModel,
) => {
  const [addContextVariable] = useAddContextVariableMutation();
  const [removeContextVariable] = useRemoveContextVariableMutation();

  return useCallback(
    async (contextVariableId: string, isSelected: boolean) => {
      if (isSelected) {
        return removeContextVariable({
          autopilotWorkflow,
          contextVariableId,
        });
      }
      return addContextVariable({
        autopilotWorkflow,
        contextVariableId,
      });
    },
    [addContextVariable, autopilotWorkflow, removeContextVariable],
  );
};

export const useToolDefinitionToggle = (
  autopilotWorkflow: AutopilotWorkflowModel,
) => {
  const [addToolDefinition] = useAddToolDefinitionMutation();
  const [removeToolDefinition] = useRemoveToolDefinitionMutation();

  return useCallback(
    async (toolId: string, toolType: ToolType, isSelected: boolean) => {
      if (isSelected) {
        return removeToolDefinition({
          autopilotWorkflow,
          toolId,
        });
      }
      return addToolDefinition({
        autopilotWorkflow,
        request: {
          tool_id: toolId,
          tool_type: toolType,
        },
      });
    },
    [addToolDefinition, autopilotWorkflow, removeToolDefinition],
  );
};

export const useUpdatePolicyDescription = () => {
  const [updateAutopilotPolicyDescription] =
    useUpdateAutopilotPolicyDescriptionMutation();

  return useMemo(
    () =>
      debounce(
        NONE_RTE_INPUT_DEBOUNCE_DELAY,
        async (autopilotWorkflowId: string, policyDescription: string) => {
          return updateAutopilotPolicyDescription({
            autopilotWorkflowId,
            policyDescription,
          });
        },
      ),
    [updateAutopilotPolicyDescription],
  );
};
