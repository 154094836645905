import { useSelector } from 'react-redux';

import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import { useGetBrandsQuery } from 'src/services/brand/brandApi';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import { selectBrandId } from 'src/slices/solve-config/solveConfigSlice';
import { Brand } from 'src/types/brandTypes';

export const useGetSelectedBrand = (): Brand | undefined => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const selectedBrandId = useSelector(selectBrandId);
  const { data = [] } = useGetBrandsQuery(undefined, {
    skip: !isMultibrandEnabled,
  });

  return (
    data.find(brand => brand.brand_id === selectedBrandId) ||
    data.find(brand => brand.is_default)
  );
};

export const useIsNewBrandCreationForbidden = () => {
  const { data: paidPlanDetails } = useGetPaidPlanDetailsQuery();
  const tier = paidPlanDetails?.tier;
  const { data: brands = [] } = useGetBrandsQuery();
  const isMultibrandOverrideEnabled = useIsFeatureFlagEnabled(
    'multibrand_override',
  );

  if (isMultibrandOverrideEnabled) {
    return false;
  }

  return tier === 'base' || (tier === 'pro' && brands.length > 1);
};

export const useBrandSidebarButton = ({
  onContactUs,
  onCreateNew,
}: {
  onContactUs: () => void;
  onCreateNew: () => void;
}) => {
  const { isLoading: isLoadingPaidPlanDetails } = useGetPaidPlanDetailsQuery();
  const { isLoading: isLoadingBrands } = useGetBrandsQuery();
  const isNewBrandCreationForbidden = useIsNewBrandCreationForbidden();

  const isLoading = isLoadingPaidPlanDetails || isLoadingBrands;

  if (isNewBrandCreationForbidden) {
    return {
      copy: 'Contact us',
      isLoading,
      onClick: onContactUs,
      shouldShowUpgradePrompt: true,
      useIcon: false,
    };
  }

  return {
    copy: 'Create new',
    isLoading,
    onClick: onCreateNew,
    shouldShowUpgradePrompt: false,
    useIcon: true,
  };
};
