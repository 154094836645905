import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { createData } from './helpers';
import { TicketMetricData } from './types';
import { NA } from 'src/constants/solve';

export interface TicketMetricsProps {
  metrics: TicketMetricData;
}

const TicketMetrics = ({ metrics }: TicketMetricsProps) => {
  const { palette } = useTheme();

  const data = useMemo(() => createData(metrics), [metrics]);

  return (
    <Box display='flex' flexDirection='column' rowGap={0.5}>
      {data.map((item, index) => (
        <Box alignItems='center' columnGap={1} display='flex' key={index}>
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {item.label}:
          </Typography>
          {Boolean(item.Value) && item.Value}
          {item.value !== null && (
            <Typography variant='font12'>{item.value}</Typography>
          )}
          {item.value === null && (
            <Typography color={palette.colors.grey[600]} variant='font12'>
              {NA}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default TicketMetrics;
