import { LineGraphDataType } from 'src/services/insights/types';

export const generateChartData = ({
  data,
  enabledLegends,
}: {
  data: LineGraphDataType | undefined;
  enabledLegends: string[];
}) => {
  const values =
    data?.values.map(value => {
      return {
        ...value,
        visible: enabledLegends.includes(value.label),
      };
    }) || [];
  return {
    ...data,
    values,
    x_axis: data?.x_axis || [],
  };
};
