import { useParams } from 'react-router';
import { Box, styled, useTheme } from '@mui/material';
import { IconCheck, IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { CollapseButton } from './api-preview/StyledComponents';
import { useGetColorMode } from './hooks';
import { InfoConsole } from './infoConsole';
import { PANELS } from './types';
import { useGetThemeBasedOnMode } from './utils';
import { WidgetSetupControl } from './WidgetSetupControl';

export const WidgetInfoPanel = ({
  expandedPanel,
  setExpandedPanel,
}: {
  expandedPanel: PANELS;
  setExpandedPanel: (panel: PANELS) => void;
}) => {
  const { channel } = useParams<'channel'>();
  const theme = useTheme();
  const colorMode = useGetColorMode();
  const isCollapsed = expandedPanel === 'api-traffic';
  const themeBasedOnMode = useGetThemeBasedOnMode();
  const isDarkMode = themeBasedOnMode.palette.mode === 'dark';

  return (
    <CollapsibleSection collapsed={isCollapsed} isDarkMode={isDarkMode}>
      {channel === 'api' && (
        <CollapseButton
          onClick={() => {
            setExpandedPanel(
              expandedPanel === 'info-log' ? 'none' : 'info-log',
            );
          }}
        >
          {expandedPanel === 'info-log' ? (
            <IconChevronUp color={theme.palette.colors.white} size={20} />
          ) : (
            <IconChevronDown color={theme.palette.colors.white} size={20} />
          )}
        </CollapseButton>
      )}

      <WidgetSetupControl />
      <Box alignItems='center' display='flex' gap='4px'>
        <Separator colorMode={colorMode} />
        <IconCheck color={theme.palette.colors.grey[500]} size={12} />
        <Typography color={theme.palette.colors.grey[500]} variant='font12'>
          Model training is completed
        </Typography>
        <Separator colorMode={colorMode} />
      </Box>
      {!isCollapsed && <InfoConsole />}
    </CollapsibleSection>
  );
};

const CollapsibleSection = styled('div')<{
  collapsed: boolean;
  isDarkMode: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.collapsed ? 0.025 : 0.95)};
  gap: 24px;
  padding: 24px 40px;
  overflow-y: hidden;
  position: relative;
  transition: 0.3s;

  ${props =>
    props.isDarkMode &&
    `& ::selection {
      background-color: ${props.theme.palette.colors.white};
      color: ${props.theme.palette.colors.black};
    }`}
`;

const Separator = styled('div')<{ colorMode: string }>`
  height: 1px;
  flex: 1 0 0;
  background-color: ${props =>
    props.colorMode === 'dark'
      ? props.theme.palette.colors.grey[800]
      : props.theme.palette.colors.slate[200]};
`;
