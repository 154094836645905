import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import { InsightPeriod } from '../../solve-insights/types';
import { INITIAL_LEGENDS } from './constants';
import HeaderFilters from './HeaderFilters';
import {
  createLineChartData,
  createOverviewMetrics,
  createSankeyData,
} from './helpers';
import TableSection from './TableSection';
import DashboardChartSection from 'src/components/dashboard-chart-section';
import SankeyDiagramSection from 'src/components/sankey-diagram-section/SankeyDiagramSection';
import { timeFilterOptions } from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { LineGraphDataType } from 'src/services/insights/types';
import {
  useGetModelInsightsChartQuery,
  useGetModelInsightsMetricsQuery,
  useGetModelInsightsTicketCountQuery,
  useGetSelfServeTriageModelsQuery,
} from 'src/services/triage/triageApi';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const TriageInsightsModelsTab = () => {
  const [enabledLegends, setEnabledLegends] = useState(INITIAL_LEGENDS);
  const { data: models, isLoading: isModelsLoading } =
    useGetSelfServeTriageModelsQuery();
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: 'triage-insights-time-range',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const [periodicalFilter, setPeriodicalFilter] =
    useState<InsightPeriod>('daily');

  const [selectedModel, setSelectedModel] = useStateParams({
    deserialize: value => value,
    initialState: '',
    paramsName: 'triage-insights-model',
    serialize: value => value,
  });

  const selectedModelData = models?.find(
    item => item.model_id === selectedModel,
  );
  const {
    data: metricChartData,
    isError: isChartError,
    isFetching: isChartFetching,
    isLoading: isChartLoading,
  } = useGetModelInsightsChartQuery(
    {
      endDate: Number(dateRange.to),
      interval: periodicalFilter,
      modelName: selectedModelData?.name || '',
      startDate: Number(dateRange.from),
    },
    { skip: !selectedModelData },
  );

  const {
    data: ticketCountMetrics,
    isError: isTicketCountError,
    isFetching: ticketMetricDataIsFetching,
  } = useGetModelInsightsTicketCountQuery(
    {
      endDate: Number(dateRange.to),
      interval: periodicalFilter,
      modelName: selectedModelData?.name || '',
      startDate: Number(dateRange.from),
    },
    { skip: !enabledLegends.find(item => item === 'Total tickets') },
  );
  const {
    data,
    isFetching: metricDataIsFetching,
    isLoading: metricDataIsLoading,
  } = useGetModelInsightsMetricsQuery(
    {
      endDate: Number(dateRange.to),
      modelName: selectedModelData?.name || '',
      startDate: Number(dateRange.from),
    },
    { skip: !selectedModelData },
  );
  useEffect(() => {
    if (!selectedModel && models?.length) {
      setSelectedModel(models[0].model_id);
    }
  }, [isModelsLoading, models, selectedModel, setSelectedModel]);
  const overviewMetrics = useMemo(
    () =>
      createOverviewMetrics({
        data: data?.overview,
        isLoading: metricDataIsLoading,
      }),
    [data?.overview, metricDataIsLoading],
  );
  const sankeyData = useMemo(
    () => createSankeyData(data?.overview),
    [data?.overview],
  );
  const handleToggleVisibility = (legend: string) => {
    const isEnabled = enabledLegends.includes(legend);
    setEnabledLegends(
      isEnabled
        ? enabledLegends.filter(item => item !== legend)
        : [...enabledLegends, legend],
    );
  };

  const lineGraphData = useMemo(
    () =>
      createLineChartData({
        enabledLegends,
        metricChartData,
        ticketCountMetrics,
      }),
    [metricChartData, ticketCountMetrics, enabledLegends],
  );

  const errorMessage = useMemo(() => {
    if (isChartError || isTicketCountError) {
      return 'Failed to load chart data. Please try again with a different time range or interval.';
    }
    return undefined;
  }, [isChartError, isTicketCountError]);

  return (
    <Box display='flex' flexDirection='column' rowGap={3}>
      <HeaderFilters
        models={models || []}
        modelsLoading={isModelsLoading}
        onDateRangeChange={setDateRange}
        onModelChange={setSelectedModel}
        selectedDateRange={dateRange}
        selectedModel={selectedModel}
      />
      <SankeyDiagramSection
        data={sankeyData}
        loading={metricDataIsLoading || metricDataIsFetching || isModelsLoading}
        overview={overviewMetrics}
      />
      <DashboardChartSection
        data={lineGraphData as LineGraphDataType}
        dateRange={dateRange}
        enabledLegends={enabledLegends}
        errorMessage={errorMessage}
        isFetching={ticketMetricDataIsFetching || isChartFetching}
        isLoading={isChartLoading || isModelsLoading}
        onToggleVisibility={handleToggleVisibility}
        periodicalFilter={periodicalFilter}
        setPeriodicalFilter={setPeriodicalFilter}
        title='Time series chart'
        yAxis={[
          { title: { text: 'Ticket count' } },
          { max: 100, title: { text: 'Rate' } },
        ]}
      />
      <TableSection
        isFetching={metricDataIsFetching}
        isLoading={metricDataIsLoading || isModelsLoading}
        labels={data?.labels || []}
        models={models || []}
        modelsLoading={isModelsLoading}
        onDateRangeChange={setDateRange}
        onModelChange={setSelectedModel}
        selectedDateRange={dateRange}
        selectedModel={selectedModel}
      />
    </Box>
  );
};

export default TriageInsightsModelsTab;
