import { useState } from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  ListItemText,
  styled,
  useTheme,
} from '@mui/material';

import {
  Checkbox,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  flattenOptions,
  handleCheckboxClick,
  handleMenuItemClick,
} from './helper';
import { Option } from 'node_modules/@forethought-technologies/forethought-elements/dist/Components/MultiSelectFilter/types';

interface ListOptionProps {
  expandedValues: string[];
  isOuter: boolean;
  item: Option;
  onChange: (value: string[]) => void;
  setExpandedValues: (value: string[]) => void;
  value: string[];
}

const ListOption = ({
  expandedValues,
  isOuter,
  item,
  onChange,
  setExpandedValues,
  value,
}: ListOptionProps) => {
  const { palette } = useTheme();

  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);

  const hasOptions = Boolean(item.options);
  const itemOptionValues = flattenOptions(item.options ?? [])
    .filter(child => !child.options?.length)
    .map(child => child.value);
  const isChecked = hasOptions
    ? itemOptionValues.every(option => value.includes(option))
    : value.includes(item.value);
  const isIndeterminate =
    !isChecked && itemOptionValues.some(option => value.includes(option));
  const isExpanded = expandedValues.includes(item.value);

  return (
    <Box display='flex' flexDirection='column'>
      <MenuItem
        key={item.label}
        onClick={() =>
          handleMenuItemClick({
            expandedValues,
            isCheckboxClicked,
            item,
            onChange,
            setExpandedValues,
            setIsCheckboxClicked,
            value,
          })
        }
      >
        <Checkbox
          aria-label={item.label}
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={e =>
            handleCheckboxClick({
              e,
              expandedValues,
              item,
              onChange,
              setExpandedValues,
              setIsCheckboxClicked,
              value,
            })
          }
          padding='4px 8px'
        />
        <ListItemText
          primary={
            <Typography noWrap variant='font14'>
              {item.optionStartAdornment ? (
                <Box alignItems='center' display='flex'>
                  <Box
                    alignItems='center'
                    color={palette.colors.grey[800]}
                    display='flex'
                    mr='5px'
                  >
                    {item.optionStartAdornment}
                  </Box>
                  {item.label}
                </Box>
              ) : (
                item.label
              )}
            </Typography>
          }
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
        {hasOptions ? (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              handleMenuItemClick({
                expandedValues,
                isCheckboxClicked,
                item,
                onChange,
                setExpandedValues,
                setIsCheckboxClicked,
                value,
              });
            }}
            sx={{
              transform: `rotate(${isExpanded ? '180' : '0'}deg)`,
            }}
          >
            <KeyboardArrowDown />
          </IconButton>
        ) : (
          <OnlyButton
            aria-label='only button'
            onClick={e => {
              e.stopPropagation();
              onChange([item.value]);
            }}
          >
            <Typography color={palette.colors.purple[500]} variant='font12'>
              Only
            </Typography>
          </OnlyButton>
        )}
      </MenuItem>
      {hasOptions && (
        <Collapse in={isExpanded}>
          <Box marginLeft={2}>
            {item.options?.map(option => (
              <ListOption
                expandedValues={expandedValues}
                isOuter={false}
                item={option}
                key={option.value}
                onChange={onChange}
                setExpandedValues={setExpandedValues}
                value={value}
              />
            ))}
          </Box>
        </Collapse>
      )}
      <BorderLine className={isOuter ? 'outer-menu-item' : ''} />
    </Box>
  );
};

const OnlyButton = styled('button')`
  display: none;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.palette.colors.purple[200]};
  }
`;

const MenuItem = styled('div')`
  height: 28px;
  display: flex;
  align-items: center;
  margin: 4px 0;
  &:hover {
    background-color: ${props => props.theme.palette.colors.purple[100]};
    cursor: pointer;
    button {
      display: flex;
    }
  }
`;

const BorderLine = styled('div')`
  &.outer-menu-item {
    margin: 4px 0;
    border-top: 1px solid ${props => props.theme.palette.colors.grey[300]};
    &:first-child {
      border-top: none;
    }
  }
`;

export default ListOption;
