import { useState } from 'react';
import { Box } from '@mui/material';

import { UPGRADE_PLAN_DIALOG_DATA } from './constants';
import PlanConfirmationModal from './PlanConfirmationModal';
import SubscriptionPlanDetailsDrawer from './SubscriptionPlanDetailsDrawer';
import SubscriptionPlanPageBody from './SubscriptionPlanPageBody';
import SubscriptionPlanPageHeader from './SubscriptionPlanPageHeader';
import UpgradePlanModal from 'src/components/upgrade-plan-modal/UpgradePlanModal';
import { useUpgradePlanMutation } from 'src/services/subscription-plan/subscriptionPageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export interface DialogData {
  label: string;
  text: string;
}

const SubscriptionPlanPage = () => {
  const dispatch = useAppDispatch();
  const [upgradePlan, { isLoading }] = useUpgradePlanMutation();
  const [isPlanDetailsDrawerOpen, setIsPlanDetailsDrawerOpen] = useState(false);
  const [dialogData, setDialogData] = useState<DialogData | null>(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const isDialogVisible = Boolean(dialogData);
  const dialogLabel = dialogData?.label || '';
  const dialogText = dialogData?.text || '';

  return (
    <Box
      sx={{
        background: 'white',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        gap: '40px',
        padding: '26px 40px',
      }}
    >
      <SubscriptionPlanPageHeader
        setIsPlanDetailsDrawerOpen={() =>
          setIsPlanDetailsDrawerOpen(isOpen => !isOpen)
        }
        setIsUpgradePlanDialogOpen={() => {
          setDialogData(UPGRADE_PLAN_DIALOG_DATA);
        }}
      />
      <SubscriptionPlanPageBody />
      <SubscriptionPlanDetailsDrawer
        isOpen={isPlanDetailsDrawerOpen}
        onClose={() => setIsPlanDetailsDrawerOpen(false)}
      />
      <PlanConfirmationModal
        isDialogVisible={isDialogVisible}
        isLoading={isLoading}
        label={dialogLabel}
        onChange={text =>
          setDialogData(data => data && { ...data, text: text })
        }
        onClose={() => setDialogData(null)}
        onSend={() => {
          upgradePlan({ upgrade_request_details: dialogText })
            .unwrap()
            .then(() => {
              setDialogData(null);
              setIsSuccessModalVisible(true);
            })
            .catch(() => {
              setDialogData(null);
              dispatch(
                setGlobalToastOptions({
                  title: 'Something went wrong, please try again',
                  variant: 'danger',
                }),
              );
            });
        }}
        value={dialogText}
      />
      <UpgradePlanModal
        isOpen={isSuccessModalVisible}
        onClose={() => setIsSuccessModalVisible(false)}
      />
    </Box>
  );
};

export default SubscriptionPlanPage;
