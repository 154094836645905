import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import get from 'lodash/fp/get';
import keyBy from 'lodash/fp/keyBy';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import {
  FreshChatCustomFieldAndValue,
  FreshChatTicketCustomField,
} from 'src/types/workflowBuilderAPITypes';

interface TicketCustomFieldsProps {
  customFieldsAndValues?: FreshChatCustomFieldAndValue[];
  setCustomFieldsAndValues: (newVal: FreshChatCustomFieldAndValue[]) => void;
  ticketCustomFields?: FreshChatTicketCustomField[];
  title?: string;
}

const TicketCustomFields: FC<
  React.PropsWithChildren<TicketCustomFieldsProps>
> = ({
  customFieldsAndValues,
  setCustomFieldsAndValues,
  ticketCustomFields,
  title = 'Ticket Custom Field',
}) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  const customFieldArr: FreshChatTicketCustomField[] = useMemo(
    () => ticketCustomFields || [],
    [ticketCustomFields],
  );

  const idCustomFieldMap = useMemo(() => {
    return keyBy('name', customFieldArr);
  }, [customFieldArr]);

  const idValueArr: FreshChatCustomFieldAndValue[] = useMemo(
    () => customFieldsAndValues || [{ name: '', value: '' }],
    [customFieldsAndValues],
  );

  return (
    <>
      <Label>{title}</Label>
      {idValueArr.map(
        ({ name, value }: FreshChatCustomFieldAndValue, idx: number) => {
          return (
            <div key={`custom-field-row-${idx}`}>
              <Row>
                <SelectWrapper>
                  <SelectDropdown
                    aria-label='Select a Field...'
                    id={`custom-field-row-${idx}`}
                    isMenuSearchable
                    menuMaxHeight={180}
                    onChange={e => {
                      if (e?.target.value) {
                        const updatedFieldValues = idValueArr
                          .slice(0, idx)
                          .concat({ name: e.target.value, value: '' })
                          .concat(idValueArr.slice(idx + 1));

                        setCustomFieldsAndValues(updatedFieldValues);
                      }
                    }}
                    options={customFieldArr.map(
                      (customField: { name: string; title: string }) => ({
                        label: customField.title,
                        value: customField.name,
                      }),
                    )}
                    value={
                      customFieldArr.some(field => field.name === name)
                        ? name
                        : ''
                    }
                  />
                </SelectWrapper>
                <Spacer width='8px' />
                {/* Initially, if user hasn't selected a field type, we wouldn't know what field value
                  input type to render. So we default it to text field. */}
                {get([name, 'type'], idCustomFieldMap) !== 'tagger' && (
                  <InputWrapper>
                    <ContextVariableAutocomplete
                      aria-label='Field Value'
                      onChange={fieldValue => {
                        if (
                          get([name, 'type'], idCustomFieldMap) === 'integer'
                        ) {
                          const reNumbers = /^[0-9\b]*$/;
                          const reCVs = /^\{\{.*\}\}$/;
                          if (reNumbers.test(fieldValue)) {
                            const updatedFieldValues = idValueArr
                              .slice(0, idx)
                              .concat({
                                name: idValueArr[idx].name,
                                value: Number(fieldValue),
                              })
                              .concat(idValueArr.slice(idx + 1));
                            setCustomFieldsAndValues(updatedFieldValues);
                          } else if (reCVs.test(fieldValue)) {
                            const updatedFieldValues = idValueArr
                              .slice(0, idx)
                              .concat({
                                name: idValueArr[idx].name,
                                value: fieldValue,
                              })
                              .concat(idValueArr.slice(idx + 1));
                            setCustomFieldsAndValues(updatedFieldValues);
                          }
                        } else {
                          const updatedFieldValues = idValueArr
                            .slice(0, idx)
                            .concat({
                              name: idValueArr[idx].name,
                              value: fieldValue,
                            })
                            .concat(idValueArr.slice(idx + 1));
                          setCustomFieldsAndValues(updatedFieldValues);
                        }
                      }}
                      placeholder='Field Value'
                      shouldIncludeSystemContextVariables
                      undefinedContextVariables={undefinedContextVariableIds}
                      value={value.toString()}
                    />
                  </InputWrapper>
                )}
                {get([name, 'type'], idCustomFieldMap) === 'tagger' && (
                  <Box
                    sx={{
                      flex: '1 1 50%',
                    }}
                  >
                    <ContextVariableSelectDropdown
                      additionalOptions={get(
                        [name, 'custom_field_options'],
                        idCustomFieldMap,
                      ).map(({ id, value }: { id: string; value: string }) => ({
                        category: 'Field Options',
                        label: id,
                        value,
                      }))}
                      aria-label='Field Value'
                      id={idValueArr[idx].name}
                      onChange={value => {
                        const updatedFieldValues = idValueArr
                          .slice(0, idx)
                          .concat({
                            name: idValueArr[idx].name,
                            value,
                          })
                          .concat(idValueArr.slice(idx + 1));

                        setCustomFieldsAndValues(updatedFieldValues);
                      }}
                      shouldIncludeSystemContextVariables
                      shouldProvideCVIdFormatting
                      value={value.toString()}
                    />
                  </Box>
                )}
                <ButtonWrapper>
                  <CloseButton
                    onClick={() => {
                      setCustomFieldsAndValues(
                        idValueArr
                          .slice(0, idx)
                          .concat(idValueArr.slice(idx + 1, idValueArr.length)),
                      );
                    }}
                    role='button'
                    src={xIcon}
                  />
                </ButtonWrapper>
              </Row>
              <Spacer height='8px' />
            </div>
          );
        },
      )}
      <LinkOut
        isDisabled={idValueArr.length === customFieldArr.length}
        onClick={() => {
          if (idValueArr.length !== customFieldArr.length) {
            setCustomFieldsAndValues(
              idValueArr.concat({
                name: '',
                value: '',
              }),
            );
          }
        }}
      >
        + Add field
      </LinkOut>
    </>
  );
};

export default TicketCustomFields;

const SelectWrapper = styled('div')`
  flex: 1 1 50%;
`;

const InputWrapper = styled('div')`
  flex: 1 1 50%;
`;

const CloseButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  width: 30px;
`;

const LinkOut = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
