import {
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material';
import { IconChartBar, IconChartLine } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';

export type ChartType = 'spline' | 'column';

export interface ChartTypeButtonProps {
  onChange: (value: ChartType) => void;
  value: ChartType;
}

const ChartTypeButton = ({ onChange, value }: ChartTypeButtonProps) => {
  return (
    <StyledToggleButtonGroup
      aria-label='chart type'
      exclusive
      onChange={(_, newChartType: ChartType) => onChange(newChartType)}
      size='small'
      value={value}
    >
      <Tooltip tooltipContent='Line'>
        <ToggleButton aria-label='line' size='small' value='spline'>
          <IconChartLine size={16} width={26} />
        </ToggleButton>
      </Tooltip>
      <Tooltip tooltipContent='Bar'>
        <ToggleButton aria-label='bar' size='small' value='column'>
          <IconChartBar size={16} width={26} />
        </ToggleButton>
      </Tooltip>
    </StyledToggleButtonGroup>
  );
};

export default ChartTypeButton;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.colors.slate[100],
  borderRadius: theme.shape.borderRadius,
  columnGap: theme.spacing(0.3),
  display: 'flex',
  height: 32,
  overflow: 'hidden',
  padding: theme.spacing(0.3),
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    height: 28,
    padding: 0,
    width: 40,
    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: 'white',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    },
  },
}));
