import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Paper, { PaperProps } from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { useGetWorkflowTags, useIsFeatureFlagEnabled } from 'src/hooks/hooks';

interface TagInputProps {
  /** Boolean that determines if the input should be disabled */
  isDisabled?: boolean;
  isSaving: boolean;
  onChange: (_: unknown, value: string[]) => void;
  /** Tags to display in the editor */
  tags: Array<string>;
}

const CustomPaper = (props: PaperProps) => (
  <Paper
    sx={theme => ({
      border: `1px solid ${theme.palette.colors.grey[300]}`,
      maxHeight: '400px',
    })}
    {...props}
  />
);

const TagInput: React.FC<React.PropsWithChildren<TagInputProps>> = ({
  isDisabled = false,
  isSaving = false,
  onChange,
  tags,
}: TagInputProps) => {
  const workflowTags = useGetWorkflowTags();
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');

  const label = isSaving ? 'Tags Saving...' : 'Tags';

  if (isMultibrandEnabled) {
    return (
      <SelectDropdown
        id='brand-tags'
        isMenuSearchable
        multiple
        onChange={e => {
          if (!Array.isArray(e.target.value)) {
            return;
          }

          onChange(e, e.target.value);
        }}
        onClear={() => onChange(null, [])}
        options={workflowTags.map(tag => ({
          label: tag,
          value: tag,
        }))}
        placeholder='Assign tags'
        value={tags}
      />
    );
  }

  return (
    <Autocomplete
      aria-busy={isSaving}
      disabled={isDisabled || isSaving}
      freeSolo
      multiple
      onChange={onChange}
      options={workflowTags}
      PaperComponent={CustomPaper}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          InputLabelProps={{
            sx: theme => ({
              color: theme.palette.colors.black,
              mb: 1,
              minHeight: 20,
              position: 'initial',
              transform: 'none',
              whiteSpace: 'normal',
              ...theme.typography.font14Medium,
            }),
          }}
          label={label}
          placeholder='Press enter to add a new tag'
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography variant='font14'>{option}</Typography>
          </li>
        );
      }}
      value={tags}
    />
  );
};

export default TagInput;
