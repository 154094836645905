import { Typography } from '@forethought-technologies/forethought-elements';
import { ChartType } from '../chart-type-button/ChartTypeButton';
import { TOPIC_COLOR_ARRAY } from '../dashboard-pages/solve-insights/topics/constants';
import { DEFAULT_CHART_STYLE } from './constants';
import LineGraph from './LineGraph';
import merge from 'lodash/fp/merge';
import { LineGraphDataType } from 'src/services/insights/types';

export interface DashboardChartProps {
  chartStyle?: { [key: string]: string };
  chartType: ChartType;
  colors?: string[];
  data: LineGraphDataType | undefined;
  multipleYAxis?: boolean;
  onToggleVisibility?: (label: string) => void;
  yAxis?: Highcharts.YAxisOptions[];
}

const DashboardChart = ({
  chartStyle,
  chartType,
  colors = TOPIC_COLOR_ARRAY,
  data,
  multipleYAxis = true,
  onToggleVisibility,
  yAxis,
}: DashboardChartProps) => {
  if (!data) {
    return <Typography variant='font16'>No data available</Typography>;
  }
  return (
    <LineGraph
      chartType={chartType}
      colors={colors}
      containerProps={{ style: merge(chartStyle, DEFAULT_CHART_STYLE) }}
      data={data}
      handleToggleVisibility={onToggleVisibility}
      multipleYAxis={multipleYAxis}
      yAxis={yAxis}
    />
  );
};

export default DashboardChart;
