import { ComponentProps } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';

interface ValueProps {
  color?: string;
  fontVariant: ComponentProps<typeof Typography>['variant'];
  handleClick: (event: React.MouseEvent) => void;
  sx: BoxProps['sx'];
  value: string;
}

const Value = ({ color, fontVariant, handleClick, sx, value }: ValueProps) => (
  <Box onClick={handleClick} sx={sx}>
    <Typography color={color} variant={fontVariant}>
      {value}
    </Typography>
  </Box>
);

export default Value;
