import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { Tabs } from '@forethought-technologies/forethought-elements';
import { TrackingEventType } from '../app/types';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';

export interface DashboardTrackingEvent {
  extra?: Record<string, string | boolean | null>;
  name: TrackingEventType;
}

export interface DashboardTab {
  Component: React.ReactNode;
  label: string;
  trackingEvent?: DashboardTrackingEvent;
}

export interface DashboardTabsProps {
  containerSx?: BoxProps['sx'];
  name: string;
  onTabChange?: (index: number) => void;
  tabContentSx?: BoxProps['sx'];
  TabHeaderControl?: React.ReactNode;
  tabHeaderSx?: BoxProps['sx'];
  tabParamsName?: string;
  tabs: DashboardTab[];
}

const DashboardTabs = ({
  containerSx,
  name,
  onTabChange,
  tabContentSx,
  TabHeaderControl,
  tabHeaderSx,
  tabParamsName,
  tabs,
}: DashboardTabsProps) => {
  const { palette } = useTheme();

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [tabIndex, setTabIndex] = useStateParams({
    deserialize: tab => Number(tab),
    initialState: 0,
    paramsName: tabParamsName || name,
    serialize: tab => String(tab),
    validator: (parameter: string) => Boolean(parameter),
  });

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    onTabChange?.(index);

    const trackingEvent = tabs[index].trackingEvent;

    if (trackingEvent) {
      emitTrackingEventCallback(trackingEvent.name, trackingEvent.extra);
    }
  };

  const tabNames = tabs.map(tab => tab.label);

  return (
    <Box display='flex' flexDirection='column' sx={containerSx}>
      <Box
        display='flex'
        justifyContent='space-between'
        px='24px'
        sx={{
          borderBottom: `1px solid ${palette.colors.slate[200]}`,
          ...tabHeaderSx,
        }}
      >
        <Tabs
          a11yProps={index => ({
            'aria-controls': `${name}-tabpanel-${index}`,
            id: `${name}-tab-${index}`,
          })}
          aria-label={`${name} tabs`}
          onChange={(_, value) => {
            handleTabChange(value);
          }}
          tabs={tabNames}
          typographyVariant='font16Bold'
          value={tabIndex}
        />
        {TabHeaderControl}
      </Box>
      <Box
        p='24px'
        sx={{ backgroundColor: palette.colors.white, ...tabContentSx }}
      >
        {tabs[tabIndex].Component}
      </Box>
    </Box>
  );
};

export default DashboardTabs;
