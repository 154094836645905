import { Box, useTheme } from '@mui/material';
import { IconCircleCheckFilled } from '@tabler/icons-react';

interface ActivenessIndicatorProps {
  isActive: boolean;
}

export const ActivenessIndicator = ({ isActive }: ActivenessIndicatorProps) => {
  const theme = useTheme();

  return isActive ? (
    <Box height='20px' sx={{ color: theme.palette.colors.green[500] }}>
      <IconCircleCheckFilled size={20} />
    </Box>
  ) : (
    <Box
      height='16.67px'
      sx={{
        border: '1px solid ' + theme.palette.colors.grey[200],
        borderRadius: '50%',
        color: theme.palette.colors.green[500],
        mr: '1px', // to fix alignment with filled check
      }}
      width='16.67px'
    />
  );
};
