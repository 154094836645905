import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetSelectedBrand } from '../hooks';
import isEmpty from 'lodash/isEmpty';
import plusIcon from 'src/assets/images/btn-plus.svg';
import trashIcon from 'src/assets/images/trash.svg';
import {
  DUPLICATE_DOMAIN_MESSAGE,
  GET_DOMAIN_REGEX,
  INVALID_URL_MESSAGE,
  VALID_DOMAIN_REGEX,
} from 'src/constants/solve';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import { ActiveStateIcon } from 'src/pages/workflow-builder/intent-form/IntentForm';
import {
  useGetWhitelistedDomainsQuery,
  useUpdateWhitelistedDomainsMutation,
} from 'src/services/brand/brandApi';
import {
  selectWidgetConfiguration,
  setIsGenericFieldsLoading,
  updateWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  SolveConfigTrackingEventTypes,
  SolveConfigurationTypes,
} from 'src/utils/enums';

const DomainFormSection = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const selectedBrand = useGetSelectedBrand();
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const { data: whitelistedDomainsData } = useGetWhitelistedDomainsQuery(
    undefined,
    { skip: !isMultibrandEnabled },
  );
  const [updateWhitelistedDomainsMutation] =
    useUpdateWhitelistedDomainsMutation({
      fixedCacheKey: 'updateWhitelistedDomains',
    });
  const currentWhiteListedDomains = isMultibrandEnabled
    ? whitelistedDomainsData?.whitelist ?? []
    : widgetConfiguration.whitelisted_domains;

  // States
  const [currentDomain, setCurrentDomain] = useState('');

  const trimmedCurrentDomain = currentDomain.trim();
  const matches = trimmedCurrentDomain.match(GET_DOMAIN_REGEX);
  const domain = matches?.groups?.domain;

  const isDuplicate =
    currentWhiteListedDomains.includes(trimmedCurrentDomain) ||
    (domain && currentWhiteListedDomains.includes(domain));

  const errorMessage =
    trimmedCurrentDomain !== '' &&
    ((!VALID_DOMAIN_REGEX.test(trimmedCurrentDomain) && INVALID_URL_MESSAGE) ||
      (isDuplicate && DUPLICATE_DOMAIN_MESSAGE));

  const isDomainInvalid =
    isEmpty(trimmedCurrentDomain) || Boolean(errorMessage);

  const onUpdateWhitelistedDomains = useCallback(
    (updatedDomains: string[]) => {
      if (isMultibrandEnabled) {
        dispatch(setIsGenericFieldsLoading(true));
        updateWhitelistedDomainsMutation(updatedDomains);
        return;
      }
      dispatch(
        updateWidgetConfiguration({
          brandId: selectedBrand?.brand_id,
          isMultibrandEnabled,
          widgetConfiguration: {
            ...widgetConfiguration,
            whitelisted_domains: updatedDomains,
          },
        }),
      );
    },
    [
      dispatch,
      isMultibrandEnabled,
      selectedBrand?.brand_id,
      updateWhitelistedDomainsMutation,
      widgetConfiguration,
    ],
  );

  const handleOnClick = () => {
    if (domain) {
      // Regex group 1 (domain) match is always in index 1
      const updatedDomains = [...currentWhiteListedDomains, domain];
      setCurrentDomain('');
      onUpdateWhitelistedDomains(updatedDomains);
    }
  };

  const handleRemoveOnClick = (index: number) => {
    const updatedDomains = [...currentWhiteListedDomains];
    updatedDomains.splice(index, 1);
    onUpdateWhitelistedDomains(updatedDomains);
    dispatchTrackingAction(SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE, {
      configuration_type: SolveConfigurationTypes.WIDGET,
      type: 'whitelisted_domains',
      value: updatedDomains,
    });
  };

  return (
    <Box>
      <Typography variant='font16Bold'>1. Domain allowlist</Typography>
      <Box pb='10px' />
      <Typography variant='font14Medium'>
        The Solve widget can only be installed on the domains listed here.
      </Typography>
      <Box pb='20px' />
      <Box alignItems='center' display='flex' flexDirection='row' gap={1}>
        <TextField
          aria-label='domain'
          onChange={({ target }) => setCurrentDomain(target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && !isDomainInvalid) {
              handleOnClick();
            }
          }}
          placeholder='e.g. https://forethought.ai'
          value={currentDomain}
        />
        <Button
          aria-label='Add domain'
          disabled={isDomainInvalid}
          onClick={handleOnClick}
          startIcon={
            <ActiveStateIcon isDisabled={isDomainInvalid} src={plusIcon} />
          }
          variant='ghost'
        />
        <Box pb='4px' />
      </Box>
      {Boolean(errorMessage) ? (
        <Typography color={palette.colors.red[500]} variant='font12'>
          {errorMessage}
        </Typography>
      ) : (
        <Typography color={palette.colors.grey[400]} variant='font12'>
          Enter your domain address to whitelist your website
        </Typography>
      )}
      {/* Current domains */}
      <Box display='flex' flexDirection='column' gap='4px' pt={1}>
        {currentWhiteListedDomains.map((domain, index) => (
          <Box alignItems='center' display='flex' gap={1} key={index}>
            <TextField
              aria-label={'Domain ' + (index + 1).toString()}
              onChange={undefined}
              readOnly
              value={domain}
            />
            <Button
              aria-label={`Delete domain ${domain}`}
              data-appcues-target='domain_remove_button'
              onClick={() => handleRemoveOnClick(index)}
              startIcon={<ActiveStateIcon src={trashIcon} />}
              variant='ghost'
            />
            <Box />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DomainFormSection;
