import { styled, useTheme } from '@mui/material';
import { IconTag } from '@tabler/icons-react';

import {
  NonIdealState,
  Typography,
} from '@forethought-technologies/forethought-elements';

export default function TagDrawerEmptyState() {
  const { palette } = useTheme();

  return (
    <Container>
      <NonIdealState
        icon={
          <IconContainer>
            <IconTag color={palette.colors.purple[500]} size={40} stroke={1} />
          </IconContainer>
        }
        title='No tag available'
      >
        <Typography color={palette.colors.grey[600]} variant='font16Bold'>
          Create tags under brands to get started
        </Typography>
      </NonIdealState>
    </Container>
  );
}

const Container = styled('div')`
  background-color: ${({ theme }) => theme.palette.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

const IconContainer = styled('div')`
  height: 40px;
  width: 40px;
`;
