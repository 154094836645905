import { ListOption } from '@forethought-technologies/forethought-elements';
import { OriginItem } from '../triage-model-detail-page/types';
import { QuickstartFieldType } from './types';
import { Helpdesk } from 'src/components/app/types';
import {
  BaseTicketField,
  ModelPaidPlan,
  SupportedHelpdesk,
  TriageField,
  TriageFreshdeskTicketFields,
  TriageHelpdeskTicketFields,
  TriageJiraTicketFields,
  TriageKustomerTicketFields,
  TriagePermissions,
  TriageSalesforceTicketFields,
  TriageTypedErrors,
  TriageZendeskTicketFields,
} from 'src/reducers/triageSettingsReducer/types';

export const TOAST_TIMEOUT = 3000;

export const premiumDisabledTooltipText =
  'Contact your CSM to change the status of premium models';

export const getDisabledModelMessage = (
  isHelpdeskNotConfigured: boolean,
  isHelpdeskSupported: boolean,
) => {
  if (isHelpdeskNotConfigured || !isHelpdeskSupported) {
    return `A helpdesk integration must be ${
      !isHelpdeskSupported ? 'selected and ' : ''
    }configured to activate the model`;
  }
  return 'A helpdesk integration must be set up for this model to activate it';
};

export const getIsModelPremium = (modelPaidPlan: ModelPaidPlan | undefined) =>
  modelPaidPlan === 'premium';

export const getTriageConfigDetailUiErrorMessage = (
  error: TriageTypedErrors,
) => {
  if (
    error === 'DUPLICATE_CUSTOM_FIELD_PARAMS' ||
    error === 'INVALID_FIELD_SELECTED'
  ) {
    return 'The model cannot save. A Forethought team member will reach out to help soon.';
  }

  return 'The model cannot save. Please try again later.';
};

export const getFieldTypesByHelpdeskAndStage = (
  helpdesk: Helpdesk | '',
): QuickstartFieldType[] => {
  switch (helpdesk) {
    case 'zendesk':
      return ['Output field'];
    case 'salesforce':
      return ['Object', 'Origins', 'Output field'];
    default:
      throw new Error(`Helpdesk ${helpdesk} not supported yet`);
  }
};

const HELPDESK_FIELD_MAPPINGS: Record<
  SupportedHelpdesk,
  {
    getLabel: (field: BaseTicketField) => string;
    getValue: (field: BaseTicketField) => string;
  }
> = {
  freshdesk: {
    getLabel: field => field.title || field.helpdesk_field_id || '',
    getValue: field => field.helpdesk_field_id || '',
  },
  jira: {
    getLabel: field => field.title || field.helpdesk_field_id || '',
    getValue: field => field.helpdesk_field_id || '',
  },
  kustomer: {
    getLabel: field => field.title || field.helpdesk_field_id || '',
    getValue: field => field.helpdesk_field_id || '',
  },
  salesforce: {
    getLabel: field => field.label || '',
    getValue: field => field.name || '',
  },
  zendesk: {
    getLabel: field => field.name || '',
    getValue: field => (field.id ? field.id.toString() : ''),
  },
};

const formatOutputFieldsAsListItem = (
  item: BaseTicketField,
  helpdesk: SupportedHelpdesk,
): ListOption => {
  const { getLabel, getValue } = HELPDESK_FIELD_MAPPINGS[helpdesk];
  return {
    label: getLabel(item),
    value: getValue(item),
  };
};

const HELPDESK_FORMATTING_MAP: Record<
  SupportedHelpdesk,
  (ticketFields: TriageHelpdeskTicketFields) => {
    loading: boolean;
    origins: OriginItem[];
    outputFields: ListOption[];
    permissions?: TriagePermissions;
    preselectedOrigins?: string[];
  }
> = {
  freshdesk: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageFreshdeskTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(field =>
        formatOutputFieldsAsListItem(field, 'freshdesk'),
      ),
    };
  },
  jira: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageJiraTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(field =>
        formatOutputFieldsAsListItem(field, 'jira'),
      ),
    };
  },
  kustomer: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageKustomerTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(field =>
        formatOutputFieldsAsListItem(field, 'kustomer'),
      ),
    };
  },
  salesforce: (ticketFields: TriageHelpdeskTicketFields) => {
    const {
      case_origins: origins,
      output_fields: outputFields,
      permissions,
      preselected_origins: preselectedOrigins,
    } = ticketFields as TriageSalesforceTicketFields;
    return {
      loading: false,
      origins,
      outputFields: outputFields.map(field =>
        formatOutputFieldsAsListItem(field, 'salesforce'),
      ),
      permissions,
      preselectedOrigins,
    };
  },
  zendesk: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageZendeskTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(field =>
        formatOutputFieldsAsListItem(field, 'zendesk'),
      ),
    };
  },
};

export const formatTicketFields = (
  ticketFields: TriageHelpdeskTicketFields | undefined,
  helpdesk?: SupportedHelpdesk,
) => {
  if (!ticketFields || !helpdesk || !HELPDESK_FORMATTING_MAP[helpdesk]) {
    return {
      loading: false,
      origins: [],
      outputFields: [],
    };
  }

  return HELPDESK_FORMATTING_MAP[helpdesk](ticketFields);
};

export const prepareTriageFieldsByHelpdesk = ({
  helpdesk,
  isChoosingExistingFieldFlow,
  outputFieldTextInputValue,
  selectedPredictedField,
}: {
  helpdesk: Helpdesk;
  isChoosingExistingFieldFlow: boolean;
  outputFieldTextInputValue: string;
  selectedPredictedField: ListOption | undefined;
}): TriageField => {
  if (!isChoosingExistingFieldFlow) {
    return { id: outputFieldTextInputValue, name: outputFieldTextInputValue };
  }

  switch (helpdesk) {
    case 'zendesk':
      return {
        id: selectedPredictedField?.value,
        name: selectedPredictedField?.label ?? '',
      };
    case 'salesforce':
      return {
        id: selectedPredictedField?.value,
        name: selectedPredictedField?.value ?? '',
      };
    case 'kustomer':
      return {
        id: selectedPredictedField?.value,
        title: selectedPredictedField?.value ?? '',
      };
    case 'jira':
      return {
        id: selectedPredictedField?.value,
        title: selectedPredictedField?.value ?? '',
      };
    default:
      throw new Error(`Helpdesk ${helpdesk} not supported yet`);
  }
};
