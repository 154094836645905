import React, { ReactNode } from 'react';
import { styled } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface ContentHeaderProps {
  buttonDataAttribute?: { [key: string]: string };
  componentsInTheMiddle?: ReactNode;
  isButtonDisabled?: boolean;
  isButtonLoading?: boolean;
  isTypeSubmit?: boolean;
  isWithButton?: boolean;
  onClickHandler?: () => void;
  title: string;
}

const ContentHeader: React.FC<React.PropsWithChildren<ContentHeaderProps>> = ({
  buttonDataAttribute,
  componentsInTheMiddle,
  isButtonDisabled,
  isButtonLoading,
  isTypeSubmit,
  isWithButton = true,
  onClickHandler,
  title,
}) => {
  return (
    <ContentHeaderContainer>
      <Typography variant='font24'>{title}</Typography>
      {componentsInTheMiddle}
      {isWithButton && (
        <Button
          {...buttonDataAttribute}
          disabled={isButtonDisabled}
          isLoading={isButtonLoading}
          onClick={onClickHandler}
          type={isTypeSubmit ? 'submit' : 'button'}
          variant='main'
        >
          Save
        </Button>
      )}
    </ContentHeaderContainer>
  );
};

export default ContentHeader;

export const ContentHeaderContainer = styled('div')`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;
