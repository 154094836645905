import { CreateModelFieldItem, CreateModelFormValue } from './types';
import { last90DaysTimeRange } from 'src/utils/timeRangeHelpers';

export const INITIAL_DATE = new Date();

export const createModelFormValue: CreateModelFormValue = {
  dateRange: last90DaysTimeRange,
  description: '',
  displayName: '',
  filters: [],
  isManual: true,
  selectedTicket: '',
};

export const createModelFields: CreateModelFieldItem[] = [
  {
    description:
      "Assign a unique name to your model for identification purposes. This name will not impact the model's prediction or performance.",
    key: 'displayName',
    label: 'Model name',
    maxLength: 100,
  },
  {
    description:
      "Provide a detailed description of your model's purpose and scope. This description will not impact the model's predictions or performance.",
    info: 'Keep the description under 200 characters',
    key: 'description',
    label: 'Model description',
    maxLength: 200,
    placeholder: 'Describe this model’s intention ',
  },
];

export const GENERATING_STATUS_COLOR =
  'linear-gradient(270deg, rgba(189, 0, 255, 0.15) 0%, rgba(56, 102, 245, 0.15) 100%)';

export const TRIAGE_LLM_SUPPORTED_HELPDESKS = [
  'salesforce',
  'zendesk',
  'kustomer',
  'jira',
  'freshdesk',
];
