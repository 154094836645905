import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { v4 as uuid } from 'uuid';
import { StyledComponent } from '@emotion/styled';
import { css, styled } from '@mui/material';
import { Box } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

// Components
import {
  Badge,
  Button,
  Colors,
  Drawer,
  SelectDropdown,
  TextField,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
// Assets
import plusIcon from '../../../assets/images/btn-plus.svg';
import circularWarningIcon from '../../../assets/images/circular-warning-icon.svg';
import trashIcon from '../../../assets/images/trash.svg';
import UnsavedChangesModal from '../../../components/unsaved-changes-modal';
import { updateIntent } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import DiscoverTopicsRenderer from '../intent-workflows-table/discover-topics-renderer/DiscoverTopicsRenderer';
import { getWorkflowIdByChannel } from '../intent-workflows-table/helper';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import words from 'lodash/words';
// Redux
import {
  createIntentWorkflow,
  setIsOverlayVisible,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import { useGetWorkflowTags, useIsFeatureFlagEnabled } from 'src/hooks/hooks';
// Hooks
import { selectIsIntentEnabledForEmail } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectDiscoverTopicsByIntentId } from 'src/slices/data/dataSlice';
import { useAppDispatch } from 'src/store/hooks';
// Styles
import { textBoldStyle, textRegularStyle } from 'src/styles/styledMixin';
// Types
import {
  ConversationChannel,
  Intent,
  Phrase,
} from 'src/types/workflowBuilderAPITypes';
import { minimumPhrasesRequired } from 'src/utils/constants';
// Enums

interface IntentFormProps {
  initialPhrases?: Phrase[];
  intent?: Intent;
  isEditing?: boolean;
  isOpen: boolean;
  isWorkflowActive?: boolean;
  onClose: () => void;
  onPostSave?: (newWorkflowId?: string | null, newIntentId?: string) => void;
  onUpdateWithLessThanRequiredPhrases?: () => void;
}

const validateTitle = (title: string) => {
  if (title.trim().length === 0) {
    return "Intent name can't be empty";
  } else if (title.length > 80) {
    return "Intent name can't be more than 80 characters";
  }
  return undefined;
};

const sortComparator = (p1: Phrase, p2: Phrase) => {
  if (p1.is_modifiable !== p2.is_modifiable) {
    return p1.is_modifiable ? -1 : 1;
  }
  return p1.phrase_text.localeCompare(p2.phrase_text);
};

export const IntentForm: FC<React.PropsWithChildren<IntentFormProps>> = ({
  initialPhrases = [],
  intent,
  isEditing,
  isOpen,
  isWorkflowActive,
  onClose,
  onPostSave,
  onUpdateWithLessThanRequiredPhrases,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // refs
  const inputBoxRef = useRef<HTMLInputElement>(null);
  // states
  const [title, setTitle] = useState<string>('');
  const [shouldValidate, setShouldValidate] = useState(false);
  const [phrases, setPhrases] = useState<Phrase[]>(initialPhrases);
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const [description, setDescription] = useState<string>('');
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const params = new URLSearchParams(location.search);
  const channel = params.get('channel') as ConversationChannel;

  const discoverTopics = useSelector(selectDiscoverTopicsByIntentId);
  const isIntentEnabledForEmail = useSelector(selectIsIntentEnabledForEmail);
  const allWorkflowTags = useGetWorkflowTags();
  const [currentTags, setCurrentTags] = useState([]);
  const [isTagSelectOpen, setIsTagSelectOpen] = useState(false);

  const headerErrorMsg = validateTitle(title);
  const isCustom = get(intent, 'is_custom', true);
  const existingPhraseTexts = (intent?.phrases || [])
    .map(phrase => phrase.phrase_text)
    .sort();
  const formPhraseTexts = phrases.map(phrase => phrase.phrase_text).sort();
  const hasUnsavedChanges =
    title !== (intent?.title || '') ||
    !isEqual(existingPhraseTexts, formPhraseTexts);
  const phraseErrors = phrases.map(phrase =>
    phrase.phrase_text.trim() === '' ? 'Field cannot be empty' : '',
  );

  const trackingEventMetadata = {
    intentId: intent?.intent_id || '',
    source: 'canvas',
  };

  const minNumberOfPhrasesAllowed = 1;

  const isSubmiButtonDisabled =
    phrases.length < minNumberOfPhrasesAllowed ||
    !!headerErrorMsg ||
    phraseErrors.some(Boolean);

  const resetForm = () => {
    setTitle('');
    setShouldValidate(false);
    setPhrases([]);
    setCurrentPhrase('');
    dispatch(setIsOverlayVisible(false));
    setDescription('');
  };

  const closeAndResetForm = () => {
    setTimeout(resetForm, 100);
    onClose();
  };

  useEffect(() => {
    if (get(intent, 'intent_id')) {
      const decorated_phrases = (intent?.phrases || [])
        .map(phrase => ({
          ...phrase,
          is_modifiable:
            phrase.is_modifiable == null ? true : phrase.is_modifiable,
        }))
        .sort(sortComparator);
      setTitle(intent?.title || '');
      setPhrases([...initialPhrases, ...decorated_phrases]);
      setDescription(intent?.description || '');
    }
    // TODO: pass in phrases as a prop instead of setting inside useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intent]);

  /**
   * This is a hack to set the initial values of the form
   * when the form is opened for the first time.
   *
   * Only for the 'new intent' form; for 'edit' form, the values are set above.
   * We should replace both useEffects by rendering the drawer and the drawer body separately.
   */
  const [hasSetInitialValues, setHasSetInitialValues] = useState(false);
  useEffect(() => {
    const shouldSetInitialValues =
      !hasSetInitialValues && initialPhrases.length > 0 && !isEditing;
    if (!shouldSetInitialValues) {
      return;
    }

    setPhrases(initialPhrases);
    setTitle(initialPhrases[0].phrase_text);
    setHasSetInitialValues(true);
  }, [hasSetInitialValues, initialPhrases, isEditing, phrases, title]);

  const addPhrase = () => {
    setPhrases([
      {
        is_modifiable: true,
        phrase_id: uuid().toString(),
        phrase_text: currentPhrase,
      },
      ...phrases,
    ]);
    setCurrentPhrase('');

    // set focus back to inputBox
    inputBoxRef.current?.focus();
  };

  // update value of an existing phrase
  const handleExistingPhraseChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newPhrases = [...phrases];
    newPhrases[index] = {
      ...phrases[index],
      phrase_text: e.target.value,
    };
    setPhrases(newPhrases);
  };

  const handleCreateIntentWorkflow = async () => {
    setIsPendingRequest(true);
    const data = await dispatch(
      createIntentWorkflow({
        description: description || undefined,
        name: title,
        phrases,
        suggested_intent_id: intent?.intent_id,
        workflow_tags: currentTags,
      }),
    ).unwrap();
    setIsPendingRequest(false);
    closeAndResetForm();
    if (data && onPostSave) {
      const workflowId = getWorkflowIdByChannel(channel, data.canvas);
      onPostSave(workflowId, data.canvas.intent_id);
    }
  };

  const handleUpdateIntent = async () => {
    if (
      phrases.length < minimumPhrasesRequired &&
      (isWorkflowActive || isIntentEnabledForEmail)
    ) {
      onUpdateWithLessThanRequiredPhrases &&
        onUpdateWithLessThanRequiredPhrases();
    } else {
      setIsPendingRequest(true);
      closeAndResetForm();
      await dispatch(
        updateIntent({
          description: description || undefined,
          intent_id: intent?.intent_id as string,
          name: title,
          phrases,
        }),
      );

      setIsPendingRequest(false);
    }
  };

  const buildTrashIcon = ({
    index,
    isDisabled,
    isModifiable,
  }: {
    index: number;
    isDisabled: boolean;
    isModifiable: boolean;
  }) => {
    if (!isModifiable) {
      return null;
    }
    const TrashButton = () => (
      <Button
        aria-label={'Delete phrase ' + (index + 1).toString()}
        disabled={isDisabled}
        onClick={() => {
          const newPhrases = [...phrases];
          newPhrases.splice(index, 1);
          setPhrases(newPhrases);
        }}
        startIcon={<ActiveStateIcon isDisabled={isDisabled} src={trashIcon} />}
        style={{ marginTop: '4px' }}
        variant='ghost'
      />
    );

    return isDisabled ? (
      <Tooltip
        tooltipContent={`Training phrase cannot be deleted since required minimum of ${minNumberOfPhrasesAllowed}.`}
      >
        <TrashButton />
      </Tooltip>
    ) : (
      <TrashButton />
    );
  };

  return (
    <Drawer
      data-testid='intent-form'
      isOpen={isOpen}
      onClose={() => {
        if (hasUnsavedChanges) {
          dispatch(setIsOverlayVisible(true));
        } else {
          closeAndResetForm();
        }
      }}
    >
      <UnsavedChangesModal
        onCancel={() => dispatch(setIsOverlayVisible(false))}
        onDiscard={closeAndResetForm}
      />
      <Content>
        <Header>
          <Title>{isEditing ? 'Edit Intent' : 'Add intent'}</Title>
          {!isCustom && (
            <BadgeContainer>
              <Badge label='Commonly used intent' variant='macro' />
              <Spacer width='56px' />
            </BadgeContainer>
          )}
        </Header>
        <Body>
          {isEditing && discoverTopics.length > 0 && (
            <DiscoverTopicsSection>
              <Box alignItems='center' display='flex'>
                <Typography
                  color={theme.palette.text.primary}
                  variant='font16Bold'
                >
                  Discover Topics
                </Typography>
                <Box marginLeft='5px' position='relative' top='3px'>
                  <Tooltip tooltipContent='Discover topics associated with this workflow'>
                    <IconInfoCircle
                      color='currentColor'
                      height={16}
                      width={16}
                    />
                  </Tooltip>
                </Box>
              </Box>

              <DiscoverTopicsListContainer>
                {
                  <DiscoverTopicsRenderer
                    numberOfTopicsToRender={3}
                    topics={discoverTopics}
                    trackingEventMetadata={trackingEventMetadata}
                  />
                }
              </DiscoverTopicsListContainer>
            </DiscoverTopicsSection>
          )}
          {isMultibrandEnabled && !isEditing && channel === 'widget' && (
            <Box>
              <SectionHeader>Tags (optional)</SectionHeader>
              <Description>
                {`Workflows can be shared across brands and will
              only be triggered for those specified by tags. If no tags are assigned, 
              the workflow will be visible to all segments.`}
              </Description>
              <Spacer height='8px' />
              <SelectDropdown
                id='brand-tags'
                isMenuSearchable
                multiple
                onChange={e => {
                  if (!Array.isArray(e.target.value)) {
                    return;
                  }

                  setCurrentTags(e.target.value);
                }}
                onClose={() => setIsTagSelectOpen(false)}
                onOpen={() => setIsTagSelectOpen(true)}
                open={isTagSelectOpen}
                options={allWorkflowTags.map(tag => ({
                  label: tag,
                  value: tag,
                }))}
                placeholder='Assign tags'
                value={currentTags}
              />
            </Box>
          )}
          <Box>
            <SectionHeader>Intent display name</SectionHeader>
            <Description>
              {`Display names are used by the model to better understand the
              intent and may be shown to your customer for confirmation. Ensure
              accuracy by using descriptive names that reflect the expected
              outcome, such as "Error Uploading Tax Return" rather than "Return
              Errors".`}
            </Description>
            <Spacer height='8px' />
            <TextField
              aria-label='Intent display name required'
              autoFocus
              data-appcues-target='AddIntentNameButton'
              error={shouldValidate && headerErrorMsg}
              label=''
              onBlur={() => setShouldValidate(true)}
              onChange={e => {
                setTitle(e.target.value);
              }}
              placeholder='Give a name to this intent'
              readOnly={false}
              required
              showRequiredLabel
              value={title}
            />
          </Box>
          {isCustom && (
            <Box display='flex' flexDirection='column' gap='15px'>
              <Box>
                <SectionHeader>More information (optional)</SectionHeader>
                <Description>
                  {`Briefly describe your customer's action or query to help predict
            accurate responses.`}
                </Description>
              </Box>
              <Typography variant='font14'>
                This intent is triggered when...
              </Typography>
              <TextField
                aria-label='More information'
                error={
                  words(description).length >= 100 &&
                  'You can only input a maximum of 100 words.'
                }
                multiline
                onChange={e => setDescription(e.target.value)}
                placeholder={
                  "describe your customer's action or query.\ne.g. the user needs help changing their email (not a mailing address). If the query only mentions ‘address’ and not email, do not predict."
                }
                value={description}
              />
            </Box>
          )}
          <Box display='flex' flexDirection='column' gap='9px'>
            <Box>
              <SectionHeader>Training phrases</SectionHeader>
              <Description>
                Input training phrases for questions that your customers would
                ask.
              </Description>
            </Box>
            <Box>
              <Box alignItems='center' display='flex' marginBottom='9px'>
                <TextField
                  aria-label='New phrase'
                  data-appcues-target='AddTrainingPhrasesTextField'
                  inputRef={inputBoxRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setCurrentPhrase(e.target.value);
                  }}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && !isEmpty(currentPhrase.trim())) {
                      addPhrase();
                    }
                  }}
                  placeholder="Type a training phrase and press 'Enter'"
                  readOnly={false}
                  value={currentPhrase}
                />
                <Spacer width='12px' />
                <Button
                  aria-label='Add phrase'
                  data-appcues-target='AddTrainingPhrasesButton'
                  disabled={isEmpty(currentPhrase.trim())}
                  onClick={addPhrase}
                  startIcon={
                    <ActiveStateIcon
                      isDisabled={isEmpty(currentPhrase.trim())}
                      src={plusIcon}
                    />
                  }
                  variant='ghost'
                />
              </Box>
              {phrases?.map((phrase: Phrase, index) => {
                return (
                  <Row key={phrase.phrase_id as string}>
                    <StyledTextField
                      aria-label={'Phrase ' + (index + 1).toString()}
                      autoFocus={Boolean(initialPhrases.length && index === 0)}
                      error={phraseErrors[index]}
                      isModifiable={!!phrase.is_modifiable}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleExistingPhraseChange(e, index)
                      }
                      placeholder="Type a training phrase and press 'Enter'"
                      readOnly={!phrase.is_modifiable}
                      value={phrase.phrase_text}
                    />
                    <Spacer width='12px' />
                    {buildTrashIcon({
                      index,
                      isDisabled: phrases.length <= minNumberOfPhrasesAllowed,
                      isModifiable: phrase.is_modifiable as boolean,
                    })}
                  </Row>
                );
              })}
              {phrases.length < minimumPhrasesRequired && (
                <>
                  <Spacer height='32px' />
                  <WarningContainer>
                    <img src={circularWarningIcon} />
                    <Spacer width='7px' />
                    <WarningLabel>
                      Active intents require at least {minimumPhrasesRequired}{' '}
                      training phrases.
                    </WarningLabel>
                  </WarningContainer>
                </>
              )}
            </Box>
          </Box>
        </Body>
        <Footer>
          <Button
            aria-label='Submit intent'
            data-appcues-target='AddIntentButton'
            disabled={isSubmiButtonDisabled || isPendingRequest}
            fullWidth
            isLoading={isPendingRequest}
            onClick={
              isEditing ? handleUpdateIntent : handleCreateIntentWorkflow
            }
            size='large'
            variant='main'
          >
            {isEditing ? 'Save changes' : 'Add intent'}
          </Button>
        </Footer>
      </Content>
    </Drawer>
  );
};

const Content = styled('div')`
  font-style: normal;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  padding: 45px 40px 24px 40px;
  overflow: auto;
`;

const Header = styled('div')`
  flex: 1 1 auto;
  max-height: 80px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  position: relative;
`;

const Description = styled('div')`
  ${textRegularStyle({
    color: theme.palette.colors.grey[600],
    fontSize: '12px',
  })};
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const Body = styled('div')`
  height: 100%;
  flex: 1 1 auto;
  max-height: calc(100% - 113px);
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Footer = styled('div')`
  height: 64px;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled('div')`
  ${textBoldStyle({ color: `${Colors.ui.text.primary}`, fontSize: '24px' })};
  line-height: 30px;
  margin-bottom: 54px;
`;

const Row = styled('div')`
  display: flex;
  margin-bottom: 9px;
`;

const Spacer = styled('div')<{
  height?: string;
  width?: string;
}>`
  height: ${props => props.height || 0};
  width: ${props => props.width || 0};
`;

const SectionHeader = styled('div')`
  ${textBoldStyle({
    color: Colors.ui.text.primary,
    fontSize: '16px',
  })};
  line-height: 22px;
  letter-spacing: -0.1px;
`;

export const ActiveStateIcon: StyledComponent<
  { isDisabled?: boolean },
  // eslint-disable-next-line @typescript-eslint/ban-types -- {} is used by default
  {},
  { src: string }
> = styled(ReactSVG)<{ isDisabled?: boolean }>`
  [fill] {
    fill: ${theme.palette.colors.purple[500]};
  }
  [stroke] {
    stroke: ${theme.palette.colors.purple[500]};
  }
  ${props =>
    props.isDisabled &&
    css`
      [fill] {
        fill: ${theme.palette.colors.grey[500]};
      }
      [stroke] {
        stroke: ${theme.palette.colors.grey[500]};
      }
    `}
`;

const StyledTextField = styled(TextField)<{ isModifiable: boolean }>`
  input {
    ${props => !props.isModifiable && 'padding: 0 16px !important'};
  }
`;

const BadgeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  top: 4px;
`;

const WarningLabel = styled('div')`
  ${textRegularStyle({
    color: Colors.ui.text.secondary,
    fontSize: '14px',
  })};
  line-height: 20px;
  letter-spacing: -0.1px;
`;

const WarningContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DiscoverTopicsSection = styled('div')`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.palette.colors.slate[100]};
`;

const DiscoverTopicsListContainer = styled('div')`
  margin-top: 4px;
  margin-bottom: 28px;
`;
