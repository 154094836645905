import { useEffect } from 'react';
import Box from '@mui/material/Box';

import {
  SelectDropdown,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import { TriageModelDetail } from 'src/reducers/triageSettingsReducer/types';

export interface ModelSelectProps {
  models: TriageModelDetail[];
  modelsLoading: boolean;
  onModelChange: (selectedModel: string) => void;
  selectedModel: string | null;
}

const ModelSelect = ({
  models,
  modelsLoading,
  onModelChange,
  selectedModel,
}: ModelSelectProps) => {
  useEffect(() => {
    if (!models.length) {
      return;
    }
    if (
      !selectedModel ||
      !models.find(
        item => item.model_id === selectedModel || item.name === selectedModel,
      )
    ) {
      onModelChange(models[0].model_id || models[0].name);
    }
  }, [models, onModelChange, selectedModel]);

  if (modelsLoading) {
    return <Skeleton width={100} />;
  }

  const value = selectedModel || '';

  return (
    <Box sx={{ '.MuiSelect-select': { py: '6px !important' } }} width='200px'>
      <SelectDropdown
        id='model-select'
        isMenuSearchable
        menuTitle='Models'
        multiple={false}
        onChange={event => onModelChange(event.target.value)}
        options={models.map(model => ({
          label: model.display_name || model.name,
          value: model.model_id || model.name,
        }))}
        value={value}
      />
    </Box>
  );
};

export default ModelSelect;
