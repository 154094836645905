import { useMemo, useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  DateRangeFilterButton,
  elevations,
  SelectDropdown,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { SIMULATION_TOOLTIP } from './constants';
import { GetModelsAndVersionsResponse } from 'src/reducers/triageSettingsReducer/types';
import {
  useDeletePredictionTestMutation,
  useGetModelsAndVersionsQuery,
} from 'src/services/triage/triageApi';
import { TriagePredictionRun } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';
import { formatReadableDate } from 'src/utils/dateUtils';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

export interface SimulationFormValues {
  dateRange?: DateRange;
  selectedModelId: string;
  selectedVersionId: string;
  testName: string;
  testType: string;
  ticketIds: string;
}

interface ManageSimulationFormProps {
  currentSimulation?: TriagePredictionRun;
  dateRange?: DateRange;
  existingSimulationNames?: string[];
  handleDisable: () => boolean;
  isEditMode: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (values: SimulationFormValues) => void | Promise<void>;
  selectedModelId?: string;
  selectedVersionId?: string;
  setDateRange?: (value: DateRange) => void;
  setSelectedModelId?: (value: string) => void;
  setSelectedVersionId?: (value: string) => void;
  setTestName?: (value: string) => void;
  setTestType?: (value: string) => void;
  setTicketIds?: (value: string) => void;
  testName?: string;
  testType?: string;
  ticketIds?: string;
}

const ManageSimulationForm = ({
  currentSimulation,
  dateRange,
  existingSimulationNames = [],
  isEditMode,
  isLoading,
  onClose,
  onSubmit,
  selectedModelId = '',
  selectedVersionId = '',
  setDateRange,
  setSelectedModelId,
  setSelectedVersionId,
  setTestName,
  setTestType,
  setTicketIds,
  testName = '',
  testType = 'ids',
  ticketIds = '',
}: ManageSimulationFormProps) => {
  const { palette } = useTheme();
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isDuplicateName, setIsDuplicateName] = useState(false);

  const [deletePredictionTest, { isLoading: isDeleting }] =
    useDeletePredictionTestMutation();
  const { data: modelAndVersionData = [] } = useGetModelsAndVersionsQuery();
  const modelList = modelAndVersionData.map(item => {
    return { label: item.model_name, value: item.model_id };
  });
  const filteredModels = modelAndVersionData
    .map(item => {
      if (item.model_id === selectedModelId) {
        return item;
      }
    })
    .filter(Boolean) as GetModelsAndVersionsResponse[];
  const versionsFromFilteredModels = filteredModels.map(item => {
    return { label: item.version_name, value: item.version_id };
  });

  const handleDeleteConfirm = async () => {
    if (currentSimulation?.prediction_test_id) {
      try {
        await deletePredictionTest(
          currentSimulation.prediction_test_id,
        ).unwrap();
        setIsDeleteConfirm(false);
      } catch (err) {
        console.error('Failed to delete', err);
      } finally {
        onClose();
      }
    }
  };

  const initialValues = useMemo(
    () => ({
      dateRange: dateRange,
      selectedModelId: currentSimulation?.model_id || selectedModelId,
      selectedVersionId: currentSimulation?.version_id || selectedVersionId,
      testName: currentSimulation?.name || testName,
      testType: testType,
      ticketIds: currentSimulation?.ticket_ids?.join(', ') || ticketIds,
    }),
    [
      currentSimulation,
      dateRange,
      selectedModelId,
      selectedVersionId,
      testName,
      testType,
      ticketIds,
    ],
  );

  const isCreateFormValid = (values: SimulationFormValues) => {
    return (
      values.testName.trim() &&
      values.selectedModelId.trim() &&
      values.selectedVersionId.trim() &&
      values.testType.trim() &&
      ((values.testType === 'dateRange' && values.dateRange) ||
        (values.testType === 'ids' && values.ticketIds.trim()))
    );
  };

  const isNameChanged = (values: SimulationFormValues) => {
    return values.testName !== initialValues.testName;
  };

  const preSelectedTestType = useMemo(() => {
    if (currentSimulation?.start_datetime && currentSimulation?.end_datetime) {
      return 'dateRange';
    } else {
      return 'ids';
    }
  }, [currentSimulation]);

  const initialDateRange =
    currentSimulation?.start_datetime && currentSimulation?.end_datetime
      ? {
          from: new Date(currentSimulation.start_datetime),
          to: new Date(currentSimulation.end_datetime),
        }
      : undefined;

  const formattedInitialDateRange = initialDateRange
    ? `${formatReadableDate(initialDateRange.from)} - ${formatReadableDate(
        initialDateRange.to,
      )}`
    : '';

  const deleteConfirmationContent = () => (
    <Box
      display='flex'
      flexDirection='column'
      margin='auto'
      rowGap='16px'
      textAlign='center'
    >
      <Typography variant='font24'>Delete simulation</Typography>
      <Typography variant='font16'>
        This simulation will be permanently deleted and will not be retrievable.
        Are you sure you want to delete the simulation?
      </Typography>
      <Box display='flex' gap={2} sx={{ justifyContent: 'center', mt: '24px' }}>
        <Button onClick={() => setIsDeleteConfirm(false)} variant='secondary'>
          Cancel
        </Button>
        <Button
          isLoading={isDeleting}
          onClick={handleDeleteConfirm}
          variant='danger'
        >
          Delete
        </Button>
      </Box>
    </Box>
  );

  const checkForDuplicateName = (name: string) => {
    const lowerCaseName = name.trim().toLowerCase();
    const isDuplicate =
      existingSimulationNames?.includes(lowerCaseName) &&
      lowerCaseName !== currentSimulation?.name;
    setIsDuplicateName(isDuplicate);
    return isDuplicate;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        dateRange: initialDateRange || dateRange,
        testType: preSelectedTestType,
      }}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, setFieldValue, submitForm, values }) => (
        <Form onSubmit={handleSubmit}>
          {isDeleteConfirm ? (
            deleteConfirmationContent()
          ) : (
            <Box
              display='flex'
              flexDirection='column'
              flexGrow={1}
              rowGap='32px'
            >
              <Typography variant='font24'>
                {isEditMode ? 'Edit' : 'Create'} simulation
              </Typography>

              <TextField
                defaultValue={isEditMode ? initialValues.testName : ''}
                error={isDuplicateName}
                helperText={
                  isDuplicateName ? 'Simulation name already exists' : ''
                }
                label='Name'
                name='testName'
                onChange={e => {
                  handleChange(e);
                  const name = e.target.value;
                  if (setTestName) {
                    setTestName(name);
                  }
                  checkForDuplicateName(name);
                }}
                placeholder='Give it a name'
                value={values.testName}
              />

              {isEditMode ? (
                <Box>
                  <Box component='label' htmlFor='model-name' mb={2}>
                    <Typography variant='font14Medium'>Model</Typography>
                  </Box>

                  <Box aria-labelledby='model-name' mb={4}>
                    <Typography variant='font14'>
                      {modelList.find(
                        model => model.value === initialValues.selectedModelId,
                      )?.label || 'Unknown model'}
                    </Typography>
                  </Box>

                  <Box component='label' htmlFor='model-version' mb={2}>
                    <Typography variant='font14Medium'>
                      Model version
                    </Typography>
                  </Box>

                  <Box aria-labelledby='model-version'>
                    <Typography variant='font14'>
                      {versionsFromFilteredModels.find(
                        version =>
                          version.value === initialValues.selectedVersionId,
                      )?.label || 'Unknown version'}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <SelectDropdown
                    id='model_selector'
                    label='Model'
                    menuMaxHeight={300}
                    onChange={e => {
                      setFieldValue('selectedModelId', e.target.value);
                      if (setSelectedModelId) {
                        setSelectedModelId(e.target.value);
                      }
                      if (setSelectedVersionId) {
                        setFieldValue('selectedVersionId', '');
                      }
                    }}
                    options={modelList}
                    placeholder='Select model'
                    showRequiredLabel={!isEditMode}
                    value={values.selectedModelId}
                  />
                  <SelectDropdown
                    id='version_selector'
                    label='Model version'
                    menuMaxHeight={300}
                    onChange={e => {
                      setFieldValue('selectedVersionId', e.target.value);
                      if (setSelectedVersionId) {
                        setSelectedVersionId(e.target.value);
                      }
                    }}
                    options={versionsFromFilteredModels}
                    placeholder='Select version'
                    showRequiredLabel={!isEditMode}
                    value={values.selectedVersionId}
                  />
                </>
              )}

              <Box aria-labelledby='test-by' component='label'>
                <Typography variant='font16Bold'>Test set criteria</Typography>
              </Box>

              {isEditMode ? (
                <Box>
                  {preSelectedTestType === 'dateRange' ? (
                    <>
                      <Box
                        alignItems='center'
                        component='label'
                        display='flex'
                        htmlFor='date-range'
                        mb={2}
                      >
                        <Typography variant='font14Medium'>
                          Date range
                        </Typography>
                        <Tooltip
                          maxWidth={320}
                          placement='right'
                          tooltipContent={SIMULATION_TOOLTIP.date_range}
                        >
                          <IconInfoCircle
                            color='currentColor'
                            height={16}
                            width={16}
                          />
                        </Tooltip>
                      </Box>

                      <Box aria-labelledby='date-range' mb={4}>
                        <Typography variant='font14'>
                          {formattedInitialDateRange}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        alignItems='center'
                        component='label'
                        display='flex'
                        htmlFor='ticket-ids'
                        mb={2}
                      >
                        <Typography variant='font14Medium'>
                          Ticket IDs
                        </Typography>
                        <Tooltip
                          maxWidth={320}
                          placement='right'
                          tooltipContent={SIMULATION_TOOLTIP.ticket_ids}
                        >
                          <IconInfoCircle
                            color='currentColor'
                            height={16}
                            width={16}
                          />
                        </Tooltip>
                      </Box>

                      <Box aria-labelledby='ticket-ids'>
                        <Typography variant='font14'>
                          {initialValues.ticketIds}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                <RadioGroup
                  aria-labelledby='test-by'
                  name='testType'
                  onChange={e => {
                    handleChange(e);
                    if (setTestType) {
                      setTestType(e.target.value);
                    }
                  }}
                  value={values.testType}
                >
                  <Box alignItems='center' display='flex'>
                    <FormControlLabel
                      control={<Radio size='small' />}
                      label='Date range'
                      name='testType'
                      sx={{ fontSize: 14, mr: '4px' }}
                      value='dateRange'
                    />
                    <Tooltip
                      maxWidth={320}
                      placement='right'
                      tooltipContent={SIMULATION_TOOLTIP.date_range}
                    >
                      <IconInfoCircle
                        color='currentColor'
                        height={16}
                        width={16}
                      />
                    </Tooltip>
                  </Box>
                  {values.testType === 'dateRange' &&
                    values.dateRange &&
                    !isEditMode && (
                      <Box pl='28px'>
                        <DateRangeFilterButton
                          onChange={dateRange => {
                            setFieldValue('dateRange', dateRange);
                            if (setDateRange) {
                              setDateRange(dateRange);
                            }
                          }}
                          options={datePickerRangeOptionsRevamp}
                          size='medium'
                          value={values.dateRange}
                        />
                      </Box>
                    )}

                  <Box alignItems='center' display='flex'>
                    <FormControlLabel
                      control={<Radio size='small' />}
                      label='Ticket IDs'
                      name='testType'
                      sx={{ fontSize: 14, mr: '4px' }}
                      value='ids'
                    />
                    <Tooltip
                      maxWidth={320}
                      placement='right'
                      tooltipContent={SIMULATION_TOOLTIP.ticket_ids}
                    >
                      <IconInfoCircle
                        color='currentColor'
                        height={16}
                        width={16}
                      />
                    </Tooltip>
                  </Box>
                  {values.testType === 'ids' && (
                    <Box pl='28px' width='100%'>
                      <TextField
                        aria-label='ticket ids'
                        disabled={isEditMode}
                        name='ticketIds'
                        onChange={e => {
                          handleChange(e);
                          if (setTicketIds) {
                            setTicketIds(e.target.value);
                          }
                        }}
                        placeholder='Enter a comma-separated list of helpdesk ticket IDs'
                        value={
                          isEditMode
                            ? initialValues.ticketIds
                            : values.ticketIds
                        }
                      />
                    </Box>
                  )}
                </RadioGroup>
              )}

              <Box marginTop='auto'>
                {isEditMode && (
                  <Divider
                    sx={{
                      background: palette.colors.slate[200],
                      boxShadow: elevations.z3,
                      margin: '12px -32px',
                      width: 'calc(100% + 64px)',
                    }}
                  />
                )}

                <Box display='flex' flexDirection='column' rowGap={2}>
                  {isEditMode && (
                    <Typography variant='font12'>
                      {currentSimulation?.status === 'pending' &&
                        'The simulation is currently being generated.'}
                      {currentSimulation?.status === 'failure' &&
                        "We couldn't generate a simulation with the current test ticket criteria filters. Please adjust the filters and try again, as they may be too restrictive and exclude relevant tickets."}
                    </Typography>
                  )}

                  <Box display='flex' gap={1} sx={{ flexFlow: 'nowrap' }}>
                    {isEditMode && currentSimulation?.status === 'failure' ? (
                      <Button
                        fullWidth
                        onClick={() => {
                          setIsDeleteConfirm(true);
                        }}
                        variant='danger'
                      >
                        Delete
                      </Button>
                    ) : (
                      <>
                        {isEditMode ? (
                          <>
                            <Button
                              onClick={() => {
                                setIsDeleteConfirm(true);
                              }}
                              variant='danger'
                            >
                              Delete
                            </Button>
                            <Button
                              disabled={
                                !isNameChanged(values) || isDuplicateName
                              }
                              fullWidth
                              isLoading={isLoading}
                              onClick={submitForm}
                              variant='main'
                            >
                              Update
                            </Button>
                          </>
                        ) : (
                          <Button
                            disabled={
                              !isCreateFormValid(values) || isDuplicateName
                            }
                            fullWidth
                            isLoading={isLoading}
                            onClick={submitForm}
                            variant='main'
                          >
                            Create
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

export default ManageSimulationForm;
