import DecommissionedWorkflowErrorModal from '../decommission-workflow-error-modal';
import ContextVariableDrawer from './ContextVariableDrawer';
import { CustomHandoffDrawer } from './CustomHandoffDrawer';
import Dialog from './Dialog';
import Intents from './Intents';
import TranslationDrawer from './TranslationDrawer';
import { WorkflowTagManagementDrawer } from './WorkflowTagManagementDrawer';

const GlobalWorkflowBuilder = () => {
  return (
    <>
      <ContextVariableDrawer />
      <TranslationDrawer />
      <Intents />
      <CustomHandoffDrawer />
      <Dialog />
      <DecommissionedWorkflowErrorModal />
      <WorkflowTagManagementDrawer />
    </>
  );
};

export default GlobalWorkflowBuilder;
