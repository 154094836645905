import { styled } from '@mui/material';

import { ButtonSelectionTranscriptComponent } from './types';
import IntentButton from 'src/pages/solve-config/intent-button/IntentButton';

export const ButtonSelectionStep = ({
  transcriptComponent,
}: {
  transcriptComponent: ButtonSelectionTranscriptComponent;
}) => {
  if (transcriptComponent.options.length === 0) {
    return (
      <ButtonsContainer>
        <IntentButton
          intentName={transcriptComponent.option_selected}
          isProactiveBubble={false}
          isSelected
        />
      </ButtonsContainer>
    );
  }

  return (
    <ButtonsContainer>
      {transcriptComponent.options.map(option => (
        <IntentButton
          intentName={option.label}
          isProactiveBubble={false}
          isSelected={option.value === transcriptComponent.option_selected}
          key={option.value}
        />
      ))}
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled('div')`
  width: 70%;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
`;
