import { SankeyOverviewData } from './types';

export const getValue = (value: SankeyOverviewData['value']) =>
  `${(value.count || 0).toLocaleString()} ${value.suffix ? value.suffix : ''}`;

export const removeZeroes = (value: string) => {
  if (value.includes('.')) {
    return value.replace(/\.?0*$/, '');
  }
  return value;
};

export const toPercentageDecimal = (value: number) => {
  return removeZeroes((value * 100).toFixed(2)) + '%';
};
