import { useMemo } from 'react';

import { createApiFilters } from './helpers';
import TicketStatusValue from './TicketStatus';
import OverviewMetricsHeader from 'src/components/overview-metrics-header';
import { OverviewMetricHeaderItemProps } from 'src/components/overview-metrics-header/OverviewMectricHeaderItem';
import {
  useGetTriageInsightOverallPredictionMetricsQuery,
  useGetTriageInsightOverallTicketMetricsQuery,
} from 'src/services/triage/triageApi';

interface OverviewMetricsSectionProps {
  apiFilters: ReturnType<typeof createApiFilters>;
}

const OverviewMetricsSection = ({
  apiFilters,
}: OverviewMetricsSectionProps) => {
  const {
    data: ticketMetrics,
    isFetching: ticketMetricsIsFetching,
    isLoading: ticketMetricsIsLoading,
  } = useGetTriageInsightOverallTicketMetricsQuery(apiFilters);
  const {
    data: predictionMetrics,
    isFetching: predictionMetricsIsFetching,
    isLoading: predictionMetricsIsLoading,
  } = useGetTriageInsightOverallPredictionMetricsQuery(apiFilters);

  const values = useMemo(() => {
    const loading =
      ticketMetricsIsLoading ||
      ticketMetricsIsFetching ||
      predictionMetricsIsLoading ||
      predictionMetricsIsFetching;
    const overallData: OverviewMetricHeaderItemProps[] = [
      {
        loading,
        metricType: 'count',
        title: 'Eligible Tickets',
        value: ticketMetrics?.eligible_tickets,
      },
      {
        title: 'Ticket status',
        value: null,
        Value: <TicketStatusValue loading={loading} value={ticketMetrics} />,
      },
      {
        loading,
        metricType: 'count',
        title: 'Written predictions',
        value: predictionMetrics?.written_predictions,
      },
      {
        loading,
        metricType: 'count',
        title: 'Correct Predictions',
        value: predictionMetrics?.correct_predictions,
      },
      {
        loading,
        metricType: 'percentage',
        title: 'Accuracy',
        value: predictionMetrics?.accuracy,
      },
    ];
    return overallData;
  }, [
    ticketMetrics,
    predictionMetrics,
    ticketMetricsIsLoading,
    ticketMetricsIsFetching,
    predictionMetricsIsLoading,
    predictionMetricsIsFetching,
  ]);

  return (
    <OverviewMetricsHeader
      gridTemplateColumns='1fr 2fr 1fr 1fr 1fr'
      values={values}
    />
  );
};

export default OverviewMetricsSection;
