import { Box } from '@mui/material';

import FilterLabelContainer from './FilterLabelContainer';
import ListOption from './ListOption';
import { Option } from 'node_modules/@forethought-technologies/forethought-elements/dist/Components/MultiSelectFilter/types';

interface ListOptionParentProps {
  expandedValues: string[];
  onChange: (value: string[]) => void;
  options: Option[];
  setExpandedValues: (value: string[]) => void;
  value: string[];
}

const ListOptionParent = ({
  expandedValues,
  onChange,
  options,
  setExpandedValues,
  value,
}: ListOptionParentProps) => {
  return (
    <Box display='flex' flex={1} flexDirection='column' width='100%'>
      <FilterLabelContainer label='Others'>
        <Box marginTop='8px'>
          {options.map(option => (
            <ListOption
              expandedValues={expandedValues}
              isOuter={true}
              item={option}
              key={option.value}
              onChange={onChange}
              setExpandedValues={setExpandedValues}
              value={value}
            />
          ))}
        </Box>
      </FilterLabelContainer>
    </Box>
  );
};

export default ListOptionParent;
