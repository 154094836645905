import { Box, useTheme } from '@mui/material';
import { IconVariable } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ActivenessIndicator } from './ActivenessIndicator';
import { CircleIcon } from './StyledComponents';
import { TemplateBadge } from 'src/components/template-badge';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

interface CVItemProps {
  cv: ContextVariable & { isActive: boolean };
  isTemplate?: boolean;
  onClick: () => void;
}

export const CVItem = ({ cv, isTemplate = false, onClick }: CVItemProps) => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      display='flex'
      gap='8px'
      onClick={onClick}
      sx={{
        border: '1px solid',
        borderColor: cv.isActive ? 'primary.main' : 'grey.300',
        borderRadius: 1,
        cursor: 'pointer',
        p: 2,
      }}
    >
      <CircleIcon bgcolor={palette.colors.blue[700]}>
        <IconVariable size={20} />
      </CircleIcon>
      <Typography variant='font14'>{cv.context_variable_name}</Typography>
      <Box
        alignItems='center'
        display='flex'
        flex={1}
        gap={1}
        justifyContent='flex-end'
      >
        {isTemplate && <TemplateBadge />}
        <ActivenessIndicator isActive={cv.isActive} />
      </Box>
    </Box>
  );
};
