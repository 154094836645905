import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconTrash } from '@tabler/icons-react';

import {
  Checkbox,
  IconButton,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { NONE_RTE_INPUT_DEBOUNCE_DELAY } from '../constants';
import { useGetSelectedBrand } from '../hooks';
import debounce from 'lodash/fp/debounce';
import {
  CreateTagDialog,
  DeleteTagDialog,
} from 'src/components/workflow-tag-dialogs/WorkflowTagDialogs';
import { useGetWorkflowTags } from 'src/hooks/hooks';
import {
  useGetBrandsQuery,
  useUpdateBrandMutation,
  useUpdateBrandTagsMutation,
} from 'src/services/brand/brandApi';
import { setIsGenericFieldsLoading } from 'src/slices/solve-config/solveConfigSlice';
import {
  setGlobalToastOptions,
  setGlobalWorkflowBuilderOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { BaseBrand } from 'src/types/brandTypes';

const useBrandConfig = () => {
  const selectedBrand = useGetSelectedBrand() ?? {
    brand_id: '',
    brand_name: '',
    is_default: false,
    tags: [],
  };
  const [brandName, setBrandName] = useState(selectedBrand.brand_name);
  const [isDefault, setIsDefault] = useState(selectedBrand.is_default);
  const [tags, setTags] = useState(selectedBrand.tags);
  const dispatch = useAppDispatch();

  const [updateBrand] = useUpdateBrandMutation({
    fixedCacheKey: 'updateBrand',
  });
  const [updateBrandTagsMutation] = useUpdateBrandTagsMutation({
    fixedCacheKey: 'updateBrandTags',
  });

  // tags are changed from other sources (i.e. tag management drawer), we should update
  // tags here accordingly
  useEffect(() => {
    setTags(selectedBrand.tags);
  }, [selectedBrand.tags]);

  const debouncedUpdateBrand = useMemo(
    () =>
      debounce(NONE_RTE_INPUT_DEBOUNCE_DELAY, async (brand: BaseBrand) => {
        updateBrand({ body: brand, brandId: selectedBrand.brand_id });
      }),
    [selectedBrand?.brand_id, updateBrand],
  );

  const debouncedUpdateTags = useMemo(
    () =>
      debounce(NONE_RTE_INPUT_DEBOUNCE_DELAY, async (tags: string[]) => {
        updateBrandTagsMutation({
          body: tags,
          brandId: selectedBrand.brand_id,
        });
      }),
    [selectedBrand.brand_id, updateBrandTagsMutation],
  );

  const updateBrandName = useCallback(
    (brandName: string) => {
      dispatch(setIsGenericFieldsLoading(true));
      setBrandName(brandName);
      debouncedUpdateBrand({
        brand_name: brandName,
        is_default: selectedBrand.is_default,
      });
    },
    [debouncedUpdateBrand, dispatch, selectedBrand.is_default],
  );

  const toggleDefaultBrand = useCallback(() => {
    dispatch(setIsGenericFieldsLoading(true));
    setIsDefault(!isDefault);
    debouncedUpdateBrand({
      brand_name: selectedBrand?.brand_name || '',
      is_default: !selectedBrand?.is_default,
    });
  }, [
    debouncedUpdateBrand,
    dispatch,
    isDefault,
    selectedBrand?.brand_name,
    selectedBrand?.is_default,
  ]);

  const updateBrandTags = useCallback(
    (tags: string[]) => {
      dispatch(setIsGenericFieldsLoading(true));
      setTags(tags);
      debouncedUpdateTags(tags);
    },
    [debouncedUpdateTags, dispatch],
  );

  return {
    brandId: selectedBrand.brand_id,
    brandName,
    isDefault,
    tags,
    toggleDefaultBrand,
    updateBrandName,
    updateBrandTags,
    updateUiBrandTags: setTags,
  };
};

export const BrandTabContent = () => {
  const selectedBrand = useGetSelectedBrand();
  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Typography variant='font14'>
        This section lets you create unique widget designs for different{' '}
        <strong>brands</strong>, giving each a personalized look. You can
        control which users see a widget by applying tags, so only those in the
        tagged brands will see it. This ensures targeted visibility and a
        tailored experience for your audience.
      </Typography>
      <BrandConfiguration key={selectedBrand?.brand_id || ''} />
    </Box>
  );
};

const BrandConfiguration = () => {
  const brand = useGetSelectedBrand();
  const { brandName, isDefault, toggleDefaultBrand, updateBrandName } =
    useBrandConfig();
  const [, setSearchParams] = useSearchParams();
  if (!brand) {
    return null;
  }

  return (
    <>
      <Box display='flex' flexDirection='column' gap={0.5}>
        <Typography variant='font16Bold'>Brand name</Typography>
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          variant='font12'
        >
          The name serves as a label for accessing and filtering workflows and
          insights.
        </Typography>
        <TextField
          aria-label='Brand name'
          description='Keep it under 50 characters'
          error={
            brandName.length === 50 && 'Brand name has reached character limit'
          }
          onChange={({ target }) => {
            const valueWithMaxLength = target.value.slice(0, 50);
            if (valueWithMaxLength === brandName) {
              return;
            }
            updateBrandName(valueWithMaxLength);
          }}
          value={brandName}
        />
      </Box>
      <WorkflowTagSelect />
      <Box display='flex' flexDirection='column'>
        <Checkbox
          checked={isDefault}
          disabled={isDefault && brand?.is_default}
          label='Set as default'
          onChange={toggleDefaultBrand}
        />
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          ml='31px'
          mt='-8px'
          variant='font12'
        >
          Enable this brand by default if no specific tags are assigned or found
          in the{' '}
          <Typography
            color={theme => theme.palette.primary.main}
            onClick={() =>
              setSearchParams(prev => {
                const searchParams = new URLSearchParams(prev);
                searchParams.set('tab', 'embed');
                return searchParams.toString();
              })
            }
            sx={{ cursor: 'pointer' }}
            variant='font12'
          >
            embed script
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

const WorkflowTagSelect = () => {
  const dispatch = useAppDispatch();
  const { data: brands = [] } = useGetBrandsQuery();
  const workflowTags = useGetWorkflowTags();
  const { brandId, tags, updateBrandTags } = useBrandConfig();
  const [tagToDelete, setTagToDelete] = useState<string>('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const tagToBrandMap = useMemo(() => {
    const map = new Map<string, string>();
    brands.forEach(brand => {
      brand.tags.forEach(tag => {
        if (brand.brand_id !== brandId) {
          map.set(tag, brand.brand_name);
        }
      });
    });
    return map;
  }, [brandId, brands]);

  return (
    <>
      <Box display='flex' flexDirection='column' gap={0.5}>
        <Typography variant='font16Bold'>Tags</Typography>
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          variant='font12'
        >
          Tag this brand to associate it with specific segments. Editing and
          managing tags can be done within{' '}
          <Typography
            color={theme => theme.palette.primary.main}
            onClick={() =>
              dispatch(
                setGlobalWorkflowBuilderOptions({
                  isWorkflowTagManagementDrawerOpen: true,
                }),
              )
            }
            sx={{ cursor: 'pointer' }}
            variant='font12'
          >
            Global Tags.
          </Typography>
        </Typography>
        <SelectDropdown
          addNewButtonText='Create new tag'
          id='brand-tags'
          isMenuSearchable
          multiple
          onAddNewClick={e => {
            e.stopPropagation();
            setIsCreateDialogOpen(true);
          }}
          onChange={e => {
            if (!Array.isArray(e.target.value)) {
              return;
            }

            const newTags = e.target.value;
            const removedTags = tags.filter(tag => !newTags.includes(tag));

            if (removedTags.length > 0) {
              return;
            }

            updateBrandTags(e.target.value);

            dispatch(
              setGlobalToastOptions({
                title: 'Updated brand tags',
                variant: 'main',
              }),
            );
          }}
          onClose={() => setIsSelectOpen(false)}
          onOpen={() => setIsSelectOpen(true)}
          open={isSelectOpen && !tagToDelete && !isCreateDialogOpen}
          options={workflowTags.map(tag => ({
            disabled: tagToBrandMap.has(tag),
            label: tagToBrandMap.has(tag)
              ? `${tag} (in use '${tagToBrandMap.get(tag)}')`
              : tag,
            optionEndAdornment: (
              <IconButton
                aria-label='Delete tag'
                onClick={e => {
                  e.stopPropagation();
                  setTagToDelete(tag);
                }}
                variant='ghost'
              >
                <IconTrash size={20} />
              </IconButton>
            ),
            value: tag,
          }))}
          placeholder='Assign tags'
          value={tags}
        />
      </Box>
      <DeleteTagDialog
        onClose={() => setTagToDelete('')}
        onSuccess={() =>
          updateBrandTags(tags.filter(tag => tag !== tagToDelete))
        }
        tagToDelete={tagToDelete}
      />
      <CreateTagDialog
        brandId={brandId}
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onSuccess={newTag => updateBrandTags([...tags, newTag])}
      />
    </>
  );
};
