import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { Box, Slider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetIsUsingRawTemplate } from '../hooks';
import DividerMenu from './DividerMenu';
import IntentEmailForm from './IntentEmailForm';
import { NoImageContainer } from './IntentEmailImageBaseComponent';
import emptyImageIcon from 'src/assets/images/empty-image-icon.svg';
import {
  ImageFields,
  ToolbarFormComponentProps,
} from 'src/components/email-builder/types';
import { ColorInput } from 'src/pages/workflow-builder-config/styles';
import {
  confirmAssetUploadComplete,
  createAssetUploadLinkAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
import { CreateAssetLinkResponse } from 'src/types/workflowBuilderAPITypes';

interface AssetData extends CreateAssetLinkResponse {
  file: File;
}

const alignmentOptions = [
  { label: 'Left', value: 'start' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'end' },
];

const IntentEmailImageForm = ({
  component,
  onClickCancel,
  onClickSave,
  setActiveComponentDisplayFields,
  setActiveComponentFields,
  setIsActiveComponentValid,
  setIsLoading,
}: ToolbarFormComponentProps) => {
  const { palette } = useTheme();

  const [assetData, setAssetData] = useState<AssetData | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const colorInputRef = useRef<HTMLInputElement>(null);

  const file = assetData?.file;
  const componentFields = component.component_fields as ImageFields;
  const imageURL = component.display_fields?.image_url || '';
  const { alignment, background_color, image_file_name, width } =
    componentFields;
  const imagePreview = (file && URL.createObjectURL(file)) || imageURL;
  const canSubmit = Boolean(file || imageURL);

  const showCustomizationSettings = !useGetIsUsingRawTemplate();

  useEffect(() => {
    setIsActiveComponentValid(Boolean(imageURL));
  }, [imageURL, setIsActiveComponentValid]);

  return (
    <IntentEmailForm
      isSaveButtonDisabled={!canSubmit}
      onClickCancel={() => onClickCancel()}
      onClickSave={() => {
        setIsLoading && setIsLoading(true);
        if (file) {
          const { asset_id, file, file_name, url } = assetData as AssetData;
          fetch(url, {
            body: file,
            headers: {
              'Content-Type': file.type,
            },
            method: 'PUT',
          })
            .then(async () => {
              const updatedComponentFields = {
                ...componentFields,
                image_file_name: file_name,
              };
              await confirmAssetUploadComplete(asset_id);
              onClickSave(updatedComponentFields);
            })
            .catch(console.error)
            .finally(() => setIsLoading && setIsLoading(false));
        } else {
          onClickSave();
          setIsLoading && setIsLoading(false);
        }
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
        height='100%'
        overflow='scroll'
      >
        <Box
          borderBottom={
            showCustomizationSettings
              ? `1px solid ${palette.colors.slate[200]}`
              : 'none'
          }
          paddingBottom='20px'
        >
          <Typography variant='font16Bold'>Image</Typography>
          <Box display='flex' justifyContent='center' margin='20px 0 10px 0'>
            {imagePreview ? (
              <ImagePreview src={imagePreview} />
            ) : (
              <Box height='160px' width='100%'>
                <NoImageContainer width={100}>
                  <img src={emptyImageIcon} />
                </NoImageContainer>
              </Box>
            )}
          </Box>
          <Button
            fullWidth
            onClick={() => fileInputRef.current?.click()}
            variant='ghost'
          >
            {image_file_name ? 'Add image' : 'Change'}
          </Button>
          <input
            accept='.jpg,.png,.gif'
            aria-label='Upload image'
            hidden
            onChange={({ target }) => {
              const file = target.files?.length ? target.files[0] : null;

              if (file) {
                const fileExtension = file.name.split('.').slice(-1).pop();
                createAssetUploadLinkAPI({
                  content_type: file.type,
                  file_extension: fileExtension ?? '',
                }).then(res => {
                  setActiveComponentFields({
                    ...componentFields,
                    image_file_name: file.name,
                  });
                  setActiveComponentDisplayFields &&
                    setActiveComponentDisplayFields({
                      ...component.display_fields,
                      image_url: URL.createObjectURL(file),
                    });
                  setAssetData({ ...res, file });
                });
              }
            }}
            ref={fileInputRef}
            type='file'
          />
        </Box>
        {showCustomizationSettings && (
          <>
            <ConfigurationsContainer>
              <ConfigurationsContentContainer>
                <Typography variant='font16Bold'>Alignment</Typography>
                <DividerMenu
                  onChange={alignment =>
                    setActiveComponentFields({
                      ...componentFields,
                      alignment,
                    })
                  }
                  options={alignmentOptions}
                  selectedValue={alignment}
                />
              </ConfigurationsContentContainer>
              <ConfigurationsContentContainer>
                <Typography variant='font16Bold'>Image Display Size</Typography>
                <Box alignItems='center' display='flex'>
                  <Box marginRight='24px' width='330px'>
                    <Slider
                      aria-label='Select image width'
                      max={100}
                      min={1}
                      onChange={(_, value) =>
                        setActiveComponentFields({
                          ...componentFields,
                          width: value,
                        })
                      }
                      value={width}
                    />
                  </Box>
                  <Box alignItems='center' display='flex'>
                    <Box
                      alignItems='center'
                      border={`1px solid ${palette.colors.grey[300]}`}
                      borderRadius='4px'
                      display='flex'
                      height='40px'
                      justifyContent='center'
                      marginRight='5px'
                      padding='10px 16px'
                      width='60px'
                    >
                      <Typography
                        color={palette.colors.grey[600]}
                        variant='font16Bold'
                      >
                        {width}
                      </Typography>
                    </Box>
                    <Typography
                      color={palette.colors.grey[600]}
                      variant='font16Bold'
                    >
                      %
                    </Typography>
                  </Box>
                </Box>
              </ConfigurationsContentContainer>
            </ConfigurationsContainer>
            <ConfigurationsContentContainer>
              <Typography variant='font16Bold'>Color</Typography>
              <Box
                alignItems='center'
                border={`1px solid ${palette.colors.grey[300]}`}
                display='flex'
                justifyContent='space-between'
                onClick={() => colorInputRef.current?.click()}
                padding='10px 16px'
              >
                <Typography color={palette.colors.black} variant='font14'>
                  Background Color
                </Typography>
                <ColorBox backgroundColor={background_color} />
              </Box>
              <ColorInput
                aria-label='Select image background color'
                onChange={e => {
                  setActiveComponentFields({
                    ...componentFields,
                    background_color: e.target.value,
                  });
                }}
                ref={colorInputRef}
                type='color'
                value={background_color}
              />
            </ConfigurationsContentContainer>
          </>
        )}
      </Box>
    </IntentEmailForm>
  );
};

export default IntentEmailImageForm;

const ImagePreview = styled('img')`
  max-width: 100%;
  max-height: 160px;
`;

const ConfigurationsContainer = styled('div')`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const ConfigurationsContentContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const ColorBox = styled('div')<{ backgroundColor: string }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: ${props => props.backgroundColor};
  border: 1px solid ${props => props.theme.palette.colors.grey[300]};
  cursor: pointer;
`;
