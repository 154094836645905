import { DashboardTableProps } from 'src/components/dashboard-table/DashboardTable';
import { TriageInsightsLabel } from 'src/services/triage/types';

export const MODEL_TABLE_COLUMNS: DashboardTableProps<TriageInsightsLabel>['columns'] =
  [
    {
      key: 'name',
      size: 300,
      title: 'Label',
    },
    {
      key: 'description',
      rows: 2,
      size: 400,
      title: 'Description',
    },
    {
      key: 'pending_count',
      size: 140,
      title: 'Pending',
      valueType: 'number',
    },
    {
      key: 'finalized_count',
      size: 200,
      title: 'Finalized tickets',
      valueType: 'number',
    },
    {
      key: 'correct_count',
      size: 200,
      title: 'Correct predictions',
      valueType: 'number',
    },
    {
      headerTooltip:
        "This indicates the accuracy of Triage's label predictions. The calculation formula is Correct Predictions/Finalized tickets x 100",
      key: 'accuracy',
      size: 200,
      title: 'Accuracy',
      valueType: 'percentage',
    },
  ];

export const INITIAL_LEGENDS = [
  'Predicted tickets',
  'Correct predictions',
  'Accuracy',
];
