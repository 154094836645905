import { Box, useTheme } from '@mui/material';

import {
  Checkbox,
  Table,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { numberWithCommas } from 'src/utils/analyticsUtils';

interface SimilarTopicSectionProps {
  neighboringTopics: DiscoverTopic[];
  selectedNeighboringTopicIds: string[];
  setSelectedNeighboringTopicIds: (values: string[]) => void;
}

const SimilarTopicSection = ({
  neighboringTopics,
  selectedNeighboringTopicIds,
  setSelectedNeighboringTopicIds,
}: SimilarTopicSectionProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { palette } = useTheme();

  if (neighboringTopics.length === 0) {
    return null;
  }

  const handleOnChange = (topicId: string, topicName: string) => {
    const isChecked = selectedNeighboringTopicIds.includes(topicId);
    if (isChecked) {
      setSelectedNeighboringTopicIds(
        selectedNeighboringTopicIds.filter(item => item !== topicId),
      );
    } else {
      setSelectedNeighboringTopicIds([...selectedNeighboringTopicIds, topicId]);
    }
    emitTrackingEventCallback(
      !isChecked
        ? 'discover-check-neighboring-topic-upon-automation'
        : 'discover-uncheck-neighboring-topic-upon-automation',
      {
        pathname: location.pathname,
        'topic-name': topicName,
      },
    );
  };

  const columns = [
    {
      cellRenderer: ({ topic_id, topic_name }: DiscoverTopic) => (
        <Box
          sx={{
            '& .MuiCheckbox-root': {
              padding: 0,
            },
            '& .MuiFormControlLabel-root': {
              margin: 0,
            },
          }}
        >
          <Checkbox
            aria-label='Select similar topic'
            checked={selectedNeighboringTopicIds.includes(topic_id)}
            onChange={() => handleOnChange(topic_id, topic_name)}
          />
        </Box>
      ),
      hideSortIcon: true,
      id: 'checkbox',
      label: '',
    },
    {
      cellRenderer: ({ topic_name }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          {topic_name}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'name',
      label: 'Similar Topic',
      minWidth: '250px',
    },
    {
      cellRenderer: ({ actionable_value }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          {numberWithCommas(actionable_value?.solve_workflow?.volume || 0) ?? 0}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'volume',
      label: 'Ticket Coverage / yr',
    },

    {
      cellRenderer: ({ actionable_value }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          ${numberWithCommas(actionable_value?.solve_workflow?.cost || 0) ?? 0}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'cost',
      label: 'Savings / yr',
    },
  ];

  return (
    <Box display='flex' flexDirection='column' mb='32px'>
      <Typography color={palette.colors.black} variant='font11'>
        Add more training phrases from the Topics below
      </Typography>
      <Box
        borderTop={`1px solid ${palette.colors.slate[200]}`}
        mt='10px'
        sx={{
          '& .MuiTableCell-head': {
            borderBottomColor: palette.colors.slate[200],
            color: palette.colors.slate[600],
            padding: '6px',
          },
        }}
      >
        <Table
          columns={columns}
          rows={neighboringTopics}
          tableRowStyles={topic => ({
            '& .MuiTableCell-head': {
              padding: '0px 0px 0px 8px',
            },
            '& .MuiTableCell-root': {
              '&:first-of-type': {
                borderRight: 0,
              },
              '&:last-child': {
                borderRight: 0,
              },
              bgcolor: selectedNeighboringTopicIds.includes(topic.topic_id)
                ? palette.colors.purple[100]
                : palette.colors.white,
              borderBottomColor: palette.colors.slate[200],
              borderRight: 1,
              borderRightColor: palette.colors.slate[200],
              padding: '4px 0px 4px 8px',
            },
          })}
        />
      </Box>
    </Box>
  );
};

export default SimilarTopicSection;
