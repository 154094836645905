import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitConfigForNonRTE } from '../hooks/useDebouncedSubmitConfig';
import { KnowledgeConfigurationPromptTextField } from 'src/components/knowledge-configuration-prompt-text-field';
import {
  selectWidgetConfiguration,
  setKnowledgeConfigurationPrompt,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

const InstructionTabContent = () => {
  const dispatch = useAppDispatch();
  const {
    knowledge_configuration_prompt: knowledgeConfigurationPrompt,
    ...rest
  } = useSelector(selectWidgetConfiguration);
  const debouncedSubmitConfig = useDebouncedSubmitConfigForNonRTE();

  return (
    <Box display='flex' flexDirection='column' gap={2} height='100%'>
      <Box alignItems='center' display='inline-flex'>
        <Typography variant='font14'>
          The <b>instructional note for Knowledge Retrieval</b> helps the AI
          deliver engaging, relevant, and personalized responses that address
          users&apos; unique needs, guiding the bot to create meaningful,
          tailored interactions. Summarize key points that will assist the bot
          in generating more personalized conversations and improving user
          experience.
        </Typography>
      </Box>
      <KnowledgeConfigurationPromptTextField
        onChange={(value: string) => {
          dispatch(setKnowledgeConfigurationPrompt(value));
          debouncedSubmitConfig(
            {
              ...rest,
              knowledge_configuration_prompt: value,
            },
            'knowledge_configuration_prompt',
            value,
          );
        }}
        value={knowledgeConfigurationPrompt}
      />
    </Box>
  );
};

export default InstructionTabContent;
