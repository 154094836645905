import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';
import { Box } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { emptyFieldTextErrorMessage } from '../../constants';
import { useGetIsUsingRawTemplate } from '../../hooks';
import IntentEmailForm from '../IntentEmailForm';
import ButtonActionSelector from './ButtonActionSelector';
import { BUTTON_CHAR_LIMIT, buttonTextErrorMessage } from './constants';
import {
  ButtonComponentFields,
  ToolbarFormComponentProps,
} from 'src/components/email-builder/types';
import ToggleButtonGroup from 'src/components/toggle-button-group';
import { getWorkflowValueFromSearch } from 'src/pages/workflow-builder-edit/autonomous-agent/helpers';
import { useEmailMode } from 'src/pages/workflow-builder-edit/hooks';
import { useGetWorkflowsForIntentQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { Routes } from 'src/utils/enums';

const IntentEmailButtonForm = ({
  component,
  onClickCancel,
  onClickSave,
  setActiveComponentFields,
  setIsActiveComponentValid,
}: ToolbarFormComponentProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { isLoading: isLoadingInteractiveEmailWorkflow, setEmailMode } =
    useEmailMode();

  const componentFields = component.component_fields as ButtonComponentFields;
  const intentDefinitionId = getWorkflowValueFromSearch(search, 'intentId');

  const isUsingDefaultTemplate = !useGetIsUsingRawTemplate();

  const {
    button_action_id,
    button_link,
    button_style = isUsingDefaultTemplate ? 'button' : 'link',
    button_text,
    button_type = 'link',
  } = componentFields;
  const isInteractiveEmailButton = button_type === 'interactive_email';
  const { data: workflowsForIntent } = useGetWorkflowsForIntentQuery(
    {
      intentDefinitionId,
    },
    {
      skip: !isInteractiveEmailButton || !intentDefinitionId,
    },
  );

  const validationSchema = yup.object().shape({
    button_action_id: yup.string().when('button_type', {
      is: 'action',
      then: yup.string().required(emptyFieldTextErrorMessage),
    }),
    button_link: yup.string().when('button_type', {
      is: 'link',
      then: yup
        .string()
        .trim()
        .min(1, 'Url is invalid')
        .required(emptyFieldTextErrorMessage)
        .test('url', 'Url is invalid', url => {
          try {
            new URL(url ?? '');
          } catch {
            return false;
          }
          return true;
        }),
    }),
    button_style: yup.string().required(emptyFieldTextErrorMessage),
    button_text: yup
      .string()
      .required(emptyFieldTextErrorMessage)
      .max(BUTTON_CHAR_LIMIT, buttonTextErrorMessage),
    button_type: yup.string().required(emptyFieldTextErrorMessage),
    interactive_email_workflow_id: yup.string().when('button_type', {
      is: 'interactive_email',
      then: yup.string().required(emptyFieldTextErrorMessage),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      button_action_id: button_action_id || '',
      button_link: button_link || '',
      button_style: button_style || 'button',
      button_text: button_text || '',
      button_type: button_type || 'link',
      interactive_email_workflow_id:
        workflowsForIntent?.interactive_email_workflow_id,
    },
    onSubmit: values => {
      onClickSave(values);
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
  });

  useEffect(() => {
    setIsActiveComponentValid(formik.isValid);
  }, [formik.isValid, formik.dirty, setIsActiveComponentValid]);

  useEffect(() => {
    if (
      !isInteractiveEmailButton ||
      !workflowsForIntent?.interactive_email_workflow_id
    ) {
      return;
    }

    setActiveComponentFields({
      interactive_email_workflow_id:
        workflowsForIntent.interactive_email_workflow_id,
    });
  }, [
    workflowsForIntent?.interactive_email_workflow_id,
    isInteractiveEmailButton,
    setActiveComponentFields,
  ]);

  return (
    <IntentEmailForm
      isSaveButtonDisabled={!formik.isValid}
      onClickCancel={onClickCancel}
      onClickSave={formik.handleSubmit}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        {isInteractiveEmailButton && !isLoadingInteractiveEmailWorkflow && (
          <Box>
            <Typography variant='font14'>
              By incorporating the &apos;Interactive&apos; button into your
              email template, you provide your customers with the option to
              communicate back and forth with a virtual assistant, in order to
              more effectively resolve their issue. Navigate to the
              <Box
                component='button'
                onClick={() => {
                  onClickSave();
                  setEmailMode('Interactive');
                }}
                sx={theme => ({
                  color: theme.palette.colors.purple[500],
                  cursor: 'pointer',
                  padding: 0,
                })}
              >
                &apos;Interactive&apos;
              </Box>
              tab to customize a chat-like interactive experience for this
              intent.
            </Typography>
          </Box>
        )}
        {isUsingDefaultTemplate && (
          <Box>
            <Box marginBottom='8px'>
              <Typography variant='font14Medium'>Button style</Typography>
            </Box>

            <Box>
              <ToggleButtonGroup
                alignment={formik.values.button_style}
                fullWidth
                items={[
                  { label: 'Themed', value: 'button' },
                  { label: 'Plain', value: 'link' },
                ]}
                onChange={buttonStyle => {
                  if (buttonStyle) {
                    setActiveComponentFields({
                      ...componentFields,
                      button_style: buttonStyle,
                    });
                    formik.setFieldValue('button_style', buttonStyle);
                  }
                }}
              />
            </Box>
          </Box>
        )}

        <Box>
          <TextField
            error={
              formik.touched.button_text && Boolean(formik.errors.button_text)
            }
            helperText={formik.touched.button_text && formik.errors.button_text}
            label='Button text'
            name='button_text'
            onBlur={formik.handleBlur}
            onChange={e => {
              setActiveComponentFields({
                ...componentFields,
                button_text: e.target.value,
              });
              formik.handleChange(e);
            }}
            onFocus={() => formik.setFieldTouched('button_text', true, true)}
            value={formik.values.button_text}
          />
        </Box>
        {!isInteractiveEmailButton && (
          <>
            <Box>
              <Box marginBottom='8px'>
                <Typography variant='font14Medium'>Button type</Typography>
              </Box>
              <Box>
                <ToggleButtonGroup
                  alignment={formik.values.button_type}
                  fullWidth
                  items={[
                    { label: 'Link', value: 'link' },
                    { label: 'Action', value: 'action' },
                  ]}
                  onChange={buttonType => {
                    if (buttonType) {
                      setActiveComponentFields({
                        ...componentFields,
                        button_type: buttonType,
                      });
                      formik.setFieldValue('button_type', buttonType);
                    }
                  }}
                />
              </Box>
            </Box>
            {formik.values.button_type === 'action' ? (
              <Box display='flex' flexDirection='column' gap='30px'>
                <Button
                  fullWidth
                  onClick={() => navigate(Routes.ACTION_BUILDER)}
                  variant='secondary'
                >
                  Create new action in Action Builder
                </Button>
                <ButtonActionSelector
                  selectedActionId={formik.values.button_action_id}
                  setSelectedAction={actionId => {
                    setActiveComponentFields({
                      ...componentFields,
                      button_action_id: actionId,
                    });
                    formik.setFieldValue('button_action_id', actionId);
                  }}
                />
              </Box>
            ) : (
              <TextField
                error={
                  formik.touched.button_link &&
                  Boolean(formik.errors.button_link)
                }
                helperText={
                  formik.touched.button_link && formik.errors.button_link
                }
                label='Button link'
                name='button_link'
                onBlur={formik.handleBlur}
                onChange={e => {
                  setActiveComponentFields({
                    ...componentFields,
                    button_link: e.target.value,
                  });
                  formik.handleChange(e);
                }}
                onFocus={() =>
                  formik.setFieldTouched('button_link', true, true)
                }
                value={formik.values.button_link as string}
              />
            )}
          </>
        )}
      </Box>
    </IntentEmailForm>
  );
};

export default IntentEmailButtonForm;
