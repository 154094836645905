import { FunctionComponent } from 'react';

import {
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_ACTION_BUILDER,
  ROUTE_ACTION_BUILDER_EDIT_V2,
  ROUTE_ANSWERS,
  ROUTE_ASSIST_AUTOMATIONS,
  ROUTE_ASSIST_AUTOMATIONS_CREATE,
  ROUTE_ASSIST_NOTES,
  ROUTE_AUTONOMOUS_AGENT_PREVIEW,
  ROUTE_CUSTOM_ANALYTICS,
  ROUTE_DISCOVER_AUTOMATION,
  ROUTE_DISCOVER_DASHBOARD,
  ROUTE_DISCOVER_TOPICS,
  ROUTE_INTEGRATIONS,
  ROUTE_IR_REPORT,
  ROUTE_LOG,
  ROUTE_LOOKER_ANALYTICS,
  ROUTE_MACRO_CONTROLS,
  ROUTE_PLAN_PAGE,
  ROUTE_PREDICT_INTENTS,
  ROUTE_PREDICT_INTENTS_TEST_DETAIL,
  ROUTE_PREDICTION_SETTINGS,
  ROUTE_PREDICTION_SETTINGS_DETAIL,
  ROUTE_PREDICTIONS,
  ROUTE_SEARCH,
  ROUTE_SECURITY,
  ROUTE_SOLVE,
  ROUTE_SOLVE_ANALYTICS_V2,
  ROUTE_SOLVE_CONFIGURATION,
  ROUTE_SOLVE_INSIGHTS_ARTICLE_DETAIL,
  ROUTE_SOLVE_INSIGHTS_MAIN,
  ROUTE_SOLVE_INSIGHTS_TOPIC_DETAIL,
  ROUTE_SOLVE_INSIGHTS_TOPIC_DETAIL_PARENT,
  ROUTE_SOLVE_INSIGHTS_WORKFLOW_DETAIL,
  ROUTE_SOLVE_LITE,
  ROUTE_SOLVE_PREVIEW,
  ROUTE_SSO_SETTINGS,
  ROUTE_TEAM,
  ROUTE_TOPIC_DETAIL,
  ROUTE_TRIAGE_INSIGHTS,
  ROUTE_TRIAGE_MODELS,
  ROUTE_TRIAGE_SIMULATIONS,
  ROUTE_TRIAGE_SIMULATIONS_DETAIL,
  ROUTE_TRIAGE_VERSION_CONTROL,
  ROUTE_WORKFLOW_BUILDER,
  ROUTE_WORKFLOW_BUILDER_EDIT,
  ROUTE_WORKFLOW_BUILDER_EDIT_EMAIL_PREVIEW,
  ROUTE_WORKFLOW_BUILDER_PREVIEW,
} from './routeDefinitions';
import AccountSettingsPage from 'src/components/dashboard-pages/account-settings-page';
import AnswersAnalyticsV1Page from 'src/components/dashboard-pages/answers-analytics-page/answersAnalyticsPageV1';
import AssistAutomationsCreatePage from 'src/components/dashboard-pages/assist-automations-create-page';
import AssistAutomationsPage from 'src/components/dashboard-pages/assist-automations-page';
import AssistNotesPage from 'src/components/dashboard-pages/assist-notes-page';
import DiscoverAllTopicsPage from 'src/components/dashboard-pages/discover-all-topics-page';
import DiscoverAutomationPage from 'src/components/dashboard-pages/discover-automation-page';
import DiscoverPage from 'src/components/dashboard-pages/discover-dashboard-page';
import TopicDetailPage from 'src/components/dashboard-pages/discover-topic-detail-page';
import EmbeddableDashboardsPage from 'src/components/dashboard-pages/embeddable-dashboards';
import LookerDashboardPage from 'src/components/dashboard-pages/embeddable-dashboards/LookerDashboard';
import HomePage from 'src/components/dashboard-pages/home-page';
import IntegrationsPage from 'src/components/dashboard-pages/integrations-page';
import IRreport from 'src/components/dashboard-pages/ir-report';
import PredictionsPage from 'src/components/dashboard-pages/predictions-page/predictionsPage';
import SearchAnalyticsPage from 'src/components/dashboard-pages/search-analytics-page/searchAnalyticsPage';
import SolveAnalyticsEmbedPage from 'src/components/dashboard-pages/solve-analytics-embed-page';
import SolveInsightsMain from 'src/components/dashboard-pages/solve-insights/SolveInsightsMain';
import SolveInsightsArticleDetail from 'src/components/dashboard-pages/solve-insights-article-detail';
import SolveInsightsTopicDetail from 'src/components/dashboard-pages/solve-insights-topic-detail';
import { SolveInsightsWorkflowDetail } from 'src/components/dashboard-pages/solve-insights-workflow-detail';
import SSOSettingsPage from 'src/components/dashboard-pages/sso-settings-page';
import TeamSettingsPage from 'src/components/dashboard-pages/team-settings-page';
import TriageConfigDetailPage from 'src/components/dashboard-pages/triage-config-detail-page';
import TriageInsightsPage from 'src/components/dashboard-pages/triage-insights-page';
import TriageModelDetailPage from 'src/components/dashboard-pages/triage-model-detail-page';
import TriageModelsOverviewPage from 'src/components/dashboard-pages/triage-models-overview-page';
import TriageSimulationsDetailPage from 'src/components/dashboard-pages/triage-simulations-detail-page';
import TriageSimulationsPage from 'src/components/dashboard-pages/triage-simulations-page';
import TriageVersionControlDetail from 'src/components/dashboard-pages/triage-version-control-detail';
import ActionBuilderPage from 'src/pages/action-builder';
import ActionBuilderDetailPage from 'src/pages/action-builder-detail/ActionBuilderDetailPage';
import AutonomousAgentPreview from 'src/pages/autonomous-agent-preview';
import BuilderParentPage from 'src/pages/builder-parent-page';
import EventsPage from 'src/pages/events/EventsPage';
import SubscriptionPlanPage from 'src/pages/plan/SubscriptionPlanPage';
import IntentTestDetailPage from 'src/pages/predict-intents/IntentTestDetailPage';
import PredictIntentsPage from 'src/pages/predict-intents/PredictIntentsPage';
import PIIRedactionSettingsPage from 'src/pages/security/PIIRedactionSettingsPage';
import SolveAnalyticsPageV2 from 'src/pages/solve-analytics';
import SolveConfigurationPage from 'src/pages/solve-config';
import SolvePreviewPage from 'src/pages/solve-config/preview/preview';
import ParentLandingPage from 'src/pages/workflow-builder/landing-page/ParentLandingPage';
import EmailPreviewPage from 'src/pages/workflow-builder-edit/email-preview-page';
import { WorkflowPreviewPage } from 'src/pages/workflow-builder-preview/workflowPreviewPage';
import { Routes } from 'src/utils/enums';

export interface RouteConfig {
  component: FunctionComponent<React.PropsWithChildren<any>>;
  featureFlags?: string[];
  ignorePermissionForSuperAdmin?: boolean;
  path: `${Routes}`;
  permissionKey?: string;
  roles?: Set<string>;
  showNavBar?: boolean;
  showSideBar?: boolean;
}

// When creating the routes, ORDER MATTERS
const routes: RouteConfig[] = [
  {
    component: HomePage,
    path: Routes.HOMEPAGE,
  },
  {
    ...ROUTE_DISCOVER_DASHBOARD,
    component: DiscoverPage,
  },
  { ...ROUTE_IR_REPORT, component: IRreport, showNavBar: false },
  {
    ...ROUTE_DISCOVER_AUTOMATION,
    component: DiscoverAutomationPage,
  },
  { ...ROUTE_DISCOVER_TOPICS, component: DiscoverAllTopicsPage },
  {
    ...ROUTE_TOPIC_DETAIL,
    component: TopicDetailPage,
  },
  { ...ROUTE_ASSIST_NOTES, component: AssistNotesPage },
  {
    ...ROUTE_ANSWERS,
    component: AnswersAnalyticsV1Page,
  },
  {
    ...ROUTE_SEARCH,
    component: SearchAnalyticsPage,
  },
  {
    ...ROUTE_SOLVE,
    component: SolveAnalyticsPageV2,
  },
  {
    ...ROUTE_SOLVE_LITE,
    component: SolveConfigurationPage,
  },
  {
    ...ROUTE_SOLVE_PREVIEW,
    component: SolvePreviewPage,
  },
  {
    ...ROUTE_SOLVE_ANALYTICS_V2,
    component: SolveAnalyticsEmbedPage,
  },
  {
    ...ROUTE_MACRO_CONTROLS,
    component: SolveAnalyticsPageV2,
  },
  {
    ...ROUTE_WORKFLOW_BUILDER,
    component: ParentLandingPage,
  },
  {
    ...ROUTE_PREDICT_INTENTS,
    component: PredictIntentsPage,
  },
  {
    ...ROUTE_PREDICT_INTENTS_TEST_DETAIL,
    component: IntentTestDetailPage,
    showNavBar: false,
  },
  {
    ...ROUTE_SOLVE_CONFIGURATION,
    component: SolveConfigurationPage,
  },
  {
    ...ROUTE_AUTONOMOUS_AGENT_PREVIEW,
    component: AutonomousAgentPreview,
    showNavBar: false,
  },
  {
    ...ROUTE_WORKFLOW_BUILDER_PREVIEW,
    component: WorkflowPreviewPage,
    showNavBar: false,
  },
  {
    ...ROUTE_WORKFLOW_BUILDER_EDIT,
    component: BuilderParentPage,
  },
  {
    ...ROUTE_WORKFLOW_BUILDER_EDIT_EMAIL_PREVIEW,
    component: EmailPreviewPage,
    showNavBar: false,
  },

  {
    ...ROUTE_ACTION_BUILDER,
    component: ActionBuilderPage,
  },
  {
    ...ROUTE_ACTION_BUILDER_EDIT_V2,
    component: ActionBuilderDetailPage,
    showNavBar: false,
  },
  {
    ...ROUTE_ASSIST_AUTOMATIONS,
    component: AssistAutomationsPage,
  },
  {
    ...ROUTE_ASSIST_AUTOMATIONS_CREATE,
    component: AssistAutomationsCreatePage,
  },
  {
    ...ROUTE_PREDICTIONS,
    component: PredictionsPage,
  },
  {
    ...ROUTE_PREDICTION_SETTINGS,
    component: TriageModelsOverviewPage,
    showNavBar: false,
  },
  {
    ...ROUTE_PLAN_PAGE,
    component: SubscriptionPlanPage,
  },
  {
    ...ROUTE_PREDICTION_SETTINGS_DETAIL,
    component: TriageConfigDetailPage,
  },
  {
    ...ROUTE_ACCOUNT_SETTINGS,
    component: AccountSettingsPage,
  },
  {
    ...ROUTE_INTEGRATIONS,
    component: IntegrationsPage,
  },
  {
    ...ROUTE_TEAM,
    component: TeamSettingsPage,
  },
  {
    ...ROUTE_LOG,
    component: EventsPage,
  },
  {
    ...ROUTE_SSO_SETTINGS,
    component: SSOSettingsPage,
  },
  {
    ...ROUTE_CUSTOM_ANALYTICS,
    component: EmbeddableDashboardsPage,
  },
  {
    ...ROUTE_SECURITY,
    component: PIIRedactionSettingsPage,
  },
  {
    ...ROUTE_LOOKER_ANALYTICS,
    component: LookerDashboardPage,
    showNavBar: false,
  },
  { ...ROUTE_SOLVE_INSIGHTS_MAIN, component: SolveInsightsMain },
  {
    ...ROUTE_SOLVE_INSIGHTS_TOPIC_DETAIL,
    component: SolveInsightsTopicDetail,
    showNavBar: false,
  },
  {
    ...ROUTE_SOLVE_INSIGHTS_TOPIC_DETAIL_PARENT,
    component: SolveInsightsTopicDetail,
    showNavBar: false,
  },
  {
    ...ROUTE_SOLVE_INSIGHTS_ARTICLE_DETAIL,
    component: SolveInsightsArticleDetail,
    showNavBar: false,
  },
  {
    ...ROUTE_TRIAGE_MODELS,
    component: TriageModelDetailPage,
    showNavBar: false,
  },
  {
    ...ROUTE_TRIAGE_VERSION_CONTROL,
    component: TriageVersionControlDetail,
    showNavBar: false,
  },
  {
    ...ROUTE_TRIAGE_SIMULATIONS,
    component: TriageSimulationsPage,
    showNavBar: false,
  },
  {
    ...ROUTE_TRIAGE_SIMULATIONS_DETAIL,
    component: TriageSimulationsDetailPage,
    showNavBar: false,
  },
  {
    ...ROUTE_SOLVE_INSIGHTS_WORKFLOW_DETAIL,
    component: SolveInsightsWorkflowDetail,
    showNavBar: false,
  },
  {
    ...ROUTE_TRIAGE_INSIGHTS,
    component: TriageInsightsPage,
  },
];

export default routes;
