import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import { Box, styled, useTheme } from '@mui/material';
import { IconStar } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { CustomImage } from '../styledComponents';
import logoPlaceholderIcon from 'src/assets/images/logo-placeholder.svg';
import poweredByFtImg from 'src/assets/images/powered-by-forethought.svg';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

interface ExpandedWidgetProps {
  children?: ReactNode;
}

const ExpandedWidget = ({ children }: ExpandedWidgetProps) => {
  const { palette } = useTheme();
  const fontColor = useSelector(selectLegibleTextColor);
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const {
    free_form_intent_detection_enabled: freeFormIntentDetectionEnabled,
    header_image: headerImage,
    tab_name: tabName,
    theme_color: themeColor,
  } = widgetConfiguration;

  const { show_on_header: showOnHeader } = widgetConfiguration.csat_config;
  const isFontColorLegibleOnSelection =
    tinycolor.readability(fontColor, '#BED6FB') >= 4.5;
  const fontColorToUse = isFontColorLegibleOnSelection
    ? fontColor
    : palette.text.primary;

  return (
    <Container
      sx={{
        '.MuiTypography-root': {
          '&::selection': {
            color: fontColorToUse,
          },
        },
      }}
    >
      {/* Header */}
      <HeaderContainer backgroundColor={themeColor}>
        <Box
          alignItems='center'
          display='flex'
          gap={1}
          height='64px'
          width='100%'
        >
          <CustomImage
            alt='Logo'
            src={headerImage ? headerImage : logoPlaceholderIcon}
          />
          <Box>
            <Typography color={fontColor} noWrap variant='font14Bold'>
              {tabName}
            </Typography>
          </Box>
          {showOnHeader && (
            <Box height='24px' ml='auto'>
              <IconStar color={fontColor} size={24} />
            </Box>
          )}
        </Box>
      </HeaderContainer>
      {/* Content */}
      <Box flex={1} overflow='scroll'>
        {children}
      </Box>
      {/* Footer */}
      {freeFormIntentDetectionEnabled && (
        <Box
          display='flex'
          flexDirection='column'
          gap='12px'
          paddingBottom='12px'
        >
          <MessageContainer>
            <Typography color={palette.colors.grey[400]} variant='font14Medium'>
              Type here
            </Typography>
          </MessageContainer>
          <PoweredByForethoughtImg
            alt='Powered by Forethought'
            src={poweredByFtImg}
          />
        </Box>
      )}
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 588px;
  border-radius: 12px;
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.05);
  background-color: ${props => props.theme.palette.colors.white};
  // 146px is the height of the header + paddings for the static widget
  position: relative;
  flex: 1;
  overflow: hidden;

  @media screen and (max-width: 1430px) {
    width: 488px;
  }
`;

const HeaderContainer = styled('div')<{ backgroundColor: string }>`
  display: flex;
  border-radius: 12px 12px 0px 0px;
  padding: 0px 16px;
  background-color: ${props => props.backgroundColor};
`;

const PoweredByForethoughtImg = styled('img')`
  width: 160px;
  height: 16px;
  align-self: center;
`;

const MessageContainer = styled('div')`
  padding: 14px 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  border-color: ${props => props.theme.palette.colors.grey[400]};
  margin: 0px 16px;
  height: 48px;
  display: flex;
`;

export default ExpandedWidget;
