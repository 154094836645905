import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CVItem } from '../../../../autonomous-agent/action-drawer/CVItem';
import { ToolItem } from '../../../../autonomous-agent/action-drawer/ToolItem';
import { GenericToolList } from '../../../../autonomous-agent/components/GenericToolList';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetAvailableToolsQuery } from 'src/services/email-autopilot/emailAutopilotApi';
import {
  useContextVariableToggle,
  useToolDefinitionToggle,
} from 'src/services/email-autopilot/hooks';
import { AutopilotWorkflowModel } from 'src/services/email-autopilot/types';
import { isToolSelected } from 'src/services/email-autopilot/utils';
import { setGlobalWorkflowBuilderOptions } from 'src/slices/ui/uiSlice';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

const useGetCVS = (
  contextVariablesInUse: string[],
): (ContextVariable & { isActive: boolean })[] => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeDynamicListContextVariables: false,
    shouldIncludeSystemContextVariables: false,
    shouldIncludeWorkflowTagContextVariable: false,
  });

  const cvs = useMemo(() => {
    // filter out conversation language
    const filteredWorkflowCvs = contextVariables.filter(
      cv => cv.context_variable_name !== 'Conversation Language',
    );
    return filteredWorkflowCvs.map(workflowCv => ({
      ...workflowCv,
      isActive: Boolean(
        contextVariablesInUse?.find(
          cvInUse => cvInUse === workflowCv.context_variable_id,
        ),
      ),
    }));
  }, [contextVariables, contextVariablesInUse]);

  return cvs;
};

interface AutopilotActionDrawerProps {
  autopilotWorkflow: AutopilotWorkflowModel;
  contextVariablesInUse: string[];
}

export const AutopilotActionDrawer = ({
  autopilotWorkflow,
  contextVariablesInUse,
}: AutopilotActionDrawerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: tools = [] } = useGetAvailableToolsQuery();
  const contextVariables = useGetCVS(contextVariablesInUse);
  const [searchActionsText, setSearchActionsText] = useState('');
  const [searchCvsText, setSearchCvsText] = useState('');
  const toggleTool = useToolDefinitionToggle(autopilotWorkflow);
  const toggleCv = useContextVariableToggle(autopilotWorkflow);

  const filteredTools = useMemo(() => {
    const toolsWithIsActive = tools.map(tool => ({
      ...tool,
      isActive: isToolSelected(autopilotWorkflow, tool.tool_id),
    }));

    return toolsWithIsActive.filter(tool =>
      tool.tool_name.toLowerCase().includes(searchActionsText.toLowerCase()),
    );
  }, [tools, searchActionsText, autopilotWorkflow]);

  // Filter context variables based on search
  const filteredCvs = contextVariables.filter(cv =>
    cv.context_variable_name
      .toLowerCase()
      .includes(searchCvsText.toLowerCase()),
  );

  const handleToolClick = (tool: AutoflowTool & { isActive: boolean }) => {
    toggleTool(tool.tool_id, tool.tool_type, tool.isActive);
  };

  const handleCvClick = (cv: ContextVariable & { isActive: boolean }) => {
    toggleCv(cv.context_variable_id, cv.isActive);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <GenericToolList
        actionButton={
          <Button
            fullWidth
            onClick={() => {
              navigate(Routes.ACTION_BUILDER_EDIT_V2);
            }}
            startIcon={<IconPlus />}
            variant='secondary'
          >
            <Typography variant='font14Bold'>Create new Action</Typography>
          </Button>
        }
        description='Select actions to apply'
        idKey='tool_id'
        items={filteredTools}
        onSearchChange={setSearchActionsText}
        renderItem={tool => (
          <ToolItem
            key={tool.tool_id}
            onClickTool={handleToolClick}
            tool={tool}
          />
        )}
        searchValue={searchActionsText}
        title='Actions'
      />

      <GenericToolList
        actionButton={
          <Button
            fullWidth
            onClick={() => {
              dispatch(
                setGlobalWorkflowBuilderOptions({ isCvDrawerOpen: true }),
              );
            }}
            startIcon={<IconPlus />}
            variant='secondary'
          >
            <Typography variant='font14Bold'>Create new CV</Typography>
          </Button>
        }
        description='Select context variables to apply'
        idKey='context_variable_id'
        items={filteredCvs}
        onSearchChange={setSearchCvsText}
        renderItem={cv => (
          <CVItem
            cv={cv}
            key={cv.context_variable_id}
            onClick={() => handleCvClick(cv)}
          />
        )}
        searchValue={searchCvsText}
        title='Context Variables'
      />
    </Box>
  );
};

export default AutopilotActionDrawer;
