export const INITIAL_EXPRESSION = {
  booleanOperator: 'and',
  expression: {
    expression_type: 'filter',
    field: '',
    negate: false,
    operator: '=',
    values: [],
  },
};
