import { styled } from '@mui/material/styles';
import { IconThumbUp } from '@tabler/icons-react';

import { useGetIsUsingRawTemplate } from '../hooks';
import {
  FeedbackComponentFields,
  IdleCanvasComponentProps,
} from 'src/components/email-builder/types';

export default function IntentEmailFeedbackButtons({
  component,
}: IdleCanvasComponentProps) {
  const { heading_text, helpful_label, not_helpful_label } =
    component.component_fields as FeedbackComponentFields;

  const shouldApplyRawFeedbackComponent = useGetIsUsingRawTemplate();

  const feedbackComponent = (
    <Container>
      <span>{heading_text || 'Was this email helpful?'}</span>
      <ButtonsContainer>
        <Button>
          <IconThumbUp /> {helpful_label || 'Yes!'}
        </Button>
        <Button>
          <IconThumbUp style={{ transform: 'rotate(180deg)' }} />
          {not_helpful_label || 'Not really'}
        </Button>
      </ButtonsContainer>
    </Container>
  );

  const rawFeedbackComponent = (
    <RawFeedbackButtonContainer>
      <span>{heading_text || 'Was this email helpful?'}</span>
      <RawButtonsContainer>
        <RawLink>{helpful_label || 'Yes!'}</RawLink>
        <RawLink> {not_helpful_label || 'Not really'}</RawLink>
      </RawButtonsContainer>
    </RawFeedbackButtonContainer>
  );

  if (shouldApplyRawFeedbackComponent) {
    return rawFeedbackComponent;
  }

  return feedbackComponent;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  text-align: center;
  justify-content: center;
`;

const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const Button = styled('button')`
  border: 1px solid ${props => props.theme.palette.colors.grey[700]};
  border-radius: 54px;
  color: ${props => props.theme.palette.colors.grey[700]};
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
  min-width: 140px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RawFeedbackButtonContainer = styled('div')`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const RawButtonsContainer = styled('div')`
  gap: 3px;
  display: flex;
`;

const RawLink = styled('span')`
  text-decoration: underline;
  color: #1251ba;
`;
