import { Box, styled, useTheme } from '@mui/material';

import { EmbedTranscriptComponent } from './types';

export const EmbedStep = ({
  transcriptComponent,
}: {
  transcriptComponent: EmbedTranscriptComponent;
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Box
        sx={{
          aspectRatio: '16 / 9',
        }}
      >
        <Box
          allowFullScreen
          component='iframe'
          name='Forethought Embed Step'
          referrerPolicy='no-referrer'
          src={transcriptComponent.embed_url}
          sx={{
            border: `1px solid ${theme.palette.colors.slate[200]}`,
            borderRadius: '8px',
            height: '100%',
            width: '100%',
          }}
          title='Forethought Embed Step'
        />
      </Box>
    </Container>
  );
};

export const Container = styled('div')`
  background-color: transparent;
  padding: 16px 16px 16px 0;
`;
