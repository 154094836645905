import { ChangeEvent } from 'react';

import { FieldOption } from '../expression-builder/ExpressionBuilder';
import { ContextVariableFilterItemValue } from './types';
import { Option } from 'node_modules/@forethought-technologies/forethought-elements/dist/Components/MultiSelectFilter/types';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';

// Helpers - Others Filter
export const flattenOptions = (options: Option[]): Option[] => {
  return options.flatMap(option =>
    option.options ? flattenOptions(option.options) : option,
  );
};
export const handleCheckboxClick = ({
  e,
  expandedValues,
  item,
  onChange,
  setExpandedValues,
  setIsCheckboxClicked,
  value,
}: {
  e: ChangeEvent<HTMLInputElement>;
  expandedValues: string[];
  item: Option;
  onChange: (value: string[]) => void;
  setExpandedValues: (value: string[]) => void;
  setIsCheckboxClicked: (value: boolean) => void;
  value: string[];
}) => {
  e.stopPropagation();
  setIsCheckboxClicked(true);

  const hasOptions = Boolean(item.options);
  const itemOptionValues = flattenOptions(item.options ?? [])
    .filter(child => !child.options?.length)
    .map(child => child.value);
  const isChecked = hasOptions
    ? itemOptionValues.every(option => value.includes(option))
    : value.includes(item.value);

  // Condition 1: If unchecked and not expanded, expand
  if (!isChecked && hasOptions && !expandedValues.includes(item.value)) {
    setExpandedValues([...expandedValues, item.value]);
  }

  if (hasOptions) {
    if (isChecked) {
      onChange(value.filter(v => !itemOptionValues.includes(v)));
    } else {
      onChange([...value, ...itemOptionValues.filter(v => !value.includes(v))]);
    }
  } else {
    if (isChecked) {
      onChange(value.filter(v => v !== item.value));
    } else {
      onChange([...value, item.value]);
    }
  }
};
export const handleMenuItemClick = ({
  expandedValues,
  isCheckboxClicked,
  item,
  onChange,
  setExpandedValues,
  setIsCheckboxClicked,
  value,
}: {
  expandedValues: string[];
  isCheckboxClicked: boolean;
  item: Option;
  onChange: (value: string[]) => void;
  setExpandedValues: (value: string[]) => void;
  setIsCheckboxClicked: (value: boolean) => void;
  value: string[];
}) => {
  if (!isCheckboxClicked) {
    if (item.options?.length) {
      // Expand/collapse logic for items with children
      if (expandedValues.includes(item.value)) {
        setExpandedValues(expandedValues.filter(v => v !== item.value));
      } else {
        setExpandedValues([...expandedValues, item.value]);
      }
    } else {
      // Toggle select/deselect for item with no children
      if (value.includes(item.value)) {
        onChange(value.filter(v => v !== item.value));
      } else {
        onChange([...value, item.value]);
      }
    }
  } else {
    // If checkbox is clicked and item has no children, deselect it
    if (!item.options?.length) {
      onChange(value.filter(v => v !== item.value));
    }
  }

  setIsCheckboxClicked(false);
};
// Helpers - Context Variable Filter
export const addDefaultField = (
  fieldOptions: FieldOption[],
  expression: ContextVariableFilterItemValue['expression'],
) => {
  if (expression.field) {
    return expression;
  }
  const firstFieldOptionItem = fieldOptions[0];
  return {
    ...expression,
    field: firstFieldOptionItem.value,
  };
};
export const getListTypeFieldOptions = (
  field: string,
  fieldOptions: FieldOption[],
) => fieldOptions.find(option => option.value === field)?.fieldOptions || [];
export const getQueryExpressionFieldType = (
  field: string | undefined,
  fieldOptions: FieldOption[],
) => {
  return fieldOptions.find(data => data.value === field)
    ?.type as QueryExpressionTypes;
};
export const updateArrayItem = (
  array: ContextVariableFilterItemValue[],
  index: number,
  newItem: Partial<ContextVariableFilterItemValue['expression']>,
) => {
  return array.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        expression: {
          ...item.expression,
          ...validatedValues(newItem),
        },
      };
    }
    return item;
  });
};
const validatedValues = (
  expression: Partial<ContextVariableFilterItemValue['expression']>,
) => {
  if (expression.operator !== 'empty') {
    return expression;
  }
  return {
    ...expression,
    values: [],
  };
};

export const listCVObjectDeserialize = (
  target: string,
): ContextVariableFilterItemValue[] => {
  return JSON.parse(target) as ContextVariableFilterItemValue[];
};
export const listCVObjectSerialize = (
  state: ContextVariableFilterItemValue[],
) => JSON.stringify(state);

export const filterSupportedContextVariables = (
  contextVariables: ContextVariable[],
) => {
  return contextVariables
    .map(cv => ({
      category: 'context_variable',
      fieldOptions: [],
      label: cv.context_variable_name,
      type: cv.context_variable_type as 'SHORT_TEXT' | 'LIST',
      value: cv.context_variable_id,
    }))
    .filter(
      cv =>
        cv.value !== 'article_ids' &&
        cv.value !== 'free_form_query' &&
        cv.value !== 'conversation_id' &&
        // Workflow tag value is UUID so filtering by label
        cv.label !== 'Workflow Tag',
    );
};
