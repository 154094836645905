import Box from '@mui/material/Box';

import OverviewMetricTitle from './OverviewMetricTitle';
import OverviewMetricValue, {
  OverviewMetricValueProps,
} from './OverviewMetricValue';

export interface OverviewMetricHeaderItemProps
  extends OverviewMetricValueProps {
  title: string;
  tooltip?: string | null;
}

const OverviewMetricsHeaderItem = ({
  title,
  tooltip,
  ...valueProps
}: OverviewMetricHeaderItemProps) => {
  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <OverviewMetricTitle title={title} tooltip={tooltip} />
      <OverviewMetricValue {...valueProps} />
    </Box>
  );
};

export default OverviewMetricsHeaderItem;
