import { useNavigate } from 'react-router-dom';

import { Button } from '@forethought-technologies/forethought-elements';
import { getTriageModelsPageUrl } from '../../triage-models-overview-page/helpers';
import { MODEL_TABLE_COLUMNS } from './constants';
import ModelSelect, { ModelSelectProps } from './ModelSelect';
import DashboardTable from 'src/components/dashboard-table';
import { useStateParams } from 'src/hooks/hooks';
import {
  TriageInsightsLabel,
  TriageInsightsModelOverviewResponse,
} from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

interface TableSectionProps extends ModelSelectProps {
  isFetching: boolean;
  isLoading: boolean;
  labels: TriageInsightsModelOverviewResponse['labels'];
  onDateRangeChange: (dateRange: DateRange) => void;
  selectedDateRange: DateRange;
}

const TableSection = ({
  isFetching,
  isLoading,
  labels,
  models,
  modelsLoading,
  onDateRangeChange,
  onModelChange,
  selectedDateRange,
  selectedModel,
}: TableSectionProps) => {
  const [searchText, setSearchText] = useStateParams({
    deserialize: value => value,
    initialState: '',
    paramsName: 'insight-model-search-text',
    serialize: value => value,
  });
  const navigate = useNavigate();

  const handleViewModel = () => {
    if (selectedModel) {
      navigate(getTriageModelsPageUrl(selectedModel));
    }
  };

  return (
    <DashboardTable<TriageInsightsLabel>
      actionColumnSize={160}
      columns={MODEL_TABLE_COLUMNS}
      data={labels || []}
      dateRangeFilter={{
        initialValue: selectedDateRange,
        onChange: onDateRangeChange,
        value: selectedDateRange,
      }}
      FilterComponent={
        <ModelSelect
          models={models}
          modelsLoading={modelsLoading}
          onModelChange={onModelChange}
          selectedModel={selectedModel}
        />
      }
      isFetching={isFetching}
      isLoading={isLoading}
      onSearch={setSearchText}
      RowAction={() => {
        return (
          <Button onClick={handleViewModel} variant='ghost'>
            View model
          </Button>
        );
      }}
      searchFields={['name', 'description']}
      searchText={searchText}
      stickyHeaderHeight={209}
    />
  );
};

export default TableSection;
