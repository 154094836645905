import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';

import { SettingsSlice } from './types';

export const initialState: SettingsSlice = {
  pusherSessionId: uuidv4(),
};

const settingsSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {},
});

export default settingsSlice.reducer;
