import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';

const EmptyState = () => {
  return (
    <Box>
      <Typography variant='font14'>No data available.</Typography>
    </Box>
  );
};

export default EmptyState;
