import { IconArticle, IconCirclePlus, IconMessage } from '@tabler/icons-react';

import { theme } from '@forethought-technologies/forethought-elements';
import GenAiIcon from './components/GenAiIcon';
import IntentEmailArticlesTab from './components/intent-email-articles-tab';
import IntentEmailButtonForm from './components/intent-email-button-form';
import IntentEmailFeedbackForm from './components/intent-email-feedback-form/IntentEmailFeedbackForm';
import IntentEmailHtmlForm from './components/intent-email-html-form/IntentEmailHtmlForm';
import { IntentEmailMacrosTab } from './components/intent-email-macros-tab/IntentEmailMacrosTab';
import IntentEmailTextForm from './components/intent-email-text-form/IntentEmailTextForm';
import IntentEmailArticle from './components/IntentEmailArticle';
import IntentEmailAutopilotComponent from './components/IntentEmailAutopilotComponent';
import IntentEmailButton from './components/IntentEmailButton';
import IntentEmailDynamicArticleForm from './components/IntentEmailDynamicArticleForm';
import IntentEmailDynamicArticleIdle from './components/IntentEmailDynamicArticleIdle';
import IntentEmailFeedbackButtons from './components/IntentEmailFeedbackButtons';
import IntentEmailHtmlIdle from './components/IntentEmailHtmlIdle';
import IntentEmailImageActive from './components/IntentEmailImageActive';
import IntentEmailImageForm from './components/IntentEmailImageForm';
import IntentEmailImageIdle from './components/IntentEmailImageIdle';
import { IntentEmailMacroActive } from './components/IntentEmailMacroActive';
import { IntentEmailMacroForm } from './components/IntentEmailMacroForm';
import { IntentEmailMacroIdle } from './components/IntentEmailMacroIdle';
import IntentEmailTextActive from './components/IntentEmailTextActive';
import IntentEmailTextIdle from './components/IntentEmailTextIdle';
import IntentEmailToolsTab from './components/IntentEmailToolsTab';
import { generateTemporaryComponent } from './utils';
import CodeSrc from 'src/assets/images/code.svg';
import buttonIconSrc from 'src/assets/images/email-builder-button.svg';
import imageIconSrc from 'src/assets/images/email-builder-image.svg';
import interactiveButtonIconSrc from 'src/assets/images/email-builder-interactive-email-button.svg';
import textIconSrc from 'src/assets/images/email-builder-text.svg';
import thumbIconSrc from 'src/assets/images/email-builder-thumb.svg';
import {
  EmailBuilderConfiguration,
  EmailBuilderConfigurationKey,
  EmailBuilderTab,
  ImageFields,
} from 'src/components/email-builder/types';

export const emptyFieldTextErrorMessage = 'Field cannot be empty';

const buttonComponentDefaultFields = {
  button_link: '',
  button_text: '',
};

const interactiveEmailButtonComponentDefaultFields = {
  button_link: '',
  button_text: 'Chat with our virtual assistant',
  button_type: 'interactive_email',
  interactive_email_workflow_id: '',
};

const textComponentDefaultFields = { message: '' };

const htmlComponentDefaultFields = {
  content: '<strong>Customize your email content!</strong>',
};

const imageComponentDefaultFields: ImageFields = {
  alignment: 'center',
  background_color: theme.palette.colors.white,
  image_file_name: '',
  width: 100,
};

export const INTENT_EMAIL_BUILDER_CONFIG: EmailBuilderConfiguration = {
  autopilot: {
    IdleCanvasComponent: IntentEmailAutopilotComponent,
    name: 'Autopilot',
  },
  button: {
    ActiveCanvasComponent: IntentEmailButton,
    iconSrc: buttonIconSrc,
    IdleCanvasComponent: IntentEmailButton,
    name: 'Button',
    temporaryComponentGenerator: () =>
      generateTemporaryComponent({
        componentFields: buttonComponentDefaultFields,
        componentType: 'button',
      }),
    ToolbarFormComponent: IntentEmailButtonForm,
  },
  dynamic_article: {
    IdleCanvasComponent: IntentEmailDynamicArticleIdle,
    name: 'Dynamic article suggestion',
    temporaryComponentGenerator: ({ componentFields }) => {
      return generateTemporaryComponent({
        componentFields,
        componentType: 'dynamic_article',
      });
    },
    ToolbarFormComponent: IntentEmailDynamicArticleForm,
  },
  feedback: {
    iconSrc: thumbIconSrc,
    IdleCanvasComponent: IntentEmailFeedbackButtons,
    name: 'Feedback',
    temporaryComponentGenerator: () => {
      return generateTemporaryComponent({
        componentFields: {
          heading_text: 'Was this email helpful?',
          helpful_label: 'Yes!',
          not_helpful_label: 'Not really',
        },
        componentType: 'feedback',
      });
    },
    ToolbarFormComponent: IntentEmailFeedbackForm,
  },
  html: {
    ActiveCanvasComponent: IntentEmailHtmlIdle,
    iconSrc: CodeSrc,
    IdleCanvasComponent: IntentEmailHtmlIdle,
    name: 'HTML',
    temporaryComponentGenerator: () =>
      generateTemporaryComponent({
        componentFields: htmlComponentDefaultFields,
        componentType: 'html',
      }),
    ToolbarFormComponent: IntentEmailHtmlForm,
  },
  image: {
    ActiveCanvasComponent: IntentEmailImageActive,
    iconSrc: imageIconSrc,
    IdleCanvasComponent: IntentEmailImageIdle,
    name: 'Image',
    temporaryComponentGenerator: () => {
      return generateTemporaryComponent({
        componentFields: imageComponentDefaultFields,
        componentType: 'image',
      });
    },
    ToolbarFormComponent: IntentEmailImageForm,
  },
  interactive_email_button: {
    ActiveCanvasComponent: IntentEmailButton,
    iconSrc: interactiveButtonIconSrc,
    IdleCanvasComponent: IntentEmailButton,
    name: 'Interactive',
    temporaryComponentGenerator: () =>
      generateTemporaryComponent({
        componentFields: interactiveEmailButtonComponentDefaultFields,
        componentType: 'interactive_email_button',
      }),
    ToolbarFormComponent: IntentEmailButtonForm,
  },
  macro: {
    ActiveCanvasComponent: IntentEmailMacroActive,
    IdleCanvasComponent: IntentEmailMacroIdle,
    name: 'Macro',
    temporaryComponentGenerator: ({ componentFields, displayFields }) =>
      generateTemporaryComponent({
        componentFields,
        componentType: 'macro',
        displayFields,
      }),
    ToolbarFormComponent: IntentEmailMacroForm,
  },
  static_article: {
    ActiveCanvasComponent: IntentEmailArticle,
    autosave: true,
    IdleCanvasComponent: IntentEmailArticle,
    name: 'Static article',
    temporaryComponentGenerator: ({ componentFields, displayFields }) => {
      return generateTemporaryComponent({
        componentFields,
        componentType: 'static_article',
        displayFields,
      });
    },
  },
  text_message: {
    ActiveCanvasComponent: IntentEmailTextActive,
    adornment: <GenAiIcon />,
    iconSrc: textIconSrc,
    IdleCanvasComponent: IntentEmailTextIdle,
    name: 'Text',
    temporaryComponentGenerator: () =>
      generateTemporaryComponent({
        componentFields: textComponentDefaultFields,
        componentType: 'text_message',
      }),
    ToolbarFormComponent: IntentEmailTextForm,
  },
};

export const INTENT_EMAIL_BUILDER_TAB_CONFIG: EmailBuilderTab[] = [
  {
    Icon: IconCirclePlus,
    label: 'Add',
    TabComponent: IntentEmailToolsTab,
    title: 'Contents',
  },
  {
    Icon: IconArticle,
    label: 'Articles',
    TabComponent: IntentEmailArticlesTab,
    title: 'Articles',
  },
];

export const MACROS_TAB: EmailBuilderTab = {
  Icon: IconMessage,
  label: 'Macros',
  TabComponent: IntentEmailMacrosTab,
  title: 'Macros',
};

export const EMAIL_RESPONSE = 'Response';
export const JOURNEY_MAP = 'Journey';
export const INTERACTIVE = 'Interactive';

// Filter for tools to show on IntentEmailToolsTab
export const TOOLS_TAB_COMPONENT_TYPES: EmailBuilderConfigurationKey[] = [
  'text_message',
  'button',
  'image',
  'html',
];

export const HELPDESKS_USING_RAW_TEMPLATE = ['intercom'];
