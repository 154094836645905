import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatTimestamp } from '../helpers';
import { ExecutionTranscriptComponent } from './types';

export const ExecutionIndicator = ({
  transcriptComponent,
}: {
  transcriptComponent: ExecutionTranscriptComponent;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      <TextContainer>
        <Typography variant='font14Bold'>
          {transcriptComponent.message}
        </Typography>
        <Typography
          color={theme.palette.colors.grey[900]}
          variant='font14Medium'
        >
          , {formatTimestamp(transcriptComponent.timestamp)}
        </Typography>
      </TextContainer>

      <Line />
    </Box>
  );
};

const TextContainer = styled('div')`
  z-index: 5;
  background-color: white;
  padding: 0 8px;
`;

const Line = styled('div')`
  z-index: 0;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.colors.slate[200]};
  position: absolute;
  top: 12px;
`;
