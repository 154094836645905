import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import SankeyDiagram, {
  SankeyDiagramProps,
} from '../sankey-diagram/SankeyDiagram';
import EmptyState from './EmptyState';
import SankeyOverview from './SankeyOverview';
import { SankeyOverviewData } from './types';

export interface SankeyDiagramSectionProps extends SankeyDiagramProps {
  height?: string;
  loading: boolean;
  overview: SankeyOverviewData[];
}

const SankeyDiagramSection = ({
  data,
  height = '400px',
  loading,
  overview,
}: SankeyDiagramSectionProps) => {
  const { palette } = useTheme();
  const noData = Boolean(!loading && !data.length);
  const hasData = Boolean(!loading && data.length);

  return (
    <Box
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius={1}
      display='flex'
      flexDirection='column'
      p={3}
    >
      {loading && <Skeleton height={height} />}
      {noData && <EmptyState />}
      {hasData && (
        <>
          <SankeyOverview data={overview} loading={loading} />
          <Box height={height}>
            <SankeyDiagram data={data} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SankeyDiagramSection;
