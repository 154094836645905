import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { toPercentageDecimal } from './helpers';
import { SankeyOverviewDataItem } from './types';

interface MetricItemsProps {
  items?: SankeyOverviewDataItem[];
  total?: number | null;
}

const MetricItems = ({ items, total }: MetricItemsProps) => {
  const { palette } = useTheme();
  if (!items) {
    return null;
  }
  return (
    <Box columnGap={5} display='flex' mt={2} width='100%'>
      {items.map((item, index) => {
        const percentChange = item.value && total ? item.value / total : 0;
        return (
          <Box display='flex' flexDirection='column' key={index} rowGap={0.5}>
            <Typography variant='font11'>{item.title}</Typography>
            <Typography variant='font18'>
              {(item.value || 0).toLocaleString()}
            </Typography>
            <Typography color={palette.colors.grey[600]} variant='font12'>
              {toPercentageDecimal(percentChange)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default MetricItems;
