import Box from '@mui/material/Box';

import OverviewMetricsHeaderItem, {
  OverviewMetricHeaderItemProps,
} from './OverviewMectricHeaderItem';

export interface OverviewMetricsHeaderProps {
  gridTemplateColumns: string;
  values: OverviewMetricHeaderItemProps[];
}

const OverviewMetricsHeader = ({
  gridTemplateColumns,
  values,
}: OverviewMetricsHeaderProps) => {
  return (
    <Box
      columnGap={1}
      display='grid'
      gridTemplateColumns={gridTemplateColumns}
      rowGap={2}
    >
      {values.map((value, index) => (
        <OverviewMetricsHeaderItem key={index} {...value} />
      ))}
    </Box>
  );
};

export default OverviewMetricsHeader;
