import { SelectChangeEvent, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  SelectDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  EXACT_MATCH_TOOLTIP,
  GROUND_TRUTH_TOOLTIP,
  MATCHING_METHOD_OPTIONS,
} from '../constants';

interface GroundTruthProps {
  accuracyCheckValue: string;
  groundTruthValue: string | undefined;
  isDisabled: boolean;
  onAccuracyCheckChange: (event: SelectChangeEvent<string>) => void;
  onGroundTruthChange: (event: SelectChangeEvent<string>) => void;
  options: { label: string; value: string }[];
  outputFieldValue: string;
}

const GroundTruth = ({
  accuracyCheckValue,
  groundTruthValue,
  isDisabled,
  onAccuracyCheckChange,
  onGroundTruthChange,
  options,
  outputFieldValue,
}: GroundTruthProps) => {
  const { palette } = useTheme();
  const showMatching =
    groundTruthValue && outputFieldValue !== groundTruthValue;

  return (
    <Box display='flex' flexDirection='column' rowGap={2}>
      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Box columnGap={1} display='flex'>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            Ground truth field
          </Typography>
          <Tooltip tooltipContent={GROUND_TRUTH_TOOLTIP}>
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        </Box>
        <SelectDropdown
          disabled={!outputFieldValue || isDisabled}
          id='ground-truth-dropdown'
          onChange={onGroundTruthChange}
          options={options}
          placeholder='select an existing field'
          value={groundTruthValue || ''}
        />
      </Box>
      {showMatching && (
        <Box display='flex' flexDirection='column' rowGap={0.5}>
          <Box columnGap={1} display='flex'>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Accuracy criteria for predictions
            </Typography>
            <Tooltip
              tooltipContent={
                <div
                  dangerouslySetInnerHTML={{ __html: EXACT_MATCH_TOOLTIP }}
                ></div>
              }
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
          <SelectDropdown
            disabled={isDisabled}
            id='exact-match-dropdown'
            onChange={onAccuracyCheckChange}
            options={MATCHING_METHOD_OPTIONS}
            value={accuracyCheckValue}
          />
        </Box>
      )}
    </Box>
  );
};

export default GroundTruth;
