import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import TicketStatusItem from '../../triage-model-detail-page/tickets-tab/TicketStatusItem';
import { createTicketStatusValue } from './helpers';
import { TriageInsightOverallTicketMetricResponse } from 'src/services/triage/types';

interface TicketStatusValueProps {
  loading: boolean;
  value?: TriageInsightOverallTicketMetricResponse;
}

const TicketStatusValue = ({ loading, value }: TicketStatusValueProps) => {
  const { palette } = useTheme();

  if (!value && !Number.isNaN(Number(value))) {
    return null;
  }

  return (
    <Box alignItems='center' columnGap={4} display='flex'>
      <TicketStatusItem
        color={palette.colors.green[500]}
        isLoading={loading}
        title='resolved'
        value={createTicketStatusValue(value?.resolved_tickets)}
      />
      <TicketStatusItem
        color={palette.colors.red[500]}
        isLoading={loading}
        title='open'
        value={createTicketStatusValue(value?.open_tickets)}
      />
      <TicketStatusItem
        color={palette.colors.black}
        isLoading={loading}
        title='in progress'
        value={createTicketStatusValue(value?.in_progress_tickets)}
      />
      <TicketStatusItem
        color={palette.colors.green[500]}
        isLoading={loading}
        title='closed'
        value={createTicketStatusValue(value?.closed_tickets)}
      />
    </Box>
  );
};

export default TicketStatusValue;
