import React, { MutableRefObject } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { IconMessage2 } from '@tabler/icons-react';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';

interface PolicyEditorProps {
  editable?: boolean;
  editorKey?: string;
  editorRef: MutableRefObject<EditorRef | null>;
  isLoading?: boolean;
  onChange: (content: string) => void;
  phrases?: string[];
  policy?: string;
}

export const PolicyEditor = ({
  editable = true,
  editorKey,
  editorRef,
  isLoading,
  onChange,
  phrases = [],
  policy = '',
}: PolicyEditorProps) => {
  const { palette } = useTheme();

  return (
    <>
      <Box
        alignItems='flex-start'
        display='flex'
        gap='12px'
        maxWidth='100%'
        mb={5}
        width='710px'
      >
        <IconMessage2 color={palette.colors.grey[600]} size={20} />
        <Box
          maxWidth='100%'
          overflow='hidden'
          textOverflow='ellipsis'
          width='100%'
        >
          <Box display='flex' mb='6px'>
            <Typography color={palette.colors.grey[600]} variant='font12Medium'>
              Customer Issue
            </Typography>
          </Box>

          {isLoading ? (
            <Skeleton variant='text' width={710} />
          ) : (
            <Typography noWrap variant='font14'>
              <i>{phrases.map(phrase => `'${phrase}'`).join(', ')}</i>
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='center'
        mb={5}
        py={3}
        sx={{
          '& .ProseMirror': {
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0 !important',
          },
          '& .remirror-editor-wrapper': {
            padding: '0 !important',
          },
          '& .remirror-mention-atom': {
            fontSize: '20px',
            lineHeight: '30px',
          },
          transition: 'all 0.1s ease-in-out',
        }}
        width='100%'
      >
        <FilledJourneyStepCard>
          {isLoading ? (
            <Skeleton variant='text' width={710} />
          ) : (
            <RichTextEditor
              editable={editable}
              editorRef={editorRef}
              initialContent={policy}
              key={editorKey}
              label='AutoFlow policy'
              onChange={onChange}
              placeholder='Start typing your policy here...'
              shouldReplaceNewlines
              shouldShowToolbar={false}
              shouldValidateHrefLength
              withEmojiButton={false}
              withExtraMentions
            />
          )}
        </FilledJourneyStepCard>
      </Box>
    </>
  );
};

interface FilledJourneyStepCardProps {
  children: React.ReactNode;
}

const FilledJourneyStepCard = ({ children }: FilledJourneyStepCardProps) => {
  const { palette } = useTheme();

  return (
    <Box alignItems='flex-start' display='flex' width='710px'>
      <img alt='' src={aaBadgeSrc} width='24px' />
      <Box display='flex' flex={1} flexDirection='column' ml={1}>
        <Typography variant='font20'>Autoflow policy</Typography>
        <Box
          pt='6px'
          sx={{
            color: palette.colors.black,
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '30px',
            whiteSpace: 'break-spaces',
          }}
          width='100%'
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
