import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { IconDotsVertical, IconTag } from '@tabler/icons-react';

import {
  Badge,
  Drawer,
  EllipsisWithTooltip,
  IconButton,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import { BrandIcon } from '../brand-icon/BrandIcon';
import HighlightedText from '../dashboard-pages/assist-notes-page/notes-table/HighlightedText';
import Spinner from '../spinner';
import {
  CreateTagDialog,
  DeleteTagDialog,
  ReassignTagDialog,
} from '../workflow-tag-dialogs/WorkflowTagDialogs';
import TagDrawerEmptyState from './TagDrawerEmptyState';
import { useSolveMetricsQueryParams } from 'src/pages/workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import ContentHeader from 'src/pages/workflow-builder-config/ContentHeader';
import { useGetBrandsQuery } from 'src/services/brand/brandApi';
import {
  useGetWidgetBreakdownMetricsQuery,
  useGetWorkflowTagsV2Query,
} from 'src/services/workflow-builder-metrics';
import {
  selectGlobalWorkflowBuilderOptions,
  setGlobalWorkflowBuilderOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Brand } from 'src/types/brandTypes';

const TAG_MENU_OPTIONS = [
  'Create new tag for ...',
  'Reassign tag ...',
  'Delete tag',
] as const;

type TagActions = Record<
  (typeof TAG_MENU_OPTIONS)[number],
  (tag: string) => void
>;

const useGetTableData = ({ searchText }: { searchText: string }) => {
  const { data: brandData, isLoading: isLoadingBrands } = useGetBrandsQuery();
  const { data: workflowTags = [], isLoading: isLoadingTags } =
    useGetWorkflowTagsV2Query();
  const { from, to } = useSolveMetricsQueryParams();
  const { data: breakdownData, isLoading: isLoadingBreakdown } =
    useGetWidgetBreakdownMetricsQuery({
      channel: 'widget',
      from,
      to,
    });

  const isLoading = isLoadingBrands || isLoadingTags || isLoadingBreakdown;

  return useMemo(() => {
    const filteredTags = workflowTags.filter(tag => {
      if (!searchText.trim().length) {
        return true;
      }
      return tag.toLowerCase().trim().includes(searchText.toLowerCase().trim());
    });

    const workflowsPerTag = new Map<string, string[]>();

    breakdownData?.breakdown.forEach(workflow => {
      workflow.workflow_tags?.forEach(tag => {
        if (!workflowsPerTag.has(tag)) {
          workflowsPerTag.set(tag, []);
        }
        workflowsPerTag.get(tag)?.push(workflow.name);
      });
    });

    const brandMap = new Map<string, { brand: Brand; tags: string[] }>();

    filteredTags.forEach(tag => {
      const brand = brandData?.find(brand => brand.tags.includes(tag));

      if (!brand) {
        return;
      }

      if (!brandMap.has(brand.brand_id)) {
        brandMap.set(brand.brand_id, { brand, tags: [] });
      }

      brandMap.get(brand.brand_id)?.tags.push(tag);
    });

    const tableData: {
      brand: Brand;
      brandIndex: number;
      isFirstTagInBrand: boolean;
      tag: string;
      workflowsUsingTag: string[];
    }[] = [];

    brandMap.forEach(({ brand, tags }) => {
      const brandIndex = brandData?.findIndex(
        listBrand => listBrand.brand_id === brand.brand_id,
      );

      if (brandIndex === -1 || brandIndex === undefined) {
        throw new Error(`Brand index not found for brand: ${brand.brand_id}`);
      }

      tags.forEach((tag, index) => {
        tableData.push({
          brand,
          brandIndex,
          isFirstTagInBrand: index === 0,
          tag,
          workflowsUsingTag: workflowsPerTag.get(tag) ?? [],
        });
      });
    });

    return { isEmpty: !tableData.length, isLoading, tableData };
  }, [
    brandData,
    searchText,
    workflowTags,
    breakdownData?.breakdown,
    isLoading,
  ]);
};

export const WorkflowTagManagementDrawer = () => {
  const dispatch = useAppDispatch();
  const { isWorkflowTagManagementDrawerOpen } = useSelector(
    selectGlobalWorkflowBuilderOptions,
  );

  return (
    <>
      <Drawer
        isOpen={isWorkflowTagManagementDrawerOpen}
        onClose={() => {
          dispatch(
            setGlobalWorkflowBuilderOptions({
              isWorkflowTagManagementDrawerOpen: false,
            }),
          );
        }}
        width='72%'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '40px',
            position: 'relative',
          }}
        >
          <Box pr='68px' pt='5px'>
            <ContentHeader isWithButton={false} title='Tag management' />
          </Box>
          <TagManagement />
        </Box>
      </Drawer>
    </>
  );
};

const TagManagement = () => {
  const [searchText, setSearchText] = useState('');
  const { isLoading, tableData } = useGetTableData({ searchText });

  return (
    <Box display='flex' flexDirection='column' gap={2} height='100%'>
      <Box maxWidth='360px'>
        <SearchBar
          onChange={e => setSearchText(e.target.value)}
          placeholder='Search tags'
          size='small'
          value={searchText}
        />
      </Box>
      {isLoading ? (
        <Spinner />
      ) : tableData?.length === 0 ? (
        <TagDrawerEmptyState />
      ) : (
        <Box
          borderBottom={theme => `1px solid ${theme.palette.colors.slate[200]}`}
          display='grid'
          gridTemplateColumns='1fr 1fr 2fr'
          rowGap='1px'
        >
          <Labels />
          {tableData?.map(
            ({
              brand,
              brandIndex,
              isFirstTagInBrand,
              tag,
              workflowsUsingTag,
            }) => (
              <Row
                brand={brand}
                index={brandIndex}
                isFirstTagInBrand={isFirstTagInBrand}
                key={tag}
                searchText={searchText}
                tag={tag}
                workflowsUsingTag={workflowsUsingTag}
              />
            ),
          )}
        </Box>
      )}
    </Box>
  );
};

const Labels = () => {
  return (
    <>
      <Label>Branded widget</Label>
      <Label>Tags</Label>
      <Label>Workflows in use</Label>
    </>
  );
};

const Label = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Typography
      bgcolor='white'
      color={theme => theme.palette.colors.grey[800]}
      p={2}
      variant='font14Bold'
    >
      {children}
    </Typography>
  );
};

const Row = ({
  brand,
  index,
  isFirstTagInBrand,
  searchText,
  tag,
  workflowsUsingTag,
}: {
  brand: Brand;
  index: number;
  isFirstTagInBrand: boolean;
  searchText: string;
  tag: string;
  workflowsUsingTag: string[];
}) => {
  const {
    brand_id: brandId,
    brand_name: brandName,
    is_default: isDefault,
    tags,
  } = brand;

  return (
    <>
      <BrandColumn
        brandName={brandName}
        index={index}
        isDefault={isDefault}
        isFirstTagInBrand={isFirstTagInBrand}
        numTags={tags.length}
      />
      <TagColumn brandId={brandId} searchText={searchText} tag={tag} />
      <WorkflowsInUseColumn workflows={workflowsUsingTag} />
    </>
  );
};

interface BrandColumnProps {
  brandName: string;
  index: number;
  isDefault: boolean;
  isFirstTagInBrand: boolean;
  numTags: number;
}

const BrandColumn = ({
  brandName,
  index,
  isDefault,
  isFirstTagInBrand,
  numTags,
}: BrandColumnProps) => {
  return (
    <ColumnBox>
      {isFirstTagInBrand && (
        <>
          <BrandIcon index={index} />
          <EllipsisWithTooltip>
            <Typography noWrap variant='font14'>
              {brandName}
            </Typography>
          </EllipsisWithTooltip>
          {isDefault && <Badge label='Default' variant='inverse' />}
          <Typography
            color={theme => theme.palette.colors.grey[500]}
            noWrap
            variant='font14'
          >
            ({numTags})
          </Typography>
        </>
      )}
    </ColumnBox>
  );
};

const TagColumn = ({
  brandId,
  searchText,
  tag,
}: {
  brandId: string;
  searchText: string;
  tag: string;
}) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tagToDelete, setTagToDelete] = useState('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [tagToReassign, setTagToReassign] = useState('');
  const tagActions: TagActions = useMemo(
    () => ({
      'Create new tag for ...': () => setIsCreateDialogOpen(true),
      'Delete tag': setTagToDelete,
      'Reassign tag ...': setTagToReassign,
    }),
    [],
  );

  return (
    <ColumnBox>
      <IconTag color={palette.colors.blue[500]} size={20} />
      <Typography variant='font14'>
        <HighlightedText searchText={searchText} text={tag} />
      </Typography>
      <EditTagButton
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        tag={tag}
        tagActions={tagActions}
      />
      <DeleteTagDialog
        onClose={() => setTagToDelete('')}
        tagToDelete={tagToDelete}
      />
      <CreateTagDialog
        brandId={brandId}
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ReassignTagDialog
        currentBrandId={brandId}
        isOpen={Boolean(tagToReassign)}
        onClose={() => setTagToReassign('')}
        tag={tag}
      />
    </ColumnBox>
  );
};

const WorkflowsInUseColumn = ({ workflows }: { workflows: string[] }) => {
  return (
    <ColumnBox flexWrap='wrap'>
      {workflows.map(workflow => (
        <Badge key={workflow} label={workflow} variant='macro' />
      ))}
    </ColumnBox>
  );
};

const ColumnBox = ({
  children,
  ...boxProps
}: React.PropsWithChildren<BoxProps>) => {
  return (
    <Box
      alignItems='center'
      bgcolor='white'
      display='flex'
      gap='6px'
      px={2}
      py={1.5}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

const EditTagButton = ({
  anchorEl,
  setAnchorEl,
  tag,
  tagActions,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: HTMLElement | null) => void;
  tag: string;
  tagActions: TagActions;
}) => {
  return (
    <>
      <IconButton
        aria-label='menu'
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        variant='ghost'
      >
        <IconDotsVertical size={20} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id='brand-menu'
        MenuListProps={{
          'aria-labelledby': 'tag-menu-button',
          role: 'listbox',
          sx: {
            py: 1.5,
          },
        }}
        onClose={e => {
          //Casting is needed as MUI has {} as a type for this event
          const event = e as MouseEvent;
          event?.stopPropagation && event.stopPropagation();
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 1,
          sx: ({ palette }) => ({
            border: `1px solid ${palette.colors.slate[200]}`,
            borderRadius: '4px',
          }),
        }}
      >
        {TAG_MENU_OPTIONS.map(option => (
          <MenuItem
            key={option}
            onClick={e => {
              e.stopPropagation();
              tagActions[option](tag);
              setAnchorEl(null);
            }}
            sx={{
              alignItems: 'center',
              display: 'flex',
              padding: '6px 16px',
            }}
          >
            <Typography variant='font14'>{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
