import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { theme, Tooltip } from '@forethought-technologies/forethought-elements';
import { Label, Row, Section, Spacer } from '../handoff-configuration/styles';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { updateActionSettings } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import type { RootState } from 'src/store/rootReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ParseJwtCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

type ParseJwtCustomizationPanelProps = {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
};

const ParseJwtCustomizationPanel: FC<
  React.PropsWithChildren<ParseJwtCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const actionSettings = canvasState?.actionSettings;
  // @ts-expect-error legacy code with untyped state
  const parseJwtCustomizationSettings: ParseJwtCustomizationSettings =
    useSelector(
      (state: RootState) =>
        state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
    );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const [customizationData, setCustomizationData] =
    useState<ParseJwtCustomizationSettings>(parseJwtCustomizationSettings);
  const isPristine = isEqual(customizationData, parseJwtCustomizationSettings);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (parseJwtCustomizationSettings) {
      setCustomizationData({ ...parseJwtCustomizationSettings });
    }
  }, [parseJwtCustomizationSettings]);

  const addContextVariable = () => {
    setCustomizationData(data => {
      return {
        ...data,
        context_variables_to_update: [
          ...customizationData.context_variables_to_update,
          '',
        ],
      };
    });
  };

  const deleteContextVariable = (indexToDelete: number) => {
    setCustomizationData(data => {
      return {
        ...data,
        context_variables_to_update: data.context_variables_to_update.filter(
          (cv, index) => index !== indexToDelete,
        ),
      };
    });
  };

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Parse JWT Data</Title>
        <Spacer height='20px' />
        <Section>
          <Row>
            <Label>Key</Label>
            <Tooltip
              tooltipContent={
                'This is the key required to successfully decode the JWT data. You can use a decode key or a JWK here.'
              }
            >
              <Box component='img' mb={1} ml={0.5} src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          <ContextVariableAutocomplete
            aria-label='aria-label-key'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                key: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.key}
          />
          <Spacer height='20px' />
          <Row>
            <Label>Encoded Context Variable</Label>
            <Tooltip tooltipContent={'This is the context variable to decode.'}>
              <Box component='img' mb={1} ml={0.5} src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          <ContextVariableSelectDropdown
            aria-label='context variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                encoded_context_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.encoded_context_variable}
          />
          <Spacer height='20px' />
          <Row>
            <Label>Context Variables to Update</Label>
            <Tooltip
              tooltipContent={
                'This is a list of context variables to update with values in the encoded data.'
              }
            >
              <Box component='img' mb={1} ml={0.5} src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          {customizationData.context_variables_to_update.map((cv, index) => {
            return (
              <Box
                key={`${cv}-${index}`}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  pb: 1,
                }}
              >
                <Box sx={{ display: 'block', width: '100%' }}>
                  <ContextVariableSelectDropdown
                    aria-label='context variable'
                    onChange={value => {
                      const newContextVariables = [
                        ...customizationData.context_variables_to_update,
                      ];
                      newContextVariables.splice(index, 1, value);

                      setCustomizationData(data => {
                        return {
                          ...data,
                          context_variables_to_update: [...newContextVariables],
                        };
                      });
                    }}
                    value={cv}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '42px',
                    justifyContent: 'flex-end',
                    width: '30px',
                  }}
                >
                  <DeleteButton
                    alt='delete context variable icon'
                    onClick={() => {
                      deleteContextVariable(index);
                    }}
                    role='button'
                    src={xIcon}
                  />
                </Box>
              </Box>
            );
          })}
        </Section>
        <Add
          isDisabled={
            customizationData.context_variables_to_update.length >= 100
          }
          onClick={addContextVariable}
        >
          + Add Context Variable
        </Add>
      </Box>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: actionSettings.action_type as string,
                configuration_fields: customizationData,
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.PARSE_JWT,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default ParseJwtCustomizationPanel;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const Add = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 16px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;
