import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, useTheme } from '@mui/material';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

import {
  Button,
  Dialog,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import copyIcon from 'src/assets/images/copy.svg';
import { TOAST_TIMEOUT } from 'src/components/dashboard-pages/triage-config-detail-page/helpers';
import UpgradePlanModal from 'src/components/upgrade-plan-modal/UpgradePlanModal';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import {
  useGetSolveApiTokenQuery,
  useRefreshSolveApiTokenMutation,
  useRequestSolveApiUpgradeMutation,
} from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

const ApiKeyTabContent = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [shouldShowUpgradePlanModal, setShouldShowUpgradePlanModal] =
    useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='font16Bold'>Key details</Typography>
      <Box marginBottom='16px' marginTop='16px'>
        <Typography variant='font14'>
          This API key is a unique identifier used for authenticating and
          authorizing access to an API.
        </Typography>
      </Box>
      <KeyDetails
        setShouldShowUpgradePlanModal={setShouldShowUpgradePlanModal}
        setShowDialog={setShowDialog}
      />
      <Box borderBottom='1px solid rgba(0, 0, 0, 0.1);' pt='38px' />
      <RefreshApiKeyDialog
        setShowDialog={setShowDialog}
        showDialog={showDialog}
      />
      <UpgradePlanModal
        isOpen={shouldShowUpgradePlanModal}
        onClose={() => setShouldShowUpgradePlanModal(false)}
      />
    </Box>
  );
};

const RefreshApiKeyDialog = ({
  setShowDialog,
  showDialog,
}: {
  setShowDialog: (shouldShow: boolean) => void;
  showDialog: boolean;
}) => {
  const [refreshSolveApiToken] = useRefreshSolveApiTokenMutation();

  return (
    <Dialog
      footer={
        <>
          <Button
            onClick={() => {
              setShowDialog(false);
            }}
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowDialog(false);
              refreshSolveApiToken();
            }}
            variant='secondary'
          >
            Refresh API key
          </Button>
        </>
      }
      hideCloseButton
      onClose={() => setShowDialog(false)}
      open={showDialog}
      title='Refresh API key'
    >
      <Box maxWidth='298px'>
        <Typography variant='font14'>
          You&apos;re about to refresh the Solve API token. Refreshing your API
          key will disable your current Solve API deployment.
        </Typography>
        <Box marginTop='16px'>
          <Typography variant='font14'>
            This action cannot be undone. Are you sure you want to proceed with
            refreshing the key?
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

const KeyDetails = ({
  setShouldShowUpgradePlanModal,
  setShowDialog,
}: {
  setShouldShowUpgradePlanModal: (shouldShow: boolean) => void;
  setShowDialog: (shouldShow: boolean) => void;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isKeyVisible, setIsKeyVisible] = useState(false);

  const { data: paidPlanData, isLoading: isLoadingPaidPlanData } =
    useGetPaidPlanDetailsQuery();
  const { data, isLoading } = useGetSolveApiTokenQuery();
  const [refreshSolveApiToken, { isLoading: isRefreshLoading }] =
    useRefreshSolveApiTokenMutation();
  const [requestUpgradeSolveApi] = useRequestSolveApiUpgradeMutation();

  const keyVisibilityButtonTitle = isKeyVisible ? 'Hide key' : 'Display key';
  const isSolveApiPaidFor = paidPlanData?.enablement_info.solve_api;

  if (isLoading || isRefreshLoading || isLoadingPaidPlanData) {
    return <Skeleton height='53.329px' width='100%' />;
  }

  if (!data?.api_key) {
    return (
      <Button onClick={() => refreshSolveApiToken()} variant='main'>
        Generate key
      </Button>
    );
  }

  if (!isSolveApiPaidFor) {
    return (
      <Button
        onClick={() => {
          requestUpgradeSolveApi();
          setShouldShowUpgradePlanModal(true);
        }}
        variant='main'
      >
        Upgrade to see API key
      </Button>
    );
  }

  return (
    <>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        marginBottom='16px'
      >
        <Typography variant='font16'>
          {isKeyVisible ? data.api_key : '**********'}
        </Typography>
        <Box display='flex' gap='8px'>
          <Tooltip tooltipContent={keyVisibilityButtonTitle}>
            <IconButton
              aria-label={keyVisibilityButtonTitle}
              onClick={() => {
                setIsKeyVisible(prev => !prev);
              }}
              variant='ghost'
            >
              {isKeyVisible ? <IconEyeOff /> : <IconEye strokeWidth='1.5px' />}
            </IconButton>
          </Tooltip>
          <Tooltip tooltipContent='Copy'>
            <IconButton
              aria-label='Copy'
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(data.api_key);
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: TOAST_TIMEOUT,
                      title: 'API token copied!',
                      variant: 'main',
                    }),
                  );
                } catch {
                  dispatch(
                    setGlobalToastOptions({
                      subtitle: 'Writing to the clipboard is not allowed.',
                      title: 'Copy failed!',
                      variant: 'danger',
                    }),
                  );
                }
              }}
              variant='ghost'
            >
              <img alt='' src={copyIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
        variant='secondary'
      >
        Refresh API key
      </Button>
      <Box marginTop='12px'>
        <Typography color={theme.palette.grey[800]} variant='font12'>
          Click on ‘Refresh’ will make the system generate a new API token for
          setting up connection. Once regenerated, the previous token will be
          inaccessible.
        </Typography>
      </Box>
    </>
  );
};

export default ApiKeyTabContent;
