import { ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  FilterButton,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface ListItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface GenericToolListProps<T> {
  actionButton?: ReactNode;
  defaultNumToShow?: number;
  description?: string | ReactNode;
  filterOptions?: { label: string; value: string }[];
  filterValue?: string;
  idKey: string;
  isLoading?: boolean;
  items: T[];
  loadingElement?: ReactNode;
  onFilterChange?: (value: string) => void;
  onSearchChange: (value: string) => void;
  renderItem: (item: T) => ReactNode;
  searchValue: string;
  showFilter?: boolean;
  title: string;
}

export const GenericToolList = <T extends ListItem>({
  actionButton,
  defaultNumToShow = 3,
  description,
  filterOptions,
  filterValue,
  idKey,
  isLoading,
  items,
  loadingElement,
  onFilterChange,
  onSearchChange,
  renderItem,
  searchValue,
  showFilter = false,
  title,
}: GenericToolListProps<T>) => {
  const { palette } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const itemsToShow = isExpanded ? items : items.slice(0, defaultNumToShow);

  return (
    <Section>
      <StickySectionHeader>
        <Typography variant='font18Bold'>{title}</Typography>
      </StickySectionHeader>
      {description && (
        <StickySectionNote mb={1}>
          <Typography color={palette.colors.grey[500]} variant='font14'>
            {description}
          </Typography>
        </StickySectionNote>
      )}
      {actionButton && (
        <Box pb={1} width='100%'>
          {actionButton}
        </Box>
      )}
      <Box
        alignItems='center'
        display='flex'
        gap={2}
        justifyContent='space-between'
        my={2}
        width={'100%'}
      >
        <Box width='100%'>
          <SearchBar
            aria-label={`${title} search bar`}
            onChange={e => onSearchChange(e.target.value)}
            placeholder='Search'
            value={searchValue}
          />
        </Box>
        {showFilter && filterOptions && filterValue && onFilterChange && (
          <Box>
            <FilterButton
              aria-label={`${title} type`}
              initialValue='All'
              onChange={onFilterChange}
              options={filterOptions}
              startAdornment={<img alt='' src='/images/filter-icon.svg' />}
              value={filterValue}
            />
          </Box>
        )}
      </Box>
      <ToolsSection>
        {isLoading ? (
          loadingElement
        ) : (
          <>
            {itemsToShow.map(item => (
              <div key={item[idKey]}>{renderItem(item)}</div>
            ))}
            {items.length > defaultNumToShow && (
              <Box pt={1}>
                <Typography variant='font14Bold'>
                  <ClickableLink onClick={() => setIsExpanded(prev => !prev)}>
                    See {isExpanded ? 'less' : 'more'} {title.toLowerCase()}
                  </ClickableLink>
                </Typography>
              </Box>
            )}
          </>
        )}
      </ToolsSection>
    </Section>
  );
};

const Section = styled('section')`
  width: 100%;
`;

const StickySectionHeader = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  margin-bottom: 8px;
`;

const StickySectionNote = styled(Box)`
  background-color: ${props => props.theme.palette.colors.white};
`;

const ToolsSection = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-bottom: 24px;
`;

const ClickableLink = styled('button')`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  padding: 0;
`;
