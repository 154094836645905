import { useMemo, useState } from 'react';
import { MRT_Row } from 'material-react-table';
import Box from '@mui/material/Box';

import {
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { InsightPeriod } from '../../solve-insights/types';
import ChartSection from './ChartSection';
import HeaderFilters from './HeaderFilters';
import { createApiFilters } from './helpers';
import OverviewMetricsSection from './OverviewMetricsSection';
import TableSection from './TableSection';
import TicketDrawerDetails from './TicketDrawerDetails';
import { timeFilterOptions } from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { TriageTicket } from 'src/services/triage/types';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const TriageInsightsTicketsTab = () => {
  const [searchType, setSearchType] = useState('keyword');
  const [searchText, setSearchText] = useState('');
  const [selectedTicket, setSelectedTicket] = useState<{
    body: string | null;
    id: string;
    title: string | null;
  } | null>(null);

  const [periodicalFilter, setPeriodicalFilter] =
    useState<InsightPeriod>('daily');

  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: 'triage-insights-time-range',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });

  const [filters, setFilters] = useState<string[]>([]);

  const handleTableRowClick = (row: MRT_Row<TriageTicket>) => {
    setSelectedTicket({
      body: row.original.body,
      id: row.original.id,
      title: row.original.title,
    });
  };

  const apiFilters = useMemo(
    () => createApiFilters({ dateRange, filters, searchText, searchType }),
    [filters, dateRange, searchType, searchText],
  );

  return (
    <Box display='flex' flexDirection='column' rowGap={3}>
      <HeaderFilters
        filters={filters}
        onDateRangeChange={setDateRange}
        onFilterChange={setFilters}
        selectedDateRange={dateRange}
      />
      <OverviewMetricsSection apiFilters={apiFilters} />
      <ChartSection
        apiFilters={apiFilters}
        dateRange={dateRange}
        onPeriodicalFilterChange={setPeriodicalFilter}
        periodicalFilter={periodicalFilter}
      />
      <TableSection
        apiFilters={apiFilters}
        filters={filters}
        onDateRangeChange={setDateRange}
        onFilterChange={setFilters}
        onSearch={setSearchText}
        onSearchTypeChange={setSearchType}
        onTableRowClick={handleTableRowClick}
        searchText={searchText}
        searchType={searchType}
        selectedDateRange={dateRange}
      />
      <Drawer
        isOpen={Boolean(selectedTicket)}
        onClose={() => setSelectedTicket(null)}
      >
        <Box display='flex' flexDirection='column' px={4} py={6} rowGap={4}>
          <Typography variant='font24'>Ticket details</Typography>
          <TicketDrawerDetails ticket={selectedTicket} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default TriageInsightsTicketsTab;
