import { useEffect } from 'react';

import {
  useGetFreshdeskFieldsQuery,
  useLazyGetFreshchatGroupsQuery,
} from 'src/services/dashboard-api/dashboardApi';
import { FreshChatField } from 'src/types/workflowBuilderAPITypes';

export const useGetFreshChatGroups = (apiUrl: string) => {
  const [getGroups, { data: freshchatGroups = [] }] =
    useLazyGetFreshchatGroupsQuery();

  useEffect(() => {
    if (apiUrl) {
      getGroups(apiUrl);
    }
  }, [apiUrl, getGroups]);

  return freshchatGroups;
};

export const useGetFreshdeskFields = (
  apiUrl: string,
): FreshChatField[] | undefined => {
  const { data: freshdeskFields } = useGetFreshdeskFieldsQuery(apiUrl, {
    skip: !apiUrl,
  });

  return freshdeskFields;
};
