import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Fade, Modal, useTheme } from '@mui/material';
import { IconBulb } from '@tabler/icons-react';

import {
  Button,
  elevations,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { PolicyEditor } from '../../../../autonomous-agent/components/PolicyEditor';
import GenAiIcon from '../GenAiIcon';
import AutopilotActionDrawer from './AutopilotActionDrawer';
import FloatingSidebar from './FloatingSidebar';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { selectIntentData } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetOrCreateAutopilotWorkflowQuery } from 'src/services/email-autopilot/emailAutopilotApi';
import { useUpdatePolicyDescription } from 'src/services/email-autopilot/hooks';
import { selectEmailBuilderState } from 'src/slices/email-builder/emailBuilderSlice';

interface AutopilotPolicyViewProps {
  onClose: () => void;
  open: boolean;
}

export function AutopilotPolicyView({
  onClose,
  open,
}: AutopilotPolicyViewProps) {
  const { intentEmailConfiguration } = useSelector(selectEmailBuilderState);
  const { data: autopilotWorkflow, isLoading } =
    useGetOrCreateAutopilotWorkflowQuery(
      {
        configurationId: intentEmailConfiguration?.configuration_id ?? '',
        version: intentEmailConfiguration?.version ?? -1,
      },
      {
        skip: !intentEmailConfiguration,
      },
    );
  const updatePolicyDescription = useUpdatePolicyDescription();
  const intent = useSelector(selectIntentData);
  const palette = useTheme().palette;
  const editorRef = useRef<EditorRef | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const prevOpen = useRef(open); // used to determine if the modal should fade in or out (only fade it when coming from another page)

  const handleClose = () => {
    prevOpen.current = false;
    onClose();
  };

  const handlePolicyChange = (newPolicy: string) => {
    return updatePolicyDescription(
      autopilotWorkflow?.autopilot_workflow_id ?? '',
      newPolicy,
    );
  };

  return (
    <Modal
      aria-labelledby='autopilot-policy-modal'
      closeAfterTransition
      onClose={handleClose}
      open={open}
    >
      <Fade appear={!prevOpen.current} in={open}>
        <Box
          sx={{
            backgroundColor: palette.colors.slate[100],
            height: '100vh',
            left: 0,
            padding: '20px',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '12px',
              boxShadow: elevations.z2,
              display: 'flex',
              padding: '16px',
            }}
          >
            <Box alignItems='center' display='flex' flex={1} gap='8px'>
              <GenAiIcon />
              <Typography variant='font18Bold'>
                Autopilot email policy
              </Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant='font24'>
                {intentEmailConfiguration?.name}
              </Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: 'right' }}>
              <Button onClick={handleClose} variant='main'>
                Close
              </Button>
            </Box>
          </Box>
          <Box display='flex'>
            <Box
              alignItems='center'
              display='flex'
              flex={1}
              flexDirection='column'
              marginTop='48px'
            >
              <PolicyEditor
                editorKey={autopilotWorkflow?.autopilot_workflow_id}
                editorRef={editorRef}
                isLoading={!autopilotWorkflow || isLoading}
                onChange={handlePolicyChange}
                phrases={
                  intent?.phrases?.map(phrase => phrase.phrase_text) ?? []
                }
                policy={autopilotWorkflow?.policy_description}
              />
            </Box>
            <Box height='100%' padding='16px 0'>
              {autopilotWorkflow && (
                <FloatingSidebar
                  isActionDrawerExpanded
                  setTabIndex={setTabIndex}
                  tabIndex={tabIndex}
                  tabs={[
                    {
                      component: (
                        <AutopilotActionDrawer
                          autopilotWorkflow={autopilotWorkflow}
                          contextVariablesInUse={
                            autopilotWorkflow?.context_variables.map(
                              cv => cv.context_variable_id,
                            ) ?? []
                          }
                        />
                      ),
                      Icon: IconBulb,
                      label: 'Available actions',
                      title: 'Available actions',
                    },
                  ]}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
