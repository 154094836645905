import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface OverviewMetricTitleProps {
  title: string;
  tooltip?: string | null;
}

const OverviewMetricTitle = ({ title, tooltip }: OverviewMetricTitleProps) => {
  const { palette } = useTheme();

  return (
    <Box alignItems='center' columnGap={0.5} display='flex'>
      <Typography color={palette.colors.grey[800]} noWrap variant='font14Bold'>
        {title}
      </Typography>
      {Boolean(tooltip) && (
        <Tooltip tooltipContent={tooltip as string}>
          <IconInfoCircle color={palette.colors.grey[700]} size={20} />
        </Tooltip>
      )}
    </Box>
  );
};

export default OverviewMetricTitle;
