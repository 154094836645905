import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import {
  Checkbox,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ContextMention from '../context-mention-input';
import { Label, Spacer, StyledInput } from '../handoff-configuration/styles';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { SalesforceMessagingHandoffActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SalesforceMessagingHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as SalesforceMessagingHandoffActionSettings;
  const initialCustomizationData: SalesforceMessagingHandoffActionSettings =
    addingAction
      ? {
          clear_session_before_handoff: false,
          hidden_fields: [],
          include_transcript: false,
          question_variable: 'free_form_query',
        }
      : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<SalesforceMessagingHandoffActionSettings>(
      initialCustomizationData,
    );
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Salesforce Messaging Handoff</Title>
        <Spacer height='20px' />
        <Typography variant='font14'>
          Forethought expects <b>Forethought_User_Question</b> and{' '}
          <b>Forethought_Conversation_ID</b> to be added as{' '}
          <span>
            <a
              href='https://help.salesforce.com/s/articleView?id=sf.miaw_custom_prechat_2.htm&type=5'
              rel='noopener noreferrer'
              target='_blank'
            >
              hidden fields
            </a>
          </span>{' '}
          to the Salesforce Pre-Chat Form.
        </Typography>
        <Spacer height='20px' />
        <Section>
          <ContextVariableSelectDropdown
            label='Question Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                question_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.question_variable}
          />
        </Section>
        <Section>
          <Tooltip tooltipContent='Forethought will use the Forethought_Conversation_ID value to query the MessagingSession object and add a CaseComment to the related Case containing the conversation transcript.'>
            <Checkbox
              checked={customizationData.include_transcript}
              label='Add conversation transcript as a CaseComment'
              onChange={() => {
                setCustomizationData({
                  ...customizationData,
                  include_transcript: !customizationData.include_transcript,
                });
              }}
            />
          </Tooltip>
        </Section>
        <Section>
          <Tooltip tooltipContent='Clears the Salesforce Messaging session before handing off to an agent.'>
            <Checkbox
              checked={customizationData.clear_session_before_handoff}
              label='Clear Salesforce Messaging session before handoff'
              onChange={() => {
                setCustomizationData({
                  ...customizationData,
                  clear_session_before_handoff:
                    !customizationData.clear_session_before_handoff,
                });
              }}
            />
          </Tooltip>
        </Section>
        <Section>
          <Tooltip tooltipContent='List of hidden fields to send to Salesforce Messaging widget and agent. The question / transcript will be passed via the hidden field Forethought_User_Question'>
            <Label>Hidden Fields</Label>
          </Tooltip>
          {customizationData.hidden_fields.map((hiddenField, idx) => {
            return (
              <Box
                key={`hidden-field-${idx}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                }}
              >
                <Box display='flex' flex={1}>
                  <StyledInput
                    onChange={e => {
                      setCustomizationData(handoffData => {
                        const newHiddenFields = structuredClone(
                          handoffData.hidden_fields,
                        );
                        newHiddenFields[idx].key = e.target.value;

                        return {
                          ...handoffData,
                          hidden_fields: newHiddenFields,
                        };
                      });
                    }}
                    placeholder={'Key'}
                    type={'text'}
                    value={hiddenField.key}
                    width='inherit'
                  />
                </Box>
                <Box display='flex' flex={2}>
                  <Box display='block' width={'100%'}>
                    <ContextMention
                      isMatchingNewStyle={true}
                      onChange={value => {
                        setCustomizationData(handoffData => {
                          const newHiddenFields = structuredClone(
                            handoffData.hidden_fields,
                          );
                          newHiddenFields[idx].value = value;

                          return {
                            ...handoffData,
                            hidden_fields: newHiddenFields,
                          };
                        });
                      }}
                      placeholder='Value'
                      value={hiddenField.value}
                    />
                  </Box>
                </Box>
                <Box alignItems='center' display='flex'>
                  <DeleteButton
                    onClick={() => {
                      setCustomizationData(handoffData => {
                        const newHiddenFields = [...handoffData.hidden_fields];
                        newHiddenFields.splice(idx, 1);

                        return {
                          ...handoffData,
                          hidden_fields: newHiddenFields,
                        };
                      });
                    }}
                    role='button'
                    src={xIcon}
                  />
                </Box>
              </Box>
            );
          })}
          <Box display='block' sx={{ marginTop: '16px' }}>
            <Add
              onClick={() => {
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    hidden_fields: [
                      ...customizationData.hidden_fields,
                      { key: '', value: '' },
                    ],
                  };
                });
              }}
            >
              + Add Hidden Field
            </Add>
          </Box>
        </Section>
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={() => {
            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type:
                    ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default SalesforceMessagingHandoffCustomizationPanel;

const Title = styled('h1')`
  font-size: var(--font-size-m);
  text-align: left;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  cursor: default;
  margin: 0 10px;
`;

const Section = styled('section')`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

const Add = styled('a')`
  color: ${theme.palette.colors.purple[500]};
  cursor: pointer;
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;
