import { useContext, useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';

import { useGetContextVariablesForTextEditor } from '../hooks';
import { replaceUnprocessibleContextVariables } from '../utils';
import { EmailBuilderContext } from 'src/components/email-builder/EmailBuilderContext';
import {
  ActiveCanvasComponentProps,
  GenerativeResponseModelFooterButtonAction,
} from 'src/components/email-builder/types';
import ResponseGenerationModal from 'src/components/response-generation-modal/ResponseGenerationModal';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { useGetHighlightedText } from 'src/hooks/useGetHighlightedText';
import {
  useGetFeatureFlagsQuery,
  useGetHelpdeskQuery,
} from 'src/services/dashboard-api';
import { executeStreamRequest } from 'src/services/email-builder/emailBuilderApi';
import { GenerateResponseActionType } from 'src/types/workflowBuilderAPITypes';

const PLACEHOLDER_TEXT =
  'Add text content here. Press "$" to select a context variable.';

const HELPEDESKS_TO_APPLY_LIMITED_TOOLBAR = ['intercom'];

interface ActiveTextProps {
  message: string;
  setActiveComponentFields: ActiveCanvasComponentProps['setActiveComponentFields'];
}

export function ActiveText({
  message,
  setActiveComponentFields,
}: ActiveTextProps) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const { data: helpdeskData } = useGetHelpdeskQuery();
  const { richTextEditorRef } = useContext(EmailBuilderContext);
  const generationModalRef = useRef<HTMLDivElement>(null);
  const { highlightedText } = useGetHighlightedText();

  const [
    isResponseGenerationModalVisible,
    setIsResponseGenerationModalVisible,
  ] = useState(false);

  const [generatedResponse, setGeneratedResponse] = useState('');
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [action, setAction] = useState<GenerateResponseActionType>('rephrase');

  const isSolveEmailStreamingGenerationEnabled = featureFlags.includes(
    'solve_email_streaming_generation_enabled',
  );

  const remirrorContextVariables = useGetContextVariablesForTextEditor();

  const applyLimitedToolbar = HELPEDESKS_TO_APPLY_LIMITED_TOOLBAR.includes(
    helpdeskData?.helpdesk || '',
  );

  const initializeGeneration = (
    action: GenerateResponseActionType,
    text: string,
  ) => {
    setIsLoadingResponse(true);
    setAction(action);
    executeStreamRequest(
      { action, text },
      concatenateResponse,
      handleFinishedGeneration,
    );
    setGeneratedResponse('');
  };

  const concatenateResponse = (newChunk: string) => {
    setGeneratedResponse(
      currentGeneratedResponse => currentGeneratedResponse + newChunk,
    );
  };

  const handleFinishedGeneration = () => {
    setIsLoadingResponse(false);
  };

  const OnCloseModal = () => {
    setIsResponseGenerationModalVisible(false);
    setGeneratedResponse('');
  };

  const handleFooterButtonClick = (
    action: GenerativeResponseModelFooterButtonAction,
  ) => {
    if (action === 'apply') {
      setAction('rephrase');
      richTextEditorRef?.current?.replaceText({
        content: generatedResponse.replaceAll('\n', '<br>'),
      });
      setIsResponseGenerationModalVisible(false);
    } else {
      initializeGeneration(action, generatedResponse);
    }
  };

  useEffect(() => {
    if (isResponseGenerationModalVisible) {
      generationModalRef.current?.scrollIntoView();
    }
  }, [isResponseGenerationModalVisible]);

  return (
    <>
      <EditorStyles>
        {richTextEditorRef && (
          <RichTextEditor
            contextVariables={remirrorContextVariables}
            editable
            editorRef={richTextEditorRef}
            highlightInvalidCVs
            includeGenerateResponseDropdown={
              isSolveEmailStreamingGenerationEnabled
            }
            initialContent={replaceUnprocessibleContextVariables(message || '')}
            onChange={message => {
              setActiveComponentFields({
                message,
              });
            }}
            onGenerativeOptionsDropdownChange={action => {
              setIsResponseGenerationModalVisible(true);
              initializeGeneration(action, highlightedText);
            }}
            placeholder={PLACEHOLDER_TEXT}
            shouldRenderLinkButton={!isResponseGenerationModalVisible}
            withAlignmentExtension={!applyLimitedToolbar}
            withEmojiButton={false}
            withHeadingExtension={!applyLimitedToolbar}
            withUnderLineButton={!applyLimitedToolbar}
          />
        )}
        {isResponseGenerationModalVisible && (
          <Box
            left='50px'
            position='absolute'
            ref={generationModalRef}
            top='40%'
            width='40vw'
            zIndex={3}
          >
            <ResponseGenerationModal
              action={action}
              generatedResponse={generatedResponse}
              isLoadingResponse={isLoadingResponse}
              onClose={OnCloseModal}
              onFooterButtonClick={action => {
                handleFooterButtonClick(action);
              }}
            />
          </Box>
        )}
      </EditorStyles>
    </>
  );
}

const EditorStyles = styled('div')`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    --rmr-font-weight-heading: normal; // override for remirror heading styles. font weight will be controlled by 'bold' tool
  }
`;
