import Box from '@mui/material/Box';

const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box bgcolor='white' height='100%'>
      {children}
    </Box>
  );
};
export default DashboardLayout;
