import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ListOption } from '@forethought-technologies/forethought-elements';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  BaseTicketFieldMappingResponse,
  EmailWorkflowTicketFieldMapping,
  HelpdeskFieldType,
  TestField,
} from 'src/pages/workflow-builder-edit/types';
import { CONVERSATION_LANGUAGE_VARIABLE_NAME } from 'src/reducers/actionBuilderReducer/constants';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getEmailWorkflowTestFields } from 'src/services/email-builder/emailBuilderApi';
import { useGetEnabledLanguagesQuery } from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import { selectEmailWorkflowState } from 'src/slices/email-workflow/emailWorkflowSlice';

export interface JourneyMapTestField {
  id: string;
  label: string;
  mapping_data?: BaseTicketFieldMappingResponse['mapping_data'];
  mapping_type?: BaseTicketFieldMappingResponse['mapping_type'];
  options?: ListOption[];
  required: boolean;
  type?: HelpdeskFieldType;
}

function getHelpdeskCustomFields(
  mapping: EmailWorkflowTicketFieldMapping,
): boolean {
  return !mapping.mapping_type;
}

function ticketFieldMappingToCustomField(
  mapping: EmailWorkflowTicketFieldMapping,
): JourneyMapTestField {
  const {
    external_ticket_field_id = '',
    external_ticket_field_title = '',
    mapping_data,
    mapping_type,
  } = mapping;

  return {
    id: external_ticket_field_id ?? mapping.context_variable_id,
    label: external_ticket_field_title,
    mapping_data,
    mapping_type,
    required: !!external_ticket_field_id,
  };
}

function testFieldToCustomField(field: TestField): JourneyMapTestField {
  return {
    id: field.id,
    label: field.title,
    options: field.field_options,
    required: false,
    type: field.type,
  };
}

export const useGetData = (isModalOpen: boolean) => {
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: false,
    shouldIncludeWorkflowTagContextVariable: false,
  });
  const { email_workflow_id: emailWorkflowId = '' } = emailWorkflow || {};
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailTranslationEnabled = featureFlags?.includes(
    'solve_email_translation_enabled',
  );

  const { data: languagesEnabledResponse } = useGetEnabledLanguagesQuery(
    'email',
    {
      skip: !isModalOpen || (isModalOpen && !isSolveEmailTranslationEnabled),
    },
  );

  const [testFields, setTestFields] = useState<JourneyMapTestField[]>([]);
  const [actionOutputCVIds, setActionOutputCVIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (emailWorkflowId && isModalOpen) {
      setIsLoading(true);

      getEmailWorkflowTestFields(emailWorkflowId)
        .then(({ action_output_cv_ids, mappings, ticket_fields_for_test }) => {
          const customFields = mappings.filter(getHelpdeskCustomFields);
          const mappedTicketFields = customFields.map(
            ticketFieldMappingToCustomField,
          );
          const testFields = (ticket_fields_for_test || [])
            .filter(field => field.type !== 'not_supported')
            .map(testFieldToCustomField);

          setTestFields([...mappedTicketFields, ...testFields]);
          setActionOutputCVIds(action_output_cv_ids);
        })
        .finally(() => setIsLoading(false));
    }
  }, [emailWorkflowId, isModalOpen, isSolveEmailTranslationEnabled]);

  const testContextVariables = useMemo(() => {
    if (!actionOutputCVIds.length || !contextVariables.length) {
      return null;
    }

    return contextVariables.filter(cv =>
      actionOutputCVIds.includes(cv.context_variable_id),
    );
  }, [actionOutputCVIds, contextVariables]);

  return {
    enabledLanguages: languagesEnabledResponse?.language_codes ?? [],
    isLoading,
    testContextVariables,
    testFields,
  };
};

export const useGetEmailFormData = (emailWorkflowId: string) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: false,
    shouldIncludeWorkflowTagContextVariable: false,
  });
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailTranslationEnabled = featureFlags?.includes(
    'solve_email_translation_enabled',
  );

  const { data: languagesEnabledResponse } = useGetEnabledLanguagesQuery(
    'email',
    {
      skip: !isSolveEmailTranslationEnabled,
    },
  );

  const [testFields, setTestFields] = useState<JourneyMapTestField[]>([]);
  const [actionOutputCVIds, setActionOutputCVIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!emailWorkflowId) {
      return undefined;
    }

    getEmailWorkflowTestFields(emailWorkflowId)
      .then(({ action_output_cv_ids, mappings, ticket_fields_for_test }) => {
        const customFields = mappings.filter(getHelpdeskCustomFields);
        const mappedTicketFields = customFields.map(
          ticketFieldMappingToCustomField,
        );
        const testFields = (ticket_fields_for_test || [])
          .filter(field => field.type !== 'not_supported')
          .map(testFieldToCustomField);

        setTestFields([...mappedTicketFields, ...testFields]);
        setActionOutputCVIds(action_output_cv_ids);
      })
      .finally(() => setIsLoading(false));
  }, [emailWorkflowId, isSolveEmailTranslationEnabled]);

  const testContextVariables = useMemo(() => {
    if (!actionOutputCVIds.length || !contextVariables.length) {
      return null;
    }

    return contextVariables.filter(cv =>
      actionOutputCVIds.includes(cv.context_variable_id),
    );
  }, [actionOutputCVIds, contextVariables]);

  return {
    enabledLanguages: languagesEnabledResponse?.language_codes ?? [],
    isLoading,
    testContextVariables,
    testFields,
  };
};

export const useGetConversationLanguageCV = () => {
  const { contextVariables } = useGetContextVariables({});
  return useMemo(
    () =>
      contextVariables.find(
        cv => cv.context_variable_name === CONVERSATION_LANGUAGE_VARIABLE_NAME,
      ),
    [contextVariables],
  );
};
