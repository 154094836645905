import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { removeZeroes } from './helpers';
import { SankeyOverviewData } from './types';

interface ComparisonChangeTextProps {
  value: SankeyOverviewData['value'];
}
const ComparisonChangeText = ({ value }: ComparisonChangeTextProps) => {
  if (!value.compareTo) {
    return <Box></Box>;
  }
  const percentChange = value.count
    ? Math.abs(((value.count - value.compareTo) / value.compareTo) * 100)
    : 0;

  return (
    <Box alignItems='center' display='flex'>
      <Typography variant='font12'>
        {removeZeroes(percentChange.toFixed(2))}% of {value.text}
      </Typography>
    </Box>
  );
};
export default ComparisonChangeText;
