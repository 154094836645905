import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import isPropValid from '@emotion/is-prop-valid';
import { Box, lighten, Menu, MenuItem, styled, useTheme } from '@mui/material';
import {
  IconDotsVertical,
  IconTags,
  IconVariable,
  IconWorld,
} from '@tabler/icons-react';

import {
  Avatar,
  Button,
  Colors,
  IconButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  doesNotificationButtonRouteMeetCondition,
  getTabNameFromPath,
  getWorkflowNavbarMessage,
  isNotificationButtonRoute,
} from '../sidebar/helpers';
import ControlMenu from './controls/menu';
import { setStepLevelTranslationsId } from 'src/actions/workflow-builder/workflowBuilderActions';
import BackIcon from 'src/assets/images/actions-back-icon.svg';
import iconBulb from 'src/assets/images/icon-bulb.svg';
import iconPurpleBulb from 'src/assets/images/icon-purple-bulb.svg';
import logo from 'src/assets/images/logo-v2-forethought.svg';
import WorkflowBuilderEditHeader from 'src/pages/workflow-builder-edit/header/WorkflowBuilderEditHeader';
import {
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useWorkflowBuilderMode,
} from 'src/pages/workflow-builder-edit/hooks';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { selectCurrentUser } from 'src/reducers/userReducer/userReducer';
import { selectIntentWorkflowId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { selectIsWorkflowActive } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  selectNavbarNotificationToggle,
  setGlobalWorkflowBuilderOptions,
  setNavbarNotificationToggle,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { breakpoints } from 'src/styles/breakpoints';
import { Routes } from 'src/utils/enums';

type NavBarProps = {
  children?: React.ReactNode;
  currentTabOverride?: React.ReactNode;
  isInSimulationsDetailPage?: boolean;
  onDeleteModel?: () => void;
  onGoBack?: () => void;
  onRenameModel?: () => void;
  toggleSidebar?: () => void;
};

const Navbar = ({
  children,
  currentTabOverride,
  isInSimulationsDetailPage,
  onDeleteModel,
  onGoBack,
  onRenameModel,
  toggleSidebar,
}: NavBarProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const role = useSelector(selectUserRole);
  const intentWorkflowId = useSelector(selectIntentWorkflowId) ?? '';

  const { autoflowWorkflow, isLoading } =
    useGetAutoflowWorkflowOrAutoflowTemplate(intentWorkflowId);

  const isNotificationVisible = useSelector(
    selectNavbarNotificationToggle(Routes.DISCOVER_DASHBOARD),
  );
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isCurrentWorkflowActive = useSelector(selectIsWorkflowActive);
  const isSolveEmailTranslationEnabled = featureFlags.includes(
    'solve_email_translation_enabled',
  );
  const isMultibrandEnabled = featureFlags.includes('multibrand_enabled');

  const ref = useRef<HTMLDivElement | null>(null);
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  // TODO: need a better way to do this. We use 'channel' from the landing page and 'view' from the edit page.
  const isEmailBuilderView =
    searchParams.get('view') === 'email' ||
    searchParams.get('channel') === 'email';

  const user = useSelector(selectCurrentUser) || {};
  const email = user?.user?.user?.email?.split('@')?.[0] || '';

  const [modalVisibility, setModalVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleModalVisibility = useCallback(() => {
    setModalVisibility(!modalVisibility);
  }, [modalVisibility]);

  useOnClickOutside(ref, () => {
    setModalVisibility(false);
  });
  const [mode] = useWorkflowBuilderMode();

  const isAutoflowWorkflow = autoflowWorkflow?.status === 'enabled';

  const tabName = getTabNameFromPath(pathname);
  const isRevampedWorkflowBuilderEditPage =
    Routes.WORKFLOW_BUILDER_EDIT === pathname;
  const isRevampedWorkflowBuilderPages = [
    Routes.WORKFLOW_BUILDER,
    Routes.WORKFLOW_BUILDER_EDIT,
    Routes.SOLVE_CONFIGURATION,
  ].includes(pathname as Routes);

  const isRevampedActionBuilderPages =
    Routes.ACTION_BUILDER === pathname ||
    Routes.ACTION_BUILDER_EDIT === pathname ||
    pathname.startsWith('/solve/action-builder');

  const isDarkMode =
    mode === 'autoflow' &&
    Routes.WORKFLOW_BUILDER_EDIT === pathname &&
    !isEmailBuilderView;

  const workflowMessage =
    intentWorkflowId && !isLoading
      ? getWorkflowNavbarMessage({
          isAutoflowWorkflow,
          mode,
        })
      : null;

  const shouldDisplayMultilingualIcon =
    !isEmailBuilderView ||
    (isEmailBuilderView && isSolveEmailTranslationEnabled);
  const shouldShowNotification =
    isNotificationButtonRoute(pathname) &&
    doesNotificationButtonRouteMeetCondition(pathname);
  const primaryHeader = useMemo(() => {
    if (isRevampedWorkflowBuilderEditPage) {
      return <WorkflowBuilderEditHeader isDarkMode={isDarkMode} isFromNavbar />;
    } else {
      return (
        <StyledTabContainer>
          <Box alignItems='center' display='flex'>
            <Typography noWrap variant='font24'>
              {currentTabOverride ?? tabName}
            </Typography>
            {isInSimulationsDetailPage && (
              <Box ml={1}>
                <IconButton
                  aria-label='menu'
                  onClick={handleMenuClick}
                  variant='ghost'
                >
                  <IconDotsVertical size={24} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  onClose={() => setAnchorEl(null)}
                  open={Boolean(anchorEl)}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      if (onRenameModel) {
                        onRenameModel();
                      }
                      handleMenuClose();
                    }}
                  >
                    Rename
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (onDeleteModel) {
                        onDeleteModel();
                      }
                      handleMenuClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </StyledTabContainer>
      );
    }
  }, [
    isRevampedWorkflowBuilderEditPage,
    isDarkMode,
    currentTabOverride,
    tabName,
    isInSimulationsDetailPage,
    anchorEl,
    onRenameModel,
    onDeleteModel,
  ]);
  return (
    <StyledNav isDarkMode={isDarkMode}>
      <StyledLogo onClick={toggleSidebar}>
        <img alt='' src={logo} />
      </StyledLogo>
      {onGoBack && <BackButton onClick={onGoBack} src={BackIcon} />}
      {primaryHeader}
      {shouldShowNotification && (
        <Box mr={2}>
          <IconButton
            aria-label='Notification'
            onClick={() =>
              dispatch(setNavbarNotificationToggle(pathname as Routes))
            }
            variant='ghost'
          >
            {isNotificationVisible ? (
              <img src={iconPurpleBulb} />
            ) : (
              <img src={iconBulb} />
            )}
          </IconButton>
        </Box>
      )}
      {Routes.WORKFLOW_BUILDER_EDIT === pathname &&
        workflowMessage &&
        !isEmailBuilderView &&
        isCurrentWorkflowActive && (
          <NavbarMessage isDarkMode={isDarkMode}>
            {workflowMessage}
          </NavbarMessage>
        )}
      {children}
      <Box
        display='flex'
        flex={shouldShowNotification ? 0 : 1}
        justifyContent='flex-end'
      >
        {isRevampedWorkflowBuilderPages || isRevampedActionBuilderPages ? (
          <Box display='flex' gap={1} mr={2}>
            {isMultibrandEnabled && (
              <Tooltip tooltipContent='Tags'>
                <Button
                  onClick={() =>
                    dispatch(
                      setGlobalWorkflowBuilderOptions({
                        isWorkflowTagManagementDrawerOpen: true,
                      }),
                    )
                  }
                  startIcon={
                    <IconTags
                      color={
                        isDarkMode
                          ? palette.colors.white
                          : palette.colors.grey[700]
                      }
                      size={20}
                    />
                  }
                  variant='ghost'
                />
              </Tooltip>
            )}
            <Tooltip tooltipContent='Context variables'>
              <Button
                onClick={() =>
                  dispatch(
                    setGlobalWorkflowBuilderOptions({ isCvDrawerOpen: true }),
                  )
                }
                startIcon={
                  <IconVariable
                    color={
                      isDarkMode
                        ? palette.colors.white
                        : palette.colors.grey[700]
                    }
                    size={20}
                  />
                }
                variant='ghost'
              />
            </Tooltip>
            {shouldDisplayMultilingualIcon && (
              <Tooltip tooltipContent='Translations'>
                <Button
                  onClick={() => {
                    dispatch(setStepLevelTranslationsId(''));
                    dispatch(
                      setGlobalWorkflowBuilderOptions({
                        isTranslationDrawerOpen: true,
                      }),
                    );
                  }}
                  startIcon={
                    <IconWorld
                      color={
                        isDarkMode
                          ? palette.colors.white
                          : palette.colors.grey[700]
                      }
                      size={20}
                    />
                  }
                  variant='ghost'
                />
              </Tooltip>
            )}
          </Box>
        ) : null}
        <div ref={ref}>
          <AvatarButton
            aria-label='Account Setting'
            onClick={toggleModalVisibility}
            type='button'
          >
            <Avatar variant='md'>{email.charAt(0).toUpperCase()}</Avatar>
          </AvatarButton>
          <div className='Hidden'>{email}</div>
          {modalVisibility && (
            <ControlMenu
              handleModalVisibility={toggleModalVisibility}
              role={role}
            />
          )}
        </div>
      </Box>
    </StyledNav>
  );
};

interface NavbarMessageProps {
  children: React.ReactNode;
  isDarkMode?: boolean;
}

const NavbarMessage = ({
  children,
  isDarkMode = false,
}: NavbarMessageProps) => {
  return (
    <Box
      sx={theme => ({
        // TODO color literal should come from design system
        backgroundColor: 'rgba(254, 177, 155, 0.20)',
        border: '1px solid' + theme.palette.colors.red[500],
        borderRadius: '5px',
        color: isDarkMode
          ? theme.palette.colors.white
          : theme.palette.colors.grey[700],
        minWidth: '50px',
        overflow: 'hidden',
        padding: '3px 16px',
        textOverflow: 'ellipsis',
      })}
    >
      <Typography noWrap variant='font14'>
        {children}
      </Typography>
    </Box>
  );
};

const StyledTabContainer = styled('h1')`
  flex: 1;
  margin: 0;
  text-transform: capitalize;
  @media only screen and ${breakpoints.mobile} {
    text-align: center;
  }
`;

const StyledLogo = styled('button')`
  background-color: ${Colors.ui.backgrounds.default};
  padding: 0;
  flex: 1;

  display: none;
  @media only screen and ${breakpoints.mobile} {
    display: flex;
  }
  ${props => (props.onClick ? 'cursor: pointer;' : undefined)}
`;

const StyledNav = styled('nav', {
  shouldForwardProp: prop => isPropValid(String(prop)) && prop !== 'isDarkMode',
})<{ isDarkMode: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 16px 40px;
  background-color: ${props =>
    props.isDarkMode
      ? props.theme.palette.colors.slate[800]
      : props.theme.palette.background.default};
  border-bottom: 1px solid ${props => props.theme.palette.colors.slate[200]};
  position: sticky;
  isolation: isolate;
  top: 0;
  z-index: 1200;
  transition: ${props =>
    props.theme.transitions.create(['background-color'], { duration: 100 })};
  & button {
    &:hover {
      // overrides button hover styles. Once dark theme variants are added please remove this
      background-color: ${props =>
        props.isDarkMode &&
        lighten(props.theme.palette.colors.brand.darkGrey, 0.1)};
    }
  }
`;

const AvatarButton = styled('button')`
  cursor: pointer;
  padding: 0;
`;

const BackButton = styled('img')`
  margin-right: 20px;
  cursor: pointer;
`;

BackButton.defaultProps = {
  'aria-label': 'Back',
  role: 'button',
};

export default Navbar;
