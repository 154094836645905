import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { elevations } from '@forethought-technologies/forethought-elements';
import ConfirmModal from './components/ConfirmModal';
import EditActiveConfigurationComponentOverlay from './components/EditActiveConfigurationComponentOverlay';
import EmailBuilderCanvas from './components/EmailBuilderCanvas';
import { EmailBuilderContextProvider } from './EmailBuilderContext';
import {
  ComponentFields,
  EmailBuilderComponent,
  EmailBuilderConfiguration,
  EmailBuilderTab,
} from './types';
import { setStepLevelTranslationsId } from 'src/actions/workflow-builder/workflowBuilderActions';
import VerticalTabBar from 'src/components/vertical-tab-bar';
import IntentEmailToggleBar from 'src/pages/workflow-builder-edit/email-builder-page/IntentEmailToggleBar';
import { setGlobalWorkflowBuilderOptions } from 'src/slices/ui/uiSlice';
import { selectGlobalWorkflowBuilderOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface EmailBuilderProps {
  contentOverCanvas?: React.ReactNode;
  emailBuilderConfiguration: EmailBuilderConfiguration;
  emailBuilderTabConfiguration: EmailBuilderTab[];
  emailConfigurationComponents: EmailBuilderComponent[];
  onAddComponent: (args: {
    component: EmailBuilderComponent;
    position: number;
  }) => void;
  onDeleteComponent: (componentId: string) => void;
  onReorderComponent: (args: { componentId: string; position: number }) => void;
  onUpdateComponent: (args: { component: EmailBuilderComponent }) => void;
}

const EmailBuilder = ({
  contentOverCanvas,
  emailBuilderConfiguration,
  emailBuilderTabConfiguration,
  emailConfigurationComponents,
  onAddComponent,
  onDeleteComponent,
  onReorderComponent,
  onUpdateComponent,
}: EmailBuilderProps) => {
  const dispatch = useAppDispatch();
  const { isTranslationDrawerOpen } = useSelector(
    selectGlobalWorkflowBuilderOptions,
  );
  const [
    tempEmailConfigurationComponents,
    setTempEmailConfigurationComponents,
  ] = useState(emailConfigurationComponents);
  const [activeEditComponent, setActiveEditComponent] =
    useState<EmailBuilderComponent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // control confirmation dialog for unsaved and invalid changes
  const [confirmation, setConfirmation] = useState<
    'unsaved' | 'invalid' | null
  >(null);

  // flag for confirmation dialog when form fields are not valid
  const [isActiveComponentValid, setIsActiveComponentValid] = useState(true);
  // when component is added, the backend will return a new component, so we need to keep track of its position in order to maintain edit state
  const activeComponentPositionRef = useRef(-1);

  useEffect(() => {
    setTempEmailConfigurationComponents(emailConfigurationComponents);

    if (activeComponentPositionRef.current >= 0) {
      setActiveEditComponent(
        emailConfigurationComponents[activeComponentPositionRef.current],
      );
    }
  }, [emailConfigurationComponents]);

  const setActiveComponentFields = useCallback(
    (componentFields: ComponentFields) => {
      setActiveEditComponent(prevComponent => {
        if (!prevComponent) {
          return null;
        }
        return {
          ...prevComponent,
          component_fields: {
            ...prevComponent.component_fields,
            ...componentFields,
          },
        };
      });
    },
    [],
  );

  function setActiveComponentDisplayFields(componentFields: ComponentFields) {
    setActiveEditComponent(prevComponent => {
      if (!prevComponent) {
        return null;
      }

      return {
        ...prevComponent,
        display_fields: componentFields,
      };
    });
  }

  function reorderComponentsDuringDrag(dragIndex: number, hoverIndex: number) {
    setTempEmailConfigurationComponents(prevComponents => {
      const copy = [...prevComponents];
      const component = copy[dragIndex];
      copy.splice(dragIndex, 1);
      copy.splice(hoverIndex, 0, component);

      return copy;
    });
  }

  function onClickCancel() {
    // filter out any temporary components
    setTempEmailConfigurationComponents(prevComponents =>
      prevComponents.filter(component => !component.temporary),
    );
    // remove edit state component
    setActiveEditComponent(null);
    // remove confirmation dialog if it exists
    setConfirmation(null);
  }

  // check to see if updates have been made to the active component's fields
  // used to determine whether to show confirmation dialog
  function isUpdated() {
    const originalComponent = tempEmailConfigurationComponents.find(
      component => component.component_id === activeEditComponent?.component_id,
    );
    if (!activeEditComponent || !originalComponent) {
      return false;
    }
    return (
      JSON.stringify(activeEditComponent) !== JSON.stringify(originalComponent)
    );
  }

  function onClickSave(componentFields?: ComponentFields) {
    if (!activeEditComponent) {
      return;
    }

    const updatedActiveEditComponent = componentFields
      ? { ...activeEditComponent, component_fields: componentFields }
      : activeEditComponent;

    if (activeEditComponent.temporary) {
      onAddComponent({
        component: updatedActiveEditComponent,
        position: tempEmailConfigurationComponents.findIndex(
          component =>
            component.component_id === activeEditComponent.component_id,
        ),
      });

      setTempEmailConfigurationComponents(prevComponents => {
        return prevComponents.map(component => {
          if (
            component.component_id === updatedActiveEditComponent.component_id
          ) {
            return updatedActiveEditComponent;
          }
          return component;
        });
      });
    } else {
      onUpdateComponent({ component: updatedActiveEditComponent });
    }
    setActiveEditComponent(null);
    setConfirmation(null);
  }

  function onClickDelete(componentId: string) {
    const component = tempEmailConfigurationComponents.find(
      component => component.component_id === componentId,
    );

    if (component && !component.temporary) {
      onDeleteComponent(componentId);
    }

    setTempEmailConfigurationComponents(prevComponents =>
      prevComponents.filter(
        component => component.component_id !== componentId,
      ),
    );
    setActiveEditComponent(null);
  }

  function onDropReorder({
    componentId,
    position,
  }: {
    componentId: string;
    position: number;
  }) {
    onReorderComponent({
      componentId,
      position,
    });
  }

  const activeEditComponentConfiguration = useMemo(() => {
    if (activeEditComponent) {
      return emailBuilderConfiguration[activeEditComponent.component_type];
    }
  }, [activeEditComponent, emailBuilderConfiguration]);

  const ToolbarFormComponent = useMemo(() => {
    if (activeEditComponent) {
      return emailBuilderConfiguration[activeEditComponent.component_type]
        ?.ToolbarFormComponent;
    }
  }, [activeEditComponent, emailBuilderConfiguration]);

  return (
    <>
      <Container>
        <DndProvider backend={HTML5Backend}>
          <EmailBuilderContextProvider>
            <EmailBuilderCanvasContainer>
              {contentOverCanvas}
              <EmailBuilderCanvas
                activeEditComponent={activeEditComponent}
                emailBuilderConfiguration={emailBuilderConfiguration}
                emailConfigurationComponents={emailConfigurationComponents}
                onAddNewComponent={({
                  componentMetadata,
                  componentType,
                  position,
                }) => {
                  const { autosave, temporaryComponentGenerator } =
                    emailBuilderConfiguration[componentType];
                  if (!temporaryComponentGenerator) {
                    throw new Error(
                      `No component generator found for component type: ${componentType}`,
                    );
                  }
                  const temporaryComponent =
                    temporaryComponentGenerator(componentMetadata);

                  setTempEmailConfigurationComponents(prevComponents => [
                    ...prevComponents.slice(0, position),
                    temporaryComponent,
                    ...prevComponents.slice(position),
                  ]);

                  setActiveEditComponent(temporaryComponent);

                  if (autosave) {
                    // The id of this component will change when it is returned, so store the position to maintain edit state
                    activeComponentPositionRef.current = position;

                    onAddComponent({
                      component: temporaryComponent,
                      position,
                    });
                  }
                }}
                onClickDelete={onClickDelete}
                onDropReorder={onDropReorder}
                onTranslationsClick={componentId => {
                  dispatch(setStepLevelTranslationsId(componentId));
                  dispatch(
                    setGlobalWorkflowBuilderOptions({
                      isTranslationDrawerOpen: true,
                    }),
                  );
                }}
                removeTemporaryComponent={onClickCancel}
                reorderComponentsDuringDrag={reorderComponentsDuringDrag}
                setActiveComponentFields={setActiveComponentFields}
                setActiveEditComponent={setActiveEditComponent}
                tempEmailConfigurationComponents={
                  tempEmailConfigurationComponents
                }
              />
            </EmailBuilderCanvasContainer>
            <EmailBuilderToolbarContainer>
              <IntentEmailToggleBar />
              <VerticalTabBar
                tabs={emailBuilderTabConfiguration.map(tab => {
                  const { TabComponent } = tab;

                  return {
                    ...tab,
                    component: (
                      <TabComponent
                        emailBuilderConfiguration={emailBuilderConfiguration}
                        emailConfigurationComponents={
                          tempEmailConfigurationComponents
                        }
                      />
                    ),
                  };
                })}
              />

              {activeEditComponent &&
                activeEditComponentConfiguration &&
                !activeEditComponentConfiguration.autosave && (
                  <EditActiveConfigurationComponentOverlay
                    activeEditComponentConfiguration={
                      activeEditComponentConfiguration
                    }
                    isLoading={isLoading}
                    onClickCancel={() => {
                      if (isTranslationDrawerOpen) {
                        return;
                      }

                      if (isActiveComponentValid) {
                        if (isUpdated()) {
                          setConfirmation('unsaved');
                          return;
                        }

                        onClickCancel();
                        return;
                      }

                      setConfirmation('invalid');
                    }}
                  >
                    {ToolbarFormComponent && (
                      <ToolbarFormContainer>
                        <ToolbarFormComponent
                          component={activeEditComponent}
                          onClickCancel={() => onClickCancel()}
                          onClickSave={componentFields =>
                            onClickSave(componentFields)
                          }
                          setActiveComponentDisplayFields={
                            setActiveComponentDisplayFields
                          }
                          setActiveComponentFields={setActiveComponentFields}
                          setIsActiveComponentValid={setIsActiveComponentValid}
                          setIsLoading={isLoading => setIsLoading(!!isLoading)}
                        />
                      </ToolbarFormContainer>
                    )}
                  </EditActiveConfigurationComponentOverlay>
                )}
            </EmailBuilderToolbarContainer>
          </EmailBuilderContextProvider>
        </DndProvider>
      </Container>

      {/* Confirm with unsaved changes */}
      <ConfirmModal
        actions={[
          {
            onClick: () => onClickCancel(),
            title: 'No, discard changes',
          },
          {
            onClick: () => onClickSave(),
            title: 'Yes, save them',
          },
        ]}
        isOpen={confirmation === 'unsaved'}
        onClose={() => setConfirmation(null)}
        title='Save your changes?'
      />

      {/* Confirm with invalid changes */}
      <ConfirmModal
        actions={[
          {
            onClick: () => onClickCancel(),
            title: 'Discard changes',
          },
          {
            onClick: () => setConfirmation(null),
            title: 'Complete the form',
          },
        ]}
        isOpen={confirmation === 'invalid'}
        onClose={() => setConfirmation(null)}
        title='Please complete the form or discard your changes'
      />
    </>
  );
};

export default EmailBuilder;

const Container = styled('div')`
  height: 100%;
  overflow-y: scroll;
  display: flex;
`;

const EmailBuilderCanvasContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 480px);
  min-width: 300px;
`;

const EmailBuilderToolbarContainer = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  box-shadow: ${elevations.z3};
  flex: 0 0 480px;
  position: sticky;
  top: 0;
  height: 100%;
`;

const ToolbarFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100% - 68px);
`;
