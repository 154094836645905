import isEmpty from 'lodash/fp/isEmpty';
import {
  CatchAllHandoffCustomization,
  SalesforceCaseCreationCustomization,
  SalesforceChatHandoffCustomization,
  SalesforceHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';

export const buildCatchAllInitialFormValue = (
  handoffCustomization: CatchAllHandoffCustomization,
) => {
  return {
    custom_message: handoffCustomization.custom_message,
  };
};

export const buildSalesforceInitialFormValue = (
  handoffCustomization: SalesforceHandoffCustomization,
) => {
  const fields_and_values =
    handoffCustomization.ticket_creation_settings?.ticket_creation_config
      ?.fields_and_values;
  const extra_prechat_form_details =
    handoffCustomization.agent_chat_handoff_settings
      ?.extra_prechat_form_details;

  return {
    agent_chat_handoff_settings: {
      button_id:
        handoffCustomization.agent_chat_handoff_settings?.button_id || '',
      chat_transcript_text_area_fields:
        handoffCustomization.agent_chat_handoff_settings
          ?.chat_transcript_text_area_fields,
      deployment_id:
        handoffCustomization.agent_chat_handoff_settings?.deployment_id || '',
      extra_prechat_form_details: isEmpty(extra_prechat_form_details)
        ? []
        : extra_prechat_form_details,
      form_prompt:
        handoffCustomization.agent_chat_handoff_settings?.form_prompt ||
        'Please fill in the information below and I will connect you to an agent.',
      handoff_success_message:
        handoffCustomization.agent_chat_handoff_settings
          ?.handoff_success_message ||
        'Starting a chat conversation with an agent.',
      ignore_name_email:
        handoffCustomization.agent_chat_handoff_settings?.ignore_name_email ||
        false,
      no_agents_available_message:
        handoffCustomization.agent_chat_handoff_settings
          ?.no_agents_available_message ||
        'There are currently no agents available.',
      store_transcript_to_chat_transcript_field_id:
        handoffCustomization.agent_chat_handoff_settings
          ?.store_transcript_to_chat_transcript_field_id,
      talk_to_agent_button_prompt:
        handoffCustomization.agent_chat_handoff_settings
          ?.talk_to_agent_button_prompt ||
        'Would you like to talk to an agent?',
    },
    include_agent_chat_handoff: handoffCustomization.include_agent_chat_handoff,
    include_ticket_creation_handoff:
      handoffCustomization.include_ticket_creation_handoff,
    ticket_creation_settings: {
      create_ticket:
        !!handoffCustomization.ticket_creation_settings?.create_ticket,
      custom_message:
        handoffCustomization.ticket_creation_settings?.custom_message || '',
      ticket_creation_config: {
        confirmation_message:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.confirmation_message || '',
        fields_and_values: isEmpty(fields_and_values)
          ? [
              {
                id: '',
                value: '',
              },
            ]
          : fields_and_values,
        handoff_form_question_message:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.handoff_form_question_message || '',
        ticket_fields:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.ticket_fields || [],
        ticket_title:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.ticket_title || '',
        update_flamethrower_conversation_deflection_status:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.update_flamethrower_conversation_deflection_status,
      },
    },
  };
};

export const buildSalesforceCaseCreationInitialFormValue = (
  handoffCustomization: SalesforceCaseCreationCustomization,
) => {
  const fields_and_values =
    handoffCustomization.ticket_creation_settings?.ticket_creation_config
      ?.fields_and_values;

  return {
    add_transcript_comment:
      handoffCustomization.add_transcript_comment || false,
    create_email_message: handoffCustomization.create_email_message || false,
    email: handoffCustomization.email || '',
    name: handoffCustomization.name || '',
    question: handoffCustomization.question || 'free_form_query',
    ticket_creation_settings: {
      ticket_creation_config: {
        fields_and_values: isEmpty(fields_and_values)
          ? [
              {
                id: '',
                value: '',
              },
            ]
          : fields_and_values,
        ticket_fields:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.ticket_fields || [],
        ticket_title:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.ticket_title || '',
        update_flamethrower_conversation_deflection_status:
          handoffCustomization.ticket_creation_settings?.ticket_creation_config
            ?.update_flamethrower_conversation_deflection_status,
      },
    },
    ticket_id_output_variable: handoffCustomization.ticket_id_output_variable,
  };
};

export const buildSalesforceChatHandoffInitialFormValue = (
  handoffCustomization: SalesforceChatHandoffCustomization,
) => {
  const extra_prechat_form_details =
    handoffCustomization.agent_chat_handoff_settings
      ?.extra_prechat_form_details;

  return {
    agent_chat_handoff_settings: {
      button_id:
        handoffCustomization.agent_chat_handoff_settings?.button_id || '',
      chat_transcript_text_area_fields:
        handoffCustomization.agent_chat_handoff_settings
          ?.chat_transcript_text_area_fields,
      deployment_id:
        handoffCustomization.agent_chat_handoff_settings?.deployment_id || '',
      extra_prechat_form_details: isEmpty(extra_prechat_form_details)
        ? []
        : extra_prechat_form_details,
      store_transcript_to_chat_transcript_field_id:
        handoffCustomization.agent_chat_handoff_settings
          ?.store_transcript_to_chat_transcript_field_id,
    },
    email: handoffCustomization.email || '',
    name: handoffCustomization.name || '',
    question: handoffCustomization.question || 'free_form_query',
  };
};

export const cleanSalesforceCustomizationData = (
  customizationData: SalesforceHandoffCustomization,
) => {
  const cleansedFieldsAndValues =
    customizationData.ticket_creation_settings?.ticket_creation_config?.fields_and_values?.filter(
      fieldAndValue => fieldAndValue.id && fieldAndValue.value,
    ) || [];

  return {
    ...customizationData,
    ticket_creation_settings: {
      ...customizationData.ticket_creation_settings,
      ticket_creation_config: {
        ...customizationData.ticket_creation_settings.ticket_creation_config,
        fields_and_values: cleansedFieldsAndValues,
      },
    },
  };
};

export const cleanSalesforceCaseCreationCustomizationData = (
  customizationData: SalesforceCaseCreationCustomization,
) => {
  const cleansedFieldsAndValues =
    customizationData.ticket_creation_settings?.ticket_creation_config?.fields_and_values?.filter(
      fieldAndValue => fieldAndValue.id && fieldAndValue.value,
    ) || [];

  return {
    ...customizationData,
    email: customizationData.email || '',
    name: customizationData.name || '',
    question: customizationData.question || '',
    ticket_creation_settings: {
      ...customizationData.ticket_creation_settings,
      ticket_creation_config: {
        ...customizationData.ticket_creation_settings.ticket_creation_config,
        fields_and_values: cleansedFieldsAndValues,
      },
    },
  };
};

export const cleanSalesforceChatHandoffCustomizationData = (
  customizationData: SalesforceChatHandoffCustomization,
) => {
  return {
    ...customizationData,
    email: customizationData.email || '',
    name: customizationData.name || '',
    question: customizationData.question || '',
  };
};
