import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import {
  SelectDropdown,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { IntegrationTabProps, OriginItem } from '../types';
import EditableSelectField from './EditableSelectField';
import IntegrationErrorInfo from './IntegrationErrorInfo';

interface IntegrationComponentProps extends IntegrationTabProps {
  integrationType: 'jira' | 'kustomer' | 'salesforce' | 'zendesk' | 'freshdesk';
  originFieldValue?: string[];
  origins?: OriginItem[];
  setOriginFieldValue?: (values: string[]) => void;
}

const HelpdeskIntegration = ({
  integrationSelectedState,
  integrationType,
  isDisabled = false,
  onChange,
  options,
  originFieldValue = [],
  origins = [],
  predictedFieldValue,
  setOriginFieldValue,
}: IntegrationComponentProps) => {
  const { palette } = useTheme();
  const [isNewField, setIsNewField] = useState(false);

  const handleSelectChange = ({
    inputValue,
    selectValue,
  }: {
    inputValue: string;
    selectValue: string;
  }) => {
    onChange({
      input: inputValue,
      integrationType,
      isNewField,
      select: selectValue,
    });
  };

  const handleToggle = (isInput: boolean) => {
    setIsNewField(isInput);
  };

  const disabled = integrationSelectedState === 'loading' || isDisabled;
  const hasError = integrationSelectedState === 'error';

  return (
    <Box display='flex' flexDirection='column' rowGap={4}>
      {integrationType === 'salesforce' && (
        <>
          <Box display='flex' flexDirection='column' rowGap={0.5}>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Object
            </Typography>
            <TooltipContainer>
              <Tooltip tooltipContent='You must output to a field on “Case” object'>
                <SelectDropdown
                  aria-label='Object dropdown'
                  disabled
                  id='object-dropdown'
                  onChange={() => undefined}
                  options={[{ label: 'Case', value: 'Case' }]}
                  placeholder='Select object'
                  value='Case'
                />
              </Tooltip>
            </TooltipContainer>
          </Box>
          <Box>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Origins
            </Typography>
            <SelectDropdown
              aria-label='Origin multiple dropdown'
              chipsToShow={4}
              disabled={disabled}
              error={!originFieldValue.length && 'Select at least one origin'}
              id='origin-dropdown'
              isMenuSearchable
              multiple
              onChange={({ target }) => {
                const { value } = target;
                if (typeof value === 'string') {
                  return;
                }
                setOriginFieldValue && setOriginFieldValue(value);
              }}
              onClear={() => setOriginFieldValue && setOriginFieldValue([])}
              onClickOnlyButton={value =>
                setOriginFieldValue && setOriginFieldValue(value)
              }
              onDeleteChip={(_, value) =>
                setOriginFieldValue &&
                setOriginFieldValue(
                  originFieldValue.filter(fieldValue => fieldValue !== value),
                )
              }
              options={origins}
              placeholder='Select origins'
              value={originFieldValue}
            />
          </Box>
        </>
      )}
      <Box display='flex' flexDirection='column' rowGap={1}>
        <EditableSelectField
          disabled={disabled}
          onChange={handleSelectChange}
          onSave={(newFieldValue: string) =>
            onChange({
              integrationType,
              isNewField: true,
              select: newFieldValue,
            })
          }
          onToggleCallback={handleToggle}
          options={options}
          tooltip='This model will output to this field in the selected integration'
          value={predictedFieldValue}
        />

        {hasError && <IntegrationErrorInfo />}
      </Box>
    </Box>
  );
};

const TooltipContainer = styled('div')`
  & > div {
    display: block;
    width: 100%;
  }
`;

export default HelpdeskIntegration;
