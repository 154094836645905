import Box from '@mui/material/Box';

import OverviewItem from './OverviewItem';
import { SankeyOverviewData } from './types';

export interface SankeyOverviewProps {
  data: SankeyOverviewData[];
  gridTemplateColumns?: string;
  loading: boolean;
}

const SankeyOverview = ({
  data,
  gridTemplateColumns = 'repeat(5, 1fr)',
  loading,
}: SankeyOverviewProps) => {
  return (
    <Box display='grid' gridTemplateColumns={gridTemplateColumns}>
      {data.map((item, index) => (
        <OverviewItem item={item} key={index} loading={loading} />
      ))}
    </Box>
  );
};

export default SankeyOverview;
