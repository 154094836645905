import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/system';

import {
  Checkbox,
  Typography,
} from '@forethought-technologies/forethought-elements';
import NameEmailQuestion from '../name-email-question/NameEmailQuestion';
import TicketCreationOnly from './ticket-creation/ticket-creation-only';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import {
  Label,
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  SalesforceCaseCreationCustomization,
  SalesforceCommonCustomization,
  ZendeskOrSalesforceContextVariables,
} from 'src/types/workflowBuilderAPITypes';

interface SalesforceCaseCreationConfigurationProps {
  customizationData: SalesforceCaseCreationCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<
    SetStateAction<SalesforceCaseCreationCustomization>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const SalesforceCaseCreationConfiguration: FC<
  React.PropsWithChildren<SalesforceCaseCreationConfigurationProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  return (
    <ContentWrapper>
      <Typography variant='font20Medium'>Salesforce Case Creation</Typography>
      <Section>
        <NameEmailQuestion
          customizationData={customizationData}
          errorMessages={errorMessages}
          isFieldTouchedArr={isFieldTouchedArr}
          setCustomizationData={
            setCustomizationData as Dispatch<
              SetStateAction<ZendeskOrSalesforceContextVariables>
            >
          }
          setIsFieldTouchedArr={setIsFieldTouchedArr}
        />
        <Spacer height='28px' />
        <Label>Ticket ID Output Context Variable</Label>
        <ContextVariableSelectDropdown
          aria-label='Ticket ID Output Context Variable'
          isClearable
          onChange={value => {
            setCustomizationData({
              ...customizationData,
              ticket_id_output_variable: value,
            });
          }}
          value={customizationData.ticket_id_output_variable || ''}
        />
        <Spacer height='28px' />
        <TicketCreationOnly
          customizationData={customizationData}
          setCustomizationData={
            setCustomizationData as Dispatch<
              SetStateAction<SalesforceCommonCustomization>
            >
          }
        />
        <Checkbox
          checked={!!customizationData?.add_transcript_comment}
          label='Add case comment containing the chat transcript'
          onChange={e => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                add_transcript_comment: e.target.checked,
              };
            });
          }}
        />
        <Checkbox
          checked={!!customizationData?.create_email_message}
          label='Also create an EmailMessage tied to this case'
          onChange={e => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                create_email_message: e.target.checked,
              };
            });
          }}
        />
        <Spacer height='100px' />
      </Section>
    </ContentWrapper>
  );
};

export default SalesforceCaseCreationConfiguration;

const ContentWrapper = styled('div')`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;
