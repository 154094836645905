import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TextWithInfo from '../dashboard-pages/triage-model-detail-page/common/TextWithInfo';
import ComparisonChangeText from './ComparisonChangeText';
import { getValue } from './helpers';
import MetricItems from './MetricItems';
import { SankeyOverviewData } from './types';

export interface OverviewItemProps {
  item: SankeyOverviewData;
  loading: boolean;
}

const OverviewItem = ({ item, loading }: OverviewItemProps) => {
  const { palette } = useTheme();
  const { items, title, tooltip, value } = item;
  const borderStyle = `1px solid ${palette.colors.slate[100]}`;

  return (
    <Box
      display='grid'
      gridTemplateRows='20px auto 18px 1fr'
      px={1.5}
      rowGap={1}
      sx={{
        borderLeft: borderStyle,
        borderRight: borderStyle,
      }}
    >
      <TextWithInfo
        title={title}
        tooltip={tooltip || ''}
        variant='font14Bold'
      ></TextWithInfo>
      {loading ? (
        <Skeleton height={100} />
      ) : (
        <>
          <Typography variant='font24'>{getValue(value)}</Typography>
          <ComparisonChangeText value={value} />
          <MetricItems items={items} total={item.value.count} />
        </>
      )}
    </Box>
  );
};

export default OverviewItem;
