import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ArticleCardStep } from './ArticleCardStep';
import { ButtonSelectionStep } from './ButtonSelectionStep';
import { CsatSubmissionStep } from './CsatSubmissionStep';
import { EmbedStep } from './EmbedStep';
import { ExecutionIndicator } from './ExecutionIndicator';
import { ImageStep } from './ImageStep';
import { IntentTrigger } from './IntentTrigger';
import { TextStep } from './TextStep';
import {
  ArticleCardTranscriptComponent,
  ButtonSelectionTranscriptComponent,
  CsatSubmissionTranscriptComponent,
  EmbedTranscriptComponent,
  ExecutionTranscriptComponent,
  ImageTranscriptComponent,
  IntentTriggeredTranscriptComponent,
  TextTranscriptComponent,
  TranscriptComponent,
} from './types';

const mapTranscriptPropsToComponent = (
  transcriptComponent: TranscriptComponent,
) => {
  const messageType = transcriptComponent.message_type;
  switch (messageType) {
    case 'text':
      return (
        <TextStep
          transcriptComponent={transcriptComponent as TextTranscriptComponent}
        />
      );
    case 'intent_trigger':
      return (
        <IntentTrigger
          transcriptComponent={
            transcriptComponent as IntentTriggeredTranscriptComponent
          }
        />
      );
    case 'execution':
      return (
        <ExecutionIndicator
          transcriptComponent={
            transcriptComponent as ExecutionTranscriptComponent
          }
        />
      );
    case 'image':
      return (
        <ImageStep
          transcriptComponent={transcriptComponent as ImageTranscriptComponent}
        />
      );
    case 'embed':
      return (
        <EmbedStep
          transcriptComponent={transcriptComponent as EmbedTranscriptComponent}
        />
      );
    case 'button_selection':
      return (
        <ButtonSelectionStep
          transcriptComponent={
            transcriptComponent as ButtonSelectionTranscriptComponent
          }
        />
      );
    case 'article_card':
      return (
        <ArticleCardStep
          transcriptComponent={
            transcriptComponent as ArticleCardTranscriptComponent
          }
        />
      );
    case 'csat_submission':
      return (
        <CsatSubmissionStep
          transcriptComponent={
            transcriptComponent as CsatSubmissionTranscriptComponent
          }
        />
      );
    case 'related_articles':
    case 'quick_feedback':
    case 'dynamic_card_selection':
    default:
      return (
        <Box sx={{ overflowWrap: 'break-word' }}>
          {JSON.stringify(transcriptComponent)}
        </Box>
      );
  }
};

export const RevampConversationTranscript = ({
  transcriptComponents,
}: {
  transcriptComponents: TranscriptComponent[];
}) => {
  return (
    <Box display='flex' flexDirection='column' gap='10px'>
      <Typography variant='font11'>Transcript</Typography>
      <TranscriptContainer>
        {transcriptComponents.map(comp => mapTranscriptPropsToComponent(comp))}
      </TranscriptContainer>
    </Box>
  );
};

const TranscriptContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
