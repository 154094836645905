import { useMemo } from 'react';

import { InsightPeriod } from '../../solve-insights/types';
import { INITIAL_LEGENDS } from './constants';
import { createApiFilters, createLineChartData } from './helpers';
import DashboardChartSection from 'src/components/dashboard-chart-section';
import { useGetTriageInsightTicketsTimeSeriesChartQuery } from 'src/services/triage/triageApi';
import { DateRange } from 'src/types/types';

interface ChartSectionProps {
  apiFilters: ReturnType<typeof createApiFilters>;
  dateRange: DateRange;
  onPeriodicalFilterChange: (period: InsightPeriod) => void;
  periodicalFilter: InsightPeriod;
}

const ChartSection = ({
  apiFilters,
  dateRange,
  onPeriodicalFilterChange,
  periodicalFilter,
}: ChartSectionProps) => {
  const { data, isFetching, isLoading } =
    useGetTriageInsightTicketsTimeSeriesChartQuery({
      ...apiFilters,
      interval: periodicalFilter,
    });

  const chartDataValues = useMemo(() => createLineChartData(data), [data]);

  const chartData = {
    ...data,
    values: chartDataValues || [],
    x_axis: data?.x_axis || [],
  };

  return (
    <DashboardChartSection
      data={chartData}
      dateRange={dateRange}
      enabledLegends={INITIAL_LEGENDS}
      isLoading={isLoading || isFetching}
      periodicalFilter={periodicalFilter}
      setPeriodicalFilter={onPeriodicalFilterChange}
      yAxis={[
        { title: { text: 'Ticket count' } },
        { max: 100, title: { text: 'Rate' } },
      ]}
    />
  );
};

export default ChartSection;
