import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { setStateCanvas } from '../../slices/canvas-workflow-builder/workflowBuilderSlice';
// Action Types
import { WorkflowBuilderActions } from './workflowBuilderActionTypes';
import { setLoading } from 'src/actions/data/data';
import { dashboardApi } from 'src/services/dashboard-api';
// Helpers
// Services
import {
  acceptProductWorkflowSuggestionAPI,
  createIntentWorkflowAPI,
  duplicateIntentWorkflowAPI,
  duplicateToProductAPI,
  getConfigurationTranslationsForOrgAPI,
  getContextVariablesStepApi,
  getModelTrainingAPI,
  getStepTranslationsForOrgAPI,
  getStepTranslationsForStepAPI,
  getWidgetApiKeyAPI,
  getWorkflowBuilderLandingDataAPI,
  getWorkflowBuilderLanguagesAPI,
  getWorkflowBuilderLanguagesEnabledAPI,
  getWorkflowBuilderSuggestedIntentsAPI,
  getWorkflowBuilderWidgetConfigAPI,
  overrideConfigurationTranslationsAPI,
  overrideTranslationsAPI,
  overrideTranslationsForStepAPI,
  refreshWidgetApiKeyAPI,
  restoreConfigurationTranslationAPI,
  restoreTranslationAPI,
  updateWorkflowBuilderLanguagesEnabledAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
// Redux
import { AppDispatch } from 'src/store/hooks';
import { SolveWidgetProduct } from 'src/types/types';
// Types
import {
  CanvasResponse,
  ConfigurationTranslation,
  ContextVariablesStep,
  ConversationChannel,
  CreateCustomHandoffRequest,
  GetConfigurationTranslationsForOrgRequest,
  GetConfigurationTranslationsForOrgResponse,
  GetRestoreTranslationResponse,
  GetStepTranslationsForOrgRequest,
  GetStepTranslationsForOrgResponse,
  GetStepTranslationsForStepRequest,
  GetStepTranslationsForStepResponse,
  LanguagesEnabledRequest,
  LanguagesEnabledResponse,
  LanguagesResponse,
  OverrideConfigurationTranslationRequest,
  OverrideTranslationRequest,
  Phrase,
  StepTranslation,
  SuggestedIntentsResponse,
  WidgetConfigResponse,
  WorkflowBuilderLandingPageData,
} from 'src/types/workflowBuilderAPITypes';
import { TranslationsTableMode } from 'src/utils/enums';

export const HTTP_STATUS_NOT_FOUND = 404;

export const getWorkflowBuilderLandingDataSuccess =
  createAction<WorkflowBuilderLandingPageData>(
    WorkflowBuilderActions.GET_LANDING_DATA_SUCCESS,
  );

export const setWorkflowBuilderChannel = createAction<string>(
  WorkflowBuilderActions.SET_CHANNEL,
);

export const getWorkflowBuilderWidgetConfigSuccess =
  createAction<WidgetConfigResponse>(
    WorkflowBuilderActions.GET_WIDGET_CONFIG_SUCCESS,
  );

export const getWorkflowBuilderLanguagesSuccess =
  createAction<LanguagesResponse>(WorkflowBuilderActions.GET_LANGUAGES_SUCCESS);

export const getWorkflowBuilderLanguagesEnabledSuccess =
  createAction<LanguagesEnabledResponse>(
    WorkflowBuilderActions.GET_LANGUAGES_ENABLED_SUCCESS,
  );

const getStepTranslationsForOrgSuccess =
  createAction<GetStepTranslationsForOrgResponse>(
    WorkflowBuilderActions.GET_STEP_TRANSLATION_FOR_ORG_SUCCESS,
  );

const getStepTranslationsForStepSuccess =
  createAction<GetStepTranslationsForStepResponse>(
    WorkflowBuilderActions.GET_STEP_TRANSLATIONS_FOR_STEP_SUCCESS,
  );

const getRestoreTranslationSuccess =
  createAction<GetRestoreTranslationResponse>(
    WorkflowBuilderActions.GET_RESTORE_TRANSLATION_SUCCESS,
  );

const getConfigurationTranslationsForOrgSuccess =
  createAction<GetConfigurationTranslationsForOrgResponse>(
    WorkflowBuilderActions.GET_CONFIGURATION_TRANSLATION_FOR_ORG_SUCCESS,
  );

const getUsableContextVariablesForTranslationSuccess =
  createAction<ContextVariablesStep>(
    WorkflowBuilderActions.GET_USABLE_CONTEXT_VARIABLES_FOR_TRANSLATION_SUCCESS,
  );

export const getWorkflowBuilderSuggestedIntentsSuccess =
  createAction<SuggestedIntentsResponse>(
    WorkflowBuilderActions.GET_SUGGESTED_INTENTS_SUCCESS,
  );

export const clearWorkflowBuilderSuggestedIntents = createAction(
  WorkflowBuilderActions.CLEAR_SUGGESTED_INTENTS,
);

export const setDraftTranslations = createAction<
  (StepTranslation | ConfigurationTranslation)[]
>(WorkflowBuilderActions.SET_DRAFT_TRANSLATIONS);

export const setIsErrorBannerVisible = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_ERROR_BANNER_VISIBLE,
);

export const setIsInfoBannerVisible = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_INFO_BANNER_VISIBLE,
);

export const setIsOverlayVisible = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_OVERLAY_VISIBLE,
);

export const setIsPreviewLoaded = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_PREVIEW_LOADED,
);

export const setIsModelTraining = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_MODEL_TRAINING,
);

const getWidgetApiKeySuccess = createAction<string>(
  WorkflowBuilderActions.GET_WIDGET_API_KEY_SUCCESS,
);

const getIsModelTraining = createAction<boolean>(
  WorkflowBuilderActions.GET_IS_MODEL_TRAINING,
);

export const setIsConfigTranslationsLoading = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_CONFIG_TRANSLATIONS_LOADING,
);

export const setIsStepTranslationsLoading = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_STEP_TRANSLATIONS_LOADING,
);

export const setStepLevelTranslationsId = createAction<string>(
  WorkflowBuilderActions.SET_STEP_LEVEL_TRANSLATIONS_ID,
);

export const setTranslationsTableMode = createAction<string>(
  WorkflowBuilderActions.SET_TRANSLATIONS_TABLE_MODE,
);

export const setIsLoading = createAction<boolean>(
  WorkflowBuilderActions.SET_IS_LOADING,
);

export const setNetworkError = createAction<string>(
  WorkflowBuilderActions.SET_NETWORK_ERROR,
);

export const getWorkflowBuilderLandingData =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderLandingDataAPI()
      .then((data: WorkflowBuilderLandingPageData) => {
        dispatch(getWorkflowBuilderLandingDataSuccess(data));
      })
      .catch(() => {
        console.error('An error has ocurred');
      });
  };

export const createIntentWorkflow = createAsyncThunk<
  CanvasResponse | void,
  {
    description?: string;
    handoff_configuration?: CreateCustomHandoffRequest;
    name: string;
    phrases: Phrase[];
    suggested_intent_id?: string | null;
    workflow_tags?: string[];
  }
>(
  WorkflowBuilderActions.CREATE_INTENT_WORKFLOW,
  async (
    {
      description,
      handoff_configuration,
      name,
      phrases,
      suggested_intent_id,
      workflow_tags,
    },
    { dispatch },
  ) => {
    return createIntentWorkflowAPI(
      name,
      phrases,
      description,
      suggested_intent_id,
      handoff_configuration,
      workflow_tags,
    )
      .then((data: CanvasResponse) => {
        dispatch(setStateCanvas(data));
        // @ts-expect-error legacy code with untyped state
        dispatch(getWorkflowBuilderWidgetConfig());
        dispatch(dashboardApi.util.invalidateTags(['Intents']));

        return data;
      })
      .catch(() => {
        console.error('An error has ocurred');
      });
  },
);

export const duplicateIntentWorkflow = createAsyncThunk<
  CanvasResponse,
  { workflowId: string }
>(
  WorkflowBuilderActions.DUPLICATE_INTENT_WORKFLOW,
  async ({ workflowId }, { dispatch }) => {
    return duplicateIntentWorkflowAPI(workflowId).then(
      (data: CanvasResponse) => {
        dispatch(setStateCanvas(data));
        // @ts-expect-error legacy code with untyped state
        dispatch(getWorkflowBuilderWidgetConfig());
        return data;
      },
    );
  },
);

export const duplicateClassicToProduct = createAsyncThunk<
  CanvasResponse,
  { intentId: string; product: SolveWidgetProduct }
>('duplicateClassicToProduct', async ({ intentId, product }, { dispatch }) => {
  return duplicateToProductAPI(intentId, product).then(
    (data: CanvasResponse) => {
      dispatch(setStateCanvas(data));
      return data;
    },
  );
});

export const acceptProductWorkflowSuggestion = createAsyncThunk<
  CanvasResponse,
  {
    intentId: string;
    product: SolveWidgetProduct;
    shouldStartFromScratch: boolean;
  }
>(
  'acceptProductWorkflowSuggestion',
  async ({ intentId, product, shouldStartFromScratch }, { dispatch }) => {
    return acceptProductWorkflowSuggestionAPI({
      intentId,
      product,
      shouldStartFromScratch,
    }).then((data: CanvasResponse) => {
      dispatch(dashboardApi.util.invalidateTags(['AutoflowWorkflow']));
      dispatch(setStateCanvas(data));
      return data;
    });
  },
);

export const getWorkflowBuilderWidgetConfig =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderWidgetConfigAPI()
      .then((data: WidgetConfigResponse) => {
        dispatch(getWorkflowBuilderWidgetConfigSuccess(data));
      })
      .catch(() => {
        console.error('An error has ocurred');
        dispatch(setLoading(false));
      });
  };

export const getWorkflowBuilderLanguages =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderLanguagesAPI()
      .then(data => {
        dispatch(getWorkflowBuilderLanguagesSuccess(data));
      })
      .catch(() => undefined);
  };

export const getWorkflowBuilderLanguagesEnabledForStep =
  (intentWorkflowId: string, stepId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderLanguagesEnabledAPI('widget')
      .then(data => {
        dispatch(getWorkflowBuilderLanguagesEnabledSuccess(data));
        if (data.language_codes.length !== 0) {
          dispatch(
            getStepTranslationsForStep({
              intent_workflow_id: intentWorkflowId,
              language_code: data.language_codes[0],
              step_id: stepId,
            }),
          );
        }
      })
      .catch(error => {
        if (error.code == HTTP_STATUS_NOT_FOUND) {
          // Config not found - create it
          updateWorkflowBuilderLanguagesEnabledAPI({
            channel: 'widget',
            language_codes: [],
            modified_date: null,
          });
        }
      });
  };

export const getEmailEnabledLanguagages = () => (dispatch: AppDispatch) => {
  return getWorkflowBuilderLanguagesEnabledAPI('email').then(data => {
    dispatch(getWorkflowBuilderLanguagesEnabledSuccess(data));
    return data.language_codes;
  });
};

export const getWorkflowBuilderLanguagesEnabledAndTranslations =
  (mode: TranslationsTableMode) =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderLanguagesEnabledAPI('widget')
      .then(data => {
        dispatch(getWorkflowBuilderLanguagesEnabledSuccess(data));
        if (data.language_codes.length !== 0) {
          dispatch(
            getStepTranslationsForOrg({
              language_code: data.language_codes[0],
              mode: mode,
            }),
          );
          if (mode === TranslationsTableMode.LIVE) {
            dispatch(
              getConfigurationTranslationsForOrg({
                language_code: data.language_codes[0],
              }),
            );
          }
        }
      })
      .catch(error => {
        if (error.code == HTTP_STATUS_NOT_FOUND) {
          // Config not found - create it
          updateWorkflowBuilderLanguagesEnabledAPI({
            channel: 'widget',
            language_codes: [],
            modified_date: null,
          });
        }
      });
  };

export const getWorkflowBuilderLanguagesEnabled =
  (channel: ConversationChannel) =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderLanguagesEnabledAPI(channel)
      .then(data => {
        dispatch(getWorkflowBuilderLanguagesEnabledSuccess(data));
      })
      .catch(error => {
        if (error.code == HTTP_STATUS_NOT_FOUND) {
          // Config not found - create it
          updateWorkflowBuilderLanguagesEnabledAPI({
            channel: 'widget',
            language_codes: [],
            modified_date: null,
          });
        }
      });
  };

export const updateWorkflowBuilderLanguagesEnabled =
  (data: LanguagesEnabledRequest) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    return updateWorkflowBuilderLanguagesEnabledAPI(data)
      .then((data: LanguagesEnabledResponse) => {
        dispatch(getWorkflowBuilderLanguagesEnabledSuccess(data));
      })
      .catch(e => {
        const errorType = e.error_type ?? '';
        dispatch(setNetworkError(errorType));
        dispatch(setIsErrorBannerVisible(true));
        console.error('An error has ocurred');
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const overrideTranslations =
  (
    overrideRequests: OverrideTranslationRequest[],
    languageCode: string,
    mode: TranslationsTableMode,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    return overrideTranslationsAPI(overrideRequests, languageCode, mode)
      .then((data: GetStepTranslationsForOrgResponse) => {
        dispatch(getStepTranslationsForOrgSuccess(data));
      })
      .catch(e => {
        const errorType = e.error_type ?? '';
        dispatch(setNetworkError(errorType));
        dispatch(setIsErrorBannerVisible(true));
        console.error('Failed to override translations');
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const overrideTranslationsForStep =
  (
    overrideRequests: OverrideTranslationRequest[],
    languageCode: string,
    intentWorkflowId: string,
    stepId: string,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    return overrideTranslationsForStepAPI(
      overrideRequests,
      languageCode,
      intentWorkflowId,
      stepId,
    )
      .then((data: GetStepTranslationsForStepResponse) => {
        dispatch(getStepTranslationsForStepSuccess(data));
      })
      .catch(e => {
        const errorType = e.error_type ?? '';
        dispatch(setNetworkError(errorType));
        dispatch(setIsErrorBannerVisible(true));
        console.error('Failed to override translations');
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const restoreTranslation =
  (
    mode: TranslationsTableMode,
    intent_workflow_id: string,
    step_id: string,
    element_path: string,
    language_code: string,
    index: number,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    return restoreTranslationAPI(
      mode,
      intent_workflow_id,
      step_id,
      element_path,
      language_code,
    )
      .then((data: StepTranslation) => {
        dispatch(
          getRestoreTranslationSuccess({
            index: index,
            translation: data,
          }),
        );
      })
      .catch(e => {
        const errorType = e.error_type ?? '';
        dispatch(setNetworkError(errorType));
        dispatch(setIsErrorBannerVisible(true));
        console.error(`Failed to restore translation for step ${step_id}`);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

export const restoreConfigurationTranslation =
  (
    configuration_id: string,
    language_code: string,
    index: number,
    source_text: string | null,
    intent_id: string | null,
    configuration_path: string | null,
    brandId: string | null,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));
    return restoreConfigurationTranslationAPI(
      configuration_id,
      language_code,
      source_text,
      intent_id,
      configuration_path,
      brandId,
    )
      .then((data: ConfigurationTranslation) => {
        dispatch(
          getRestoreTranslationSuccess({
            index: index,
            translation: data,
          }),
        );
      })
      .catch(e => {
        const errorType = e.error_type ?? '';
        dispatch(setNetworkError(errorType));
        dispatch(setIsErrorBannerVisible(true));
        console.error(
          `Failed to restore translation for configuration ${configuration_id}`,
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getConfigurationTranslationsForOrg =
  (data: GetConfigurationTranslationsForOrgRequest) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setIsConfigTranslationsLoading(true));

    return getConfigurationTranslationsForOrgAPI(data)
      .then(data => {
        dispatch(getConfigurationTranslationsForOrgSuccess(data));
      })
      .catch(() => {
        console.error(
          `Failed to fetch step translations for language ${data.language_code}`,
        );
      })
      .finally(() => {
        dispatch(setIsConfigTranslationsLoading(false));
      });
  };

export const overrideConfigurationTranslations =
  (
    overrideConfigurationRequests: OverrideConfigurationTranslationRequest[],
    language_code: string,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));

    return overrideConfigurationTranslationsAPI(
      overrideConfigurationRequests,
      language_code,
    )
      .then((data: GetConfigurationTranslationsForOrgResponse) => {
        dispatch(getConfigurationTranslationsForOrgSuccess(data));
      })
      .catch(e => {
        const errorType = e.error_type ?? '';
        dispatch(setNetworkError(errorType));
        dispatch(setIsErrorBannerVisible(true));
        console.error('Failed to override translations');
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getStepTranslationsForOrg =
  (data: GetStepTranslationsForOrgRequest) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setIsStepTranslationsLoading(true));

    return getStepTranslationsForOrgAPI(data)
      .then(data => {
        dispatch(getStepTranslationsForOrgSuccess(data));
      })
      .catch(() => {
        console.error(
          `Failed to fetch step translations for language ${data.language_code}`,
        );
      })
      .finally(() => {
        dispatch(setIsStepTranslationsLoading(false));
      });
  };

export const getStepTranslationsForStep =
  (data: GetStepTranslationsForStepRequest) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setIsStepTranslationsLoading(true));

    return getStepTranslationsForStepAPI(data)
      .then(data => {
        dispatch(getStepTranslationsForStepSuccess(data));
      })
      .catch(() => {
        console.error(
          `Failed to fetch step translations for language ${data.language_code}, workflow ${data.intent_workflow_id}, step ${data.step_id}`,
        );
      })
      .finally(() => {
        dispatch(setIsStepTranslationsLoading(false));
      });
  };

export const loadUsableContextVariablesForTranslation =
  (intentWorkflowId: string, stepId: string, version: number) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const data = await getContextVariablesStepApi(
        intentWorkflowId,
        version,
        stepId,
      );
      dispatch(getUsableContextVariablesForTranslationSuccess(data));
    } catch {
      console.error(
        `Failed to fetch usable context variables for ${intentWorkflowId}, ${stepId}`,
      );
    }
  };

export const getWorkflowBuilderSuggestedIntents =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    return getWorkflowBuilderSuggestedIntentsAPI()
      .then(data => {
        dispatch(getWorkflowBuilderSuggestedIntentsSuccess(data));
      })
      .catch(() => {
        console.error('An error has ocurred');
      });
  };

export const getWidgetApiKey =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const data = await getWidgetApiKeyAPI();
      dispatch(getWidgetApiKeySuccess(data.api_key));
    } catch (e) {
      console.error(`Failed to fetch widget api key, error: ${e}`);
    }
  };

export const refreshWidgetApiKey =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const data = await refreshWidgetApiKeyAPI();
      dispatch(getWidgetApiKeySuccess(data.api_key));
    } catch (e) {
      console.error(`Failed to refresh widget api key, error: ${e}`);
    }
  };

export const getModelTrainingData =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    return getModelTrainingAPI()
      .then(data => {
        dispatch(getIsModelTraining(data.is_model_training));
      })
      .catch(() => {
        console.error('An error has occurred');
      });
  };
