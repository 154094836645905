import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  FilterButton,
  SearchBar,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { actionFilterOptions, routeFilterOptions } from '../../constants';
import {
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useGetBuilderQueryParams,
  useGetIsTemplateIntentWorkflow,
} from '../../hooks';
import {
  sortContextVariables,
  sortTools,
} from '../../workflow-builder/business-logic/helpers';
import { GenericToolList } from '../components/GenericToolList';
import { useGetUsableTools } from '../hooks';
import { CVItem } from './CVItem';
import { ToolItem } from './ToolItem';
import groupBy from 'lodash/fp/groupBy';
import filterIcon from 'src/assets/images/action-type-filter.svg';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  useAddAutoflowToolMutation,
  useDeleteAutoflowToolMutation,
  usePatchAutoflowCVsMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  emptyIntent,
  openGlobalIntentDrawer,
  setGlobalWorkflowBuilderOptions,
  setIntentToEdit,
} from 'src/slices/ui/uiSlice';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';
import { replaceActionIdInRoute } from 'src/utils/actionBuilder/helpers';

const DEFAULT_TOOL_NUM_TO_SHOW = 3;

const useGetCVS = (
  intentWorkflowId: string,
): (ContextVariable & { isActive: boolean })[] => {
  const { autoflowWorkflow } =
    useGetAutoflowWorkflowOrAutoflowTemplate(intentWorkflowId);
  const isTemplate = useGetIsTemplateIntentWorkflow();

  const { context_variables: contextVariablesInUse } = autoflowWorkflow ?? {};

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
    shouldIncludeTemplateContextVariables: isTemplate,
  });

  const cvs = useMemo(() => {
    // filter out conversation language
    const filteredWorkflowCvs = contextVariables.filter(
      cv => cv.context_variable_name !== 'Conversation Language',
    );
    return filteredWorkflowCvs.map(workflowCv => ({
      ...workflowCv,
      isActive: Boolean(
        contextVariablesInUse?.find(
          cvInUse => cvInUse === workflowCv.context_variable_id,
        ),
      ),
    }));
  }, [contextVariables, contextVariablesInUse]);

  return cvs;
};

interface ActionDrawerProps {
  intentWorkflowId: string;
  setEditToolId: (toolId: string) => void;
}

export const ActionDrawer = ({
  intentWorkflowId,
  setEditToolId,
}: ActionDrawerProps) => {
  const cvs = useGetCVS(intentWorkflowId);

  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { view } = useGetBuilderQueryParams();
  const { data } = useGetIntentsQueryWithProduct(
    undefined,
    view === 'email' ? { productOverride: 'interactive_email' } : undefined,
  );
  const { intents: allIntents = [] } = data ?? {};
  const { isLoading, tools } = useGetUsableTools();
  const isTemplate = useGetIsTemplateIntentWorkflow();

  const navigate = useNavigate();

  const [addAutoflowTool] = useAddAutoflowToolMutation();
  const [deleteAutoflowTool] = useDeleteAutoflowToolMutation();
  const [patchContextVariable] = usePatchAutoflowCVsMutation();

  const [isIntentRoutingsExpanded, setIsIntentRoutingsExpanded] =
    useState(false);
  const [searchRoutesText, setSearchRoutesText] = useState('');
  const [searchActionsText, setSearchActionsText] = useState('');
  const [searchCvsText, setSearchCvsText] = useState('');
  const [routeFilter, setRouteFilter] = useState('All');
  const [actionFilter, setActionFilter] = useState('All');

  const lowerCaseSearchRoutesText = searchRoutesText.toLowerCase();
  const lowerCaseSearchActionsText = searchActionsText.toLowerCase();
  const lowerCaseSearchCvsText = searchCvsText.toLowerCase();

  const groups = groupBy('tool_type', [
    ...tools.sort((a, b) => Number(b.isActive) - Number(a.isActive)),
  ]);

  const apiTools = groups['api_call'] ?? [];
  const templateApiTools = groups['template_api_call'] ?? [];
  const systemTools = groups['demo'] ?? [];
  const systemBuiltInTools = groups['system_built_in'] ?? [];
  const handoffTools = (groups['hand_off'] ?? []).sort(sortTools);
  const intentRoutingTools = (groups['intent_routing'] ?? []).sort(sortTools);

  const nonHandoffTools = [
    ...apiTools,
    ...systemTools,
    ...templateApiTools,
    ...systemBuiltInTools,
  ]
    .sort(sortTools)
    .filter(({ tool_name: toolName }) => {
      return toolName.toLowerCase().includes(lowerCaseSearchActionsText);
    })
    .filter(
      ({ tool_type: toolType }) =>
        actionFilter === 'All' ||
        (actionFilter === 'API' && toolType === 'api_call') ||
        (actionFilter === 'Other' && toolType !== 'api_call'),
    );

  const workflowTools = [...intentRoutingTools, ...handoffTools]
    .sort(sortTools)
    .map(intent => {
      const fullIntentDetails = allIntents.find(
        ({ intent_definition_id: intentDefinitionId }) =>
          intentDefinitionId === intent.tool_id,
      );

      return {
        ...intent,
        is_autoflow: fullIntentDetails?.is_autoflow || false,
      };
    })
    .filter(({ tool_name: toolName }) => {
      return toolName.toLowerCase().includes(lowerCaseSearchRoutesText);
    })
    .filter(
      ({ is_autoflow: isAutoflow, tool_type: toolType }) =>
        routeFilter === 'All' ||
        (routeFilter === 'Handoff Workflow' && toolType === 'hand_off') ||
        (routeFilter === 'Workflow - Classic' &&
          toolType !== 'hand_off' &&
          !isAutoflow) ||
        (routeFilter === 'Workflow - AutoFlow' &&
          toolType !== 'hand_off' &&
          isAutoflow),
    );

  const contextVariablesToDisplay = (
    isTemplate ? cvs.filter(cv => cv.isActive) : cvs
  )
    .sort(sortContextVariables)
    .filter(({ context_variable_name: contextVariableName }) => {
      return contextVariableName.toLowerCase().includes(lowerCaseSearchCvsText);
    });

  const intentRoutingsToRender = workflowTools;

  const numOfActiveActions = nonHandoffTools.filter(
    tool => tool.isActive,
  ).length;
  const numOfActiveCvs = cvs.filter(cv => cv.isActive).length;
  const activeIntentRoutingTool = workflowTools.find(tool => tool.isActive);

  const hasAnyNonHandoffTools = Boolean(nonHandoffTools.length);
  const hasAnyCvs = Boolean(cvs.length);
  const hasAnyIntentRoutingTools = Boolean(workflowTools.length);

  const getNonHandoffToolMessage = () => {
    if (isTemplate) {
      return 'Action in use for this template';
    }

    if (numOfActiveActions) {
      return `${numOfActiveActions} Action${
        numOfActiveActions > 1 ? 's' : ''
      } applied`;
    }

    if (hasAnyNonHandoffTools) {
      return 'You can select multiple actions to apply';
    }

    return "No actions created yet. Let's create one";
  };

  const getCvMessage = () => {
    if (numOfActiveCvs) {
      return `${numOfActiveCvs} Pre-defined context variable${
        numOfActiveCvs > 1 ? 's' : ''
      } applied`;
    }

    if (hasAnyCvs) {
      return 'You can select pre-defined context variables to apply';
    }

    return "No context variable created yet. Let's create one";
  };

  const getIntentRoutingToolMessage = () => {
    if (Boolean(activeIntentRoutingTool)) {
      return 'Redirect to other ‘Live’ workflows applied';
    }

    if (hasAnyIntentRoutingTools) {
      return 'You can select workflows to apply';
    }

    return "No workflows created yet. Let's create one";
  };

  const applyLoadingStateToMessages = (getMessage: () => string) => {
    if (isLoading) {
      return <Skeleton />;
    }

    return getMessage();
  };

  const onClickTool = async (tool: AutoflowTool & { isActive: boolean }) => {
    if (tool.tool_type === 'system_built_in' && !tool.isActive) {
      setEditToolId(tool.tool_id);
      return;
    }

    if (isTemplate) {
      return;
    }

    // if clicked tool is active, deactivate it
    if (tool.isActive) {
      deleteAutoflowTool({
        body: { tool_id: tool.tool_id },
        intentWorkflowId,
      });
      return;
    }

    const toolData = {
      body: { tool_id: tool.tool_id, tool_type: tool.tool_type },
      intentWorkflowId,
    };

    addAutoflowTool(toolData);
  };

  const onClickCV = (cv: ContextVariable & { isActive: boolean }) => {
    const cvData = {
      body: { context_variable_id: cv.context_variable_id },
      intentWorkflowId,
    };

    patchContextVariable(cvData);
  };

  return (
    <>
      {(!isTemplate || Boolean(nonHandoffTools.length)) && (
        <GenericToolList
          actionButton={
            !isTemplate && (
              <Button
                fullWidth
                onClick={() => {
                  navigate(replaceActionIdInRoute(''));
                }}
                startIcon={<IconPlus />}
                variant='secondary'
              >
                <Typography variant='font14Bold'>Create new Action</Typography>
              </Button>
            )
          }
          description={applyLoadingStateToMessages(getNonHandoffToolMessage)}
          filterOptions={actionFilterOptions}
          filterValue={actionFilter}
          idKey='tool_id'
          isLoading={isLoading}
          items={nonHandoffTools}
          loadingElement={<LoadingState />}
          onFilterChange={newValue => setActionFilter(newValue)}
          onSearchChange={value => setSearchActionsText(value)}
          renderItem={tool => (
            <ToolItem
              key={tool.tool_id}
              onClickTool={onClickTool}
              setEditToolId={setEditToolId}
              tool={tool}
            />
          )}
          searchValue={searchActionsText}
          showFilter={true}
          title='Actions'
        />
      )}
      {(!isTemplate || Boolean(intentRoutingsToRender.length)) && (
        <Section>
          <StickySectionHeader>
            <Typography variant='font18Bold'>Routes</Typography>
          </StickySectionHeader>
          <StickySectionNote mb={1}>
            <Typography color={palette.colors.grey[500]} variant='font14'>
              {applyLoadingStateToMessages(getIntentRoutingToolMessage)}
            </Typography>
          </StickySectionNote>
          {!isTemplate && (
            <Box pb={1} width='100%'>
              <Button
                fullWidth
                onClick={() => {
                  dispatch(setIntentToEdit(emptyIntent));
                  dispatch(openGlobalIntentDrawer({ type: 'landing' }));
                }}
                startIcon={<IconPlus />}
                variant='secondary'
              >
                <Typography variant='font14Bold'>
                  Create new Workflow
                </Typography>
              </Button>
            </Box>
          )}
          <Box
            alignItems='center'
            display='flex'
            gap={2}
            justifyContent='space-between'
            my={2}
            width={'100%'}
          >
            <Box width={'100%'}>
              <SearchBar
                aria-label='Routes search bar'
                onChange={e => setSearchRoutesText(e.target.value)}
                placeholder='Search'
                value={searchRoutesText}
              />
            </Box>
            <Box>
              <FilterButton
                aria-label='Route type'
                initialValue='All'
                onChange={newValue => {
                  setRouteFilter(newValue);
                }}
                options={routeFilterOptions}
                startAdornment={<img alt='' src={filterIcon} />}
                value={routeFilter}
              />
            </Box>
          </Box>
          <ToolsSection>
            {isLoading ? (
              <LoadingState />
            ) : (
              intentRoutingsToRender.map(tool => (
                <ToolItem
                  badgeText={
                    tool.tool_type === 'hand_off'
                      ? 'Handoff Workflow'
                      : `Workflow - ${
                          tool.is_autoflow ? 'AutoFlow' : 'Classic'
                        }`
                  }
                  key={tool.tool_id}
                  onClickTool={onClickTool}
                  setEditToolId={setEditToolId}
                  tool={tool}
                />
              ))
            )}
            {intentRoutingTools.length > DEFAULT_TOOL_NUM_TO_SHOW && (
              <Box pt={1}>
                <Typography variant='font14Bold'>
                  <ClickableLink
                    onClick={() => setIsIntentRoutingsExpanded(prev => !prev)}
                  >
                    See {isIntentRoutingsExpanded ? 'less' : 'more'}{' '}
                    &apos;Live&apos; workflows
                  </ClickableLink>
                </Typography>
              </Box>
            )}
          </ToolsSection>
        </Section>
      )}
      {(!isTemplate || Boolean(contextVariablesToDisplay.length)) && (
        <GenericToolList
          actionButton={
            !isTemplate && (
              <Button
                fullWidth
                onClick={() => {
                  dispatch(
                    setGlobalWorkflowBuilderOptions({ isCvDrawerOpen: true }),
                  );
                }}
                startIcon={<IconPlus />}
                variant='secondary'
              >
                <Typography variant='font14Bold'>Create new CV</Typography>
              </Button>
            )
          }
          description={applyLoadingStateToMessages(getCvMessage)}
          idKey='context_variable_id'
          isLoading={isLoading}
          items={contextVariablesToDisplay}
          loadingElement={<LoadingState />}
          onSearchChange={value => setSearchCvsText(value)}
          renderItem={cv => (
            <CVItem
              cv={cv}
              isTemplate={isTemplate}
              onClick={() => onClickCV(cv)}
            />
          )}
          searchValue={searchCvsText}
          title='Context Variables'
        />
      )}
    </>
  );
};

const LoadingState = () => {
  const elements = Array(DEFAULT_TOOL_NUM_TO_SHOW)
    .fill(null)
    .map((_, index) => <Skeleton height='54px' key={index} />);

  return <>{elements}</>;
};

const Section = styled('section')`
  width: 100%;
`;

const StickySectionHeader = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  margin-bottom: 8px;
`;

const StickySectionNote = styled(Box)`
  background-color: ${props => props.theme.palette.colors.white};
`;

const ToolsSection = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-bottom: 24px;
`;

const ClickableLink = styled('button')`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  padding: 0;
`;

export default ActionDrawer;
