import { FormEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { numbersToStringWithCommas } from '../solve-insights/helpers';
import { validateAutogenFilters } from '../triage-models-overview-page/helpers';
import { CreateModelFormValue } from '../triage-models-overview-page/types';
import { addLabelErrorMessages, REQUIRED_TRAINING_PHRASES } from './constants';
import {
  IntegrationChangeParams,
  OriginItem,
  TagFormValue,
  TriageModelLabelOverview,
} from './types';
import {
  HelpdeskResponseV2,
  TagDefinitionResponse,
  TagPhrase,
  TriageAutoGenStatus,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';

export const isModelReadOnly = (model?: VersionedTriageModel) =>
  Boolean(model?.is_published);

export const hasComponent = <T extends string>(
  condition: T | undefined,
  component: React.ReactNode,
) => Boolean(condition) && component;

export const convertFormPhrasesToTrainingPhrases = (
  thisLabelsPhrases: Array<string | undefined>,
  tagId: string,
  existingPhrases: TagPhrase[],
): TagPhrase[] => {
  const otherTagPhrases = existingPhrases.filter(
    phrase => phrase.tag_id !== tagId,
  );
  const onlyPopulatedNewPhrases = thisLabelsPhrases.filter(Boolean) as string[];
  const newPhrases = onlyPopulatedNewPhrases.map(phrase => {
    const existingPhrase = existingPhrases.find(
      existingPhrase =>
        existingPhrase.tag_id === tagId &&
        existingPhrase.phrase_text === phrase,
    );
    if (existingPhrase) {
      return {
        phrase_id: existingPhrase.phrase_id,
        phrase_text: phrase,
        tag_id: tagId,
      };
    } else {
      return {
        phrase_id: uuidv4(),
        phrase_text: phrase,
        tag_id: tagId,
      };
    }
  });
  return [...newPhrases, ...otherTagPhrases];
};
export const numToString = (value: number) =>
  numbersToStringWithCommas({ number: value });

export const numToPercentage = (value: number | null) =>
  value === null ? null : `${Math.round(value * 100.0)}%`;

export const getTagFormValuesFromModel = (
  model: VersionedTriageModel,
  tagId: string | null,
): TagFormValue | undefined => {
  if (!tagId) {
    return undefined;
  }
  const label = model.labels.find(label => label.tag_id === tagId);
  if (!label) {
    return undefined;
  }
  const phrases = model.phrases.filter(phrase => phrase.tag_id === tagId);
  const trainingPhrases = phrases.map(phrase => phrase.phrase_text);
  return {
    addTrainingPhrase: '',
    description: label.description,
    name: label.title,
    trainingPhrases,
  };
};

export const mergeLabelIntoExistingLabels = (
  newLabel: TagDefinitionResponse,
  existingLabels: TagDefinitionResponse[],
): TagDefinitionResponse[] => {
  const newLabelList = [...existingLabels];
  const existingLabelIndex = existingLabels.findIndex(
    existingLabel => existingLabel.tag_id === newLabel.tag_id,
  );
  if (existingLabelIndex !== -1) {
    newLabelList[existingLabelIndex] = newLabel;
    return newLabelList;
  } else {
    return [...newLabelList, newLabel];
  }
};

export const convertFormTagToTagDefinition = (
  title: string,
  description: string,
  existingLabels: TagDefinitionResponse[],
  modelName: string,
  tagId: string | null,
  model: VersionedTriageModel,
): TagDefinitionResponse => {
  const existingLabel = existingLabels.find(
    existingLabel => existingLabel.tag_id === tagId,
  );
  const valueMapping = model.model.model_output_formatter.value_mapping;
  if (existingLabel) {
    const mappedValue = valueMapping
      ? (valueMapping[existingLabel.title] as string)
      : '';
    return {
      description: description,
      is_active: existingLabel.is_active,
      model_name: modelName,
      output_field_value: mappedValue || existingLabel.output_field_value,
      tag_id: existingLabel.tag_id,
      title: title,
    };
  } else {
    return {
      description: description,
      is_active: true,
      model_name: modelName,
      output_field_value: null,
      tag_id: uuidv4(),
      title: title,
    };
  }
};

export const deriveNewModelFromIntegrationChange = (
  updateData: IntegrationChangeParams,
  model: VersionedTriageModel,
  helpdeskData: HelpdeskResponseV2,
): {
  accuracy_method: string;
  default_label: string | null;
  ground_truth_field: { id: string; name: string } | null;
  helpdesk_data_source: string;
  model_name: string;
  output_field_config:
    | {
        case_origins?: OriginItem[];
        is_create_new_field: boolean;
        new_output_field: { id: string | null; name: string } | null;
      }
    | {
        case_origins?: OriginItem[];
      }
    | null;
  value_mapping: Record<string, string | boolean>;
} => {
  const currentHelpdesk = helpdeskData.helpdesk;
  const currentModel = model.model;

  const outputFieldConfig = updateData.isNewField
    ? {
        is_create_new_field: true,
        new_output_field: {
          id: updateData.select || '',
          name: updateData.input || '',
        },
        ...(currentHelpdesk === 'salesforce' && {
          case_origins: updateData.origins || [],
        }),
      }
    : updateData.select
    ? {
        is_create_new_field: false,
        new_output_field: {
          id: updateData.select,
          name: updateData.select,
        },
        ...(currentHelpdesk === 'salesforce' && {
          case_origins: updateData.origins || [],
        }),
      }
    : updateData.predictedFieldValueName &&
      (updateData.select || updateData.input)
    ? {
        is_create_new_field: false,
        new_output_field: {
          id: updateData.predictedFieldValueName,
          name: updateData.predictedFieldValueName,
        },
        ...(currentHelpdesk === 'salesforce' && {
          case_origins: updateData.origins || [],
        }),
      }
    : {
        is_create_new_field: false,
        new_output_field: null,
        ...(currentHelpdesk === 'salesforce' && {
          case_origins: updateData.origins || [],
        }),
      };

  // Determine the ground truth field
  const groundTruthField = updateData.isNewField
    ? {
        id: updateData.select || '',
        name: updateData.input || '',
      }
    : updateData.groundTruthValue
    ? {
        id: updateData.groundTruthValue,
        name: updateData.groundTruthValue,
      }
    : null;

  // Ensure value_mapping is always a valid object
  const valueMapping = currentModel.model_output_formatter?.value_mapping || {};

  return {
    accuracy_method: updateData.accuracyCheckValue || 'exact_match',
    default_label: updateData.defaultLabel || null,
    ground_truth_field: groundTruthField,
    helpdesk_data_source: currentHelpdesk,
    model_name: currentModel.model.name,
    output_field_config: outputFieldConfig,
    value_mapping: valueMapping,
  };
};

export const createSaveLabelTooltip = ({
  isModelEditable,
  trainingPhrases,
}: {
  isModelEditable: boolean;
  trainingPhrases: string[];
}) => {
  if (!isModelEditable) {
    return 'Labels cannot be edited for a published version.';
  }
  if (trainingPhrases.length < REQUIRED_TRAINING_PHRASES) {
    return addLabelErrorMessages.trainingPhrases;
  }
  return '';
};

export const addPercentageValue = (
  value: number,
  overview?: TriageModelLabelOverview,
) => {
  const percentageValue =
    value && overview?.eligible_count ? value / overview.eligible_count : null;
  const percentage = percentageValue
    ? `(${(percentageValue * 100).toFixed(2)}%)`
    : '';

  return `${numToString(value)} ${percentage}`;
};

export const createAutogenFilters = (
  autogenData?: VersionedTriageModel['autogeneration_data'],
) => {
  if (!autogenData) {
    return [];
  }
  return autogenData.filters.map(filter => {
    return {
      booleanOperator: autogenData.filter_operator,
      expression: {
        ...filter,
        expression_type: '',
      },
    };
  });
};

export const modelFormValidator = (values: CreateModelFormValue) => {
  const errors: Partial<Omit<CreateModelFormValue, 'filters'>> & {
    filters?: string;
  } = {};

  if (values.isManual === false && !values.selectedTicket) {
    errors.selectedTicket = 'Ticket is required';
  }

  if (values.isManual === false && values.filters.length) {
    const isValid = validateAutogenFilters({
      filters: values.filters,
    });
    if (!isValid) {
      errors.filters = 'Field values are required';
    }
  }

  if (values.isManual === false && values.filters.length) {
    const isValid = validateAutogenFilters({
      filters: values.filters,
    });
    if (!isValid) {
      errors.filters = 'Field values are required';
    }
  }
  if (!values.displayName.trim()) {
    errors.displayName = 'Model name is required';
  }

  if (!values.description.trim()) {
    errors.description = 'Model description is required';
  }

  return errors;
};

export const hasModelInfoChanged = (
  values: CreateModelFormValue,
  initialFormValues: Partial<CreateModelFormValue>,
) => {
  return (
    values.displayName !== initialFormValues.displayName ||
    values.description !== initialFormValues.description
  );
};

export const invalidFormElementSubmitted = (
  event: FormEvent<HTMLFormElement>,
) => {
  return (event.target as Element).classList.contains('MuiPaper-root');
};

export const verifyIsAutogen = (status: TriageAutoGenStatus) => {
  if (!status) {
    return false;
  }
  const autogenStatuses = ['generating', 'collecting_data', 'errored'];
  return autogenStatuses.includes(status);
};
