import { labelInfoMap } from '../../triage-model-detail-page/constants';
import { SankeyOverviewData } from 'src/components/sankey-diagram-section/types';
import {
  LineGraphDataType,
  LineGraphValueData,
} from 'src/services/insights/types';
import {
  TriageInsightModelMetricsResponse,
  TriageInsightsModelOverviewResponse,
} from 'src/services/triage/types';

export const createSankeyData = (
  metricsData: TriageInsightsModelOverviewResponse['overview'] | undefined,
) => {
  if (!metricsData) {
    return [];
  }

  return [
    {
      from: 'Total tickets',
      to: 'Eligible',
      weight: metricsData.eligible_count,
    },
    {
      from: 'Total tickets',
      negative: true,
      to: 'Ineligible',
      weight: metricsData.ineligible_count,
    },
    {
      from: 'Eligible',
      to: 'Predicted',
      weight: metricsData.prediction_count,
    },
    {
      from: 'Eligible',
      negative: true,
      to: 'Non-predicted',
      weight: metricsData.non_predicted_count,
    },
    {
      from: 'Predicted',
      to: 'Finalized',
      weight: metricsData.finalized_count,
    },
    {
      color: '#7b91b1',
      from: 'Predicted',
      id: 'Pending',
      to: 'Pending',
      transitionColor: ['#A66AFECC', '#7b91b1'],
      weight: metricsData.pending_count,
    },
    {
      from: 'Finalized',
      to: 'Correct',
      weight: metricsData.correct_count,
    },
    {
      from: 'Finalized',
      negative: true,
      to: 'Inorrect',
      weight: metricsData.incorrect_count,
    },

    {
      from: 'Correct',
      to: 'Correct predictions',
      weight: 0,
    },
    {
      from: 'Incorrect',
      to: 'Incorrect predictions',
      weight: 0,
    },
  ];
};

const add = (a: number | null, b: number | null) => {
  if (a === null || b === null) {
    return 0;
  }

  return a + b;
};

export const createOverviewMetrics = ({
  data,
}: {
  data: TriageInsightsModelOverviewResponse['overview'] | undefined;
  isLoading: boolean;
}): SankeyOverviewData[] => {
  const totalTickets = data
    ? add(data.eligible_count, data.ineligible_count)
    : 0;
  const eligibleTickets = data
    ? add(data.prediction_count, data.non_predicted_count)
    : 0;
  const predictedTickets = data
    ? add(data.finalized_count, data.pending_count)
    : 0;
  const finalizedTickets = data
    ? add(data.correct_count, data.incorrect_count)
    : 0;

  return [
    {
      items: [
        {
          title: 'Eligible',
          value: data?.eligible_count,
        },
        {
          title: 'Ineligible',
          value: data?.ineligible_count,
        },
      ],
      metricType: 'count',
      title: 'Total tickets',
      tooltip: labelInfoMap.total_count.tooltip,
      value: {
        count: totalTickets,
      },
    },
    {
      items: [
        {
          title: 'Predicted',
          value: data?.prediction_count,
        },
        {
          title: 'Non-predicted',
          value: data?.non_predicted_count,
        },
      ],
      metricType: 'count',
      title: 'Eligible tickets',
      tooltip: labelInfoMap.prediction_count.tooltip,
      value: {
        compareTo: totalTickets,
        count: eligibleTickets,
        text: 'total',
      },
    },
    {
      items: [
        {
          title: 'Finalized',
          value: data?.finalized_count,
        },
        {
          title: 'Pending',
          value: data?.pending_count,
        },
      ],
      metricType: 'count',
      title: 'Predicted tickets',
      tooltip: labelInfoMap.prediction_count.tooltip,
      value: {
        compareTo: eligibleTickets,
        count: predictedTickets,
        text: 'eligible',
      },
    },
    {
      items: [
        {
          title: 'Correct',
          value: data?.correct_count,
        },
        {
          title: 'Incorrect',
          value: data?.incorrect_count,
        },
      ],
      metricType: 'count',
      title: 'Finalized tickets',
      tooltip: labelInfoMap.prediction_count.tooltip,
      value: {
        compareTo: predictedTickets,
        count: finalizedTickets,
        text: 'predicted',
      },
    },
    {
      metricType: 'count',
      title: 'Correct predictions',
      tooltip: labelInfoMap.correct_count.tooltip,
      value: {
        compareTo: finalizedTickets,
        count: data?.correct_count,
        suffix: data?.correct_count
          ? `(${((data?.correct_count / finalizedTickets) * 100).toFixed(2)}%)`
          : '',
        text: 'finalized',
      },
    },
  ];
};

// TODO: to be removed. for now, this is just a
// reference implementation for the
// overview metrics
export const _createOverviewMetrics = (
  metricsData: TriageInsightModelMetricsResponse | undefined,
  loading: boolean,
) =>
  [
    {
      metricType: 'count',
      title: 'Total tickets',
      tooltip: labelInfoMap.total_count.tooltip,
      value: metricsData?.total,
    },
    {
      metricType: 'count',
      title: 'Eligible tickets',
      tooltip: labelInfoMap.eligible_count.tooltip,
      value: metricsData?.eligible,
    },
    {
      metricType: 'count',
      title: 'Predicted tickets',
      tooltip: labelInfoMap.prediction_count.tooltip,
      value: metricsData?.predicted,
    },
    {
      metricType: 'count',
      title: 'Pending',
      tooltip: labelInfoMap.pending_count.tooltip,
      value: metricsData?.pending,
    },
    {
      metricType: 'count',
      title: 'Finalized tickets',
      tooltip: labelInfoMap.finalized_count.tooltip,
      value: metricsData?.finalized,
    },
    {
      metricType: 'count',
      title: 'Correct predictions',
      tooltip: labelInfoMap.correct_count.tooltip,
      value: metricsData?.correct,
    },
    /**
    {
      metricType: 'percentage',
      title: 'Accuracy',
      tooltip: labelInfoMap.avg_accuracy.tooltip,
      value: metricsData?.,
    },
    {
      metricType: 'count',
      title: '# on active classifiers',
      value: metricsData?.active_models_count,
    },
    {
      metricType: 'cost',
      title: 'Realized savings',
      tooltip:
        'The cost savings realized by using Triage to predict labels for tickets.',
      value: metricsData?.realized_cost_savings,
    },
     */
  ].map(item => ({ ...item, loading }));

export const createLineChartData = ({
  enabledLegends,
  metricChartData,
  ticketCountMetrics,
}: {
  enabledLegends: string[];
  metricChartData?: LineGraphDataType;
  ticketCountMetrics?: LineGraphValueData;
}) => {
  const isTotalTicketsEnabled = enabledLegends.includes('Total tickets');
  if (!metricChartData) {
    return;
  }
  const metricChartDataValues = metricChartData.values.map(item => {
    if ((item.label as string) === 'Accuracy') {
      return {
        ...item,
        yAxis: 1,
      };
    }
    return {
      ...item,
      yAxis: 0,
    };
  });
  if (!isTotalTicketsEnabled) {
    return {
      ...metricChartData,
      values: [
        { label: 'Total tickets', value: [], yAxis: 0 },
        ...metricChartDataValues,
      ],
    };
  }
  return {
    ...metricChartData,
    values: [
      {
        label: 'Total tickets',
        value: ticketCountMetrics?.value || [],
        yAxis: 0,
      },
      ...metricChartDataValues,
    ],
  };
};
