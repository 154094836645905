import React, { type FC } from 'react';
import { styled } from '@mui/material';
import {
  CenterAlignButton,
  CommandButtonGroup,
  LeftAlignButton,
  RightAlignButton,
  ToggleBoldButton,
  ToggleBulletListButton,
  ToggleItalicButton,
  ToggleOrderedListButton,
  ToggleUnderlineButton,
  Toolbar,
  useCurrentSelection,
  VerticalDivider,
} from '@remirror/react';
import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconItalic,
  IconList,
  IconListNumbers,
  IconUnderline,
} from '@tabler/icons-react';

import GenerativeOptionsDropdown from '../generative-options-dropdown/GenerativeOptionsDropdown';
import { buttonStyles } from './helpers/buttonStyles';
import ContextVariableTool from './ContextVariableTool';
import { EmojiButton } from './EmojiButton';
import HeadingLevelTool from './HeadingLevelTool';
import { type MentionComponentProps } from './types';
import { GenerateResponseActionType } from 'src/types/workflowBuilderAPITypes';

export const TopToolbar: FC<
  React.PropsWithChildren<{
    contextVariables: MentionComponentProps['contextVariables'] | null;
    disabled: boolean;
    includeGenerateResponseDropdown?: boolean;
    onGenerativeOptionsDropdownChange?: (
      value: GenerateResponseActionType,
    ) => void;
    withAlignmentExtension: boolean;
    withEmojiButton: boolean;
    withHeadingExtension: boolean;
    withListButtons: boolean;
    withUnderLineButton: boolean;
  }>
> = ({
  contextVariables,
  disabled,
  includeGenerateResponseDropdown = false,
  onGenerativeOptionsDropdownChange,
  withAlignmentExtension,
  withEmojiButton,
  withHeadingExtension,
  withListButtons,
  withUnderLineButton,
}) => {
  const { empty } = useCurrentSelection();
  return (
    <StyledToolbar>
      {withHeadingExtension && (
        <>
          <CommandButtonGroup>
            <HeadingLevelTool />
          </CommandButtonGroup>
          <VerticalDivider />
        </>
      )}
      <CommandButtonGroup>
        <ToggleBoldButton
          disabled={disabled}
          icon={<IconBold size='18px' />}
          sx={buttonStyles}
        />
        <ToggleItalicButton
          disabled={disabled}
          icon={<IconItalic size='18px' />}
          sx={buttonStyles}
        />
        {withUnderLineButton && (
          <ToggleUnderlineButton
            disabled={disabled}
            icon={<IconUnderline size='18px' />}
            sx={buttonStyles}
          />
        )}
      </CommandButtonGroup>
      {withListButtons && (
        <>
          <VerticalDivider />
          <CommandButtonGroup>
            <ToggleBulletListButton
              disabled={disabled}
              icon={<IconList size='18px' />}
              sx={buttonStyles}
            />
            <ToggleOrderedListButton
              disabled={disabled}
              icon={<IconListNumbers size='18px' />}
              sx={buttonStyles}
            />

            {withAlignmentExtension && (
              <>
                <LeftAlignButton
                  icon={<IconAlignLeft size='18px' />}
                  sx={buttonStyles}
                />
                <CenterAlignButton
                  icon={<IconAlignCenter size='18px' />}
                  sx={buttonStyles}
                />
                <RightAlignButton
                  icon={<IconAlignRight size='18px' />}
                  sx={buttonStyles}
                />
              </>
            )}
          </CommandButtonGroup>
        </>
      )}
      {contextVariables && contextVariables.length > 0 && (
        <>
          <VerticalDivider />
          <CommandButtonGroup>
            <ContextVariableTool
              contextVariables={contextVariables}
              disabled={disabled}
            />
          </CommandButtonGroup>
        </>
      )}
      {withEmojiButton && (
        <>
          <VerticalDivider />
          <EmojiButton disabled={disabled} />
        </>
      )}
      {includeGenerateResponseDropdown && (
        <>
          <VerticalDivider />
          <GenerativeOptionsDropdown
            isDisabled={empty}
            onChange={value =>
              onGenerativeOptionsDropdownChange &&
              onGenerativeOptionsDropdownChange(value)
            }
          />
        </>
      )}
    </StyledToolbar>
  );
};

const StyledToolbar = styled(Toolbar)`
  padding: 10px 10px 0;
  background-color: initial;
`;
